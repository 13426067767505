import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import Accordion from "../../../../ui/Accordion/Accordion";

import { categoriesTreeSelector } from "../../../../../store/productCategory";
import { itemIsLoadingSelector } from "../../../../../store/product";
import {
	childCategoriesSelector,
	parentCategoriesSelector,
	setChildCategories,
	setParentCategories,
} from "../../../../../store/productQuery";

import { keys } from "../../../../../locales/localeskeys";

import classes from "./Category.module.scss";

const Category = (props) => {
	const dispatch = useDispatch();

	const { i18n, t } = useTranslation();
	const lngId = i18n.language;

	const [searchParams, setSearchParams] = useSearchParams();
	const categorySearchParm = searchParams.get("categories") ?? "";

	const categoriesTree = useSelector(categoriesTreeSelector);
	const itemIsLoading = useSelector(itemIsLoadingSelector);
	const childChecked = useSelector(childCategoriesSelector);
	const parentChecked = useSelector(parentCategoriesSelector);

	const [checkedCount, setCheckedCount] = useState(0);

	const parentChange = (event) => {
		if (event.target.checked) {
			dispatch(
				setParentCategories({
					categoriesTree: categoriesTree,
					parentCategories: [...parentChecked, event.target.id],
				})
			);
		} else {
			dispatch(
				setParentCategories({
					categoriesTree: categoriesTree,
					parentCategories: parentChecked.filter((element) => element !== event.target.id),
				})
			);
		}
	};
	const childChange = (event) => {
		if (event.target.checked) {
			dispatch(
				setChildCategories({
					categoriesTree: categoriesTree,
					childCategories: [...childChecked, event.target.id],
				})
			);
		} else {
			dispatch(
				setChildCategories({
					categoriesTree: categoriesTree,
					childCategories: childChecked.filter((element) => element !== event.target.id),
				})
			);
		}
	};

	useEffect(() => {
		const parents = categoriesTree
			.filter((parent) => {
				return (
					parentChecked.some((el) => el === "p_" + parent.id) &&
					!parent.children?.some((child) => childChecked.some((el) => el === "c_" + child.id))
				);
			})
			.map((el) => el.children.map((child) => child.id))
			.flat();

		const categories = [...new Set([...parents, ...childChecked.map((el) => el.substring(2))])];

		if (categorySearchParm && categorySearchParm !== categories.toString()) {
			const categories = categorySearchParm.split(",").map((el) => "c_" + el);

			dispatch(
				setChildCategories({
					categoriesTree: categoriesTree,
					childCategories: categories.filter((el) => el.startsWith("c_")),
				})
			);
		}
	}, [categorySearchParm]);

	useEffect(() => {
		searchParams.set("page", 1);
		const parents = categoriesTree
			.filter((parent) => {
				return (
					parentChecked.some((el) => el === "p_" + parent.id) &&
					!parent.children?.some((child) => childChecked.some((el) => el === "c_" + child.id))
				);
			})
			.map((el) => el.children.map((child) => child.id))
			.flat();

		const categories = [...new Set([...parents, ...childChecked.map((el) => el.substring(2))])];
		if (categorySearchParm !== categories.toString()) {
			if (categories.length > 0) {
				searchParams.set("categories", categories.toString());
				setSearchParams(searchParams);
			} else {
				searchParams.delete("categories");
				setSearchParams(searchParams);
			}
		}
	}, [parentChecked, childChecked]);

	useEffect(() => {
		const totalCount = parentChecked.length + childChecked.length;
		setCheckedCount(totalCount);
	}, [parentChecked, childChecked]);

	return (
		<div className={classes.categoriesContainer}>
			<Accordion
				className={classes.accordionLayout}
				header={t(keys.PRODUCTS.CATEGORY)}
				headerClass={classes.header}
				iconClass={classes.icon}
				active={categorySearchParm}
				data={categoriesTree}
				checkedCount={checkedCount}
			>
				<div className={classes.list}>
					{categoriesTree &&
						categoriesTree
							.map((parent) => {
								return {
									id: "p_" + parent.id,
									label: lngId.includes("fr") ? parent.value_fr : parent.value_en,
									values: parent.children.map((child) => {
										return {
											id: "c_" + child.id,
											value: lngId.includes("fr") ? child.value_fr : child.value_en,
										};
									}),
								};
							})
							.map((parent) => (
								<div className={classes.layout} key={parent.id}>
									<div
										className={`${classes.element} ${classes.parent} ${
											parentChecked?.some((el) => el === parent.id) ? classes.checked : ""
										}`}
										key={parent.id}
									>
										<input
											type="checkbox"
											id={parent.id}
											name={parent.id}
											onChange={(event) => parentChange(event)}
											checked={parentChecked?.some((el) => el === parent.id)}
											disabled={itemIsLoading}
										/>
										<label htmlFor={parent.id}>{parent.label}</label>
									</div>
									{parent.values &&
										parent.values.length > 1 &&
										parent.values.map((child) => (
											<div
												className={`${classes.element} ${classes.child} ${
													childChecked?.some((el) => el === child.id) ? classes.checked : ""
												}`}
												key={child.id}
											>
												<input
													type="checkbox"
													id={child.id}
													name={child.id}
													onChange={(event) => childChange(event)}
													checked={childChecked?.some((el) => el === child.id)}
													disabled={itemIsLoading}
												/>
												<label htmlFor={child.id}>{child.value}</label>
											</div>
										))}
								</div>
							))}
				</div>
			</Accordion>
		</div>
	);
};

export default Category;
