import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { constants as constant } from "../locales/constant";

/**
 * Fournir les library d'architect
 */
export const getLibrary = createAsyncThunk("architect/getLibrary", async (_, { rejectWithValue }) => {
	try {
		const response = await fetch(constant.API_SERVER + "/library/");
		if (!response.ok) {
			throw new Error("fetching architect failed");
		}
		return await response.json();
	} catch (err) {
		return rejectWithValue(err.message, err);
	}
});

/**
 * Fournir les webinar d'architect
 */
export const getWebinar = createAsyncThunk("architect/getWebinar", async (_, { rejectWithValue }) => {
	try {
		const response = await fetch(constant.API_SERVER + "/event/?location=0");
		if (!response.ok) {
			throw new Error("fetching webinars failed");
		}
		return await response.json();
	} catch (err) {
		return rejectWithValue(err.message, err);
	}
});

/**
 * Fournir les visites d'architect
 */
export const getVisit = createAsyncThunk("architect/getVisit", async (_, { rejectWithValue }) => {
	try {
		const response = await fetch(constant.API_SERVER + "/event/?location=1");
		if (!response.ok) {
			throw new Error("fetching webinars failed");
		}
		return await response.json();
	} catch (err) {
		return rejectWithValue(err.message, err);
	}
});

/**
 * Fournir les evenement d'architect
 */
export const getEvents = createAsyncThunk("architect/getEvents", async (_, { rejectWithValue }) => {
	try {
		const response = await fetch(constant.API_SERVER + "/event/?location=3");
		if (!response.ok) {
			throw new Error("fetching webinars failed");
		}
		return await response.json();
	} catch (err) {
		return rejectWithValue(err.message, err);
	}
});

const initialState = {
	currentRequestId: undefined,
	error: undefined,
	loading: "done",
	categories: undefined,
	count: 0,
};

const architect = createSlice({
	name: "architect",
	initialState: initialState,
	reducers: {},
	extraReducers: {
		[getLibrary.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;

				state.categories = payload;
			}
		},
		[getLibrary.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[getLibrary.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
		[getWebinar.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;

				state.events = payload;
			}
		},
		[getWebinar.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[getWebinar.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
		[getVisit.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;

				state.visits = payload;
			}
		},
		[getVisit.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[getVisit.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
		[getEvents.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;

				state.events = payload;
			}
		},
		[getEvents.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[getEvents.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
	},
});

export default architect;
export const isLoadingSelector = (state) => state?.architect?.loading === "pending";
export const architectHasErrorSelector = (state) => !!state?.architect?.error && state.architect.error !== "";
export const categoriesSelector = (state) => state?.architect?.categories;
export const eventsSelector = (state) => state?.architect?.events;
export const visitsSelector = (state) => state?.architect?.visits;
