import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { constants as constant } from "../locales/constant";

/**
 * Fournir la liste des marque
 */
export const getBrands = createAsyncThunk("productBrand/getBrands", async (_, { rejectWithValue }) => {
	try {
		const response = await fetch(constant.API_SERVER + "/brand/");
		if (!response.ok) {
			throw new Error("fetching brands failed");
		}
		return await response.json();
	} catch (err) {
		return rejectWithValue(err.message, err);
	}
});

const productBrandAdapter = createEntityAdapter({});

const productBrand = createSlice({
	name: "productBrand",
	initialState: productBrandAdapter.getInitialState({
		loading: "done",
		currentRequestId: undefined,
		error: undefined,
	}),
	reducers: {},
	extraReducers: {
		[getBrands.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;

				productBrandAdapter.removeAll(state);
				productBrandAdapter.addMany(state, payload);
			}
			return state;
		},
		[getBrands.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[getBrands.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
	},
});

export default productBrand;
export const brandSelectors = productBrandAdapter.getSelectors((state) => state?.productBrand);
export const brandSelector = createSelector(brandSelectors.selectAll, (brands) => brands);
export const branssLoadingSelector = (state) => state?.productBrand?.loading === "pending";
