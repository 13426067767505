import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { Fragment, useEffect } from "react";
import { Icon } from "@iconify/react";

import Loading from "../../ui/loading/Loading";
import HeaderCarousel from "../../ui/HeaderCarousel/HeaderCarousel";
import ConstructionCarousel from "./ConstructionCarousel";
import Button from "../../ui/Button/Button";
import CustomNavLink from "../../ui/customNavLink/CustomNavLink";

import { constructionSubsegmentSelector, getSubsegment, isLoadingSelector } from "../../../store/subSegment";
import { constructionDiscoverProductSelector, ConstructionHeaderSelector } from "../../../store/mediaBase";
import { keys } from "../../../locales/localeskeys";

import classes from "./Construction.module.scss";
import useElementSize from "../../../hooks/useElementSize";
import Resources from "../Resources/Resources";

const Construction = () => {
	const dispatch = useDispatch();

	const { i18n, t } = useTranslation();
	const lngId = i18n.language;
	const search = `?lng=${lngId}`;
	const [gridRef, { width: gridWidth }] = useElementSize();

	const isLoading = useSelector(isLoadingSelector);
	const constructionSubsegments = useSelector(constructionSubsegmentSelector);
	const constructionHeaderMedia = useSelector(ConstructionHeaderSelector);
	const constructionDiscoverProduct = useSelector(constructionDiscoverProductSelector);

	useEffect(() => {
		window.scrollTo(0, 0);

		if (!isLoading && (!constructionSubsegments || constructionSubsegments.length === 0))
			dispatch(
				getSubsegment({
					type: 1,
					lng: lngId,
				})
			);
	}, [isLoading, constructionSubsegments]);

	const toLink = (url) => {
		return url.includes("http")
			? {
					pathname: new URL(url).pathname,
					search: new URL(url).searchParams.toString(),
			  }
			: url.replace("lng_id", lngId);
	};

	return (
		<Fragment>
			{(!constructionSubsegments || isLoading) && <Loading className={classes.loading} type={"bounce"} />}
			{constructionSubsegments && constructionSubsegments.length > 0 && !isLoading && (
				<Fragment>
					<div className={classes.constructionHeader}>
						<HeaderCarousel data={constructionHeaderMedia} />
					</div>
					<div className={classes.layout} ref={gridRef}>
						<h1 className={classes.title}>{t(keys.CONSTRUCTION.TITLE1)}</h1>
						<p className={classes.description}>{t(keys.CONSTRUCTION.DESCRIPTION)}</p>
						<ul>
							<li>
								<CustomNavLink to={`/adstore/${search}`}>
									{t(keys.CONSTRUCTION.ALL_PRODUCTS)}
								</CustomNavLink>
							</li>
							<li>
								<CustomNavLink to={`/adstore/${search}&brands=${["Adseal"].join(",")}`}>
									{t(keys.CONSTRUCTION.SEALANTS)}
								</CustomNavLink>
							</li>
							<li>
								<CustomNavLink to={`/adstore/${search}&brands=${["Adfoam"].join(",")}`}>
									{t(keys.CONSTRUCTION.INSULATING_FOAMS)}
								</CustomNavLink>
							</li>
							<li>
								<CustomNavLink to={`/adstore/${search}&brands=${["Adbond"].join(",")}`}>
									{t(keys.CONSTRUCTION.ADHESIVES)}
								</CustomNavLink>
							</li>
						</ul>
						{constructionDiscoverProduct && constructionDiscoverProduct.length > 0 && (
							<div className={classes.discoverLayout}>
								{constructionDiscoverProduct.map((product, index) => (
									<CustomNavLink
										to={toLink(product?.url)}
										target={product?.url.includes("http") ? "_blank" : "_self"}
										className={classes.card}
										key={index}
									>
										{gridWidth > 769 && index % 2 === 0 && (
											<div
												className={classes.left}
												style={{
													backgroundImage: `url(${
														lngId.includes("fr") ? product.img_fr : product.img_en
													})`,
												}}
											/>
										)}
										{gridWidth <= 769 && (
											<div
												className={classes.left}
												style={{
													backgroundImage: `url(${
														lngId.includes("fr") ? product.img_fr : product.img_en
													})`,
												}}
											/>
										)}
										<div className={classes.right}>
											{gridWidth > 769 && product.icon && (
												<div
													className={classes.icon}
													style={{ backgroundImage: `url(${product.icon})` }}
												/>
											)}
											<div className={classes.details}>
												{(lngId.includes("fr") ? product.title_fr : product.title_en) && (
													<h1>
														{lngId.includes("fr") ? product.title_fr : product.title_en}
													</h1>
												)}
												{gridWidth > 769 &&
													(lngId.includes("fr")
														? product.description_fr
														: product.description_en) && (
														<p>
															{lngId.includes("fr")
																? product.description_fr
																: product.description_en}
														</p>
													)}
											</div>
										</div>
										{gridWidth > 769 && index % 2 === 1 && (
											<div
												className={classes.left}
												style={{
													backgroundImage: `url(${
														lngId.includes("fr") ? product.img_fr : product.img_en
													})`,
												}}
											/>
										)}
									</CustomNavLink>
								))}
							</div>
						)}
						<h2 className={classes.title2}>{t(keys.CONSTRUCTION.TITLE2)}</h2>
						<div className={classes.subSegmentLayout}>
							{constructionSubsegments &&
								constructionSubsegments.map((curSubSegment) => (
									<div className={classes.container} key={curSubSegment.id}>
										<div className={classes.right}>
											<h1 className={classes.title}>
												{lngId.includes("fr") ? curSubSegment.title_fr : curSubSegment.title_en}
											</h1>
											<hr />
											{(lngId.includes("fr")
												? curSubSegment.description_fr
												: curSubSegment.description_en) && (
												<h1 className={classes.description}>
													{t(keys.GLOBAL.COMMON.DESCRIPTION)}
												</h1>
											)}
											{(lngId.includes("fr")
												? curSubSegment.description_fr
												: curSubSegment.description_en) && (
												<p className={classes.details}>
													{lngId.includes("fr")
														? curSubSegment.description_fr
														: curSubSegment.description_en}
												</p>
											)}
											<div className={classes.sheet}>
												<h1>{t(keys.MANUFACTURING.DOWNLOAD_SHEET)} :</h1>
												<Icon
													className={classes.icon}
													icon="bxs:file-pdf"
													onClick={() => {
														window.open(
															lngId.includes("fr")
																? curSubSegment.sheet_url_fr
																: curSubSegment.sheet_url_en,
															"_blank"
														);
													}}
												/>
											</div>
										</div>
										{curSubSegment && curSubSegment.imgs && curSubSegment.imgs.length > 0 && (
											<div className={classes.left}>
												<ConstructionCarousel
													curSubSegment={curSubSegment}
													pictures={curSubSegment.imgs}
												/>
											</div>
										)}
									</div>
								))}
						</div>
						<Resources />
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default Construction;
