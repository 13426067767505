import React, { useImperativeHandle, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { AnimatePresence, motion } from "framer-motion";

import classes from "./SearchBar.module.scss";

const SearchBar = React.forwardRef((props, ref) => {
	const inputRef = useRef();
	const [inputHasValue, setInputHasValue] = useState(props.value && props.value !== "");
	const closeAlwaysOn = props.closeAlwaysOn;
	const activate = () => {
		inputRef.current.focus();
	};

	const onChange = (event) => {
		setInputHasValue(event.target.value !== "");
		if (props.onChange) props.onChange(event.target.value);
	};

	const eraseSearch = () => {
		setInputHasValue(false);
		props.onChange("");
	};

	useImperativeHandle(ref, () => {
		return {
			focus: activate,
		};
	});

	return (
		<form
			data-is-mobile={props.isMobile || "false"}
			className={`${classes.SearchBar} ${classes[props.theme]} ${
				props.isValid === false ? classes.invalid : ""
			} ${props.className}`}
			onSubmit={props.onSubmit}
			autoComplete="off"
		>
			<Icon icon="mdi:magnify" className={classes.iconSearch} onClick={props.onSubmit} />
			<input
				onKeyDown={props.onKeyDown}
				ref={inputRef}
				type={props.type}
				id={props.id}
				value={props.value ? props.value : ""}
				onChange={onChange}
				onBlur={props.onBlur}
				placeholder={props.placeholder}
				disabled={props.disabled}
				autoComplete="off"
			/>

			<div className={classes.closeContainer} onClick={props.onClick}>
				<AnimatePresence>
					{(inputHasValue === true) | closeAlwaysOn && (
						<svg
							onClick={eraseSearch}
							className={classes.close}
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<motion.path
								strokeLinecap="round"
								strokeWidth="2.5"
								className={classes.pathOne}
								initial={{ opacity: 0, d: `M 0 18 L 18 18 M 18 18 L 0 18` }}
								animate={{ opacity: 1, d: `M 1 1 L 18 18 M 18 1 L 1 18` }}
								exit={{
									d: [
										`M 1 1 L 18 18 M 18 1 L 1 18`,
										`M 18 1 L 1 18 M 18 1 L 1 18`,
										`M 18 18 L 0 18 M 18 18 L 0 18`,
									],
								}}
								transition={{ duration: 0.3 }}
							/>
						</svg>
					)}
				</AnimatePresence>
			</div>
		</form>
	);
});

export default SearchBar;
