import React, { useState } from "react";

import classes from "./NavDrop.module.scss";
import CustomNavLink from "../../ui/customNavLink/CustomNavLink";
import { keys } from "../../../locales/localeskeys";
import productCompatibility from "../../../assets/svg/product-compatibility.svg";

const NavDrop = (props) => {
	const [activeID, setActiveID] = useState("");
	const gridTemplateColumns = (props.data.length === 2 || props.data.length === 4 )? 'repeat(2, 48%)' : 'repeat(3, 32%)';


	const linkHoverHandler = (id) => {
		setActiveID(id);
	};

	const linkOutHandler = () => {
		setActiveID("");
	};

	const onClick = (item) => {
		if (item.onClick) item.onClick(item);
		else if (props.onClick) props.onClick(item);
		if (props.hide) props.hide();
	};

	return (
		<div
			className={`${classes.navDrop}  ${props.activeID === props.parentId ? classes["active"] : ""}`}
			style={{ left: props.left, right: props.right, backgroundColor: props.backgroundColor}}
		>
			<div
				className={`${classes.inner} ${props.activeID === props.parentId ? classes["active"] : ""}`}
				style={{ height: props.activeID === props.parentId ? props.height : 0 }}
			>
				{!props.cube && !props.box && <ul className={props.activeID === props.parentId ? classes["active"] : ""}>
					{props.data &&
						props.data.length > 0 &&
						props.data.map((item) => (
							<li
								key={item.id}
								className={props.activeID === props.parentId ? classes["active"] : ""}
								onClick={() => onClick(item)}
							>
								{item.link && (
									<CustomNavLink
										id={item.id}
										to={item.link}
										target={item.target || "_self"}
										className={`${classes.link} ${activeID === item.id ? classes["active"] : ""}`}
										style={{ width: props.width }}
										onMouseOver={() => {
											linkHoverHandler(item.id);
										}}
										onMouseOut={linkOutHandler}
										onClick={item.onClick}
									>
										{item.children}
									</CustomNavLink>
								)}
								{!item.link && (
									<div
										id={item.id}
										className={`${classes.link} ${props.link} ${activeID === item.id ? classes["active"] : ""}`}
										style={{ width: props.width }}
										onMouseOver={() => {
											linkHoverHandler(item.id);
										}}
										onMouseOut={linkOutHandler}
										onClick={item.onClick}
									>
										{item.children}
									</div>
								)}
							</li>
						))}
				</ul>}

				{props.cube && <ul
					className={`${props.activeID === props.parentId ? classes["active"] : ""} ${classes.dropdowncards}`}
					style={{ gridTemplateColumns }}
				>
					{props.cube && props.data &&
						props.data.length > 0 &&
						props.data.map((item) => (
							<li
								key={item.id}
								className={props.activeID === props.parentId ? classes["active"] : ""}
								onClick={() => onClick(item)}
							>
								{item.link && (
									<CustomNavLink
										id={item.id}
										to={item.link}
										target={item.target || "_self"}
										className={classes.segmentPickerCard}
										style={{ width: props.width }}
										onMouseOver={() => {
											linkHoverHandler(item.id);
										}}
										onMouseOut={linkOutHandler}
										onClick={item.onClick}
									>
										{item?.img && (
											<img src={item.img} alt="url" />
										)}
										{item.children && (
											<p>{item.children}</p>
										)}
									</CustomNavLink>
								)}
							</li>
						))}
				</ul>}
				{props.box && <ul
					className={`${props.activeID === props.parentId ? classes["active"] : ""} ${classes.dropdowncards}`}
					style={{ gridTemplateColumns }}
				>
					{props.box && props.data &&
						props.data.length > 0 &&
						props.data.map((item) => (
							<li
								key={item.id}
								className={props.activeID === props.parentId ? classes["active"] : ""}
								onClick={() => onClick(item)}
							>
								{item.link && (
									<CustomNavLink
										id={item.id}
										to={item.link}
										target={"_blank"}
										className={classes.element}
										style={{ width: props.width }}
										onMouseOver={() => {
											linkHoverHandler(item.id);
										}}
										onMouseOut={linkOutHandler}
										onClick={item.onClick}
									>
										{item.children && (
											<div className={classes.text}>
												<p>{item.children}</p>
											</div>
										)}
										{item?.img && (
											<img src={item.img} alt="tube.svg" />
										)}
									</CustomNavLink>
								)}
							</li>
						))}
				</ul>}
			</div>
		</div>
	);
};

export default NavDrop;
