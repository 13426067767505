import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";

import { constants as constant } from "../locales/constant";

/**
 * Fournir la liste des commandes en historique
 * utiliser les cookies httpsonly pour s'authentifier
 */
export const getReports = createAsyncThunk(
	"report/getReports",
	/**
	 * Fournir la liste des produits
	 * @param _
	 * @param rejectWithValue
	 * @param getState
	 * @param dispatch
	 */
	async (_, { rejectWithValue, getState, dispatch }) => {
		try {
			const {
				auth: { access_token },
			} = getState();

			const {
				report: { page },
			} = getState();

			const {
				report: { searchFilter },
			} = getState();

			const {
				report: { statusFilter },
			} = getState();

			const {
				report: { statusDateRange },
			} = getState();

			const header = new Headers();
			if (access_token) {
				header.append("Authorization", "Bearer " + access_token);
			}
			const queryParam =
				constant.API_SERVER +
				"/report/?" +
				(page && page !== "" ? "&page=" + page : "") +
				(searchFilter ? "&search=" + searchFilter : "") +
				(statusFilter.length > 0 ? "&status=" + statusFilter : "") +
				(statusDateRange > 0 ? "&dateRange=" + statusDateRange : "");

			const response = await fetch(queryParam, {
				credentials: "include",
				headers: header,
			});
			
			if (!response.ok) {
				throw new Error("fetching reports failed");
			}
			
			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

/**
 * Imprimer un rapport
 */
export const printReport = createAsyncThunk(
	"report/printReport",
	/**
	 * Fournir la liste des produits
	 * @param report
	 * @param rejectWithValue
	 * @param getState
	 * @param dispatch
	 */
	async (report, { rejectWithValue, getState, dispatch }) => {
		try {
			const {
				auth: { access_token },
			} = getState();

			const header = new Headers();
			if (access_token) {
				header.append("Authorization", "Bearer " + access_token);
			}
			const queryParam = `${constant.API_SERVER}/printreport/${report.id}/`;

			const response = await fetch(queryParam, {
				credentials: "include",
				headers: header,
			});
			if (!response.ok) {
				throw new Error("fetching reports failed");
			}
			return await response.blob();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

const reportAdapter = createEntityAdapter({});

const report = createSlice({
	name: "report",
	initialState: reportAdapter.getInitialState({
		loading: "done",
		printing: "done",
		currentRequestId: undefined,
		error: undefined,
		page: 1,
		searchFilter: "",
		statusFilter: [],
		statusDateRange: 0,
	}),
	reducers: {
		setPage(state, action) {
			state.page = action.payload;
		},
		setSearchFilter(state, action) {
			state.searchFilter = action.payload;
		},
		setStatusFilter(state, action) {
			state.statusFilter = action.payload;
		},
		setDateRangeFilter(state, action) {
			state.statusDateRange = action.payload;
		},
	},
	extraReducers: {
		[getReports.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;

				reportAdapter.removeAll(state);

				state.count = payload.count;
				state.next = payload.next;
				state.previous = payload.previous;
				payload = "results" in payload ? payload.results : [payload];

				reportAdapter.addMany(state, Array.isArray(payload) ? payload : [payload]);
			}
			return state;
		},
		[getReports.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[getReports.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
				reportAdapter.removeAll(state);
			}
			return state;
		},
		[printReport.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.printing = "done";
				state.error = undefined;
				
				openBase64NewTab(payload);
			}
			return state;
		},
		[printReport.pending]: (state, { meta }) => {
			if (!state.printing || state.printing === "done") {
				state.currentRequestId = meta.requestId;
				state.printing = "pending";
				state.error = undefined;
			}
			return state;
		},
		[printReport.rejected]: (state, { meta, payload }) => {
			if (state.printing === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.printing = "done";
				state.error = undefined;
			}
			return state;
		},
	},
});

export default report;
export const { setPage, setSearchFilter, setStatusFilter, setDateRangeFilter } = report.actions;
export const reportSelectors = reportAdapter.getSelectors((state) => state?.report);
export const reportsSelector = createSelector(reportSelectors.selectAll, (reports) => reports);
export const reportSelector = createSelector(reportSelectors.selectById, (report) => report);
export const orderConfirmationSelector = createSelector(reportSelectors.selectById, (report) => {
	return report.report.filter((r) => r.trans_type === 0);
});
export const orderPackingSelector = createSelector(reportSelectors.selectById, (report) => {
	return report.report.filter((r) => r.trans_type === 1);
});
export const orderInvoiceSelector = createSelector(reportSelectors.selectById, (report) => {
	return report.report.filter((r) => r.trans_type === 2);
});
export const isLoadingSelector = (state) => state?.report?.loading === "pending";
export const isPrintingSelector = (state) => state?.report?.printing === "pending";
export const searchFilterSelector = (state) => state?.report?.searchFilter;
export const statusFilterSelector = (state) => state?.report?.statusFilter;
export const pageSelector = (state) => state?.report?.page;
export const countSelector = (state) => state?.report?.count;

function openBase64NewTab(blob) {
	if (window.navigator && window.navigator.msSaveOrOpenBlob) {
		window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
	} else {
		const blobUrl = window.URL.createObjectURL(blob);
		window.open(blobUrl, '_blank');
	}
}