import SineBounce from "../../../assets/svg/Loading/sineBounce.svg";
import PlaceSwitch from "../../../assets/svg/Loading/PlaceSwitch.svg";
import Linear from "../../../assets/svg/Loading/Linear.svg";

import classes from "./Loading.module.scss";

const Loading = (props) => {
	return (
		<div className={`${props.className || ""} ${classes.loading} `}>
			{props.type === "bounce" && (
				<object type="image/svg+xml" data={SineBounce}>
					svg-animation
				</object>
			)}
			{props.type === "place" && (
				<object type="image/svg+xml" data={PlaceSwitch}>
					svg-animation
				</object>
			)}
			{props.type === "linear" && (
				<object type="image/svg+xml" data={Linear}>
					svg-animation
				</object>
			)}
		</div>
	);
};
export default Loading;
