import React, { useMemo } from "react";
import {TextField, InputAdornment} from "@material-ui/core";
import { Icon } from "@iconify/react";

export const TextInput = React.forwardRef(({ label, value, onChange, onKeyDown, type, icon }, ref) => {
	const iconElement = useMemo(() => {
		if (icon) return icon;

		if (type === "search") return "material-symbols:search"
	}, [type, icon]);
	

	return (
		<TextField
			value={value}
			inputRef={ref}
			label={label}
			onChange={onChange}
			type={type}
			InputProps={{ 
				onKeyDown: onKeyDown,
				endAdornment: iconElement && (<InputAdornment position="end"><Icon icon={iconElement} /></InputAdornment>),
			}}
		/>
	);
});
