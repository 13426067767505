import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	search: "",
	brand: "",
	sort: "",
	favorite: false,
	colorTones: [],
};

const variantQuery = createSlice({
	name: "variantQuery",
	initialState,
	reducers: {
		setSearch(state, action) {
			state.search = action.payload;
		},
		setBrand(state, action) {
			state.brand = action.payload;
		},
		setSort(state, action) {
			state.sort = action.payload;
		},
		setFavorite(state, action) {
			state.favorite = action.payload;
		},
		setColorTones(state, action) {
			state.colorTones = action.payload;
		},
		clearAll(state) {
			state.search = "";
			state.brand = "";
			state.sort = "";
			state.favorite = false;
			state.colorTones = [];
		},
	},
});

export const { setSearch, setSort, setFavorite, setColorTones, setBrand, clearAll } = variantQuery.actions;
export default variantQuery;

export const searchSelector = (state) => state?.variantQuery?.search;
export const brandSelector = (state) => state?.variantQuery?.brand;
export const sortSelector = (state) => state?.variantQuery?.sort;
export const favoriteSelector = (state) => state?.variantQuery?.favorite;
export const colorTonesSelector = (state) => state?.variantQuery?.colorTones;
