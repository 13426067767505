import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";

import { constants as constant } from "../locales/constant";
import encodeSlash from "../hooks/encodeSlash";

/**
 * Fournir la liste des variants d'un produit
 * utiliser les cookies httpsonly pour s'authentifier
 */
export const getVariants = createAsyncThunk(
	"variant/getVariants",
	/**
	 * Fournir la liste des variants
	 * @param _
	 * @param item_id
	 * @param brand_id
	 * @param rejectWithValue
	 * @param getState
	 * @param dispatch
	 */
	async ({ item_id, brand_id }, { rejectWithValue, getState, dispatch }) => {
		try {
			const {
				auth: { access_token },
			} = getState();

			const {
				variantQuery: { search },
			} = getState();
			const {
				variantQuery: { sort },
			} = getState();
			const {
				variantQuery: { favorite },
			} = getState();
			const {
				auth: { currencyCode },
			} = getState();

			const header = new Headers();
			if (access_token) {
				header.append("Authorization", "Bearer " + access_token);
			}
			const queryParam =
				`${constant.API_SERVER}/variant/?` +
				(item_id && item_id !== "" ? "&item_id=" + encodeURIComponent(encodeSlash(item_id)) : "") +
				(brand_id && brand_id !== "" ? "&brand_id=" + brand_id : "") +
				(search && search !== "" ? "&search=" + encodeURIComponent(encodeSlash(search)) : "") +
				(sort && sort !== "" ? "&sort=" + sort : "") +
				(favorite && favorite === true ? "&favorite=true" : "") +
				(!access_token && currencyCode && currencyCode !== "" ? "&currency=" + currencyCode : "");

			const response = await fetch(queryParam, {
				credentials: "include",
				headers: header,
			});
			if (!response.ok) {
				throw new Error("fetching items failed");
			}
			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

const variantAdapter = createEntityAdapter({});

const variant = createSlice({
	name: "variant",
	initialState: variantAdapter.getInitialState({
		loading: "done",
		currentRequestId: undefined,
		error: undefined,
		itemid: undefined,
		brandid: undefined,
		displayImage: true,
	}),
	reducers: {
		setVariants(state, action) {
			variantAdapter.removeAll(state);
			variantAdapter.addMany(state, Array.isArray(action.payload) ? action.payload : [action.payload]);
		},
		clear(state, action) {
			state.itemid = undefined;
			state.brandid = undefined;
			state.displayImage = true;
		},
	},
	extraReducers: {
		[getVariants.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;
				state.itemid = meta.arg.item_id;
				state.brandid = meta.arg.brand_id;
				state.title = payload ? payload.title : "Overview";
				state.description_fr = payload ? payload.description_fr : "description_fr";
				state.description_en = payload ? payload.description_en : "description_en";
				state.displayImage = payload && payload.result && !payload.result.some((v) => v.hex !== "#000000");

				variantAdapter.removeAll(state);
				variantAdapter.addMany(state, payload && payload.result ? payload.result : []);
			}
			return state;
		},
		[getVariants.pending]: (state, { meta }) => {
			state.currentRequestId = meta.requestId;
			state.loading = "pending";
			state.error = undefined;

			return state;
		},
		[getVariants.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
	},
});

export const { setVariants, clear } = variant.actions;
export default variant;
export const variantSelectors = variantAdapter.getSelectors((state) => state?.variant);
export const variantSpecificationsStock = () =>
	createSelector(variantSelectors.selectAll, (variants) =>
		variants
			.filter((v) => v.is_stock === true)
			.map((v) => ({
				id: v.id,
				title: v.erp_code,
				subtitle_fr: v.description_fr,
				subtitle_en: v.description_en,
				image: v.image,
				hex: v.hex,
				description_format_en: v.description_format_en,
				description_format_fr: v.description_format_fr,
			}))
	);

export const variantSpecificationsNonStock = () =>
	createSelector(variantSelectors.selectAll, (variants) =>
		variants
			.filter((v) => v.is_stock === false)
			.map((v) => ({
				id: v.id,
				title: v.erp_code,
				subtitle_fr: v.description_fr,
				subtitle_en: v.description_en,
				image: v.image,
				hex: v.hex,
				description_format_en: v.description_format_en,
				description_format_fr: v.description_format_fr,
			}))
	);
export const variantFormats = (specificationId) =>
	createSelector(
		variantSelectors.selectAll,
		(variants) => variants.find((v) => v.erp_code === specificationId)?.formats
	);
export const variantIsLoadingSelector = (state) => state?.variant?.loading === "pending";
export const variantTitleSelector = (state) => state?.variant?.title;
export const variantDescriptionFrSelector = (state) => state?.variant?.description_fr;
export const variantDescriptionEnSelector = (state) => state?.variant?.description_en;
export const displayImageSelector = (state) => state?.variant?.displayImage;
export const isVariantLoadedSelector = ({ itemid, brandid }) => {
	return (state) => {
		if (!state || !state.variant) return false;
		if (state?.variant?.itemid === undefined) return false;
		if (brandid === undefined) {
			return state?.variant && state?.variant?.itemid === itemid;
		} else return state?.variant?.itemid === itemid && state?.variant?.brandid === brandid;
	};
};
export const specificationsSelector = () =>
	createSelector(variantSelectors.selectAll, (variants) => {
		return variants.map((v) => ({
			id: v.id,
			title: v.erp_code,
			subtitle_fr: v.description_fr,
			subtitle_en: v.description_en,
			image: v.image,
			hex: v.hex,
			description_format_en: v.description_format_en,
			description_format_fr: v.description_format_fr,
		}));
	});
export const formatsSelector = () =>
	createSelector(variantSelectors.selectAll, (variants) => {
		const formatMap = new Map();
		variants.forEach((v) => {
			v.formats.forEach((f) => {
				formatMap.set(f.id, f);
			});
		});
		return Array.from(formatMap.values());
	});
