/**
 * Custom hook that provides utilities to interact with the browser's localStorage.
 *
 * @param {string} key - The key under which the value will be stored in localStorage.
 * @returns {Object} An object containing functions to set, get, and remove items from localStorage.
 */
export const useLocalStorage = (key) => {
	/**
	 * Stores a value in localStorage under the specified key.
	 *
	 * @param {*} value - The value to store in localStorage. It will be serialized to JSON.
	 */
	const setItem = (value) => {
		try {
			window.localStorage.setItem(key, JSON.stringify(value));
		} catch (error) {
			console.error("Error setting item in localStorage:", error);
		}
	};

	/**
	 * Retrieves the value associated with the specified key from localStorage.
	 *
	 * @returns {*} The value stored in localStorage, parsed from JSON, or undefined if not found.
	 */
	const getItem = () => {
		try {
			const item = window.localStorage.getItem(key);
			return item ? JSON.parse(item) : undefined;
		} catch (error) {
			console.error("Error getting item from localStorage:", error);
		}
	};

	/**
	 * Removes the value associated with the specified key from localStorage.
	 */
	const removeItem = () => {
		try {
			window.localStorage.removeItem(key);
		} catch (error) {
			console.error("Error removing item from localStorage:", error);
		}
	};

	return { setItem, getItem, removeItem };
};
