import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { constants as constant } from "../locales/constant";

/**
 * Fournir la liste des subsegments
 */
export const getSubsegments = createAsyncThunk("subsegment/getSubsegments", async ({ lng }, { rejectWithValue }) => {
	try {
		const response = await fetch(constant.API_SERVER + "/subSegment/?pageSize=32&sortState=a-z&lng=${lng}");
		if (!response.ok) {
			throw new Error("fetching subsegment failed");
		}
		return await response.json();
	} catch (err) {
		return rejectWithValue(err.message, err);
	}
});

/**
 * Fournir les subsegment adequoit au type selectionne
 */
export const getSubsegment = createAsyncThunk(
	"segment/getSubsegment",
	/**
	 * Fournir la comande ouverte non paye
	 * @param _
	 * @param type
	 * @param rejectWithValue
	 * @param getState
	 * @param dispatch
	 */
	async ({ type, lng, sortState, page, search, subSegment, pageSize }, { rejectWithValue, getState, dispatch }) => {
		try {
			let queryParam;
			if (subSegment) queryParam = `${constant.API_SERVER}/subSegment/${subSegment}/`;
			else
				queryParam =
					`${constant.API_SERVER}/subSegment/?type=${type}&lng=${lng}` +
					(sortState && sortState !== "" ? "&sortState=" + sortState : "") +
					(page && page !== "" ? "&page=" + page : "") +
					(pageSize && pageSize !== "" ? "&pageSize=" + pageSize : "") +
					(search && search !== "" ? "&search=" + search : "");

			const response = await fetch(queryParam, {
				credentials: "include",
				headers: {
					"Content-Type": "application/json",
				},
			});
			if (!response.ok) {
				throw new Error("fetching orders failed");
			}
			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);
const subsegmentAdapter = createEntityAdapter({});

const subsegment = createSlice({
	name: "subsegment",
	initialState: subsegmentAdapter.getInitialState({
		currentRequestId: undefined,
		error: undefined,
		loading: "done",
		currentSegment: undefined,
		subSegments: undefined,
		count: 0,
	}),
	reducers: {},
	extraReducers: {
		[getSubsegments.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;

				subsegmentAdapter.removeAll(state);
				if (payload.results) subsegmentAdapter.addMany(state, payload.results);
			}
			return state;
		},
		[getSubsegments.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[getSubsegments.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
		[getSubsegment.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;

				state.count = payload.count;
				state.next = payload.next;
				state.previous = payload.previous;

				state.subSegments = "results" in payload ? payload.results : [payload];
			}
		},
		[getSubsegment.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[getSubsegment.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
	},
});

export default subsegment;
export const subsegmentSelectors = subsegmentAdapter.getSelectors((state) => state?.subsegment);
export const subsegmentSelector = createSelector(subsegmentSelectors.selectAll, (subsegments) => subsegments);
export const segmentSelectors = createSelector(subsegmentSelectors.selectAll, (subsegments) => {
	const array = subsegments.map((sub) => sub.segment).filter((sub) => sub && sub.segment_type !== 0);
	const result = [];
	const map = new Map();
	for (const item of array) {
		if (!map.has(item.id)) {
			map.set(item.id, true);
			result.push(item);
		}
	}
	return result;
});
export const subsegmentsLoadingSelector = (state) => state?.subsegment?.loading === "pending";
export const isLoadingSelector = (state) => state?.subsegment?.loading === "pending";
export const subsegmentsSelector = (state) => state?.subsegment?.subSegments;
export const constructionSubsegmentSelector = (state) =>
	state?.subsegment?.subSegments?.filter((s) => s?.erp_code.includes("B2B Construction"));
export const subSegmentVideoSelector = (state, subSegmentId) => {
	return subSegmentId ? state?.subsegment?.subSegments?.find((v) => v.erp_code === subSegmentId)?.videos : undefined;
};
export const countSelector = (state) => state?.subsegment?.count;
