import React from "react";
import { useTranslation } from "react-i18next";

import { constants } from "../../../locales/constant";
import { keys } from "../../../locales/localeskeys";

import classes from "./JamesHardie.module.scss";
import TemplatePartner from "../TemplatePartner";

const JamesHardie = () => {
	const { t } = useTranslation();

	return (
		<TemplatePartner
			itemId={constants.jamesHardi.itemId}
			brandId={constants.jamesHardi.brandId}
			action={constants.jamesHardi.action}
			bannerFr={constants.jamesHardi.images.banner_fr}
			bannerEn={constants.jamesHardi.images.banner_en}
			description={t(keys.PARTNERS.JAMESHARDI.DESCRIPTION)}
			subDescription={t(keys.PARTNERS.JAMESHARDI.SUBDESCIPTION)}
			titleColor={t(keys.PARTNERS.JAMESHARDI.TITLECOLOR)}
			sections={constants.jamesHardi.sections}
			colorChart={constants.jamesHardi.images.colorChart}
			DigitalChart={constants.jamesHardi.images.DigitalChart}
			orderColorDescription={t(keys.PARTNERS.JAMESHARDI.ORDERCOLORDESCRIPTION)}
			orderColorButton={t(keys.PARTNERS.JAMESHARDI.ORDERCOLORBUTTON)}
			ChartUrlFr={constants.jamesHardi.pdf.chart_url_fr}
			ChartUrlEn={constants.jamesHardi.pdf.chart_url_en}
			digitalColorDescription={t(keys.PARTNERS.JAMESHARDI.DIGITALCOLORDESCRIPTION)}
			orderColorMobileDescription={t(keys.PARTNERS.JAMESHARDI.DIGITALCOLORMOBILEDESCRIPTION)}
			style={classes.cardStyle}
		/>
	);
};

export default JamesHardie;
