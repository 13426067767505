import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(Backend)
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		backend: {
			loadPath: "/static/assets/i18n/{{ns}}/{{lng}}.json",
		},
		lng: "en-us",
		supportedLng: ["en-ca", "en-us", "fr-ca"],
		fallbackLng: "en-us",
		detection: {
			order: ["path", "cookie", "navigator", "htmlTag", "localStorage", "subdomain"],
		},
		debug: false,
		ns: ["translations"],
		defaultNS: "translations",
		interpolation: {
			escapeValue: false,
			formatSeparator: ",",
		},
		react: {
			useSuspense: true,
		},
	});

export default i18n;
