import s from "./Button.module.scss";
import { motion } from "framer-motion";
import { buttonAnim } from "./Button.animation";

const Button = (props) => {
	return (
		<motion.button
			id={props.id}
			variants={buttonAnim}
			initial="hidden"
			animate="show"
			type={props.type || "button"}
			className={`${s.button} ${s[props.size] || ""} ${s[props.color] || ""} ${props.className || ""}`}
			onClick={props.onClick}
			disabled={props.disabled}
			style={props.style}
			value={props.value}
			key={props.key}
		>
			<div className={`${s.content} ${s[props.weight] || ""} ${props.content || ""}`} style={props.textStyle}>
				{props.children}
			</div>
		</motion.button>
	);
};

export default Button;
