import React, { Fragment, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";

import { addNewWallet, authHasErrorSelector, isLoadingSelector } from "../../../store/auth";
import TextInput from "../../ui/Input/TextInput/TextInput";
import Button from "../../ui/Button/Button";

import { keys } from "../../../locales/localeskeys";

import Linear from "../../../assets/svg/Loading/Linear.svg";
import classes from "./CreditCardForm.module.scss";

const CreditCardForm = (props) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const isLoading = useSelector(isLoadingSelector);
	const hasError = useSelector(authHasErrorSelector);

	const [cardState, setCardState] = useState({
		cvc: "",
		expiry: "",
		focus: "",
		name: "",
		number: "",
	});
	const [validCard, setValidCard] = useState();
	const [displayValidCard, setDisplayValidCard] = useState(false);
	const [closeForm, setCloseForm] = useState(false);

	useEffect(() => {
		if (closeForm === true && !isLoading && !hasError) props.closeAddCreditCard();
	}, [closeForm, isLoading, hasError, props]);

	const handleInputFocus = (e) => {
		setCardState({ ...cardState, focus: e.target.name });
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;

		setCardState({ ...cardState, [name]: value });
	};

	const handleCallback = ({ issuer }, isValid) => {
		setValidCard(isValid);
		setDisplayValidCard(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (!validCard || !cardState.cvc || !cardState.expiry || !cardState.name || !cardState.number) {
			setValidCard(false);
			setDisplayValidCard(true);
			return;
		}
		dispatch(
			addNewWallet({
				card_number: cardState.number,
				card_name: cardState.name,
				expiry_date: cardState.expiry.slice(0, 2) + cardState.expiry.slice(-2),
				security_code: cardState.cvc,
			})
		);
		setCloseForm(true);
	};

	const protalElement = document.getElementById("overlays");

	return (
		<Fragment>
			{ReactDOM.createPortal(
				<div className={classes.backdrop} onClick={props.closeAddCreditCard} />,
				protalElement
			)}
			{ReactDOM.createPortal(
				<div className={classes.creditCard} data-is-active={true}>
					<div className={classes.title}>
						<p>{t(keys.CHECKOUT.PAYMENT.ADDCREDITCARD)}</p>
						<Icon className={classes.icon} onClick={props.closeAddCreditCard} icon="bi:x-lg" />
					</div>
					{/*<CreditCardSample*/}
					{/*	cvc={cardState.cvc}*/}
					{/*	expiry={cardState.expiry}*/}
					{/*	focused={cardState.focus}*/}
					{/*	name={cardState.name}*/}
					{/*	number={cardState.number}*/}
					{/*	callback={handleCallback}*/}
					{/*/>*/}
					<form className={classes.inputLayout} onSubmit={handleSubmit}>
						<TextInput
							type="tel"
							name="number"
							className={classes.input1}
							inputClassName={classes.input_radius}
							placeholder={t(keys.CHECKOUT.PAYMENT.NUMBERCREDITCARD)}
							pattern="[\d| ]{14,22}"
							required
							onChange={handleInputChange}
							onFocus={handleInputFocus}
						/>
						<TextInput
							type="text"
							name="name"
							className={classes.input2}
							inputClassName={classes.input_radius}
							placeholder={t(keys.CHECKOUT.PAYMENT.NAMECREDITCARD)}
							required
							onChange={handleInputChange}
							onFocus={handleInputFocus}
						/>
						<div className={classes.lastinput}>
							<TextInput
								type="tel"
								name="expiry"
								className={classes.input1}
								inputClassName={classes.input_radius}
								placeholder={t(keys.CHECKOUT.PAYMENT.EXPCREDITCARD)}
								pattern="\d\d/\d\d|\d\d/\d\d\d\d|\d\d\d\d"
								required
								onChange={handleInputChange}
								onFocus={handleInputFocus}
							/>
							<TextInput
								type="tel"
								name="cvc"
								className={classes.input1}
								inputClassName={classes.input_radius}
								placeholder="CVC"
								pattern="\d{3,4}"
								required
								onChange={handleInputChange}
								onFocus={handleInputFocus}
							/>
						</div>
						<Button size="big" type="submit" className={classes.btn} disabled={isLoading}>
							{t(keys.CHECKOUT.ADDRESS.CONFIRM)}
						</Button>
						{isLoading && (
							<object type="image/svg+xml" data={Linear}>
								svg-animation
							</object>
						)}
						{((displayValidCard === true && validCard === false) || hasError) && (
							<p>{t(keys.CHECKOUT.PAYMENT.INVALIDCARD)}</p>
						)}
					</form>
				</div>,
				protalElement
			)}
		</Fragment>
	);
};

export default CreditCardForm;
