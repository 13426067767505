import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ProductGrid from "./ProductGrid/ProductGrid";
import ProductSideBar from "./ProductSideBar/ProductSideBar";
import ProductSearchBar from "./ProductGrid/ProductSearchBar/ProductSearchBar";

import { isLoggedInSelector } from "../../../store/auth";
import { getCategories } from "../../../store/productCategory";
import { getBrands } from "../../../store/productBrand";
import { getSubsegments } from "../../../store/subSegment";

import { keys } from "../../../locales/localeskeys";

import classes from "./ProductList.module.scss";
import Button from "../../ui/Button/Button";
import CustomNavLink from "../../ui/customNavLink/CustomNavLink";
import ProductFilterOverlay from "../productFilterOverlay/ProductFilterOverlay";

const ProductList = () => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;
	const search = `?lng=${lngId}`;

	const isLoggedIn = useSelector(isLoggedInSelector);

	const [pageSize, setPageSize] = useState(0);
	const [displayOverlay, setDisplayOverlay] = useState("");

	useEffect(() => {
		dispatch(getCategories());
		dispatch(getBrands());
		dispatch(getSubsegments({ lng: lngId }));
	}, []);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	return (
		<div className={classes.container}>
			{!isLoggedIn && (
				<div className={classes.header}>
					<CustomNavLink className={classes.link} to={`/sign-in${search}`}>
						{t(keys.PRODUCTS.VARIANT.LOG_IN)}
					</CustomNavLink>
					<span> {t(keys.PRODUCTS.LOGIN_TXT)}</span>
				</div>
			)}
			<ProductSearchBar pageSize={pageSize} />
			<Button
				className={classes.btn}
				onClick={() => {
					setDisplayOverlay("open");
				}}
			>
				{t(keys.PRODUCTS.FILTERS)}
			</Button>
			<ProductFilterOverlay
				onClose={() => {
					setDisplayOverlay("");
				}}
				onOpen={displayOverlay}
			/>
			<div className={classes.body}>
				<ProductSideBar />
				<ProductGrid setPageSize={setPageSize} pageSize={pageSize} />
			</div>
			<Button className={classes.btn} onClick={scrollToTop}>
				{t(keys.PRODUCTS.BACK_TO_TOP)}
			</Button>
		</div>
	);
};

export default ProductList;
