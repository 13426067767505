import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setSearch } from "../store/productQuery";

export const useSearch = ({ onSearchChange, onSearchSubmit }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const searchChangeHandler = (event) => {
		const value = event.target.value;

		if (onSearchChange) {
			onSearchChange(value);
		}

		dispatch(setSearch(value));

		if (value !== "") {
			queryParams.set("search", value);
		} else {
			queryParams.delete("search");
		}

		navigate(
			{
				pathname: location.pathname,
				search: queryParams.toString(),
			},
			{ replace: true }
		);
	};

	const enterSubmitHandler = (event) => {
		if (event.key === "Enter") {
			searchSubmitHandler(event);
		}
	};

	const searchSubmitHandler = (event) => {
		const value = event.target.value;
		event.preventDefault();

		if (onSearchSubmit) {
			onSearchSubmit(value);
		}

		navigate({
			pathname: location.pathname,
			search: queryParams.toString(),
		});
	};

	const isMatchInTarget = (target, reference) => {
		const wordsInReference = reference.split(/\s+/);
		return wordsInReference.every((term) => target.includes(term));
	};

	return { searchChangeHandler, enterSubmitHandler, searchSubmitHandler, isMatchInTarget };
};
