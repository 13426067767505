import React from "react";

import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
	getContentAnchorEl: null,
	anchorOrigin: {
		vertical: "bottom",
		horizontal: "left",
	},
	transformOrigin: {
		vertical: "top",
		horizontal: "left",
	},
};

export const SelectDropdown = ({ id, label, value, options, onChange }) => {
	return (
		<FormControl>
			<InputLabel id={id}>{label}</InputLabel>
			<Select labelId={id} value={options.includes(value) ? value : ""} onChange={onChange} MenuProps={MenuProps}>
				{options.map((option, key) => (
					<MenuItem key={key} value={option}>
						{option}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
