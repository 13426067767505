import React from "react";

import classes from "./RoundCheckbox.module.scss";

const RoundCheckbox = React.forwardRef((props, ref) => {
	return (
		<div className={`${classes.checkbox} ${props.isValid === false ? classes.invalid : ""}`}>
			<input
				ref={ref}
				id={props.id}
				type="checkbox"
				defaultChecked={props.defaultChecked}
				onChange={props.onChange}
				disabled={props.disabled}
				checked={props.checked}
			/>
			<label htmlFor={props.id}>
				<span></span> {props.label}
			</label>
		</div>
	);
});

export default RoundCheckbox;
