import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { constants } from "../locales/constant";

export const getFaq = createAsyncThunk("faq/getFaq", async (_, { rejectWithValue }) => {
	try {
		const response = await fetch(`${constants.API_SERVER}/faq/`);

		if (!response.ok) {
			throw new Error("fetching faqs failed");
		}

		return await response.json();
	} catch (err) {
		return rejectWithValue(err.message, err);
	}
});

const faqAdapter = createEntityAdapter({});

const faq = createSlice({
	name: "faq",
	initialState: faqAdapter.getInitialState({
		loading: "done",
		currentRequestId: undefined,
		error: undefined,
	}),
	reducers: {
		setFaq(state, action) {
			faqAdapter.removeAll(state);
			faqAdapter.addMany(state, Array.isArray(action.payload) ? action.payload : [action.payload]);
		},
	},
	extraReducers: {
		[getFaq.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;

				faqAdapter.removeAll(state);
				faqAdapter.addMany(state, payload);
			}
			return state;
		},
		[getFaq.pending]: (state, { meta }) => {
			state.currentRequestId = meta.requestId;
			state.loading = "pending";
			state.error = undefined;

			return state;
		},
		[getFaq.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
	},
});

export const { setFaq } = faq.actions;
export default faq;

const faqSelectors = faqAdapter.getSelectors((state) => state.faq);

export const faqSelector = createSelector(faqSelectors.selectAll, (faqs) => faqs);
export const isLoadingSelector = (state) => state?.faq?.loading === "pending";
