import React, { Fragment, useEffect } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";

import { keys } from "../../../locales/localeskeys";

import classes from "./ShoppingCart.module.scss";
import SiteCartItem from "../../sidecart/siteCartItem/SiteCartItem";
import Button from "../../ui/Button/Button";
import { setCartOpen } from "../../../store/cart";

const ShoppingCart = (props) => {
	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const closeShoppingCart = () => {
		props.closeShoppingCart();
		document.body.style.overflow = "";
	};

	useEffect(() => {
		document.body.style.overflow = "hidden";
	}, []);

	const protalElement = document.getElementById("overlays");
	return (
		<Fragment>
			{ReactDOM.createPortal(
				<div className={classes.address} data-is-active={true}>
					<div className={classes.title}>
						<p>
							{props.orderLine.length > 1
								? `${t(keys.CART.OVERVIEW.TITLE)} (${props.orderLine.length} ${t(
										keys.CART.OVERVIEW.ITEMS
								  )})`
								: `${t(keys.CART.OVERVIEW.TITLE)} (${props.orderLine.length} ${t(
										keys.CART.OVERVIEW.ITEM
								  )})`}
						</p>
						<Icon className={classes.icon} onClick={closeShoppingCart} icon="bi:x-lg" />
					</div>
					{props.orderLine && props.orderLine.length > 0 && (
						<div className={classes.content}>
							<div className={classes.details}>
								{props.orderLine
									.filter((line) => line.item && line.variant)
									.map((line, index) => (
										<div key={line.id}>
											<SiteCartItem
												variant={line.variant}
												item={line.item}
												qty={Number(line.qty).toFixed(0)}
												qty_pack={Number(line.qty_pack).toFixed(0)}
												amount={Number(line.amount)}
												unitAmount={Number(line.unitAmount)}
												images={line.overview_image}
											/>

											{props.orderLine.length !== index + 1 && <hr />}
										</div>
									))}
							</div>
						</div>
					)}
				</div>,
				protalElement
			)}
		</Fragment>
	);
};

export default ShoppingCart;
