import { useMemo } from "react";

/**
 * Custom hook that provides localization utilities based on the provided language.
 * 
 * @param {string} language - The full language code (e.g., "en-US").
 * @returns {Object} An object containing localization functions.
 */
export const useLocalized = (language) => {
    const normalizedLanguage = useMemo(() => language.toLowerCase(), [language]);
    const baseLanguage = useMemo(() => normalizedLanguage.split("-")[0], [normalizedLanguage]);

    /**
     * Retrieves a localized value from an object based on a key type and the base language.
     * 
     * @param {Object} obj - The object containing localized values (e.g., {"title_en": "Hello", "title_fr": "Allô"}).
     * @param {string} keyType - The key type used to construct the localization key (e.g., "title").
     * @returns {string} The localized value corresponding to the key type and base language, or an empty string if not found.
     */
    const getLocalizedValueByKeyType = (obj, keyType) => {
        const key = `${keyType}_${baseLanguage}`;
        return obj[key] || "";
    };

    /**
     * Retrieves a localized value from an object based on a custom mapping and the full language code.
     * 
     * @param {Object} obj - The object containing localized values (e.g., {"erp_code": "240 ml", "conversion_en_us": "8.11 fl.oz"}).
     * @param {Object} customMapping - A mapping object where keys are language codes (e.g., "en_us") 
     * and values are the corresponding keys in the `obj` (e.g., { en_us: "conversion_en_us", default: "erp_code" }).
     * @returns {string} The localized value based on the custom mapping, or an empty string if not found.
     */
    const getLocalizedValueByCustomMapping = (obj, customMapping) => {
        const key = customMapping[normalizedLanguage.replace(/-/, "_")] ?? customMapping["default"];
        return obj[key] || "";
    };

    return { getLocalizedValueByKeyType, getLocalizedValueByCustomMapping };
};
