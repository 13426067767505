import React, { useState } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import { printReport } from "../../../../../store/report";

import classes from "./ReportLink.module.scss";
import { Icon } from "@iconify/react";

const ReportLink = ({ classes, items, label }) => {
	const dispatch = useDispatch();
	const [isCollapsed, setIsCollapsed] = useState(true);

	const handleOnClick = (e, report) => {
		e.preventDefault();
		dispatch(printReport({ id: report.id, code: report.erp_code }));
	};

	const handleToggle = () => {
		setIsCollapsed((prev) => !prev);
	};

	return (
		<div className={classes.reports}>
			<div className={classes.header} onClick={handleToggle}>
				{label}
				<Icon icon={isCollapsed ? "gridicons:chevron-down" : "gridicons:chevron-up"} />
			</div>
			<div className={clsx(classes.main, !isCollapsed ? classes.expanded : "")}>
				{items.map((report, key) => (
					<a key={key} href="#" onClick={(e) => handleOnClick(e, report)}>
						{report.erp_code}
					</a>
				))}
			</div>
		</div>
	);
};

ReportLink.defaultProps = {
	classes,
};

export default ReportLink;
