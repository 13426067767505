export const keys = {
	GLOBAL: {
		COMMON: {
			DESCRIPTION: "GLOBAL.COMMON.DESCRIPTION",
			LANGUAGE: "GLOBAL.COMMON.LANGUAGE",
			OF: "GLOBAL.COMMON.OF",
			PER: "GLOBAL.COMMON.PER",
			NOT_UPPER: "GLOBAL.COMMON.NOT_UPPER",
			COMPATIBLE_WITH: "GLOBAL.COMMON.COMPATIBLE_WITH",
			UNIT: "GLOBAL.COMMON.UNIT",
			UNITS: "GLOBAL.COMMON.UNITS",
			BAG: "GLOBAL.COMMON.BAG",
			BAGS: "GLOBAL.COMMON.BAGS",
			BARREL: "GLOBAL.COMMON.BARREL",
			BARRELS: "GLOBAL.COMMON.BARRELS",
			BOX: "GLOBAL.COMMON.BOX",
			BOXES: "GLOBAL.COMMON.BOXES",
			EACH: "GLOBAL.COMMON.EACH",
			EACHS: "GLOBAL.COMMON.EACHS",
			CANE: "GLOBAL.COMMON.CANE",
			CANES: "GLOBAL.COMMON.CANES",
			DRUM: "GLOBAL.COMMON.DRUM",
			DRUMS: "GLOBAL.COMMON.DRUMS",
			PAIL: "GLOBAL.COMMON.PAIL",
			PAILS: "GLOBAL.COMMON.PAILS",
			TOTE: "GLOBAL.COMMON.TOTE",
			TOTES: "GLOBAL.COMMON.TOTES",
			SHORT_MONTHS: "GLOBAL.COMMON.SHORT_MONTHS",
			SHORT_DAYS: "GLOBAL.COMMON.SHORT_DAYS",
			YES: "GLOBAL.COMMON.YES",
			NO: "GLOBAL.COMMON.NO",
			ADCLEAN: "GLOBAL.COMMON.ADCLEAN",
			ADMOBILE: "GLOBAL.COMMON.ADMOBILE",
			CCD: "GLOBAL.COMMON.CCD",
			INTERNET: "GLOBAL.COMMON.INTERNET",
			NICHIA: "GLOBAL.COMMON.NICHIA",
			EMAIL: "GLOBAL.COMMON.EMAIL",
			RADIO: "GLOBAL.COMMON.RADIO",
			REF: "GLOBAL.COMMON.REF",
			REP: "GLOBAL.COMMON.REP",
			MEDIAS: "GLOBAL.COMMON.MEDIAS",
			SPEC: "GLOBAL.COMMON.SPEC",
			SHOW: "GLOBAL.COMMON.SHOW",
			TV: "GLOBAL.COMMON.TV",
			WEB: "GLOBAL.COMMON.WEB",
			UNEXPECTED: "GLOBAL.COMMON.UNEXPECTED",
			JANUARY: "GLOBAL.COMMON.JANUARY",
			FEBRUARY: "GLOBAL.COMMON.FEBRUARY",
			MARCH: "GLOBAL.COMMON.MARCH",
			APRIL: "GLOBAL.COMMON.APRIL",
			MAY: "GLOBAL.COMMON.MAY",
			JUNE: "GLOBAL.COMMON.JUNE",
			JULY: "GLOBAL.COMMON.JULY",
			AUGUST: "GLOBAL.COMMON.AUGUST",
			SEPTEMBER: "GLOBAL.COMMON.SEPTEMBER",
			OCTOBER: "GLOBAL.COMMON.OCTOBER",
			NOVEMBER: "GLOBAL.COMMON.NOVEMBER",
			DECEMBER: "GLOBAL.COMMON.DECEMBER",
			MONTH: "GLOBAL.COMMON.MONTH",
			YEAR: "GLOBAL.COMMON.YEAR",
			STATUS: "GLOBAL.COMMON.STATUS",
			PROMO: "GLOBAL.COMMON.PROMO",
			BY: "GLOBAL.COMMON.BY",
			FRENCH: "GLOBAL.COMMON.FRENCH",
			ENGLISH: "GLOBAL.COMMON.ENGLISH",
			TIME: "GLOBAL.COMMON.TIME",
			TERMS_APPLY: "GLOBAL.COMMON.TERMS_APPLY",
			LOGIN_TO_SEE_PRICE: "GLOBAL.COMMON.LOGIN_TO_SEE_PRICE",
			CONTACT_US: "GLOBAL.COMMON.CONTACT_US",
			VIEW_CERTIFICAT: "GLOBAL.COMMON.VIEW_CERTIFICAT",
		},
		SEARCH: "GLOBAL.SEARCH",
		FILTER: "GLOBAL.FILTER",
		ADNEWS: {
			HEADER_LEFT: "GLOBAL.ADNEWS.HEADER_LEFT",
			HEADER_RIGHT: "GLOBAL.ADNEWS.HEADER_RIGHT",
			CAPTION_LEFT: "GLOBAL.ADNEWS.CAPTION_LEFT",
			CAPTION_RIGHT: "GLOBAL.ADNEWS.CAPTION_RIGHT",
			INPUT: "GLOBAL.ADNEWS.INPUT",
			BUTTON: "GLOBAL.ADNEWS.BUTTON",
			FEEDBACK: "GLOBAL.ADNEWS.FEEDBACK",
		},
		FOOTER: {
			ADSTORE: "GLOBAL.FOOTER.ADSTORE",
			ADSEAL: "GLOBAL.FOOTER.ADSEAL",
			ADBOND: "GLOBAL.FOOTER.ADBOND",
			ADTHANE: "GLOBAL.FOOTER.ADTHANE",
			ADFOAM: "GLOBAL.FOOTER.ADFOAM",
			THERMOBOND: "GLOBAL.FOOTER.THERMOBOND",
			ACCESSORIES: "GLOBAL.FOOTER.ACCESSORIES",
			SPECIALIZED: "GLOBAL.FOOTER.SPECIALIZED",
			CONSTRUCTION: "GLOBAL.FOOTER.CONSTRUCTION",
			PROFESSIONALS: "GLOBAL.FOOTER.PROFESSIONALS",
			MANUFACTURERS: "GLOBAL.FOOTER.MANUFACTURERS",
			ARCHITECT: "GLOBAL.FOOTER.ARCHITECT",
			SERVICES: "GLOBAL.FOOTER.SERVICES",
			WEBINAR: "GLOBAL.FOOTER.WEBINAR",
			TESTING: "GLOBAL.FOOTER.TESTING",
			IN_PERSON_CONSULTATION: "GLOBAL.FOOTER.IN_PERSON_CONSULTATION",
			REMOTE_CONSULTATION: "GLOBAL.FOOTER.REMOTE_CONSULTATION",
			ADMOBILE: "GLOBAL.FOOTER.ADMOBILE",
			TOOLS: "GLOBAL.FOOTER.TOOLS",
			TUTORIALS: "GLOBAL.FOOTER.TUTORIALS",
			COLOR_MATCH: "GLOBAL.FOOTER.COLOR_MATCH",
			SEALANT_COMP: "GLOBAL.FOOTER.SEALANT_COMP",
			PRODUCT_SELECTOR: "GLOBAL.FOOTER.PRODUCT_SELECTOR",
			SEALANT_CALC: "GLOBAL.FOOTER.SEALANT_CALC",
			FAQ: "GLOBAL.FOOTER.FAQ",
			LIBRARY: "GLOBAL.FOOTER.LIBRARY",
			ABOUT: "GLOBAL.FOOTER.ABOUT",
			CONTACT_US: "GLOBAL.FOOTER.CONTACT_US",
			FIND_STORE: "GLOBAL.FOOTER.FIND_STORE",
			ADNEWS: "GLOBAL.FOOTER.ADNEWS",
			TEAM: "GLOBAL.FOOTER.TEAM",
			CAREER: "GLOBAL.FOOTER.CAREER",
			HISTORY: "GLOBAL.FOOTER.HISTORY",
			PARTNERS: "GLOBAL.FOOTER.PARTNERS",
			LAB: "GLOBAL.FOOTER.LAB",
			FACTORY: "GLOBAL.FOOTER.FACTORY",
			GREENGUARD: "GLOBAL.FOOTER.GREENGUARD",
			ACCOUNT: "GLOBAL.FOOTER.ACCOUNT",
			ACCOUNT_MNG: "GLOBAL.FOOTER.ACCOUNT_MNG",
			ORDERS: "GLOBAL.FOOTER.ORDERS",
			INVOICES: "GLOBAL.FOOTER.INVOICES",
			OPTION_PRIVACY: "GLOBAL.FOOTER.OPTION_PRIVACY",
			OPTION_SITEMAP: "GLOBAL.FOOTER.OPTION_SITEMAP",
			FQA_SIGNUP: "GLOBAL.FOOTER.FQA_SIGNUP",
		},
		NAVBAR: {
			HOME: "GLOBAL.NAVBAR.HOME",
			PRODUCTS: "GLOBAL.NAVBAR.PRODUCTS",
			ALLPRODUCTS: "GLOBAL.NAVBAR.ALLPRODUCTS",
			SERVICES: "GLOBAL.NAVBAR.SERVICES",
			TOOLS: "GLOBAL.NAVBAR.TOOLS",
			FIND_STORE: "GLOBAL.NAVBAR.FIND_STORE",
			MYACCOUNT: "GLOBAL.NAVBAR.MYACCOUNT",
			MYPRODUCTS: "GLOBAL.NAVBAR.MYPRODUCTS",
			PAYMENT: "GLOBAL.NAVBAR.PAYMENT",
			INVOICES: "GLOBAL.NAVBAR.INVOICES",
			ORDERS: "GLOBAL.NAVBAR.ORDERS",
			PRODUCT_COMPATIBILITY: "GLOBAL.NAVBAR.PRODUCT_COMPATIBILITY",
			COLOR_TOOL: "GLOBAL.NAVBAR.COLOR_TOOL",
			GUIDED_SELECTOR: "GLOBAL.NAVBAR.GUIDED_SELECTOR",
			AUTOMATION: "GLOBAL.NAVBAR.AUTOMATION",
			ADROBOT: "GLOBAL.NAVBAR.ADROBOT",
			DTECH: "GLOBAL.NAVBAR.DTECH",
			PARTNERS: "GLOBAL.NAVBAR.PARTNERS",
			JAMESHARDI: "GLOBAL.NAVBAR.JAMESHARDI",
			NICHIHA: "GLOBAL.NAVBAR.NICHIHA",
			CERACLAD: "GLOBAL.NAVBAR.CERACLAD",
			CALCULATOR: "GLOBAL.NAVBAR.CALCULATOR",
			CONTACT_US: "GLOBAL.NAVBAR.CONTACT_US",
			ABOUT_US: "GLOBAL.NAVBAR.ABOUT_US",
			JOINTEAM: "GLOBAL.NAVBAR.JOINTEAM",
			LANGUE: "GLOBAL.NAVBAR.LANGUE",
			COLORMATCH: "GLOBAL.NAVBAR.COLORMATCH",
			FAQ: "GLOBAL.NAVBAR.FAQ",
			SIGN_IN_SIGN_UP: "GLOBAL.NAVBAR.SIGN_IN_SIGN_UP",
			LANGUAGE_MESSAGE: {
				CANADA_TO_USA: {
					MESSAGE: "GLOBAL.NAVBAR.LANGUAGE_MESSAGE.CANADA_TO_USA.MESSAGE",
					BUTTON: "GLOBAL.NAVBAR.LANGUAGE_MESSAGE.CANADA_TO_USA.BUTTON",
					LINK: "GLOBAL.NAVBAR.LANGUAGE_MESSAGE.CANADA_TO_USA.LINK",
				},
				USA_TO_CANADA: {
					MESSAGE: "GLOBAL.NAVBAR.LANGUAGE_MESSAGE.USA_TO_CANADA.MESSAGE",
					BUTTON: "GLOBAL.NAVBAR.LANGUAGE_MESSAGE.USA_TO_CANADA.BUTTON",
					LINK: "GLOBAL.NAVBAR.LANGUAGE_MESSAGE.USA_TO_CANADA.LINK",
				},
			},
		},
		COOKIE: {
			MESSAGE: "GLOBAL.COOKIE.MESSAGE",
			CONTENT: "GLOBAL.COOKIE.CONTENT",
			ACCEPT_BUTTON: "GLOBAL.COOKIE.ACCEPT_BUTTON",
			MANAGE_BUTTON: "GLOBAL.COOKIE.MANAGE_BUTTON",
			MANAGE_COOKIES: "GLOBAL.COOKIE.MANAGE_COOKIES",
			PRIVACY_POLICY: "GLOBAL.COOKIE.PRIVACY_POLICY",
			RESPECT_PRIVACY: "GLOBAL.COOKIE.RESPECT_PRIVACY",
			SAVE_PREFERENCES: "GLOBAL.COOKIE.SAVE_PREFERENCES",
			ACCEPT_ALL_COOKIES: "GLOBAL.COOKIE.ACCEPT_ALL_COOKIES",
			COOKIE_CATEGORIES: {
				ADVERTISING: {
					HEADER: "GLOBAL.COOKIE.COOKIE_CATEGORIES.ADVERTISING.HEADER",
					DESCRIPTION: "GLOBAL.COOKIE.COOKIE_CATEGORIES.ADVERTISING.DESCRIPTION",
				},
				FUNCTIONAL: {
					HEADER: "GLOBAL.COOKIE.COOKIE_CATEGORIES.FUNCTIONAL.HEADER",
					DESCRIPTION: "GLOBAL.COOKIE.COOKIE_CATEGORIES.FUNCTIONAL.DESCRIPTION",
				},
				ANALYTICAL: {
					HEADER: "GLOBAL.COOKIE.COOKIE_CATEGORIES.ANALYTICAL.HEADER",
					DESCRIPTION: "GLOBAL.COOKIE.COOKIE_CATEGORIES.ANALYTICAL.DESCRIPTION",
				},
				ESSENTIAL: {
					HEADER: "GLOBAL.COOKIE.COOKIE_CATEGORIES.ESSENTIAL.HEADER",
					DESCRIPTION: "GLOBAL.COOKIE.COOKIE_CATEGORIES.ESSENTIAL.DESCRIPTION",
				},
			},
		},
	},
	AUTH: {
		META: {
			TITLE: "AUTH.META.TITLE",
			DESCRIPTION: "AUTH.META.DESCRIPTION",
			OG_TITLE: "AUTH.META.OG_TITLE",
			OG_DESCRIPTION: "AUTH.META.OG_DESCRIPTION",
		},
		SIGN_IN: {
			SIGN_IN: "AUTH.SIGN_IN.SIGN_IN",
			SIGN_IN_TITLE: "AUTH.SIGN_IN.SIGN_IN_TITLE",
			EMAIL: "AUTH.SIGN_IN.EMAIL",
			PASSWORD: "AUTH.SIGN_IN.PASSWORD",
			REMEMBER: "AUTH.SIGN_IN.REMEMBER",
			FORGOT_PASSWORD: "AUTH.SIGN_IN.FORGOT_PASSWORD",
			NO_ACCOUNT: "AUTH.SIGN_IN.NO_ACCOUNT",
			SIGN_UP: "AUTH.SIGN_IN.SIGN_UP",
			LOG_OUT: "AUTH.SIGN_IN.LOG_OUT",
			WRONG_EMAIL: "AUTH.SIGN_IN.WRONG_EMAIL",
			WRONG_PASSWORD: "AUTH.SIGN_IN.WRONG_PASSWORD",
			WRONG_CONFIRMATION: "AUTH.SIGN_IN.WRONG_CONFIRMATION",
			SIGN_IN_FAILED: "AUTH.SIGN_IN.SIGN_IN_FAILED",
			FORGOT_PASSWORD_QUESTION: "AUTH.SIGN_IN.FORGOT_PASSWORD_QUESTION",
			FORGOT_PASSWORD_Description: "AUTH.SIGN_IN.FORGOT_PASSWORD_Description",
			REQUEST_RESET_LINK: "AUTH.SIGN_IN.REQUEST_RESET_LINK",
			BACK_TO_LOGIN: "AUTH.SIGN_IN.BACK_TO_LOGIN",
		},
		FORGOT_PASSWORD: {
			FORGOT_PASSWORD_QUESTION: "AUTH.FORGOT_PASSWORD.FORGOT_PASSWORD_QUESTION",
			FORGOT_PASSWORD_Description: "AUTH.FORGOT_PASSWORD.FORGOT_PASSWORD_Description",
			REQUEST_RESET_LINK: "AUTH.FORGOT_PASSWORD.REQUEST_RESET_LINK",
			BACK_TO_LOGIN: "AUTH.FORGOT_PASSWORD.BACK_TO_LOGIN",
			FORGOT_PASSWORD_SENT: "AUTH.FORGOT_PASSWORD.FORGOT_PASSWORD_SENT",
			FORGOT_PASSWORD_SENT_Description1: "AUTH.FORGOT_PASSWORD.FORGOT_PASSWORD_SENT_Description1",
			FORGOT_PASSWORD_SENT_Description2: "AUTH.FORGOT_PASSWORD.FORGOT_PASSWORD_SENT_Description2",
			RESEND_REQUEST: "AUTH.FORGOT_PASSWORD.RESEND_REQUEST",
			CREATE_NEW_PASSWORD: "AUTH.FORGOT_PASSWORD.CREATE_NEW_PASSWORD",
			SUBMIT: "AUTH.FORGOT_PASSWORD.SUBMIT",
			CONFIRM_PASSWORD: "AUTH.FORGOT_PASSWORD.CONFIRM_PASSWORD",
			PASSWORD_RESET: "AUTH.FORGOT_PASSWORD.PASSWORD_RESET",
			PASSWORD_RESET_DESCRIPTION: "AUTH.FORGOT_PASSWORD.PASSWORD_RESET_DESCRIPTION",
			LOGIN: "AUTH.FORGOT_PASSWORD.LOGIN",
			ERROR: "AUTH.FORGOT_PASSWORD.ERROR",
		},
		SIGN_UP: {
			TITLE: "AUTH.SIGN_UP.TITLE",
			COMPANY_NAME: "AUTH.SIGN_UP.COMPANY_NAME",
			EMAIL: "AUTH.SIGN_UP.EMAIL",
			PHONE: "AUTH.SIGN_UP.PHONE",
			LABEL_ADDRESS1: "AUTH.SIGN_UP.LABEL_ADDRESS1",
			LABEL_ADDRESS2: "AUTH.SIGN_UP.LABEL_ADDRESS2",
			ADDRESS1: "AUTH.SIGN_UP.ADDRESS1",
			ADDRESS2: "AUTH.SIGN_UP.ADDRESS2",
			ZIP_CODE: "AUTH.SIGN_UP.ZIP_CODE",
			POSTALCODE: "AUTH.SIGN_UP.POSTALCODE",
			CITY: "AUTH.SIGN_UP.CITY",
			PROVINCE: "AUTH.SIGN_UP.PROVINCE",
			STATE: "AUTH.SIGN_UP.STATE",
			COUNTRY: "CHECKOUT.ADDRESS.COUNTRY",
			IS_DISTRIBUTOR: "AUTH.SIGN_UP.IS_DISTRIBUTOR",
			WITCH_DISTRIBUTOR: "AUTH.SIGN_UP.WITCH_DISTRIBUTOR",
			HEAD_ABOUT: "AUTH.SIGN_UP.HEAD_ABOUT",
			ACCEPT: "AUTH.SIGN_UP.ACCEPT",
			TERMS: "AUTH.SIGN_UP.TERMS",
			CREATE_ACCOUNT: "AUTH.SIGN_UP.CREATE_ACCOUNT",
			ALREADY_ACCOUNT: "AUTH.SIGN_UP.ALREADY_ACCOUNT",
			ALREADY_ACCOUNT_ADFAST: "AUTH.SIGN_UP.ALREADY_ACCOUNT_ADFAST",
			SIGN_UP_FAILED: "AUTH.SIGN_UP.SIGN_UP_FAILED",
			FOR_NOT_VALID: "AUTH.SIGN_UP.FOR_NOT_VALID",
			ACCOUNT_CREATED: "AUTH.SIGN_UP.ACCOUNT_CREATED",
			STAY_CONNECTED: "AUTH.SIGN_UP.STAY_CONNECTED",
			THANKS: "AUTH.SIGN_UP.THANKS",
			MESSAGE1: "AUTH.SIGN_UP.MESSAGE1",
			MESSAGE2: "AUTH.SIGN_UP.MESSAGE2",
			FILL_MANDATORY_FIELD: "AUTH.SIGN_UP.FILL_MANDATORY_FIELD",
			FILL_VALID_EMAIL: "AUTH.SIGN_UP.FILL_VALID_EMAIL",
			FILL_VALID_PASSWORD: "AUTH.SIGN_UP.FILL_VALID_PASSWORD",
			FILL_VALID_CONFIRM_PASSWORD: "AUTH.SIGN_UP.FILL_VALID_CONFIRM_PASSWORD",
			PASSWORD_CONDITIONS: "AUTH.SIGN_UP.PASSWORD_CONDITIONS",
			DO_HAVE_ADFS_ACC: "AUTH.SIGN_UP.DO_HAVE_ADFS_ACC",
			HAVE_ADFS_ACC: "AUTH.SIGN_UP.HAVE_ADFS_ACC",
			DOESNT_HAVE_ADFS_ACC: "AUTH.SIGN_UP.DOESNT_HAVE_ADFS_ACC",
			ACCOUNTNUM: "AUTH.SIGN_UP.ACCOUNTNUM",
			MIN_ORDER: "AUTH.SIGN_UP.MIN_ORDER",
		},
		EMAIL_CONFIRMATION: {
			VERIFIED: "AUTH.EMAIL_CONFIRMATION.VERIFIED",
			TANKS: "AUTH.EMAIL_CONFIRMATION.TANKS",
		},
	},
	CART: {
		META: {
			TITLE: "CART.META.TITLE",
			DESCRIPTION: "CART.META.DESCRIPTION",
			OG_TITLE: "CART.META.OG_TITLE",
			OG_DESCRIPTION: "CART.META.OG_DESCRIPTION",
		},
		SIDE: {
			TITLE: "CART.SIDE.TITLE",
			DESCRIPTION: "CART.SIDE.DESCRIPTION",
			ITEMS_NUMBER: "CART.SIDE.ITEMS_NUMBER",
			SUBTOTAL: "CART.SIDE.SUBTOTAL",
			VIEW_CART: "CART.SIDE.VIEW_CART",
			CONTINUE_SHOPPING: "CART.SIDE.CONTINUE_SHOPPING",
		},
		OVERVIEW: {
			TITLE: "CART.OVERVIEW.TITLE",
			ITEMS: "CART.OVERVIEW.ITEMS",
			ITEM: "CART.OVERVIEW.ITEM",
			ORDERSUMMARY: "CART.OVERVIEW.ORDERSUMMARY",
			SUBTOTAL: "CART.OVERVIEW.SUBTOTAL",
			CHECKOUT: "CART.OVERVIEW.CHECKOUT",
			AVAILABLE: "CART.OVERVIEW.AVAILABLE",
			REMOVE: "CART.OVERVIEW.REMOVE",
			FORMAT: "CART.OVERVIEW.FORMAT",
		},
		ERROR: {
			TEXT1: "CART.ERROR.TEXT1",
			DATE: "CART.ERROR.DATE",
			INCLUSIVELY: "CART.ERROR.INCLUSIVELY",
			TEXT2: "CART.ERROR.TEXT2",
			TEXT3: "CART.ERROR.TEXT3",
		},
	},
	CHECKOUT: {
		META: {
			TITLE: "CHECKOUT.META.TITLE",
			DESCRIPTION: "CHECKOUT.META.DESCRIPTION",
			OG_TITLE: "CHECKOUT.META.OG_TITLE",
			OG_DESCRIPTION: "CHECKOUT.META.OG_DESCRIPTION",
		},
		MAIN: {
			TITLE: "CHECKOUT.MAIN.TITLE",
			ASSISTANCE: "CHECKOUT.MAIN.ASSISTANCE",
			PHONENUMBER: "CHECKOUT.MAIN.PHONENUMBER",
			POREFTITLE: "CHECKOUT.MAIN.POREFTITLE",
			POREF: "CHECKOUT.MAIN.POREF",
			REFERENCE: "CHECKOUT.MAIN.REFERENCE",
			FULLNAME: "CHECKOUT.MAIN.FULLNAME",
			DLVMODETITLE: "CHECKOUT.MAIN.DLVMODETITLE",
			DLVMODETITLEMOBILE: "CHECKOUT.MAIN.DLVMODETITLEMOBILE",
			DLVAT: "CHECKOUT.MAIN.DLVAT",
			CHOSEDLV: "CHECKOUT.MAIN.CHOSEDLV",
			DLVMODE: "CHECKOUT.MAIN.DLVMODE",
			DELIVERY: "CHECKOUT.MAIN.DELIVERY",
			PICKUPLABEL: "CHECKOUT.MAIN.PICKUPLABEL",
			DATEPICKER: "CHECKOUT.MAIN.DATEPICKER",
			DATEWARNING: "CHECKOUT.MAIN.DATEWARNING",
			DEFAULTADDRESS: "CHECKOUT.MAIN.DEFAULTADDRESS",
			OTHERADDRESS: "CHECKOUT.MAIN.OTHERADDRESS",
			EDITADDRESS: "CHECKOUT.MAIN.EDITADDRESS",
			ADDADDRESS: "CHECKOUT.MAIN.ADDADDRESS",
			BACK: "CHECKOUT.MAIN.BACK",
			CONTINUE: "CHECKOUT.MAIN.CONTINUE",
			PAYMENT: "CHECKOUT.MAIN.PAYMENT",
			CHARGES: "CHECKOUT.MAIN.CHARGES",
			DLVDAY: "CHECKOUT.MAIN.DLVDAY",
			PICKUPTIME: "CHECKOUT.MAIN.PICKUPTIME",
			CUSTNOTE: "CHECKOUT.MAIN.CUSTNOTE",
			SELECTDATEDLV: "CHECKOUT.MAIN.SELECTDATEDLV",
			SELECTDATE: "CHECKOUT.MAIN.SELECTDATE",
			ERROR: "CHECKOUT.MAIN.ERROR",
			FILL_MANDATORY_FIELD: "CHECKOUT.MAIN.FILL_MANDATORY_FIELD",
		},
		ADDRESS: {
			COUNTRY: "CHECKOUT.ADDRESS.COUNTRY",
			NAME: "CHECKOUT.ADDRESS.NAME",
			PHONE: "CHECKOUT.ADDRESS.PHONE",
			ADDRESS: "CHECKOUT.ADDRESS.ADDRESS",
			UNIT: "CHECKOUT.ADDRESS.UNIT",
			CITY: "CHECKOUT.ADDRESS.CITY",
			PROVINCE: "CHECKOUT.ADDRESS.PROVINCE",
			STATE: "CHECKOUT.ADDRESS.STATE",
			ZIP: "CHECKOUT.ADDRESS.ZIP",
			POSTALCODE: "CHECKOUT.ADDRESS.POSTALCODE",
			CONFIRM: "CHECKOUT.ADDRESS.CONFIRM",
			ADD: "CHECKOUT.ADDRESS.ADD",
			EDIT: "CHECKOUT.ADDRESS.EDIT",
			DEFAULT: "CHECKOUT.ADDRESS.DEFAULT",
		},
		SUMMARY: {
			REVIEW: "CHECKOUT.SUMMARY.REVIEW",
			SUBTOTAL: "CHECKOUT.SUMMARY.SUBTOTAL",
			TAX: "CHECKOUT.SUMMARY.TAX",
			TOTAL: "CHECKOUT.SUMMARY.TOTAL",
			ORDER: "CHECKOUT.SUMMARY.ORDER",
		},
		PAYMENT: {
			NETTERMS: "CHECKOUT.PAYMENT.NETTERMS",
			CREDITCARD: "CHECKOUT.PAYMENT.CREDITCARD",
			ADDCREDITCARD: "CHECKOUT.PAYMENT.ADDCREDITCARD",
			YOURCREDITCARD: "CHECKOUT.PAYMENT.YOURCREDITCARD",
			NAMECREDITCARD: "CHECKOUT.PAYMENT.NAMECREDITCARD",
			NUMBERCREDITCARD: "CHECKOUT.PAYMENT.NUMBERCREDITCARD",
			EXPCREDITCARD: "CHECKOUT.PAYMENT.EXPCREDITCARD",
			ENDING: "CHECKOUT.PAYMENT.ENDING",
			INVALIDCARD: "CHECKOUT.PAYMENT.INVALIDCARD",
			SUCCESS_TITLE: "CHECKOUT.PAYMENT.SUCCESS_TITLE",
			SUCCESS_SUB_TITLE: "CHECKOUT.PAYMENT.SUCCESS_SUB_TITLE",
			SUCCESS_TEXT1: "CHECKOUT.PAYMENT.SUCCESS_TEXT1",
			SUCCESS_TEXT2: "CHECKOUT.PAYMENT.SUCCESS_TEXT2",
			SUCCESS_BTN: "CHECKOUT.PAYMENT.SUCCESS_BTN",
			FAILED_TXT1: "CHECKOUT.PAYMENT.FAILED_TXT1",
			FAILED_TXT2: "CHECKOUT.PAYMENT.FAILED_TXT2",
		},
	},
	PRIVACY: {
		META: {
			TITLE: "PRIVACY.META.TITLE",
			DESCRIPTION: "PRIVACY.META.DESCRIPTION",
			OG_TITLE: "PRIVACY.META.OG_TITLE",
			OG_DESCRIPTION: "PRIVACY.META.OG_DESCRIPTION",
		},
		BANNER_CAPTION: "PRIVACY.BANNER_CAPTION",
		TITLE: "PRIVACY.TITLE",
		INTRO_1: "PRIVACY.INTRO_1",
		INTRO_2: "PRIVACY.INTRO_2",
		HEADER_1: "PRIVACY.HEADER_1",
		PAR_1: "PRIVACY.PAR_1",
		HEADER_2: "PRIVACY.HEADER_2",
		PAR_2: "PRIVACY.PAR_2",
		HEADER_3: "PRIVACY.HEADER_3",
		SUBHEADER_3_A: "PRIVACY.SUBHEADER_3_A",
		PAR_3_A_1: "PRIVACY.PAR_3_A_1",
		PAR_3_A_2: "PRIVACY.PAR_3_A_2",
		PAR_3_A_3: "PRIVACY.PAR_3_A_3",
		PAR_3_A_4: "PRIVACY.PAR_3_A_4",
		HEADER_4: "PRIVACY.HEADER_4",
		SUBHEADER_4_A: "PRIVACY.SUBHEADER_4_A",
		PAR_4_A: "PRIVACY.PAR_4_A",
		SUBHEADER_4_B: "PRIVACY.SUBHEADER_4_B",
		PAR_4_B: "PRIVACY.PAR_4_B",
		HEADER_5: "PRIVACY.HEADER_5",
		PAR_5: "PRIVACY.PAR_5",
		HEADER_6: "PRIVACY.HEADER_6",
		PAR_6_1: "PRIVACY.PAR_6_1",
		PAR_6_2: "PRIVACY.PAR_6_2",
		HEADER_7: "PRIVACY.HEADER_7",
		PAR_7: "PRIVACY.PAR_7",
		HEADER_8: "PRIVACY.HEADER_8",
		PAR_8: "PRIVACY.PAR_8",
		HEADER_9: "PRIVACY.HEADER_9",
		PAR_9: "PRIVACY.PAR_9",
		HEADER_10: "PRIVACY.HEADER_10",
		PAR_10_1: "PRIVACY.PAR_10_1",
		PAR_10_2: "PRIVACY.PAR_10_2",
		PAR_10_3: "PRIVACY.PAR_10_3",
	},
	DETAILED_PRODUCT: {
		META: {
			TITLE: "DETAILED_PRODUCT.META.TITLE",
			DESCRIPTION: "DETAILED_PRODUCT.META.DESCRIPTION",
			OG_TITLE: "DETAILED_PRODUCT.META.OG_TITLE",
			OG_DESCRIPTION: "DETAILED.PRODUCT.OG_DESCRIPTION",
		},
	},
	PRODUCTS: {
		META: {
			TITLE: "PRODUCTS.META.TITLE",
			DESCRIPTION: "PRODUCTS.META.DESCRIPTION",
			OG_TITLE: "PRODUCTS.META.OG_TITLE",
			OG_DESCRIPTION: "PRODUCTS.META.OG_DESCRIPTION",
		},
		OVERVIEW: {
			FORMAT: "PRODUCTS.OVERVIEW.FORMAT",
			FORMAT_AVAILABLE: "PRODUCTS.OVERVIEW.FORMAT_AVAILABLE",
			COLOR: "PRODUCTS.OVERVIEW.COLOR",
			SUBSTRATES: "PRODUCTS.OVERVIEW.SUBSTRATES",
			SPECIFICATIONS: "PRODUCTS.OVERVIEW.SPECIFICATIONS",
			COMPATIBILITY: "PRODUCTS.OVERVIEW.COMPATIBILITY",
			VIEW_COLORS_BTN: "PRODUCTS.OVERVIEW.VIEW_COLOR",
			BUY_NOW_BTN: "PRODUCTS.OVERVIEW.BUY_NOW",
			ADD_TO_CART_BTN: "PRODUCTS.OVERVIEW.ADD_TO_CART",
			TECHNICAL_DOCUMENT: "PRODUCTS.OVERVIEW.TECHNICAL_DOCUMENT",
			PYYSICAL_PROPERTIES: "PRODUCTS.OVERVIEW.PYYSICAL_PROPERTIES",
			CAROUSEL_TAB: "PRODUCTS.OVERVIEW.CAROUSEL_TAB",
			FEATURES: "PRODUCTS.OVERVIEW.FEATURES",
			TDS: "PRODUCTS.OVERVIEW.TDS",
			SDS: "PRODUCTS.OVERVIEW.SDS",
			GARANTIE: "PRODUCTS.OVERVIEW.GARANTIE",
			RESOURCES: "PRODUCTS.OVERVIEW.RESOURCES",
			VIEW_PRODUCT: "PRODUCTS.OVERVIEW.VIEW_PRODUCT",
			SELECT_OPTION: "PRODUCTS.OVERVIEW.SELECT_OPTION",
			SELECT_FORMAT: "PRODUCTS.OVERVIEW.SELECT_FORMAT",
			SELECT_YOUR_FORMAT: "PRODUCTS.OVERVIEW.SELECT_FORMAT",
			SELECT_QUANTITY: "PRODUCTS.OVERVIEW.SELECT_QUANTITY",
			PRICE_LIST: "PRODUCTS.OVERVIEW.PRICE_LIST",
			PO_OF: "PRODUCTS.OVERVIEW.PO_OF",
			CALC_TOOL: "PRODUCTS.OVERVIEW.CALC_TOOL",
			CALCULATOR: "PRODUCTS.OVERVIEW.CALCULATOR",
			DESCRIPTION: "PRODUCTS.OVERVIEW.DESCRIPTION",
			DETAILS: "PRODUCTS.OVERVIEW.DETAILS",
			APPLICATION: "PRODUCTS.OVERVIEW.APPLICATION",
			RELATED_DOC: "PRODUCTS.OVERVIEW.RELATED_DOC",
			PACKAGING: "PRODUCTS.OVERVIEW.PACKAGING",
			USAGE: "PRODUCTS.OVERVIEW.USAGE",
			OVERVIEW: "PRODUCTS.OVERVIEW.OVERVIEW",
			RESETALL: "PRODUCTS.OVERVIEW.RESETALL",
		},
		VARIANT: {
			PRODUCT_DETAILS_PAGE: "PRODUCTS.VARIANT.PRODUCT_DETAILS_PAGE",
			QUANTITY: "PRODUCTS.VARIANT.QUANTITY",
			LIST_PRICE: "PRODUCTS.VARIANT.LIST_PRICE",
			YOUR_PRICE: "PRODUCTS.VARIANT.YOUR_PRICE",
			GET_IT_BETWEEN: "PRODUCTS.VARIANT.GET_IT_BETWEEN",
			AVAILBLE1: "PRODUCTS.VARIANT.AVAILBLE1",
			AVAILBLE2: "PRODUCTS.VARIANT.AVAILBLE2",
			NOT_AVAILABLE: "PRODUCTS.VARIANT.NOT_AVAILABLE",
			LOG_IN: "PRODUCTS.VARIANT.LOG_IN",
			LOG_IN_AD: "PRODUCTS.VARIANT.LOG_IN_AD",
			GET_QUOTE: "PRODUCTS.VARIANT.GET_QUOTE",
			GET_QUOTE_AD: "PRODUCTS.VARIANT.GET_QUOTE_AD",
			QUOTE_SENT: "PRODUCTS.VARIANT.QUOTE_SENT",
			SELECT_COLOR: "PRODUCTS.VARIANT.SELECT_COLOR",
			STANDARD_COLOR: "PRODUCTS.VARIANT.STANDARD_COLOR",
			ON_DEMAND_COLOR: "PRODUCTS.VARIANT.ON_DEMAND_COLOR",
			MIN_ORDER: "PRODUCTS.VARIANT.MIN_ORDER",
			TRY: "PRODUCTS.VARIANT.TRY",
			STANDARD: "PRODUCTS.VARIANT.STANDARD",
			ON_REQUEST: "PRODUCTS.VARIANT.ON_REQUEST",
			COLOR_NOT_FOUND: "PRODUCTS.VARIANT.COLOR_NOT_FOUND",
			VARIANTSIDEBAR: {
				Transparent: "PRODUCTS.VARIANT.VARIANTSIDEBAR.Transparent",
				White: "PRODUCTS.VARIANT.VARIANTSIDEBAR.White",
				Gray: "PRODUCTS.VARIANT.VARIANTSIDEBAR.Gray",
				Red: "PRODUCTS.VARIANT.VARIANTSIDEBAR.Red",
				Orange: "PRODUCTS.VARIANT.VARIANTSIDEBAR.Orange",
				Yellow: "PRODUCTS.VARIANT.VARIANTSIDEBAR.Yellow",
				Green: "PRODUCTS.VARIANT.VARIANTSIDEBAR.Green",
				Blue: "PRODUCTS.VARIANT.VARIANTSIDEBAR.Blue",
				Purple: "PRODUCTS.VARIANT.VARIANTSIDEBAR.Purple",
				Metalic: "PRODUCTS.VARIANT.VARIANTSIDEBAR.Metalic",
			},
		},
		SHOW_ONLY_FAVORITE: "PRODUCTS.SHOW_ONLY_FAVORITE",
		SHOW_ONLY_QUOTED: "PRODUCTS.SHOW_ONLY_QUOTED",
		SORT_BY: "PRODUCTS.SORT_BY",
		FEATURED: "PRODUCTS.FEATURED",
		ALPHANUMERICAL_ASC: "PRODUCTS.ALPHANUMERICAL_ASC",
		ALPHANUMERICAL_DES: "PRODUCTS.ALPHANUMERICAL_DES",
		SEARCH_COLOR_NUMBER: "PRODUCTS.SEARCH_COLOR_NUMBER",
		CATEGORY: "PRODUCTS.CATEGORY",
		INDUSTRIES: "PRODUCTS.INDUSTRIES",
		BRANDS: "PRODUCTS.BRANDS",
		VIEWMORE: "PRODUCTS.VIEWMORE",
		VIEWLESS: "PRODUCTS.VIEWLESS",
		RESET_FILTERS: "PRODUCTS.RESET_FILTERS",
		LOGIN_TXT: "PRODUCTS.LOGIN_TXT",
		FILTERS: "PRODUCTS.FILTERS",
		BACK_TO_TOP: "PRODUCTS.BACK_TO_TOP",
		APPLY: "PRODUCTS.APPLY",
		CLEAR: "PRODUCTS.CLEAR",
	},
	PROFILE: {
		META: {
			TITLE: "PROFILE.META.TITLE",
			DESCRIPTION: "PROFILE.META.DESCRIPTION",
			OG_TITLE: "PROFILE.META.OG_TITLE",
			OG_DESCRIPTION: "PROFILE.META.OG_DESCRIPTION",
		},
	},
	SERVICE: {
		META: {
			TITLE: "SERVICE.META.TITLE",
			DESCRIPTION: "SERVICE.META.DESCRIPTION",
			OG_TITLE: "SERVICE.META.OG_TITLE",
			OG_DESCRIPTION: "SERVICE.META.OG_DESCRIPTION",
		},
	},
	VARIANT: {
		META: {
			TITLE: "VARIANT.META.TITLE",
			DESCRIPTION: "VARIANT.META.DESCRIPTION",
			OG_TITLE: "VARIANT.META.OG_TITLE",
			OG_DESCRIPTION: "VARIANT.META.OG_DESCRIPTION",
		},
	},
	HOME: {
		META: {
			TITLE: "HOME.META.TITLE",
			DESCRIPTION: "HOME.META.DESCRIPTION",
			OG_TITLE: "HOME.META.OG_TITLE",
			OG_DESCRIPTION: "HOME.META.OG_DESCRIPTION",
		},
		NEWSLETTER: {
			TITLE: "HOME.NEWSLETTER.TITLE",
			INSTA: "HOME.NEWSLETTER.INSTA",
			FIRSTNAME: "HOME.NEWSLETTER.FIRSTNAME",
			LASTNAME: "HOME.NEWSLETTER.LASTNAME",
			EMAIL: "HOME.NEWSLETTER.EMAIL",
			COMPANY: "HOME.NEWSLETTER.COMPANY",
			PROMOTIONAL: "HOME.NEWSLETTER.PROMOTIONAL",
			ADFASTNEWS: "HOME.NEWSLETTER.ADFASTNEWS",
			TEXTE1: "HOME.NEWSLETTER.TEXTE1",
			TEXTE2: "HOME.NEWSLETTER.TEXTE2",
			TEXTE3: "HOME.NEWSLETTER.TEXTE3",
			TEXTE4: "HOME.NEWSLETTER.TEXTE4",
		},
		BANNER_CAPTION: "HOME.BANNER_CAPTION",
		SEGMENT_PICKER: {
			TITLE: "HOME.SEGMENT_PICKER.TITLE",
			MANUFACTURER: "HOME.SEGMENT_PICKER.MANUFACTURER",
			CONSTRUCTION: "HOME.SEGMENT_PICKER.CONSTRUCTION",
			ARCHITECT: "HOME.SEGMENT_PICKER.ARCHITECT",
		},
		DISCOVER_PRODUCT: "HOME.DISCOVER_PRODUCT",
		ADSTORE_CAPTION: "HOME.ADSTORE_CAPTION",
		ADSCHOOL_CAPTION: "HOME.ADSCHOOL_CAPTION",
		ADMOBILE_CAPTION: "HOME.ADMOBILE_CAPTION",
		PRODUCT_NEED: "HOME.PRODUCT_NEED",
		HOW_MANY: "HOME.HOW_MANY",
		WHAT_COLOR: "HOME.WHAT_COLOR",
		ADTOOLS_CAPTION: "HOME.ADTOOLS_CAPTION",
		PARTNER: "HOME.PARTNER",
		BUTTON_ADSTORE: "HOME.BUTTON_ADSTORE",
		BUTTON_SEE_MORE: "HOME.BUTTON_SEE_MORE",
		BUTTON_SEE_LESS: "HOME.BUTTON_SEE_LESS",
		BUTTON_SHOP_NOW: "HOME.BUTTON_SHOP_NOW",
		NEED_HELP: "HOME.NEED_HELP",
		CONTACT_US: "HOME.CONTACT_US",
	},
	ACCOUNT: {
		META: {
			TITLE: "ACCOUNT.META.TITLE",
			DESCRIPTION: "ACCOUNT.META.DESCRIPTION",
			OG_TITLE: "ACCOUNT.META.OG_TITLE",
			OG_DESCRIPTION: "ACCOUNT.META.OG_DESCRIPTION",
		},
		ACCOUNT: {
			TITLE: "ACCOUNT.ACCOUNT.TITLE",
			HOLDER: "ACCOUNT.ACCOUNT.HOLDER",
			NAME: "ACCOUNT.ACCOUNT.NAME",
			ORDER_TITLE: "ACCOUNT.ACCOUNT.ORDER_TITLE",
			ORDER_DESCRIPTION: "ACCOUNT.ACCOUNT.ORDER_DESCRIPTION",
			PROFIL_TITLE: "ACCOUNT.ACCOUNT.PROFIL_TITLE",
			PROFIL_DESCRIPTION: "ACCOUNT.ACCOUNT.PROFIL_DESCRIPTION",
			ADDRESSES_TITLE: "ACCOUNT.ACCOUNT.ADDRESSES_TITLE",
			ADDRESSES_DESCRIPTION: "ACCOUNT.ACCOUNT.ADDRESSES_DESCRIPTION",
			PAYMENT_TITLE: "ACCOUNT.ACCOUNT.PAYMENT_TITLE",
			PAYMENT_DESCRIPTION: "ACCOUNT.ACCOUNT.PAYMENT_DESCRIPTION",
		},
		PROFILE: {
			TITLE: "ACCOUNT.PROFILE.TITLE",
			SAVE: "ACCOUNT.PROFILE.SAVE",
			EDIT: "ACCOUNT.PROFILE.EDIT",
			RESETPASSWORD: "ACCOUNT.PROFILE.RESETPASSWORD",
			REQUESTSENT: "ACCOUNT.PROFILE.REQUESTSENT",
		},
		ADDRESS: {
			TITLE: "ACCOUNT.ADDRESS.TITLE",
			ADD: "ACCOUNT.ADDRESS.ADD",
			EDIT: "ACCOUNT.ADDRESS.EDIT",
			REMOVE: "ACCOUNT.ADDRESS.REMOVE",
			SETDEFAULT: "ACCOUNT.ADDRESS.SETDEFAULT",
			DEFAULT: "ACCOUNT.ADDRESS.DEFAULT",
		},
		WALLET: {
			TITLE: "ACCOUNT.WALLET.TITLE",
			ADD: "ACCOUNT.WALLET.ADD",
			ADDTITLE: "ACCOUNT.WALLET.ADDTITLE",
			ADDSUBTITLE: "ACCOUNT.WALLET.ADDSUBTITLE",
			ADDTEXTE: "ACCOUNT.WALLET.ADDTEXTE",
			REMOVE: "ACCOUNT.WALLET.REMOVE",
		},
		ORDERS: {
			TITLE: "ACCOUNT.ORDERS.TITLE",
			OPEN: "ACCOUNT.ORDERS.OPEN",
			CONFIRMED: "ACCOUNT.ORDERS.CONFIRMED",
			DELIVERED: "ACCOUNT.ORDERS.DELIVERED",
			INVOICED: "ACCOUNT.ORDERS.INVOICED",
			CANCELED: "ACCOUNT.ORDERS.CANCELED",
			PENDING: "ACCOUNT.ORDERS.PENDING",
			SALESORDER: "ACCOUNT.ORDERS.SALESORDER",
			PURCHASEORDER: "ACCOUNT.ORDERS.PURCHASEORDER",
			ORDERDATE: "ACCOUNT.ORDERS.ORDERDATE",
			TOTAL: "ACCOUNT.ORDERS.TOTAL",
			STATUS: "ACCOUNT.ORDERS.STATUS",
			BUYALLAGAIN: "ACCOUNT.ORDERS.BUYALLAGAIN",
			BUYAGAIN: "ACCOUNT.ORDERS.BUYAGAIN",
			VIEWPS: "ACCOUNT.ORDERS.VIEWPS",
			VIEWIS: "ACCOUNT.ORDERS.VIEWIS",
			VIEWCS: "ACCOUNT.ORDERS.VIEWCS",
			FILTERS: {
				DATE_RANGE: "ACCOUNT.ORDERS.FILTERS.DATE_RANGE",
				DATE_RANGE_ALL: "ACCOUNT.ORDERS.FILTERS.DATE_RANGE_ALL",
				DATE_RANGE_7_DAYS_AGO: "ACCOUNT.ORDERS.FILTERS.DATE_RANGE_7_DAYS_AGO",
				DATE_RANGE_1_MONTH_AGO: "ACCOUNT.ORDERS.FILTERS.DATE_RANGE_1_MONTH_AGO",
				DATE_RANGE_6_MONTHS_AGO: "ACCOUNT.ORDERS.FILTERS.DATE_RANGE_6_MONTHS_AGO",
				DATE_RANGE_1_YEAR_AGO: "ACCOUNT.ORDERS.FILTERS.DATE_RANGE_1_YEAR_AGO",
			},
			EMPTY_STATES: {
				MATCHESNOTFOUND: {
					HEADER: "ACCOUNT.ORDERS.EMPTY_STATES.MATCHESNOTFOUND.HEADER",
					MESSAGE: "ACCOUNT.ORDERS.EMPTY_STATES.MATCHESNOTFOUND.MESSAGE",
					ACTION: "ACCOUNT.ORDERS.EMPTY_STATES.MATCHESNOTFOUND.ACTION",
					BUTTON: "ACCOUNT.ORDERS.EMPTY_STATES.MATCHESNOTFOUND.BUTTON",
				},
				ORDERSNOTFOUND: {
					HEADER: "ACCOUNT.ORDERS.EMPTY_STATES.ORDERSNOTFOUND.HEADER",
					MESSAGE: "ACCOUNT.ORDERS.EMPTY_STATES.ORDERSNOTFOUND.MESSAGE",
					ACTION: "ACCOUNT.ORDERS.EMPTY_STATES.ORDERSNOTFOUND.ACTION",
					BUTTON: "ACCOUNT.ORDERS.EMPTY_STATES.ORDERSNOTFOUND.BUTTON",
				},
			},
		},
	},
	MANUFACTURING: {
		META: {
			TITLE: "MANUFACTURING.META.TITLE",
			DESCRIPTION: "MANUFACTURING.META.DESCRIPTION",
			OG_TITLE: "MANUFACTURING.META.OG_TITLE",
			OG_DESCRIPTION: "MANUFACTURING.META.OG_DESCRIPTION",
		},
		PRODUCT_USED: "MANUFACTURING.PRODUCT_USED",
		BANNER_INTRODUCTION: "MANUFACTURING.BANNER_INTRODUCTION",
		BANNER_Title: "MANUFACTURING.BANNER_Title",
		BANNER_CAPTION: "MANUFACTURING.BANNER_CAPTION",
		SELECT_TITLE: "MANUFACTURING.SELECT_TITLE",
		SELECT_DESCRIPTION: "MANUFACTURING.SELECT_DESCRIPTION",
		SELECT_SEARCH: "MANUFACTURING.SELECT_SEARCH",
		SELECT_INDUSTRY: "MANUFACTURING.SELECT_INDUSTRY",
		READ_MORE: "MANUFACTURING.READ_MORE",
		ADDITIONAL_VIDEO: "MANUFACTURING.ADDITIONAL_VIDEO",
		PRODUCT_GRID_TITLE: "MANUFACTURING.PRODUCT_GRID_TITLE",
		DOWNLOAD_SHEET: "MANUFACTURING.DOWNLOAD_SHEET",
		INDUSTRY: "MANUFACTURING.INDUSTRY",
	},
	CONSTRUCTION: {
		META: {
			TITLE: "CONSTRUCTION.META.TITLE",
			DESCRIPTION: "CONSTRUCTION.META.DESCRIPTION",
			OG_TITLE: "CONSTRUCTION.META.OG_TITLE",
			OG_DESCRIPTION: "CONSTRUCTION.META.OG_DESCRIPTION",
		},
		VISUAL_DIAGRAM: "CONSTRUCTION.VISUAL_DIAGRAM",
		FIND_THE_PRODUCT: "CONSTRUCTION.FIND_THE_PRODUCT",
		PRODUCT_GRID_TITLE: "CONSTRUCTION.PRODUCT_GRID_TITLE",
		ALL_PRODUCTS: "CONSTRUCTION.ALL_PRODUCTS",
		SEALANTS: "CONSTRUCTION.SEALANTS",
		INSULATING_FOAMS: "CONSTRUCTION.INSULATING_FOAMS",
		ADHESIVES: "CONSTRUCTION.ADHESIVES",
		TITLE1: "CONSTRUCTION.TITLE1",
		DESCRIPTION: "CONSTRUCTION.DESCRIPTION",
		TITLE2: "CONSTRUCTION.TITLE2",
	},
	ARCHITECT: {
		HELP: {
			TITLE: "ARCHITECT.HELP.TITLE",
			SALESREP: "ARCHITECT.HELP.SALESREP",
			FINDDOC: "ARCHITECT.HELP.FINDDOC",
			WEBINAIRES: "ARCHITECT.HELP.WEBINAIRES",
			DISTRIBUTOR: "ARCHITECT.HELP.DISTRIBUTOR",
		},
		META: {
			TITLE: "ARCHITECT.META.TITLE",
			DESCRIPTION: "ARCHITECT.META.DESCRIPTION",
			OG_TITLE: "ARCHITECT.META.OG_TITLE",
			OG_DESCRIPTION: "ARCHITECT.META.OG_DESCRIPTION",
		},
		TITLE: "ARCHITECT.TITLE",
		DESCRIPTION: "ARCHITECT.DESCRIPTION",
		LIBRARYTXT: "ARCHITECT.LIBRARYTXT",
		VISITTXT: "ARCHITECT.VISITTXT",
		WEBINARTXT: "ARCHITECT.WEBINARTXT",
		EVENTTXT: "ARCHITECT.EVENTTXT",
		LIBRARY: {
			META: {
				TITLE: "ARCHITECT.LIBRARY.META.TITLE",
				DESCRIPTION: "ARCHITECT.LIBRARY.META.DESCRIPTION",
				OG_TITLE: "ARCHITECT.LIBRARY.META.OG_TITLE",
				OG_DESCRIPTION: "ARCHITECT.LIBRARY.META.OG_DESCRIPTION",
			},
			TITLE: "ARCHITECT.LIBRARY.TITLE",
			DESCRIPTION: "ARCHITECT.LIBRARY.DESCRIPTION",
			REQUEST: "ARCHITECT.LIBRARY.REQUEST",
			RESSOURCES: "ARCHITECT.LIBRARY.RESSOURCES",
			LABEL: "ARCHITECT.LIBRARY.LABEL",
			SEARCH: "ARCHITECT.LIBRARY.SEARCH",
			RESET: "ARCHITECT.LIBRARY.RESET",
			NO_RESULT_FOUND: "ARCHITECT.LIBRARY.NO_RESULT_FOUND",
			SDS_TDS: "ARCHITECT.LIBRARY.SDS_TDS",
		},
		VISIT: {
			META: {
				TITLE: "ARCHITECT.VISIT.META.TITLE",
				DESCRIPTION: "ARCHITECT.VISIT.META.DESCRIPTION",
				OG_TITLE: "ARCHITECT.VISIT.META.OG_TITLE",
				OG_DESCRIPTION: "ARCHITECT.VISIT.META.OG_DESCRIPTION",
			},
			TITLE: "ARCHITECT.VISIT.TITLE",
			DESCRIPTION1: "ARCHITECT.VISIT.DESCRIPTION1",
			DESCRIPTION2: "ARCHITECT.VISIT.DESCRIPTION2",
			DESCRIPTION3: "ARCHITECT.VISIT.DESCRIPTION3",
			REQUEST: "ARCHITECT.VISIT.REQUEST",
		},
		WEBINAR: {
			META: {
				TITLE: "ARCHITECT.WEBINAR.META.TITLE",
				DESCRIPTION: "ARCHITECT.WEBINAR.META.DESCRIPTION",
				OG_TITLE: "ARCHITECT.WEBINAR.META.OG_TITLE",
				OG_DESCRIPTION: "ARCHITECT.WEBINAR.META.OG_DESCRIPTION",
			},
			TITLE: "ARCHITECT.WEBINAR.TITLE",
			DESCRIPTION: "ARCHITECT.WEBINAR.DESCRIPTION",
			UPCOMING: "ARCHITECT.WEBINAR.UPCOMING",
			QUEBEC: "ARCHITECT.WEBINAR.QUEBEC",
			CANADA: "ARCHITECT.WEBINAR.CANADA",
			USA: "ARCHITECT.WEBINAR.USA",
			ATS: "ARCHITECT.WEBINAR.ATS",
			ON_LIGNE: "ARCHITECT.WEBINAR.ON_LIGNE",
			IN_PERSON: "ARCHITECT.WEBINAR.IN_PERSON",
			REGISTER: "ARCHITECT.WEBINAR.REGISTER",
			LOCATION: "ARCHITECT.WEBINAR.LOCATION",
			EVENT: "ARCHITECT.WEBINAR.EVENT",
			DATE: "ARCHITECT.WEBINAR.DATE",
		},
		EVENT: {
			META: {
				TITLE: "ARCHITECT.EVENT.META.TITLE",
				DESCRIPTION: "ARCHITECT.EVENT.META.DESCRIPTION",
				OG_TITLE: "ARCHITECT.EVENT.META.OG_TITLE",
				OG_DESCRIPTION: "ARCHITECT.EVENT.META.OG_DESCRIPTION",
			},
			TITLE: "ARCHITECT.EVENT.TITLE",
			UPCOMING: "ARCHITECT.EVENT.UPCOMING",
			LOCATION: "ARCHITECT.EVENT.LOCATION",
			EVENT: "ARCHITECT.EVENT.EVENT",
			ADROBOT_TITLE: "ARCHITECT.EVENT.ADROBOT_TITLE",
			ADROBOT_DESCRIPTION: "ARCHITECT.EVENT.ADROBOT_DESCRIPTION",
			ADROBOT_TEXT: "ARCHITECT.EVENT.ADROBOT_TEXT",
			VIEW_WEBSITE: "ARCHITECT.EVENT.VIEW_WEBSITE",
		},
	},
	CONTACT: {
		META: {
			TITLE: "CONTACT.META.TITLE",
			DESCRIPTION: "CONTACT.META.DESCRIPTION",
			OG_TITLE: "CONTACT.META.OG_TITLE",
			OG_DESCRIPTION: "CONTACT.META.OG_DESCRIPTION",
		},
		TITLE: "CONTACT.TITLE",
		TITLE_FORM: "CONTACT.TITLE_FORM",
		DESCRIPTION: "CONTACT.DESCRIPTION",
		HOW_HELP: "CONTACT.HOW_HELP",
		IS_COMPANY: "CONTACT.IS_COMPANY",
		OUR_OFFICE: "CONTACT.OUR_OFFICE",
		PHONE: "CONTACT.PHONE",
		FREE: "CONTACT.FREE",
		EMAIL: "CONTACT.EMAIL",
		FAX: "CONTACT.FAX",
		ADDRESS: "CONTACT.ADDRESS",
		CONFIRMATION: "CONTACT.CONFIRMATION",
		OPENING_HOURS: "CONTACT.OPENING_HOURS",
	},
	INCONSTRUCTION: {
		META: {
			TITLE: "INCONSTRUCTION.META.TITLE",
			DESCRIPTION: "INCONSTRUCTION.META.DESCRIPTION",
			OG_TITLE: "INCONSTRUCTION.META.OG_TITLE",
			OG_DESCRIPTION: "INCONSTRUCTION.META.OG_DESCRIPTION",
		},
		TITLE: "INCONSTRUCTION.TITLE",
		TEXT0: "INCONSTRUCTION.TEXT0",
		TEXT1: "INCONSTRUCTION.TEXT1",
		TEXT2: "INCONSTRUCTION.TEXT2",
		TEXT3: "INCONSTRUCTION.TEXT3",
		TEXT35: "INCONSTRUCTION.TEXT35",
		TEXT4: "INCONSTRUCTION.TEXT4",
		TEXT45: "INCONSTRUCTION.TEXT45",
		TEXT5: "INCONSTRUCTION.TEXT5",
		TEXT6: "INCONSTRUCTION.TEXT6",
		TEXT7: "INCONSTRUCTION.TEXT7",
		TEXT8: "INCONSTRUCTION.TEXT8",
		TEXT9: "INCONSTRUCTION.TEXT9",
	},
	ADROBOT: {
		META: {
			TITLE: "MANUFACTURING.META.TITLE",
			DESCRIPTION: "MANUFACTURING.META.DESCRIPTION",
			OG_TITLE: "MANUFACTURING.META.OG_TITLE",
			OG_DESCRIPTION: "MANUFACTURING.META.OG_DESCRIPTION",
		},
		TITLE1: "ADROBOT.TITLE1",
		TITLE2: "ADROBOT.TITLE2",
		TITLE3: "ADROBOT.TITLE3",
		TITLE4: "ADROBOT.TITLE4",
		DESCRIPTION1: "ADROBOT.DESCRIPTION1",
		DESCRIPTION2: "ADROBOT.DESCRIPTION2",
		DESCRIPTION3: "ADROBOT.DESCRIPTION3",
		PICKER_TITLE1: "ADROBOT.PICKER_TITLE1",
		PICKER_TITLE2: "ADROBOT.PICKER_TITLE2",
		PICKER_TITLE3: "ADROBOT.PICKER_TITLE3",
		PICKER_DESCRIPTION1: "ADROBOT.PICKER_DESCRIPTION1",
		PICKER_DESCRIPTION2: "ADROBOT.PICKER_DESCRIPTION2",
		PICKER_DESCRIPTION3: "ADROBOT.PICKER_DESCRIPTION3",
		PICKER_DESCRIPTION4: "ADROBOT.PICKER_DESCRIPTION4",
		INCLUDE: "ADROBOT.INCLUDE",
		OVERVIEW: "ADROBOT.OVERVIEW",
		ADVANTAGES: "ADROBOT.ADVANTAGES",
		APPLICATIONS: "ADROBOT.APPLICATIONS",
		MAIN_CHAR: "ADROBOT.MAIN_CHAR",
		PAYLOAD: "ADROBOT.PAYLOAD",
		MAX_REACH: "ADROBOT.MAX_REACH",
		CONSTRUCTION_TYPE: "ADROBOT.CONSTRUCTION_TYPE",
		VERSION_ENV: "ADROBOT.VERSION_ENV",
		MOUNT_POS: "ADROBOT.MOUNT_POS",
		CLASS: "ADROBOT.CLASS",
		TECH_DATA_SHEET: "ADROBOT.TECH_DATA_SHEET",
		CONTACT: "ADROBOT.CONTACT",
		CONTACT_TXT: "ADROBOT.CONTACT_TXT",
		CONTACT_TXT2: "ADROBOT.CONTACT_TXT2",
		MORE: "ADROBOT.MORE",
		LESS: "ADROBOT.LESS",
	},
	TOOLS: {
		TITLE: "TOOLS.TITLE",
		SEALANTCALCULATOR: {
			META: {
				TITLE: "TOOLS.SEALANTCALCULATOR.META.TITLE",
				DESCRIPTION: "TOOLS.SEALANTCALCULATOR.META.DESCRIPTION",
				OG_TITLE: "TOOLS.SEALANTCALCULATOR.META.OG_TITLE",
				OG_DESCRIPTION: "TOOLS.SEALANTCALCULATOR.META.OG_DESCRIPTION",
			},
			TITLE: "TOOLS.SEALANTCALCULATOR.TITLE",
			DESCRIPTION: "TOOLS.SEALANTCALCULATOR.DESCRIPTION",
			DIMENSION_TITLE: "TOOLS.SEALANTCALCULATOR.DIMENSION_TITLE",
			DIMENSION: "TOOLS.SEALANTCALCULATOR.DIMENSION",
			FEET_TITLE: "TOOLS.SEALANTCALCULATOR.FEET_TITLE",
			FEET: "TOOLS.SEALANTCALCULATOR.FEET",
			CALCULATE: "TOOLS.SEALANTCALCULATOR.CALCULATE",
			CARTRIDGE: "TOOLS.SEALANTCALCULATOR.CARTRIDGE",
			SAUSAGE: "TOOLS.SEALANTCALCULATOR.SAUSAGE",
			OR: "TOOLS.SEALANTCALCULATOR.OR",
			RESET: "TOOLS.SEALANTCALCULATOR.RESET",
			LEGAL: "TOOLS.SEALANTCALCULATOR.LEGAL",
			COLOR_MATCH: "TOOLS.SEALANTCALCULATOR.COLOR_MATCH",
			PRODUCT_COMPATIBILITY: "TOOLS.SEALANTCALCULATOR.PRODUCT_COMPATIBILITY",
			PRODUCT_SELECTOR: "TOOLS.SEALANTCALCULATOR.PRODUCT_SELECTOR",
			SEALANT_CALCULATOR: "TOOLS.SEALANTCALCULATOR.SEALANT_CALCULATOR",
			RELATED_PRODUCT: "TOOLS.SEALANTCALCULATOR.RELATED_PRODUCT",
			ACCESSOIRE_TITLE: "TOOLS.SEALANTCALCULATOR.ACCESSOIRE_TITLE",
			ACCESSOIRE_TITLE1: "TOOLS.SEALANTCALCULATOR.ACCESSOIRE_TITLE1",
			ACCESSOIRE_TITLE2: "TOOLS.SEALANTCALCULATOR.ACCESSOIRE_TITLE2",
			ACCESSOIRE_DESC1: "TOOLS.SEALANTCALCULATOR.ACCESSOIRE_DESC1",
			ACCESSOIRE_DESC2: "TOOLS.SEALANTCALCULATOR.ACCESSOIRE_DESC2",
			ACCESSOIRE_LINK: "TOOLS.SEALANTCALCULATOR.ACCESSOIRE_LINK",
			BACKEROD_TITLE: "TOOLS.SEALANTCALCULATOR.BACKEROD_TITLE",
			BACKEROD_TITLE1: "TOOLS.SEALANTCALCULATOR.BACKEROD_TITLE1",
			BACKEROD_TITLE2: "TOOLS.SEALANTCALCULATOR.BACKEROD_TITLE2",
			BACKEROD_DESC1: "TOOLS.SEALANTCALCULATOR.BACKEROD_DESC1",
			BACKEROD_DESC2: "TOOLS.SEALANTCALCULATOR.BACKEROD_DESC2",
			BACKEROD_LINK: "TOOLS.SEALANTCALCULATOR.BACKEROD_LINK",
			SETTING_LINK: "TOOLS.SEALANTCALCULATOR.SETTING_LINK",
		},
	},
	SEARCH: {
		META: {
			TITLE: "CONTACT.META.TITLE",
			DESCRIPTION: "CONTACT.META.DESCRIPTION",
			OG_TITLE: "CONTACT.META.OG_TITLE",
			OG_DESCRIPTION: "CONTACT.META.OG_DESCRIPTION",
		},
		TITLE: "SEARCH.TITLE",
		SEARCH_BY_KEYWORD: "SEARCH.SEARCH_BY_KEYWORD",
		SEARCH: "SEARCH.SEARCH",
		REFINE: "SEARCH.REFINE",
		PRODUCT_CATEGORY: "SEARCH.PRODUCT_CATEGORY",
		DOCUMENT_TYPE: "SEARCH.DOCUMENT_TYPE",
		APPLICATION: "SEARCH.APPLICATION",
		CLEAR: "SEARCH.CLEAR",
		SEARCH_RESULT: "SEARCH.SEARCH_RESULT",
		APPLY: "SEARCH.APPLY",
		SDS: "SEARCH.SDS",
		TDS: "SEARCH.TDS",
		ALL: "SEARCH.ALL",
		PRODUCTS: "SEARCH.PRODUCTS",
		DOCUMENTS: "SEARCH.DOCUMENTS",
		PAGES: "SEARCH.PAGES",
		LOAD: "SEARCH.LOAD",
	},
	ABOUTUS: {
		META: {
			TITLE: "ABOUTUS.META.TITLE",
			DESCRIPTION: "ABOUTUS.META.DESCRIPTION",
			OG_TITLE: "ABOUTUS.META.OG_TITLE",
			OG_DESCRIPTION: "ABOUTUS.META.OG_DESCRIPTION",
		},
		TITLE: "ABOUTUS.TITLE",
		INTRO: "ABOUTUS.INTRO",
		CEO_H2: "ABOUTUS.CEO_H2",
		CEO_H1: "ABOUTUS.CEO_H1",
		CEO_P1_1: "ABOUTUS.CEO_P1_1",
		CEO_P1_2: "ABOUTUS.CEO_P1_2",
		CEO_P1_3: "ABOUTUS.CEO_P1_3",
		CEO_P1_SPAN1: "ABOUTUS.CEO_P1_SPAN1",
		CEO_P1_SPAN2: "ABOUTUS.CEO_P1_SPAN2",
		CEO_P2: "ABOUTUS.CEO_P2",
		VALUE: "ABOUTUS.VALUE",
		AGILITY: "ABOUTUS.AGILITY",
		INGENIOSITY: "ABOUTUS.INGENIOSITY",
		AUDACITY: "ABOUTUS.AUDACITY",
		GOODWILL: "ABOUTUS.GOODWILL",
		ADJUST: "ABOUTUS.ADJUST",
		INNOVATE: "ABOUTUS.INNOVATE",
		DARE: "ABOUTUS.DARE",
		HELP: "ABOUTUS.HELP",
		AGILITY_P: "ABOUTUS.AGILITY_P",
		INGENIOSITY_P: "ABOUTUS.INGENIOSITY_P",
		AUDACITY_P: "ABOUTUS.AUDACITY_P",
		GOODWILL_P: "ABOUTUS.GOODWILL_P",
		CULTURE_TITLE: "ABOUTUS.CULTURE_TITLE",
		CULTURE_DESCRIPTION: "ABOUTUS.CULTURE_DESCRIPTION",
		CULTURE1_H1: "ABOUTUS.CULTURE1_H1",
		CULTURE1_H2: "ABOUTUS.CULTURE1_H2",
		CULTURE1_H3: "ABOUTUS.CULTURE1_H3",
		CULTURE1_P1: "ABOUTUS.CULTURE1_P1",
		CULTURE1_P2: "ABOUTUS.CULTURE1_P2",
		CULTURE1_P3: "ABOUTUS.CULTURE1_P3",
		MINORITY: "ABOUTUS.MINORITY",
		WOMEN: "ABOUTUS.WOMEN",
		WOMEN_ENG: "ABOUTUS.WOMEN_ENG",
		WOMEN_VP: "ABOUTUS.WOMEN_VP",
		WOMEN_D: "ABOUTUS.WOMEN_D",
		YOUTH: "ABOUTUS.YOUTH",
		TURNOVER: "ABOUTUS.TURNOVER",
		CULTURE1_LI1: "ABOUTUS.CULTURE1_LI1",
		CULTURE1_LI2: "ABOUTUS.CULTURE1_LI2",
		CULTURE2_H1: "ABOUTUS.CULTURE2_H1",
		CULTURE2_H2: "ABOUTUS.CULTURE2_H2",
		CULTURE2_P: "ABOUTUS.CULTURE2_P",
		CULTURE2_LI1: "ABOUTUS.CULTURE2_LI1",
		CULTURE2_LI2: "ABOUTUS.CULTURE2_LI2",
		CULTURE2_LI3: "ABOUTUS.CULTURE2_LI3",
		SPOTLIGHT: "ABOUTUS.SPOTLIGHT",
		PARTNERS: "ABOUTUS.PARTNERS",
	},
	PARTNERS: {
		TITLE: "PARTNERS.TITLE",
		MORE: "PARTNERS.MORE",
		JAMESHARDI: {
			DESCRIPTION: "PARTNERS.JAMESHARDI.DESCRIPTION",
			SUBDESCIPTION: "PARTNERS.JAMESHARDI.SUBDESCIPTION",
			TITLECOLOR: "PARTNERS.JAMESHARDI.TITLECOLOR",
			ORDERCOLORDESCRIPTION: "PARTNERS.JAMESHARDI.ORDERCOLORDESCRIPTION",
			ORDERCOLORBUTTON: "PARTNERS.JAMESHARDI.ORDERCOLORBUTTON",
			DIGITALCOLORDESCRIPTION: "PARTNERS.JAMESHARDI.DIGITALCOLORDESCRIPTION",
			DIGITALCOLORMOBILEDESCRIPTION: "PARTNERS.JAMESHARDI.DIGITALCOLORMOBILEDESCRIPTION",
		},
		NICHIHA: {
			DESCRIPTION: "PARTNERS.NICHIHA.DESCRIPTION",
			SUBDESCIPTION: "PARTNERS.NICHIHA.SUBDESCIPTION",
			TITLECOLOR: "PARTNERS.NICHIHA.TITLECOLOR",
			ORDERCOLORDESCRIPTION: "PARTNERS.NICHIHA.ORDERCOLORDESCRIPTION",
			ORDERCOLORBUTTON: "PARTNERS.NICHIHA.ORDERCOLORBUTTON",
			DIGITALCOLORDESCRIPTION: "PARTNERS.NICHIHA.DIGITALCOLORDESCRIPTION",
			DIGITALCOLORMOBILEDESCRIPTION: "PARTNERS.NICHIHA.DIGITALCOLORMOBILEDESCRIPTION",
		},
		CERACLAD: {
			DESCRIPTION: "PARTNERS.CERACLAD.DESCRIPTION",
			SUBDESCIPTION: "PARTNERS.CERACLAD.SUBDESCIPTION",
			TITLECOLOR: "PARTNERS.CERACLAD.TITLECOLOR",
			ORDERCOLORDESCRIPTION: "PARTNERS.CERACLAD.ORDERCOLORDESCRIPTION",
			ORDERCOLORBUTTON: "PARTNERS.CERACLAD.ORDERCOLORBUTTON",
			DIGITALCOLORDESCRIPTION: "PARTNERS.CERACLAD.DIGITALCOLORDESCRIPTION",
			DIGITALCOLORMOBILEDESCRIPTION: "PARTNERS.CERACLAD.DIGITALCOLORMOBILEDESCRIPTION",
		},
		MODAL: {
			TITLE: "PARTNERS.MODAL.TITLE",
			FIRSTNAME: "PARTNERS.MODAL.FIRSTNAME",
			LASTNAME: "PARTNERS.MODAL.LASTNAME",
			COMPANY: "PARTNERS.MODAL.COMPANY",
			EMAIL: "PARTNERS.MODAL.EMAIL",
			PHONE: "PARTNERS.MODAL.PHONE",
			STATE: "PARTNERS.MODAL.STATE",
			BUTTON: "PARTNERS.MODAL.BUTTON",
			FILL_MANDATORY_FIELD: "PARTNERS.MODAL.FILL_MANDATORY_FIELD",
			SUCCESS_TITLE: "PARTNERS.MODAL.SUCCESS_TITLE",
			SUCCESS_FIRST_SUBTITLE: "PARTNERS.MODAL.SUCCESS_FIRST_SUBTITLE",
			SUCCESS_SECOND_SUBTITLE: "PARTNERS.MODAL.SUCCESS_SECOND_SUBTITLE",
		},
		ORDER: "PARTNERS.ORDER",
		DOWNLOAD: "PARTNERS.DOWNLOAD",
	},
	FIND_STORE: {
		META: {
			TITLE: "FIND_STORE.META.TITLE",
			DESCRIPTION: "FIND_STORE.META.DESCRIPTION",
			OG_TITLE: "FIND_STORE.META.OG_TITLE",
			OG_DESCRIPTION: "FIND_STORE.META.OG_DESCRIPTION",
			PAGE_TITLE: "FIND_STORE.META.PAGE_TITLE",
			PAGE_DESCRIPTION: "FIND_STORE.META.PAGE_DESCRIPTION",
			HERE: "FIND_STORE.META.HERE",
			FIND: "FIND_STORE.META.FIND",
			CLEAR: "FIND_STORE.META.CLEAR",
			LIST: "FIND_STORE.META.LIST",
			MAP: "FIND_STORE.META.MAP",
			VIEW_WEBSITE: "FIND_STORE.META.VIEW_WEBSITE",
			GET_DIRECTION: "FIND_STORE.META.GET_DIRECTION",
			PLACEHOLDER: "FIND_STORE.META.PLACEHOLDER",
			PREVIOUS: "FIND_STORE.META.PREVIOUS",
			PAGE: "FIND_STORE.META.PAGE",
			OF: "FIND_STORE.META.OF",
			NEXT: "FIND_STORE.META.NEXT",
		},
		OFFICE: {
			TITLE: "FIND_STORE.OFFICE.TITLE",
		},
		STORE: {
			TITLE: "FIND_STORE.STORE.TITLE",
			SUBTITLE: "FIND_STORE.STORE.SUBTITLE",
		},
	},
	FAQ: {
		META: {
			TITLE: "FAQ.META.TITLE",
			DESCRIPTION: "FAQ.META.DESCRIPTION",
			OG_TITLE: "FAQ.META.OG_TITLE",
			OG_DESCRIPTION: "FAQ.META.OG_DESCRIPTION",
		},
		TAB_BAR: {
			NEWS: "FAQ.TAB_BAR.NEWS",
			HOWTO: "FAQ.TAB_BAR.HOWTO",
			FAQ: "FAQ.TAB_BAR.FAQ",
			TECHNICAL_BULLETIN: "FAQ.TAB_BAR.TECHNICAL_BULLETIN",
			PROJECTS: "FAQ.TAB_BAR.PROJECTS",
		},
		TITLE: "FAQ.TITLE",
	},
	COLOR_MATCH: {
		Meta: {
			TITLE: "COLOR_MATCH.Meta.TITLE",
			DESCRIPTION: "COLOR_MATCH.Meta.DESCRIPTION",
			OG_TITLE: "COLOR_MATCH.Meta.OG_TITLE",
			OG_DESCRIPTION: "COLOR_MATCH.Meta.OG_DESCRIPTION",
		},
		TITLE: "COLOR_MATCH.TITLE",
		DESCRIPTION: {
			FIRST: "COLOR_MATCH.DESCRIPTION.FIRST",
			SECOND: "COLOR_MATCH.DESCRIPTION.SECOND",
		},
		STEP: {
			FIRST: "COLOR_MATCH.STEP.FIRST",
			SECOND: "COLOR_MATCH.STEP.SECOND",
			THIRD: "COLOR_MATCH.STEP.THIRD",
		},
		SELECT_TYPE: {
			TITLE: "COLOR_MATCH.SELECT_TYPE.TITLE",
			TABS: {
				FIRST: "COLOR_MATCH.SELECT_TYPE.TABS.FIRST",
				SECOND: "COLOR_MATCH.SELECT_TYPE.TABS.SECOND",
				THIRD: "COLOR_MATCH.SELECT_TYPE.TABS.THIRD",
			},
			SEARCH_MANUFACTURER: "COLOR_MATCH.SELECT_TYPE.SEARCH_MANUFACTURER",
			SEARCH_COLOR_CODE: "COLOR_MATCH.SELECT_TYPE.SEARCH_COLOR_CODE",
			SEARCH_COLOR_NAME: "COLOR_MATCH.SELECT_TYPE.SEARCH_COLOR_NAME",
			APPLY: "COLOR_MATCH.SELECT_TYPE.APPLY",
		},
		SELECT_COLOR: {
			TITLE: "COLOR_MATCH.SELECT_COLOR.TITLE",
			DOWNLOAD_COLOR_CHART: "COLOR_MATCH.SELECT_COLOR.DOWNLOAD_COLOR_CHART",
		},
		NO_RESULT: {
			NO_COLOR: "COLOR_MATCH.NO_RESULT.NO_COLOR",
			COULDNT_FIND: "COLOR_MATCH.NO_RESULT.COULDNT_FIND",
			TRY_AGAIN: "COLOR_MATCH.NO_RESULT.TRY_AGAIN",
			RESET_SELECTION: "COLOR_MATCH.NO_RESULT.RESET_SELECTION",
		},
		BEST_MATCH: {
			TITLE: "COLOR_MATCH.BEST_MATCH.TITLE",
			FIND_COLO_MATCH: "COLOR_MATCH.BEST_MATCH.FIND_COLO_MATCH",
			RESET_SELECTION: "COLOR_MATCH.BEST_MATCH.RESET_SELECTION",
			TAB: {
				TH1: "COLOR_MATCH.BEST_MATCH.TAB.TH1",
				TH2: "COLOR_MATCH.BEST_MATCH.TAB.TH2",
				TH3: "COLOR_MATCH.BEST_MATCH.TAB.TH3",
				TH4: "COLOR_MATCH.BEST_MATCH.TAB.TH4",
				BUY: "COLOR_MATCH.BEST_MATCH.TAB.BUY",
				PLUS: "COLOR_MATCH.BEST_MATCH.TAB.PLUS",
				OF: "COLOR_MATCH.BEST_MATCH.TAB.OF",
				LIGNE_PER_PAGE: "COLOR_MATCH.BEST_MATCH.TAB.LIGNE_PER_PAGE",
			},
			NO_MATCH_FOUND: "COLOR_MATCH.BEST_MATCH.NO_MATCH_FOUND",
			ALERT: "COLOR_MATCH.BEST_MATCH.ALERT",
			MATCH_ACCURENCY_TITLE: "COLOR_MATCH.BEST_MATCH.MATCH_ACCURENCY_TITLE",
			MATCH_ACCURENCY_DETAIL: "COLOR_MATCH.BEST_MATCH.MATCH_ACCURENCY_DETAIL",
			DELTA: "COLOR_MATCH.BEST_MATCH.DELTA",
			MATCH_ACCURENCY_DESCRIPTION: "COLOR_MATCH.BEST_MATCH.MATCH_ACCURENCY_DESCRIPTION",
		},
	},
	GREENGUARD: {
		META: {
			TITLE: "GREENGUARD.META.TITLE",
			DESCRIPTION: "GREENGUARD.META.DESCRIPTION",
			OG_TITLE: "GREENGUARD.META.OG_TITLE",
			OG_DESCRIPTION: "GREENGUARD.META.OG_DESCRIPTION",
		},
		SECTION: {
			PAGE: "GREENGUARD.SECTION.PAGE",
		},
	},
	RELOCATION: {
		META: {
			TITLE: "RELOCATION.META.TITLE",
			DESCRIPTION: "RELOCATION.META.DESCRIPTION",
			OG_TITLE: "RELOCATION.META.OG_TITLE",
			OG_DESCRIPTION: "RELOCATION.META.OG_DESCRIPTION",
		},
	},
	CALCULATOR_TITLE: "calculator_title",
	CALCULATOR_CAPTION: "calculator_caption",
	CALCULATOR_CAPTION_V: "calculator_caption_v",
	CALCULATOR_DIMENSIONS: "calculator_dimensions",
	CALCULATOR_COVERAGE: "calculator_coverage",
	CALCULATOR_BUTTON: "calculator_button",
	CALCULATOR_OUTPUT: "calculator_output",
	CALCULATOR_CARTRIDGES: "calculator_cartridges",
	CALCULATOR_SAUSAGES: "calculator_sausages",
	CALCULATOR_DROPDOWN: "calculator_dropdown",
	CALCULATOR_NUMBER: "calculator_number",
	CAROUSEL_TAB_1: "carousel_tab_1",
	CAROUSEL_TAB_2: "carousel_tab_2",
	CAROUSEL_TAB_3: "carousel_tab_3",
};
