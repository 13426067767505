import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { eventsSelector, getEvents, isLoadingSelector } from "../../../store/architect";
import { EventHeaderSelector } from "../../../store/mediaBase";
import React, { Fragment, useEffect } from "react";
import Loading from "../../ui/loading/Loading";
import classes from "./Events.module.scss";
import HeaderCarousel from "../../ui/HeaderCarousel/HeaderCarousel";
import { keys } from "../../../locales/localeskeys";
import CustomNavLink from "../../ui/customNavLink/CustomNavLink";
import Button from "../../ui/Button/Button";
import Accordion from "../../ui/Accordion/Accordion";
import LinkTree from "../../ui/LinkTree/LinkTree";
import Resources from "../Resources/Resources";

const Events = () => {
	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const dispatch = useDispatch();

	const events = useSelector(eventsSelector);
	const eventsisLoading = useSelector(isLoadingSelector);
	const eventHEader = useSelector(EventHeaderSelector);

	var months = [
		t(keys.GLOBAL.COMMON.JANUARY),
		t(keys.GLOBAL.COMMON.FEBRUARY),
		t(keys.GLOBAL.COMMON.MARCH),
		t(keys.GLOBAL.COMMON.APRIL),
		t(keys.GLOBAL.COMMON.MAY),
		t(keys.GLOBAL.COMMON.JUNE),
		t(keys.GLOBAL.COMMON.JULY),
		t(keys.GLOBAL.COMMON.AUGUST),
		t(keys.GLOBAL.COMMON.SEPTEMBER),
		t(keys.GLOBAL.COMMON.OCTOBER),
		t(keys.GLOBAL.COMMON.NOVEMBER),
		t(keys.GLOBAL.COMMON.DECEMBER),
	];

	useEffect(() => {
		dispatch(getEvents());
	}, []);

	const timeFormat = (time) => {
		let hours = parseInt(time.substr(0, 2));
		const minutes = parseInt(time.substr(3, 2));
		const ampm = hours >= 12 ? "pm" : "am";
		hours = hours % 12;
		hours = hours ? hours : 12;
		return hours + ":" + (minutes < 10 ? "0" : "") + minutes + " " + ampm;
	};

	return (
		<Fragment>
			{(!eventHEader || eventsisLoading) && <Loading className={classes.loading} type={"bounce"} />}
			{!eventsisLoading && events && events.length > 0 && (
				<Fragment>
					<HeaderCarousel className={classes.header} data={eventHEader} />
					<div className={classes.layout}>
						<LinkTree
							className={classes.Tree}
							tree={[
								{ label: t(keys.GLOBAL.NAVBAR.HOME), to: "/" },
								{ label: t(keys.ARCHITECT.TITLE), to: "/architect/" },
								{ label: t(keys.ARCHITECT.EVENTTXT), to: "/architect/events/" },
							]}
						/>
						<h1 className={classes.title}>{t(keys.ARCHITECT.EVENT.TITLE)}</h1>
						{eventHEader && eventHEader.length > 0 && (
							<Fragment>
								{(lngId.includes("fr") ? eventHEader[0].description_fr : eventHEader[0].description_en)
									?.split("#")
									?.map((str, index) => (
										<p className={classes.description} key={index}>
											{str}
										</p>
									))}
							</Fragment>
						)}
						<div className={classes.center}>
							<h2>{t(keys.ARCHITECT.EVENT.UPCOMING)}</h2>
							<hr />
						</div>
						<Accordion
							className={classes.events}
							headerClass={classes.headerClass}
							iconPlusMinus={classes.iconPlusMinus}
							accordionBody={classes.accordionBody}
							header={t(keys.ARCHITECT.WEBINAR.QUEBEC)}
							dark
						>
							{events
								.filter((e) => e.zone === 0)
								.map((event) => (
									<div key={event.id} className={classes.event}>
										{event.date && (
											<div className={classes.date}>
												<p>{Number(event.date.substring(8, 10))}</p>
												<p className={classes.month}>
													{months[Number(event.date.substring(5, 7)) - 1]}
												</p>
											</div>
										)}
										<div className={classes.details}>
											<h2 key={event.id}>
												{lngId.includes("fr") ? event.title_fr : event.title_en},
												<span> {event.city}</span>
											</h2>
											{event.date && (
												<div className={classes.mobileDate}>
													<p>{t(keys.ARCHITECT.WEBINAR.DATE)} :</p>
													<p className={classes.value}>{event.date}</p>
												</div>
											)}
											<div className={classes.addInfo}>
												<p>
													{lngId.includes("fr") ? event.description_fr : event.description_en}
												</p>
												<div className={classes.register}>
													<CustomNavLink
														to={lngId.includes("fr") ? event.url_fr : event.url_en}
														target={"_blank"}
													>
														<Button className={classes.content} color={"outlineBlue"}>
															{t(keys.ARCHITECT.EVENT.VIEW_WEBSITE)}
														</Button>
													</CustomNavLink>
												</div>
											</div>
										</div>
									</div>
								))}
						</Accordion>
						<Accordion
							className={classes.events}
							headerClass={classes.headerClass}
							iconPlusMinus={classes.iconPlusMinus}
							accordionBody={classes.accordionBody}
							header={t(keys.ARCHITECT.WEBINAR.CANADA)}
							dark
						>
							{events
								.filter((e) => e.zone === 1)
								.map((event) => (
									<div key={event.id} className={classes.event}>
										{event.date && (
											<div className={classes.date}>
												<p>{Number(event.date.substring(8, 10))}</p>
												<p className={classes.month}>
													{months[Number(event.date.substring(5, 7)) - 1]}
												</p>
											</div>
										)}
										<div className={classes.details}>
											<h2 key={event.id}>
												{lngId.includes("fr") ? event.title_fr : event.title_en},
												<span> {event.city}</span>
											</h2>
											{event.date && (
												<div className={classes.mobileDate}>
													<p>Date : </p>
													<p className={classes.value}>{event.date}</p>
												</div>
											)}
											<div className={classes.addInfo}>
												<p>
													{lngId.includes("fr") ? event.description_fr : event.description_en}
												</p>
												<div className={classes.register}>
													<CustomNavLink
														to={lngId.includes("fr") ? event.url_fr : event.url_en}
														target={"_blank"}
													>
														<Button className={classes.content} color={"outlineBlue"}>
															{t(keys.ARCHITECT.EVENT.VIEW_WEBSITE)}
														</Button>
													</CustomNavLink>
												</div>
											</div>
										</div>
									</div>
								))}
						</Accordion>
						<Accordion
							className={classes.events}
							headerClass={classes.headerClass}
							iconPlusMinus={classes.iconPlusMinus}
							accordionBody={classes.accordionBody}
							header={t(keys.ARCHITECT.WEBINAR.USA)}
							dark
						>
							{events
								.filter((e) => e.zone === 2)
								.map((event) => (
									<div key={event.id} className={classes.event}>
										{event.date && (
											<div className={classes.date}>
												<p>{Number(event.date.substring(8, 10))}</p>
												<p className={classes.month}>
													{months[Number(event.date.substring(5, 7)) - 1]}
												</p>
											</div>
										)}
										<div className={classes.details}>
											<h2 key={event.id}>
												{lngId.includes("fr") ? event.title_fr : event.title_en},
												<span> {event.city}</span>
											</h2>
											{event.date && (
												<div className={classes.mobileDate}>
													<p>Date :</p>
													<p className={classes.value}> {event.date}</p>
												</div>
											)}
											<div className={classes.addInfo}>
												<p>
													{lngId.includes("fr") ? event.description_fr : event.description_en}
												</p>
												<div className={classes.register}>
													<CustomNavLink
														to={lngId.includes("fr") ? event.url_fr : event.url_en}
														target={"_blank"}
													>
														<Button className={classes.content} color={"outlineBlue"}>
															{t(keys.ARCHITECT.EVENT.VIEW_WEBSITE)}
														</Button>
													</CustomNavLink>
												</div>
											</div>
										</div>
									</div>
								))}
						</Accordion>
						<div className={classes.resouces}>
							<Resources />
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default Events;
