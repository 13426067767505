import React from "react";
import Switch from "react-switch";

import classes from "./ToggleSwitch.module.scss";

const ToggleSwitch = React.forwardRef((props, ref) => {
	return (
		<div className={`${classes.SwitchContainer} ${props.className || ""}`}>
			<label className={`${props.labelClassName ? props.labelClassName : ""}`}>{props.label}</label>
			<Switch
				id={props.id}
				ref={ref}
				onChange={props.onChange}
				checked={props.checked}
				className="react-switch"
				onColor={props.onColor}
				onHandleColor={props.onHandleColor}
				handleDiameter={props.handleDiameter}
				uncheckedIcon={props.uncheckedIcon}
				checkedIcon={props.checkedIcon}
				boxShadow={props.boxShadow}
				activeBoxShadow={props.activeBoxShadow}
				height={props.height}
				width={props.width}
				disabled={props.disabled}
			/>
		</div>
	);
});

export default ToggleSwitch;
