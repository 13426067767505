import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";

import SegmentPicker from "./SegmentPicker/SegmentPicker";
import DiscoverProduct from "./DIscoverProduct/DiscoverProduct";
import HeaderCarousel from "../ui/HeaderCarousel/HeaderCarousel";
import Button from "../ui/Button/Button";
import CustomNavLink from "../ui/customNavLink/CustomNavLink";
import Partner from "./Partner/Partner";

import { homeHeaderSelector } from "../../store/mediaBase";

import { keys } from "../../locales/localeskeys";

import AdtoolsLogo from "../../assets/svg/adtools-desktop.svg";

import classes from "./Home.module.scss";

const Home = () => {
	const { t } = useTranslation();

	const homeHeaderMedia = useSelector(homeHeaderSelector);

	return (
		<div>
			<div className={classes.homeHeader}>
				<HeaderCarousel data={homeHeaderMedia} />
			</div>
			<div className={`${classes.home}`}>
				<SegmentPicker />
				<DiscoverProduct />
				<Partner />
				<div className={classes.addToolsLayout}>
					<img src={AdtoolsLogo} alt="adtool-logo.svg" />
					<div className={classes.left}>
						<h1>{t(keys.HOME.PRODUCT_NEED)}</h1>
						<h1>{`${t(keys.HOME.HOW_MANY)} ${t(keys.HOME.WHAT_COLOR)}`}</h1>
						<p>{t(keys.HOME.ADTOOLS_CAPTION)}</p>
						<CustomNavLink
							className={classes.btn}
							to="https://adfastcorp.com/outils-numeriques/"
							target="_blank"
						>
							<Icon className={classes.icon} icon="bi:arrow-right-square-fill" />
						</CustomNavLink>
					</div>
				</div>
				<div className={classes.contactLayout}>
					<h1>{t(keys.HOME.NEED_HELP)}</h1>
					<CustomNavLink className={classes.btnLink} to={"/contact-us"}>
						<Button className={classes.btn}>{t(keys.HOME.CONTACT_US)}</Button>
					</CustomNavLink>
				</div>
			</div>
		</div>
	);
};

export default Home;
