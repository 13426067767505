import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";

import Loading from "../../ui/loading/Loading";
import SearchBar from "../../ui/Input/SearchBar/SearchBar";
import Dropdown from "../../ui/Input/Dropdown/Dropdown";
import Pagination from "../../ui/Pagination/Pagination";
import HeaderCarousel from "../../ui/HeaderCarousel/HeaderCarousel";
import Button from "../../ui/Button/Button";
import useElementSize from "../../../hooks/useElementSize";
import CustomNavLink from "../../ui/customNavLink/CustomNavLink";

import { ManufacturingHeaderSelector } from "../../../store/mediaBase";
import { countSelector, getSubsegment, isLoadingSelector, subsegmentsSelector } from "../../../store/subSegment";

import { keys } from "../../../locales/localeskeys";
import classes from "./Manufacturing.module.scss";
import adrobot from "../../../assets/jpeg/adrobot-image.jpg";
import adrobot_logo from "../../../assets/png/adrobot-logo.png";

const Manufacturing = () => {
	const dispatch = useDispatch();

	const [gridRef, { width: gridWidth }] = useElementSize();

	const { i18n, t } = useTranslation();
	const lngId = i18n.language;

	const navigate = useNavigate();

	const isLoading = useSelector(isLoadingSelector);
	const subSegments = useSelector(subsegmentsSelector);
	const segmentStore = useSelector(countSelector);
	const ManufacturingHeaderMedia = useSelector(ManufacturingHeaderSelector);

	const layoutRef = useRef();
	const searchRef = useRef();
	const [searchFilter, setSearchFilter] = useState("");
	const [sortState, setSortState] = useState("a-z");
	const [currentPage, setCurrentPage] = useState(1);
	const [productListWidth, setProductListWidth] = useState(0);

	useEffect(() => {
		if (isLoading === false)
			dispatch(
				getSubsegment({
					type: 2,
					lng: lngId,
					sortState: sortState,
					page: currentPage,
					pageSize: productListWidth ? 4 * parseInt(productListWidth / 368) : 16,
				})
			);
	}, [lngId, sortState, currentPage]);

	useEffect(() => {
		if (layoutRef.current) {
			setProductListWidth(layoutRef.current.offsetWidth);
		}
	}, [layoutRef.current]);

	const searchChangeHandler = (value) => {
		setSearchFilter(value);
	};

	const searchEraseHandler = () => {
		setSearchFilter("");
		dispatch(
			getSubsegment({
				type: 2,
				lng: lngId,
				sortState: sortState,
				page: currentPage,
				search: "",
				pageSize: productListWidth ? 4 * parseInt(productListWidth / 308) : 16,
			})
		);
	};

	const industryChangeHandler = (event) => {
		if (event.target.value !== "default") {
			navigate(event.target.value);
		}
	};

	const changePageHandler = (page) => {
		setCurrentPage(page);
	};

	const searchSubmit = (event) => {
		event.preventDefault();
		dispatch(
			getSubsegment({
				type: 2,
				lng: lngId,
				sortState: sortState,
				page: currentPage,
				search: searchFilter,
				pageSize: 4 * parseInt((window.innerWidth - (window.innerWidth > 1200 ? 416 : 128)) / 317),
			})
		);
	};

	return (
		<Fragment>
			<div className={classes.manufacturingHeader}>
				<HeaderCarousel data={ManufacturingHeaderMedia} />
			</div>
			<div className={classes.layout} ref={gridRef}>
				<div className={classes.filter}>
					<p className={classes.title}>{t(keys.MANUFACTURING.BANNER_Title)}</p>
					<p className={classes.description}>{t(keys.MANUFACTURING.BANNER_CAPTION)}</p>
					<div className={classes.input}>
						<SearchBar
							ref={searchRef}
							id="search_manufacturing"
							className={classes.search}
							placeholder={t(keys.MANUFACTURING.SELECT_SEARCH)}
							value={searchFilter}
							onChange={(value) => searchChangeHandler(value)}
							onClick={searchEraseHandler}
							disabled={isLoading}
							onSubmit={searchSubmit}
						/>
						{subSegments && subSegments.length > 0 && !isLoading && (
							<Dropdown
								id="industry"
								label={t(keys.MANUFACTURING.INDUSTRY)}
								labelPosition="left"
								onChange={industryChangeHandler}
								disabled={isLoading}
								className={classes.dropdown}
								wrapperClassName={classes.wrapperClassName}
							>
								<option value="default">{t(keys.MANUFACTURING.SELECT_INDUSTRY)}</option>
								{subSegments.map((subSegment) => (
									<option value={`/manufacturing/${subSegment.erp_code}/`}>
										{lngId.includes("fr") ? subSegment.title_fr : subSegment.title_en}
									</option>
								))}
							</Dropdown>
						)}
					</div>
				</div>
				{isLoading && <Loading type={"linear"} />}
				<div className={classes.body} ref={layoutRef}>
					{subSegments &&
						subSegments.length > 0 &&
						!isLoading &&
						subSegments.map((subSegment) => (
							<CustomNavLink
								className={classes.subSegmentItem}
								to={`/manufacturing/${subSegment.erp_code}/`}
								key={subSegment.id}
							>
								{(subSegment.img || subSegment.img_cart) && (
									<img
										src={subSegment.img_cart ? subSegment.img_cart : subSegment.img}
										alt={subSegment.erp_code}
									/>
								)}
								{!subSegment.img && !subSegment.img_cart && (
									<Icon className={classes.noImage} icon="ant-design:file-image-outlined" />
								)}
								<div className={classes.details}>
									<h1>{lngId.includes("fr") ? subSegment.title_fr : subSegment.title_en}</h1>
									<h2>
										{lngId.includes("fr") ? subSegment.description_fr : subSegment.description_en}
									</h2>
									<p>{t(keys.MANUFACTURING.READ_MORE)}</p>
								</div>
							</CustomNavLink>
						))}
				</div>
				{!isLoading && (
					<Pagination
						className={classes.pagination}
						currentPage={currentPage}
						totalCount={segmentStore}
						pageSize={16}
						onPageChange={(page) => changePageHandler(page)}
					/>
				)}
				<div className={classes.adRobotLayout}>
					<h1>{t(keys.ARCHITECT.EVENT.ADROBOT_TITLE)}</h1>
					<p>{t(keys.ARCHITECT.EVENT.ADROBOT_DESCRIPTION)}</p>
					<div className={classes.bDetails}>
						<img src={adrobot} alt="adrobot image" />
						<div className={classes.bRight}>
							<div className={classes.imgContainer}>
								<img src={adrobot_logo} alt="adrobot logo" />
							</div>
							<p>{t(keys.ARCHITECT.EVENT.ADROBOT_TEXT)}</p>
							<div className={classes.iconContainer}>
								<CustomNavLink className={classes.btnLink} to={"/adrobot"}>
									<Button className={classes.btn}>
										<Icon
											className={classes.icon}
											icon="material-symbols:arrow-right-alt-rounded"
										/>
									</Button>
								</CustomNavLink>
							</div>
						</div>
					</div>
				</div>
				<div className={classes.contactLayout}>
					<h1>{t(keys.HOME.NEED_HELP)}</h1>
					<CustomNavLink className={classes.btnLink} to={"/contact-us"}>
						<Button className={classes.btn}>{t(keys.HOME.CONTACT_US)}</Button>
					</CustomNavLink>
				</div>
			</div>
		</Fragment>
	);
};

export default Manufacturing;
