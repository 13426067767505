import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dropdown from "../../../ui/Input/Dropdown/Dropdown";
import TextInput from "../../../ui/Input/TextInput/TextInput";

import { keys } from "../../../../locales/localeskeys";

import Cartridge from "../../../../assets/svg/cartridge-90.svg";
import Sausage from "../../../../assets/svg/sausage.svg";

import classes from "./SealantCalculator.module.scss";
import { Icon } from "@iconify/react";

const SealantCalculator = (props) => {
	const { t } = useTranslation();

	const [cartridges, setCartridges] = useState({ value: 0, isLoading: false });
	const [sausages, setSausages] = useState({ value: 0, isLoading: false });
	const [joint, setJoint] = useState(0);
	const [coverage, setCoverage] = useState(0);

	const textInputHandler = (e) => {
		setCoverage(e.target.value * 12 * 2.54);
	};

	const dropDownChangeHandler = (event) => {
		switch (event.target.selectedIndex) {
			case 1:
				setJoint((0.47625 / 4) * 2.54);
				break;
			case 2:
				setJoint(((0.47625 * 3) / 8) * 2.54);
				break;
			case 3:
				setJoint((0.47625 / 2) * 2.54);
				break;
			case 4:
				setJoint(((0.47625 * 5) / 8) * 2.54);
				break;
			case 5:
				setJoint(((0.47625 * 3) / 4) * 2.54);
				break;
			default:
				setJoint(0);
		}
	};

	useEffect(() => {
		calculateSealant();
	}, [coverage, joint]);

	const calculateSealant = () => {
		setCartridges({ value: 0, isLoading: true });
		setSausages({ value: 0, isLoading: true });

		setTimeout(() => {
			setCartridges({ value: Math.ceil((joint * coverage) / 304), isLoading: false });
			setSausages({ value: Math.ceil((joint * coverage) / 600), isLoading: false });
		}, 350);
	};

	return (
		<div className={classes.bodyLayout}>
			<div className={classes.calculatorLeftContainer}>
				<Icon className={classes.icon} icon="carbon:calculator-check" />
				<h1>{t(keys.CALCULATOR_TITLE)}</h1>
				<h3>{t(keys.CALCULATOR_CAPTION)}</h3>
			</div>
			<div className={classes.input}>
				<div className={classes.body}>
					<div className={classes.dimensions}>
						<label>{t(keys.CALCULATOR_DIMENSIONS)}</label>
						<Dropdown className={classes.inputJoint} onChange={dropDownChangeHandler}>
							<option id={0}>{t(keys.CALCULATOR_DROPDOWN)}</option>
							<option id={1}>3/16 x 1/4</option>
							<option id={2}>3/16 x 3/8</option>
							<option id={3}>3/16 x 1/2</option>
							<option id={4}>3/16 x 5/8</option>
							<option id={5}>3/16 x 3/4</option>
						</Dropdown>
					</div>
					<div className={classes.numbers}>
						<label>{t(keys.CALCULATOR_COVERAGE)}</label>
						<TextInput
							type="number"
							onChange={textInputHandler}
							id="Coverage"
							placeholder={t(keys.CALCULATOR_NUMBER)}
							className={classes.inputCoverage}
							inputClassName={classes.inputClassName}
						/>
					</div>
				</div>
			</div>
			<div className={classes.output}>
				<div className={classes.title}>
					<label>{t(keys.CALCULATOR_OUTPUT)}</label>
				</div>
				<div className={classes.body}>
					<div>
						<p>{t(keys.CALCULATOR_CARTRIDGES)}</p>
						<p>(304ml)</p>
						<h1>{cartridges.isLoading ? "*" : cartridges.value}</h1>
						<img src={Cartridge} alt="cartridge.svg" />
					</div>
					<div>
						<p>{t(keys.CALCULATOR_SAUSAGES)}</p>
						<p>(600ml)</p>
						<h1>{sausages.isLoading ? "*" : sausages.value}</h1>
						<img src={Sausage} alt="sausage.svg" />
					</div>
				</div>
			</div>
		</div>
	);
};
export default SealantCalculator;
