import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import { ImageMap } from "@qiuz/react-image-map";

import { getSubsegment, isLoadingSelector, subsegmentsSelector } from "../../store/subSegment";

import CustomNavLink from "../ui/customNavLink/CustomNavLink";
import Button from "../ui/Button/Button";
import Loading from "../ui/loading/Loading";
import Characteristics from "../ui/Characteristics/Characteristics";
import ImageMapIcon from "../Segment/Construction/ImageMapIcon";
import ModalVideo from "../Segment/ModalVideo/ModalVideo";

import { constants } from "../../locales/constant";
import { keys } from "../../locales/localeskeys";

import classes from "./DTech.module.scss";

const DTech = () => {
	const dispatch = useDispatch();

	const { i18n, t } = useTranslation();
	const lngId = i18n.language;
	const search = `?lng=${lngId}`;

	const isLoading = useSelector(isLoadingSelector);
	const subSegments = useSelector(subsegmentsSelector);

	const [curSubSegment, setCurSubSegment] = useState();
	const [currentMap, setCurrentMap] = useState();
	const [isOpen, setOpen] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
		if ((isLoading === false && !subSegments) || subSegments.length === 0)
			dispatch(
				getSubsegment({
					lng: lngId,
					subSegment: "Graco",
				})
			);
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (subSegments) {
			const sub = subSegments.find((sub) => sub.erp_code === "Graco");
			if (sub) setCurSubSegment(sub);
		}
	}, [subSegments]);

	const openModal = () => {
		setOpen(true);
	};

	const closeModal = () => {
		setOpen(false);
	};

	return (
		<Fragment>
			<ModalVideo videoId="acCE_dIBUOU" isOpen={isOpen} onClose={closeModal} />
			{(!curSubSegment || isLoading) && <Loading className={classes.loading} type={"linear"} />}
			{curSubSegment && !isLoading && (
				<div className={classes.layout}>
					<div className={classes.body}>
						<div className={classes.description}>
							<div className={classes.texte}>
								<h1>{lngId.includes("fr") ? curSubSegment.title_fr : curSubSegment.title_en}</h1>
								<div className={classes.longTexte}>
									{(lngId.includes("fr")
										? curSubSegment.description_fr
										: curSubSegment.description_en
									)
										.split("#")
										.map((str, index) => (
											<p key={index}>
												{str}
												{(lngId.includes("fr")
													? curSubSegment.description_fr
													: curSubSegment.description_en
												).split("#").length -
													1 ===
													index && (
													<CustomNavLink to={`/adrobot/${search}`}> Adrobot</CustomNavLink>
												)}
											</p>
										))}
								</div>
							</div>
							{curSubSegment && curSubSegment.imgs && curSubSegment.imgs.length > 0 && (
								<div className={classes.imgMap}>
									<ImageMap
										src={curSubSegment.imgs[0].url}
										map={curSubSegment.imgs[0].imgMaps.map((map) => {
											return {
												width: `2rem`,
												height: `2rem`,
												left: `${map.left}%`,
												top: `${map.top}%`,
												href: map.href,
												render: () => (
													<Fragment>
														<ImageMapIcon
															id={map.id}
															current={currentMap?.id}
															onClick={() =>
																setCurrentMap(
																	currentMap && currentMap.id === map.id
																		? undefined
																		: map
																)
															}
														/>
														{map.id === currentMap?.id &&
															(lngId.includes("fr") ? map.title_fr : map.title_en) && (
																<div className={classes.details}>
																	<div className={classes.vr} />
																	<div className={classes.texte}>
																		<h1>
																			{lngId.includes("fr")
																				? map.title_fr
																				: map.title_en}
																		</h1>
																		{(lngId.includes("fr")
																			? map.description_fr
																			: map.description_en) && (
																			<ul>
																				{(lngId.includes("fr")
																					? map.description_fr
																					: map.description_en
																				)
																					.split("#")
																					.map((str, index) => (
																						<li key={index}>{str}</li>
																					))}
																			</ul>
																		)}
																	</div>
																</div>
															)}
													</Fragment>
												),
											};
										})}
									/>
								</div>
							)}
							<Characteristics data={constants.graco} />
							<div className={classes.video}>
								<h1>{t(keys.ADROBOT.TITLE4)}</h1>
								<div className={classes.container}>
									<div className={classes.imgContainer}>
										<img
											src="https://img.youtube.com/vi/acCE_dIBUOU/hqdefault.jpg"
											alt="youtube cover"
											onClick={openModal}
										/>
										<Icon className={classes.iconPlay} onClick={openModal} icon="fa:play" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className={classes.contact_layout}>
						<div className={classes.contact_body}>
							<p>
								{t(keys.ADROBOT.CONTACT_TXT2)}
								<CustomNavLink to={`/adrobot/${search}`}>Adrobot</CustomNavLink>
							</p>
							<CustomNavLink className={classes.btnLink} to={"/contact-us"}>
								<Button className={classes.btn}>{t(keys.ADROBOT.CONTACT)}</Button>
							</CustomNavLink>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default DTech;
