import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import SearchBar from "../../../../ui/Input/SearchBar/SearchBar";
import ToggleSwitch from "../../../../ui/Input/ToggleSwitch/ToggleSwitch";
import Dropdown from "../../../../ui/Input/Dropdown/Dropdown";
import encodeSlash from "../../../../../hooks/encodeSlash";

import { currencySelector, isLoggedInSelector } from "../../../../../store/auth";
import { getVariants, isVariantLoadedSelector, variantIsLoadingSelector } from "../../../../../store/variant";
import {
	favoriteSelector,
	searchSelector,
	setFavorite,
	setSearch,
	setSort,
	sortSelector,
} from "../../../../../store/variantQuery";
import { itemIsLoadingSelector } from "../../../../../store/product";

import { keys } from "../../../../../locales/localeskeys";

import classes from "./VariantSearchBar.module.scss";

const VariantSearchBar = () => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const params = useParams();
	const { itemid, brandid } = params;

	const isLoggedIn = useSelector(isLoggedInSelector);
	const searchState = useSelector(searchSelector);
	const sortState = useSelector(sortSelector);
	const favoriteState = useSelector(favoriteSelector);
	const itemsLoading = useSelector(itemIsLoadingSelector);
	const variantLoading = useSelector(variantIsLoadingSelector);
	const isVariantloaded = useSelector(isVariantLoadedSelector({ itemid, brandid }));
	const currency = useSelector(currencySelector);

	const searchRef = useRef();
	const [oldSearch, setOldSearch] = useState("");
	const [isSubmited, setIsSubmited] = useState(false);
	const [prevCurrency, setPrevCurrency] = useState("");

	useEffect(() => {
		dispatch(setSearch(""));
		dispatch(setFavorite(false));
		dispatch(setSort(""));

		queryParams.delete("searchColor");
		queryParams.delete("sort");
		queryParams.delete("favorite");
		navigate(
			{
				pathname: location.pathname,
				search: queryParams.toString(),
			},
			{ replace: true }
		);
		setPrevCurrency(currency);
	}, []);

	/**
	 * gerer l'initialisation du component
	 * reintialiser le store de requete au valeur du query parameter
	 * charger les produits a l'initiation
	 * recharger les produit si les parametre de recherche change du lien de l'exporateur
	 */
	useEffect(() => {
		if (isLoggedIn !== undefined) {
			if (currency && !itemsLoading && !variantLoading && (isVariantloaded === false || isSubmited === true)) {
				dispatch(getVariants({ item_id: itemid, brand_id: brandid }));
				setIsSubmited(false);
			}
		}
	}, [isLoggedIn, searchState, favoriteState, sortState, itemid, brandid, currency, isSubmited, isVariantloaded]);

	useEffect(() => {
		if (isLoggedIn !== undefined) {
			if (currency && prevCurrency && currency !== prevCurrency) {
				dispatch(getVariants({ item_id: itemid, brand_id: brandid }));
				setIsSubmited(false);
				setPrevCurrency(currency);
			}
		}
	}, [isLoggedIn, currency, prevCurrency]);

	const searchChangeHandler = (searchValue) => {
		dispatch(setSearch(searchValue));
		/**
		 * enlever le parametre si la valeur du fitre est vide
		 */
		if (searchValue !== "") {
			queryParams.set("searchColor", searchValue);
		} else {
			queryParams.delete("searchColor");
		}
		/**
		 * modifier les parametres du lien au changement du filtre
		 * remplacer l'historique
		 */
		if (oldSearch === undefined || oldSearch === "" || isSubmited === true) {
			setIsSubmited(false);
			navigate({
				pathname: `/adstore/variants/${itemid}/${brandid}/`,
				search: queryParams.toString(),
			});
		} else {
			navigate(
				{
					pathname: `/adstore/variants/${encodeSlash(itemid)}/${encodeSlash(brandid)}/`,
					search: queryParams.toString(),
				},
				{ replace: true }
			);
		}
		setOldSearch(searchValue);
	};

	/**
	 * Modifier la valeur du store et le parametre query du sort
	 * @param event
	 */
	const sortChangeHandler = (event) => {
		dispatch(setSort(event.target.value));
		if (event.target.value !== "Featured") {
			queryParams.set("sort", event.target.value);
		} else {
			queryParams.delete("sort");
		}
		navigate({
			pathname: location.pathname,
			search: queryParams.toString(),
		});
		setIsSubmited(true);
	};

	/**
	 * Modifier la valeur du store et le parametre query du favorite only
	 * @param checked
	 */
	const favoriteChangeHandler = (checked) => {
		dispatch(setFavorite(checked));
		if (checked === true) {
			queryParams.set("favorite", checked);
		} else {
			queryParams.delete("favorite");
		}
		navigate(
			{
				pathname: `/adstore/variants/${encodeSlash(itemid)}/${encodeSlash(brandid)}/`,
				search: queryParams.toString(),
			},
			{ replace: true }
		);
		setIsSubmited(true);
	};

	const eraseHandler = () => {
		searchChangeHandler("");
		setIsSubmited(true);
	};

	const submitHandler = (event) => {
		event.preventDefault();
		setIsSubmited(true);
	};

	return (
		<div className={classes.searchBarContainer}>
			<div>
				<SearchBar
					ref={searchRef}
					id="search_variant"
					placeholder={t(keys.PRODUCTS.SEARCH_COLOR_NUMBER)}
					value={searchState ? searchState : ""}
					onChange={(value) => searchChangeHandler(value)}
					onClick={eraseHandler}
					onSubmit={submitHandler}
					disabled={variantLoading}
				/>
			</div>
			<div className={classes.sortByContainer}>
				<Dropdown
					id="sortBy"
					label={t(keys.PRODUCTS.SORT_BY)}
					labelPosition="left"
					value={sortState ? sortState : ""}
					onChange={sortChangeHandler}
					disabled={variantLoading}
				>
					<option value="a-z">{t(keys.PRODUCTS.ALPHANUMERICAL_ASC)}</option>
					<option value="z-a">{t(keys.PRODUCTS.ALPHANUMERICAL_DES)}</option>
				</Dropdown>
			</div>
			<div className={classes.favoriteContainer}>
				<ToggleSwitch
					label={t(keys.PRODUCTS.SHOW_ONLY_FAVORITE)}
					checked={favoriteState}
					onChange={favoriteChangeHandler}
					onColor="#86d3ff"
					onHandleColor="#2693e6"
					handleDiameter={30}
					uncheckedIcon={false}
					checkedIcon={false}
					disabled={variantLoading}
					boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
					activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
				/>
			</div>
			{/*<Button onClick={openFilter} className={classes.filterBtn}>*/}
			{/*	{t(keys.GLOBAL.FILTER)}*/}
			{/*</Button>*/}
			{/*<NavMenu filterOpen={filterOpen} setFilterOpen={setFilterOpen}>*/}
			{/*	<ToggleSwitch*/}
			{/*		label={t(keys.PRODUCTS.SHOW_ONLY_FAVORITE)}*/}
			{/*		checked={favoriteState}*/}
			{/*		onChange={favoriteChangeHandler}*/}
			{/*		onColor="#86d3ff"*/}
			{/*		onHandleColor="#2693e6"*/}
			{/*		handleDiameter={30}*/}
			{/*		uncheckedIcon={false}*/}
			{/*		checkedIcon={false}*/}
			{/*		disabled={variantLoading}*/}
			{/*		boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"*/}
			{/*		activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"*/}
			{/*	/>*/}
			{/*	<OverlayDropdown*/}
			{/*		id="sortBy"*/}
			{/*		label={t(keys.PRODUCTS.SORT_BY)}*/}
			{/*		labelPosition="left"*/}
			{/*		value={sortState ? sortState : ""}*/}
			{/*		onChange={sortChangeHandler}*/}
			{/*		disabled={variantLoading}*/}
			{/*	>*/}
			{/*		<option value="Featured">{t(keys.PRODUCTS.FEATURED)}</option>*/}
			{/*		<option value="a-z">{t(keys.PRODUCTS.ALPHANUMERICAL_ASC)}</option>*/}
			{/*		<option value="z-a">{t(keys.PRODUCTS.ALPHANUMERICAL_DES)}</option>*/}
			{/*	</OverlayDropdown>*/}
			{/*</NavMenu>*/}
		</div>
	);
};

export default VariantSearchBar;
