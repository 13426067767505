import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Icon } from "@iconify/react";

import Button from "../../../../ui/Button/Button";
import TextInput from "../../../../ui/Input/TextInput/TextInput";
import ItemCard from "./itemCard/ItemCard";
import SealantCalculatorModal from "../../../ProductOverview/SealantCalculator/SealantCalculatorModal";
import CustomNavLink from "../../../../ui/customNavLink/CustomNavLink";
import decodeSlash from "../../../../../hooks/decodeSlash";
import encodeSlash from "../../../../../hooks/encodeSlash";
import { useLocalized } from "../../../../../hooks";

import { variantFormats } from "../../../../../store/variant";
import { isLoggedInSelector, quoteRequest, setPrecedentUrl } from "../../../../../store/auth";
import { addLine, isLoadingSelector, orderLinesSelector, updateLine } from "../../../../../store/cart";

import { keys } from "../../../../../locales/localeskeys";

import classes from "./VariantSelector.module.scss";

const VariantSelector = (props) => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;
	const { getLocalizedValueByCustomMapping } = useLocalized(lngId);

	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const params = useParams();
	const { itemid, brandid, specificationid, formatid } = decodeSlash(params);

	const formatsState = useSelector(variantFormats(specificationid));
	const isLoggedIn = useSelector(isLoggedInSelector);
	const isCartLoading = useSelector(isLoadingSelector);
	const lines = useSelector(orderLinesSelector);

	const variantSelectorRef = useRef();

	const [active, setActive] = useState("");
	const [packaging, setPackaging] = useState("");
	const [packagingFormat, setPackagingFormat] = useState("");
	const [qty, setQty] = useState(1);
	const [qty_pack, setQty_pack] = useState(1);
	const [genericPrice, setGenericPrice] = useState();
	const [customPrice, setCustomPrice] = useState();
	const [shippingRange, setShippingRange] = useState("");
	const [quoteRequested, setQuoteRequested] = useState(false);
	const [sealantCalculaterOpen, setSealantCalculaterOpen] = useState(false);
	const [shakeError, setShakeError] = useState(false);
	const [curFormat, setCurFormat] = useState();
	const [customerVariantPrice, setCustomerVariantPrice] = useState();

	const formatChangeHandler = (format) => {
		setCurFormat(format);
		if (!formatid || formatid !== format.erp_code) {
			navigate(
				{
					pathname: `/adstore/variants/${encodeSlash(itemid)}/${encodeSlash(brandid)}/${encodeSlash(
						specificationid
					)}/${encodeSlash(format.erp_code)}/`,
					search: queryParams.toString(),
				},
				{ replace: true }
			);
		} else {
			navigate(
				{
					pathname: `/adstore/variants/${encodeSlash(itemid)}/${encodeSlash(brandid)}/${encodeSlash(
						specificationid
					)}/`,
					search: queryParams.toString(),
				},
				{ replace: true }
			);
		}
	};

	const changeQtyHandler = (event) => {
		if (event.target.value) {
			setQty_pack(parseInt(event.target.value));
		} else {
			setQty_pack(1);
		}
	};

	const onBlurQtyHandler = (event) => {
		if (
			curFormat &&
			event.target.value <
				(curFormat?.multiple && curFormat?.multiple !== 0
					? Math.floor((curFormat?.min_qty / curFormat?.multiple).toFixed(0))
					: 1)
		) {
			setShakeError(true);
			setQty_pack(
				curFormat?.multiple && curFormat?.multiple !== 0
					? Math.floor((curFormat?.min_qty / curFormat?.multiple).toFixed(0))
					: 1
			);
			setTimeout(() => {
				setShakeError(false);
			}, 350);
		}
	};

	const addQty = () => {
		setQty_pack(qty_pack + 1);
	};

	const minusQty = () => {
		if (
			curFormat &&
			qty_pack - 1 <
				(curFormat?.multiple && curFormat?.multiple !== 0
					? Math.floor((curFormat?.min_qty / curFormat?.multiple).toFixed(0))
					: 1)
		) {
			setShakeError(true);
			setTimeout(() => {
				setShakeError(false);
			}, 350);

			return;
		}
		if (qty_pack > 1) setQty_pack(qty_pack - 1);
	};

	const closeSealantCalculator = () => {
		setSealantCalculaterOpen(false);
	};

	useEffect(() => {
		setActive(formatid ? "active" : "");
		setQty_pack(1);
		setPackaging("");
		setGenericPrice(undefined);
		setCustomPrice(undefined);
	}, [formatid]);

	useEffect(() => {
		if (formatsState && formatsState.length === 1) {
			navigate(
				{
					pathname: `/adstore/variants/${encodeSlash(itemid)}/${encodeSlash(brandid)}/${encodeSlash(
						specificationid
					)}/${encodeSlash(formatsState[0].erp_code)}/`,
					search: queryParams.toString(),
				},
				{ replace: true }
			);
			setCurFormat(formatsState[0]);
		}
		if (formatsState && formatsState.length > 0 && formatid) {
			navigate(
				{
					pathname: `/adstore/variants/${encodeSlash(itemid)}/${encodeSlash(brandid)}/${encodeSlash(
						specificationid
					)}/${encodeSlash(formatid)}/`,
					search: queryParams.toString(),
				},
				{ replace: true }
			);
			setCurFormat(formatsState.find((f) => f.erp_code === formatid));
		} else {
			setCurFormat(undefined);
		}
	}, [formatsState, specificationid, formatid]);

	useEffect(() => {
		if (isLoggedIn && curFormat && curFormat.sales_prices && curFormat.sales_prices.length > 0) {
			const curPrice = curFormat.sales_prices.find((p) => p.qty <= qty);
			setCustomerVariantPrice(curPrice ? curPrice.price : undefined);
		} else setCustomerVariantPrice(undefined);
	}, [qty, curFormat, isLoggedIn]);

	useEffect(() => {
		if (curFormat && curFormat?.min_qty > curFormat?.multiple && curFormat?.on_hand <= curFormat?.min_qty)
			setQty_pack(
				curFormat?.multiple && curFormat?.multiple !== 0
					? Math.floor((curFormat?.min_qty / curFormat?.multiple).toFixed(0))
					: 1
			);
		else setQty_pack(1);
	}, [curFormat?.multiple, curFormat?.min_qty]);

	useEffect(() => {
		setQty(qty_pack * curFormat?.multiple);
	}, [qty_pack, curFormat?.multiple]);

	const requestQuoteHandler = useCallback(() => {
		setQuoteRequested(true);
		dispatch(quoteRequest({ specificationId: specificationid, brandId: brandid, formatId: formatid }));
	}, [specificationid, brandid, formatid]);

	useEffect(() => {
		let packagingFormat = "";
		if (curFormat) {
			const cur_packaging = props.packaging > 0 ? props.packaging : curFormat.packaging;
			switch (cur_packaging) {
				case 1:
					packagingFormat = t(keys.GLOBAL.COMMON.BAG);
					if (
						curFormat?.on_hand > 0 &&
						curFormat?.multiple > 0 &&
						Number(curFormat?.on_hand) / Number(curFormat?.multiple) > 1
					)
						setPackagingFormat(t(keys.GLOBAL.COMMON.BAGS));
					else setPackagingFormat(packagingFormat);
					break;
				case 2:
					packagingFormat = t(keys.GLOBAL.COMMON.BARREL);
					if (
						curFormat?.on_hand > 0 &&
						curFormat?.multiple > 0 &&
						Number(curFormat?.on_hand) / Number(curFormat?.multiple) > 1
					)
						setPackagingFormat(t(keys.GLOBAL.COMMON.BARRELS));
					else setPackagingFormat(packagingFormat);
					break;
				case 3:
					packagingFormat = t(keys.GLOBAL.COMMON.BOX);
					if (
						curFormat?.on_hand > 0 &&
						curFormat?.multiple > 0 &&
						Number(curFormat?.on_hand) / Number(curFormat?.multiple) > 1
					)
						setPackagingFormat(t(keys.GLOBAL.COMMON.BOXES));
					else setPackagingFormat(packagingFormat);
					break;
				case 4:
					packagingFormat = t(keys.GLOBAL.COMMON.EACH);
					if (
						curFormat?.on_hand > 0 &&
						curFormat?.multiple > 0 &&
						Number(curFormat?.on_hand) / Number(curFormat?.multiple) > 1
					)
						setPackagingFormat(t(keys.GLOBAL.COMMON.EACHS));
					else setPackagingFormat(packagingFormat);
					break;
				case 5:
					packagingFormat = t(keys.GLOBAL.COMMON.CANE);
					if (
						curFormat?.on_hand > 0 &&
						curFormat?.multiple > 0 &&
						Number(curFormat?.on_hand) / Number(curFormat?.multiple) > 1
					)
						setPackagingFormat(t(keys.GLOBAL.COMMON.CANES));
					else setPackagingFormat(packagingFormat);
					break;
				case 6:
					packagingFormat = t(keys.GLOBAL.COMMON.DRUM);
					if (
						curFormat?.on_hand > 0 &&
						curFormat?.multiple > 0 &&
						Number(curFormat?.on_hand) / Number(curFormat?.multiple) > 1
					)
						setPackagingFormat(t(keys.GLOBAL.COMMON.DRUMS));
					else setPackagingFormat(packagingFormat);
					break;
				case 7:
					packagingFormat = t(keys.GLOBAL.COMMON.PAIL);
					if (
						curFormat?.on_hand > 0 &&
						curFormat?.multiple > 0 &&
						Number(curFormat?.on_hand) / Number(curFormat?.multiple) > 1
					)
						setPackagingFormat(t(keys.GLOBAL.COMMON.PAILS));
					else setPackagingFormat(packagingFormat);
					break;
				case 8:
					packagingFormat = t(keys.GLOBAL.COMMON.TOTE);
					if (
						curFormat?.on_hand > 0 &&
						curFormat?.multiple > 0 &&
						Number(curFormat?.on_hand) / Number(curFormat?.multiple) > 1
					)
						setPackagingFormat(t(keys.GLOBAL.COMMON.TOTES));
					else setPackagingFormat(packagingFormat);
					break;
				default:
					curFormat?.multiple > 1
						? (packagingFormat = t(keys.GLOBAL.COMMON.BOX))
						: (packagingFormat = t(keys.GLOBAL.COMMON.EACH));
					if (
						curFormat?.on_hand > 0 &&
						curFormat?.multiple > 0 &&
						Number(curFormat?.on_hand) / Number(curFormat?.multiple) > 1
					)
						curFormat?.multiple > 1
							? setPackagingFormat(t(keys.GLOBAL.COMMON.BOXES))
							: setPackagingFormat(t(keys.GLOBAL.COMMON.EACHS));
					else setPackagingFormat(packagingFormat);
					break;
			}
			const multiple = curFormat?.multiple > 0 ? curFormat?.multiple : 1;
			const unit = multiple > 1 ? t(keys.GLOBAL.COMMON.UNITS) : t(keys.GLOBAL.COMMON.UNIT);
			setPackaging(` (${multiple} ${unit}/${packagingFormat})`);

			let price = Number(curFormat?.price)
				.toFixed(2)
				.toString()
				.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			if (lngId.includes("fr")) price = price.replace(".", ",");
			if (curFormat && curFormat.price > 0) {
				setGenericPrice(
					<div className={classes.details}>
						<p>{t(keys.PRODUCTS.VARIANT.LIST_PRICE)} </p>
						<p className={`${customerVariantPrice ? classes.disable : ""}`}>
							<span className={classes.price}>
								{lngId.includes("en") ? "$" : ""}
								{price}
								{lngId.includes("fr") ? "$" : ""}
							</span>
							/
							{lngId.includes("fr")
								? props.description_format_fr ?? curFormat?.description_fr
								: props.description_format_en ?? curFormat?.description_en
								? lngId.includes("fr")
									? props.description_format_fr ?? curFormat?.description_fr
									: props.description_format_en ?? curFormat?.description_en
								: curFormat?.erp_code}
						</p>
					</div>
				);
			}

			if (isLoggedIn) {
				if (customerVariantPrice && customerVariantPrice > 0) {
					let price = Number(customerVariantPrice)
						.toFixed(2)
						.toString()
						.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
					if (lngId.includes("fr")) price = price.replace(".", ",");
					setCustomPrice(
						<div className={classes.details}>
							<p>{t(keys.PRODUCTS.VARIANT.YOUR_PRICE)}</p>
							<p>
								<span className={classes.customPrice}>
									{lngId.includes("en") ? "$" : ""}
									{price}
									{lngId.includes("fr") ? "$" : ""}
								</span>
								/
								{lngId.includes("fr")
									? props.description_format_fr ?? curFormat?.description_fr
									: props.description_format_en ?? curFormat?.description_en
									? lngId.includes("fr")
										? props.description_format_fr ?? curFormat?.description_fr
										: props.description_format_en ?? curFormat?.description_en
									: curFormat?.erp_code}
							</p>
						</div>
					);
				} else {
					if (!quoteRequested) {
						setCustomPrice(
							<div className={classes.details}>
								<p className={classes.link} onClick={requestQuoteHandler}>
									{t(keys.PRODUCTS.VARIANT.GET_QUOTE)}
								</p>
							</div>
						);
					} else {
						setCustomPrice(
							<div className={classes.details}>
								<p>{t(keys.PRODUCTS.VARIANT.QUOTE_SENT)}</p>
							</div>
						);
					}
				}
			} else {
				setCustomPrice(
					<div className={classes.details}>
						<CustomNavLink to={`/sign-in`} className={classes.link}>
							{t(keys.PRODUCTS.VARIANT.LOG_IN)}
						</CustomNavLink>
						<p>{t(keys.PRODUCTS.VARIANT.LOG_IN_AD)}</p>
					</div>
				);
			}

			const leadtime = curFormat?.leadTime > 0 ? curFormat?.leadTime : 10;
			setShippingRange(`${t(keys.PRODUCTS.VARIANT.AVAILBLE1)} ${leadtime} ${t(keys.PRODUCTS.VARIANT.AVAILBLE2)}`);
		}
	}, [t, lngId, curFormat, customerVariantPrice, isLoggedIn, quoteRequested, requestQuoteHandler]);

	const addToCartHandler = () => {
		if (isLoggedIn) {
			if (curFormat) {
				let price;
				if (customerVariantPrice) price = Number(customerVariantPrice).toFixed(2);
				else price = Number(curFormat?.price).toFixed(2);
				const line = lines && lines.find((l) => l.variant.id === curFormat.variant_id);

				if (line) {
					dispatch(
						updateLine({
							id: line.id,
							variant_id: line.variant.id,
							qty: Number(qty) + Number(line.qty),
							qty_pack: Number(qty_pack) + Number(line.qty_pack),
							unitAmount: Number(price),
							amount: ((Number(qty) + Number(line.qty)) * Number(price)).toFixed(2),
							cartOpen: true,
						})
					);
				} else {
					dispatch(
						addLine({
							variant_id: curFormat.variant_id,
							qty: Number(qty),
							qty_pack: Number(qty_pack),
							unitAmount: Number(price),
							amount: (Number(qty) * Number(price)).toFixed(2),
						})
					);
				}
			}
		} else {
			dispatch(setPrecedentUrl({ pathname: location.pathname, search: location.search }));
			navigate({ pathname: `/sign-in`, search: queryParams.toString() });
		}
	};

	useEffect(() => {
		if (variantSelectorRef && variantSelectorRef?.current) {
			variantSelectorRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	}, [variantSelectorRef, variantSelectorRef?.current]);

	return (
		<Fragment>
			{sealantCalculaterOpen && (
				<SealantCalculatorModal
					close={() => {
						closeSealantCalculator();
					}}
				/>
			)}
			<div
				className={classes.variantSelectorBody}
				ref={variantSelectorRef}
				style={{ width: `${props.length * 200 - 50}px` }}
			>
				<ItemCard
					className={classes.itemOverView}
					title={lngId.includes("fr") ? props.title_fr : props.title_en}
					hex={props.hex}
					image={curFormat && curFormat.image ? curFormat.image : props.image}
				/>
				<div className={classes.detailSelector}>
					<div>
						<h1>{t(keys.PRODUCTS.OVERVIEW.FORMAT)}</h1>
						{formatsState && formatsState.length > 0 && (
							<div className={classes.formatButtons}>
								{formatsState.map((format) => (
									<Button
										key={`${format.erp_code} ${props.stock}`}
										id={format.erp_code}
										className={classes.buttons}
										color={formatid === format.erp_code ? "primary" : "outlineBlack"}
										size="big"
										onClick={() => formatChangeHandler(format)}
									>
										{getLocalizedValueByCustomMapping(format, { en_us: "conversion_en_us", default: "erp_code" })}
									</Button>
								))}
							</div>
						)}
					</div>
					{curFormat && (
						<div className={classes.priceLayout}>
							{curFormat?.price !== undefined &&
								(customerVariantPrice === undefined ||
									Number(customerVariantPrice) < curFormat?.price) &&
								genericPrice}
							{customPrice}
						</div>
					)}
					<div className={`${classes.qty} ${active ? classes[active] : ""}`}>
						<h1>{`${t(keys.PRODUCTS.VARIANT.QUANTITY)}${packaging}`}</h1>
						{(curFormat &&
							packagingFormat &&
							curFormat?.min_qty &&
							curFormat?.min_qty > curFormat?.multiple &&
							curFormat?.on_hand <= curFormat?.min_qty) === true && (
							<div className={`${classes.minimumOrder} ${shakeError === true ? classes.shakeError : ""}`}>
								<Icon icon="oui:asterisk" />
								<p>{`${t(keys.PRODUCTS.VARIANT.MIN_ORDER)} ${
									curFormat?.multiple && curFormat?.multiple !== 0
										? (curFormat?.min_qty / curFormat?.multiple).toFixed(0)
										: curFormat?.min_qty
								} ${packagingFormat} ${t(keys.GLOBAL.COMMON.OF)} ${curFormat?.erp_code}`}</p>
							</div>
						)}
						{formatsState && formatsState.length > 0 && (
							<div className={classes.qtyButtons}>
								<Button
									id="minus"
									className={classes.buttons}
									onClick={minusQty}
									color="outlineBlack"
									size="small"
								>
									-
								</Button>
								<TextInput
									id="value"
									className={classes.textInput}
									inputClassName={classes.input}
									onChange={changeQtyHandler}
									onBlur={onBlurQtyHandler}
									type="number"
									value={qty_pack}
									shakeError={shakeError}
								/>
								<Button
									id="add"
									className={classes.buttons}
									onClick={addQty}
									color="outlineBlack"
									size="small"
								>
									+
								</Button>
								<h1>
									{`${(curFormat && curFormat.multiple ? curFormat?.multiple : 1) * qty_pack}
								${
									(curFormat && curFormat.multiple ? curFormat?.multiple : 1) * qty_pack > 1
										? t(keys.GLOBAL.COMMON.UNITS)
										: t(keys.GLOBAL.COMMON.UNIT)
								}`}
								</h1>
							</div>
						)}
						{curFormat && Number(curFormat.on_hand) / Number(curFormat.multiple) < 1 && (
							<div className={classes.details}>
								<span className={classes.notAvailable}>{shippingRange}</span>
							</div>
						)}
						{curFormat && Number(curFormat.on_hand) / Number(curFormat.multiple) >= 1 && (
							<div className={classes.onhand}>
								{`${
									Number(curFormat.multiple) === 0
										? 0
										: (Number(curFormat.on_hand) / Number(curFormat.multiple)).toFixed(0)
								} ${packagingFormat} ${t(keys.CART.OVERVIEW.AVAILABLE)}`}
							</div>
						)}
						<Button
							className={classes.addCartButton}
							onClick={addToCartHandler}
							disabled={
								isCartLoading ||
								shakeError ||
								(!customerVariantPrice && !(curFormat && curFormat?.price > 0))
							}
						>
							{t(keys.PRODUCTS.OVERVIEW.ADD_TO_CART_BTN)}
						</Button>
					</div>
				</div>
				<div className={classes.calculatorContainer}>
					<Icon className={classes.icon} icon="carbon:calculator-check" />
					<h1>{t(keys.CALCULATOR_TITLE)}</h1>
					<h3>{t(keys.CALCULATOR_CAPTION)}</h3>
					<Button
						className={classes.buttons}
						color="outlineBlack"
						size="big"
						onClick={() => setSealantCalculaterOpen(true)}
					>
						{t(keys.PRODUCTS.VARIANT.TRY)}
					</Button>
				</div>
			</div>
		</Fragment>
	);
};

export default VariantSelector;
