import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import ColorCard from "./colorCard/ColorCard";
import VariantSelector from "../variantSelector/VariantSelector";
import decodeSlash from "../../../../../hooks/decodeSlash";

import { itemIsLoadingSelector } from "../../../../../store/product";
import { variantFormats } from "../../../../../store/variant";

import classes from "./ColorLine.module.scss";

const ColorLine = (props) => {
	const { i18n } = useTranslation();
	const lngId = i18n.language;

	const params = useParams();
	const { itemid, brandid, specificationid, formatid } = decodeSlash(params);

	const itemsLoading = useSelector(itemIsLoadingSelector);
	const formatsState = useSelector(variantFormats(specificationid));

	const [selectedSpec, setSelectedSpec] = useState();
	const [itemOverviewImage, setItemOverviewImage] = useState();

	useEffect(() => {
		setSelectedSpec(props.specifications.find((spec) => spec.title === specificationid));
	}, [specificationid]);

	useEffect(() => {
		if (!itemsLoading && selectedSpec && formatsState) {
			if (formatid) {
				let curFormat = formatsState.find((f) => f.id === formatid);
				if (curFormat && curFormat.image) setItemOverviewImage(curFormat.image);
				else setItemOverviewImage(selectedSpec.image);
			} else setItemOverviewImage(selectedSpec.image);
		}
	}, [itemsLoading, selectedSpec, formatsState, formatid]);

	return (
		<div className={classes.colorLineContainer}>
			<div className={classes.colorLineElements}>
				{props.specifications.map((specification) => (
					<ColorCard
						key={specification.id}
						id={specification.id}
						title={specification.title}
						image={specification.image}
						hex={specification.hex}
						subtitle={lngId.includes("fr") ? specification.subtitle_fr : specification.subtitle_en}
						stock={props.stock}
						description_format_fr={specification.description_format_fr}
						description_format_en={specification.description_format_en}
						displayImage={props.displayImage}
						itemid={itemid}
						brandid={brandid}
						specificationid={specificationid}
					/>
				))}
			</div>
			{selectedSpec && (
				<VariantSelector
					id={selectedSpec.id}
					length={props.length}
					title={selectedSpec.title}
					title_fr={`${selectedSpec.title} ${selectedSpec.subtitle_fr}`}
					title_en={`${selectedSpec.title} ${selectedSpec.subtitle_en}`}
					hex={selectedSpec.hex}
					image={itemOverviewImage}
					stock={props.stock}
					description_format_fr={selectedSpec.description_format_fr}
					description_format_en={selectedSpec.description_format_en}
				/>
			)}
		</div>
	);
};

export default ColorLine;
