import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
	root: {
		"&": {
			display: "flex",
			alignItems: "center",
		},
	},
	icon: {
		borderRadius: "50%",
		border: `1px solid ${theme.palette.neutral.grey.main}`,
		width: 20,
		height: 20,
		backgroundColor: theme.palette.neutral.light,
		"input:hover ~ &": {
			backgroundColor: theme.palette.neutral.grey.light,
		},
		"input:disabled ~ &": {
			background: theme.palette.neutral.grey.light,
		},
	},
	checkedIcon: {
		"&:before": {
			display: "block",
			width: 14,
			height: 14,
			content: '""',
			position: "absolute",
			backgroundColor: theme.palette.primary.main,
			borderRadius: "100%",
			top: "50%",
			left: "50%",
			transform: "translate(-50%, -50%)",
		},
	},
}));

export const RadioInput = React.forwardRef(({ id, label, value, checked, onChange }, ref) => {
	const classes = useStyles();

	return (
		<label htmlFor={id} className={classes.root}>
			<Radio
				id={id}
				value={value}
				inputRef={ref}
				checked={checked}
				onChange={onChange}
				color="default"
				checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
				icon={<span className={classes.icon} />}
				inputProps={{ "aria-label": label }}
			/>
			<span>{label}</span>
		</label>
	);
});
