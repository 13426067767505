import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { useLocalStorage } from "./useLocalStorage";
import { isLoggedInSelector, isLoggedOutSelector, languageSelector } from "../store/auth";

import { constants } from "../locales/constant";

/**
 * Custom hook that manages the user's language and currency settings based on the detected language,
 * IP location, or URL parameters. It also interacts with `localStorage` to persist user preferences.
 *
 * @param {Function} dispatch - The dispatch function used to trigger actions in the application state (e.g., for currency updates).
 * @param {Function} setCurrency - The action creator used to update the currency in the application state.
 * @returns {Object} An object containing the current language (`lngId`) and the suggested language (`suggestedLanguage`).
 */
export const useLanguage = (dispatch, setCurrency) => {
	const { i18n } = useTranslation();
	const browserLanguage = navigator.language || navigator.userLanguage;

	const { getItem, setItem } = useLocalStorage("localize");
	const localize = getItem();

	const [language, setLanguage] = useState(i18n.language);
	const [suggestedLanguage, setSuggestedLanguage] = useState(i18n.language);
	const [loadingLanguage, setLoadingLanguage] = useState(true);

	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);
	const currentLngParam = queryParams.get("lng");

	const isLoggedIn = useSelector(isLoggedInSelector);
	const isLoggedOut = useSelector(isLoggedOutSelector);
	const userLanguage = useSelector(languageSelector);

	/**
	 * Fetches the country code based on the user's IP address.
	 * If the fetch is successful, it stores the country code in local storage and returns it.
	 * If it fails, it logs the error and returns `null`.
	 * 
	 * @returns {string|null} The country code or null if the fetch fails.
	 */
	const fetchCountryCode = async () => {
		try {
			const response = await fetch(`${constants.API_SERVER}/ip-location/`);
			const { status, countryCode } = await response.json();
			setItem({ countryCode });
			return status === "success" ? countryCode : null;
		} catch (error) {
			console.error("Error fetching IP location:", error);
			return null;
		}
	};

	/**
	 * Computes the default language to be used based on the user's country code, local storage, or login status.
	 * - If a language is saved in local storage, it uses that.
	 * - If the user is logged in, it uses the user's preferred language.
	 * - If the user's country code is "CA" (Canada), it sets either French (fr-ca) or English (en-ca) based on the browser language.
	 * - Otherwise, it uses the default language from i18n.
	 * 
	 * @param {string} countryCode - The country code used to determine language preferences.
	 * @returns {string} The computed default language.
	 */
	const computedDefaultLanguage = (countryCode) => {
		if (localize?.language) {
			return localize.language;
		}

		if (isLoggedIn) {
			return userLanguage.toLowerCase();
		}
		
		if (countryCode?.toLowerCase() === "ca") {
			return browserLanguage.startsWith("fr") ? "fr-ca" : "en-ca";
		}
		
		return i18n.language;
	};

	/**
	 * Computes the suggested language based on the login status or local storage.
	 * - If the user is logged in, it returns the user's preferred language.
	 * - If the language is stored in local storage, it returns that.
	 * 
	 * @returns {string} The computed suggested language.
	 */
	const computedSuggestedLanguage = useMemo(() => {
		if (isLoggedIn) {
			return userLanguage.toLowerCase();
		}

		if (localize?.language) {
			return localize.language;
		}
	}, [isLoggedIn, localize]);

	/**
	 * Determines if a language change is needed or if a hidden message should be displayed.
	 * - If the user is logged in and has a stored language, it compares it with the current language.
	 * - If no language is stored and the user is logged in, it checks if the current language matches the user's preferred language.
	 * - If the user is logged out, it checks if the suggested language matches the current language.
	 * 
	 * @returns {boolean} Whether the message should be hidden based on language matching.
	 */
	const hiddenMessage = useMemo(() => {
		if (isLoggedIn && localize?.language) {
			return (
				localize.language === language ||
				(localize.language.endsWith("ca") && language.endsWith("ca")) ||
				language === userLanguage.toLowerCase()
			);
		}

		if (isLoggedIn && localize.language === undefined) return userLanguage === language;

		if (!isLoggedIn) return (suggestedLanguage === language || suggestedLanguage.endsWith("ca") && language.endsWith("ca"));

		return false;
	}, [isLoggedIn, localize, language, suggestedLanguage]);

	/**
	 * Effect to determine and set the user's default language.
	 * If no language is stored locally, fetch the country code to infer language,
	 * then update URL parameters if a language param (`lng`) is missing or invalid.
	 */
	useEffect(async () => {
		const validLanguages = i18n.options.supportedLng;

		let countryCode = localize?.countryCode;
		if (!countryCode) {
			countryCode = await fetchCountryCode();
		}

		let defaultLanguage = computedDefaultLanguage(countryCode);
		setSuggestedLanguage(defaultLanguage);

		if (currentLngParam && validLanguages.includes(currentLngParam)) {
			defaultLanguage = currentLngParam;
		} else {
			queryParams.set("lng", defaultLanguage);
			navigate({ pathname: location.pathname, search: queryParams.toString() });
		}

		i18n.changeLanguage(defaultLanguage).then(() => setLoadingLanguage(false));
	}, []);

	/**
	 * Effect to update the suggested language based on the computed suggested language.
	 * Triggers when `computedSuggestedLanguage` changes.
	 */
	useEffect(() => {
		if (computedSuggestedLanguage) {
			setSuggestedLanguage(computedSuggestedLanguage);
		}
	}, [computedSuggestedLanguage]);

	/**
	 * Effect to handle language changes when the user is logged in.
	 * If `localize.language` is set, use it, otherwise use `userLanguage`.
	 */
	useEffect(() => {
		if (isLoggedIn && userLanguage) {
			if (localize?.language) {
				i18n.changeLanguage(localize.language);
			} else {
				i18n.changeLanguage(userLanguage.toLowerCase());
			}
		}
	}, [isLoggedIn, userLanguage]);

	/**
	 * Effect to reset language to default settings when the user logs out.
	 * Retrieves and sets the default language, then changes `i18n` language accordingly.
	 */
	useEffect(() => {
		if (isLoggedOut) {
			let countryCode = localize?.countryCode;

			let defaultLanguage = computedDefaultLanguage(countryCode);
			setSuggestedLanguage(defaultLanguage);
			i18n.changeLanguage(defaultLanguage);
		}
	}, [isLoggedOut]);

	/**
	 * When the i18n language changes, this effect updates the language
	 * and triggers an update to the global currency based on the selected language.
	 *
	 * @returns {void}
	 */
	useEffect(() => {
		setLanguage(i18n.language);

		const currency = i18n.language.endsWith("ca") ? "CAD" : "USD";
		dispatch(setCurrency(currency));

		if (currentLngParam != i18n.language) {
			queryParams.set("lng", i18n.language);
			navigate({ pathname: location.pathname, search: queryParams.toString() });
		}
	}, [i18n.language]);

	return { language, suggestedLanguage, loadingLanguage, hiddenMessage };
};
