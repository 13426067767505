import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import cookie from "js-cookie";
import ReactGA from "react-ga";

import PrivacyModal from "./components/Privacy/PrivacyModal";
import PrivacySideCart from "./components/Privacy/PrivacySideCart";
import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar/Navbar";
import LanguageMessage from "./components/navbar/LanguageMessage/LanguageMessage";
import Routing from "./Routing";

import {
	accessExpSelector,
	isAdvertisingEnabled,
	isLoadingSelector,
	isLoggedInSelector,
	isLoggedOutSelector,
	isSavedCookie,
	refresh,
	saveCookies,
	setCookie,
	setCurrency,
} from "./store/auth";
import { clear, getOrder } from "./store/cart";
import { getMediaBase } from "./store/mediaBase";
import { getGoogleMapKey } from "./store/offices";
import { clearAll } from "./store/productQuery";

import { constants } from "./locales/constant";
import { useLanguage } from "./hooks";

import "./styles/global.scss";

let logoutTimer;

const calculateRemainingTime = (access_token_expiration) => {
	const currentTime = new Date().getTime();
	const refreshExpTime = new Date(access_token_expiration).getTime();
	return refreshExpTime - currentTime;
};

ReactGA.initialize(constants.TRACKING_ID);

function App() {
	const dispatch = useDispatch();

	const { language, suggestedLanguage, loadingLanguage, hiddenMessage } = useLanguage(dispatch, setCurrency);

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const isAuthLoading = useSelector(isLoadingSelector);
	const isLoggedIn = useSelector(isLoggedInSelector);
	const isLoggedOut = useSelector(isLoggedOutSelector);
	const accessExp = useSelector(accessExpSelector);
	const savedCookie = useSelector(isSavedCookie);
	const advertisingEnabled = useSelector(isAdvertisingEnabled);

	const [showSideCart, setShowSideCart] = useState(false);

	const handleManageCookiesClick = () => {
		setShowSideCart(true);
	};

	const handleCloseSideCart = () => {
		setShowSideCart(false);
	};

	useEffect(() => {
		if (!location.pathname.includes("adstore")) {
			dispatch(clearAll());
		}
	}, [location?.pathname]);

	useEffect(() => {
		if (savedCookie === false) {
			const cookies = cookie.get("cookiesPolicy");
			if (cookies) {
				let cookiesJson = JSON.parse(cookies);
				if (cookiesJson.savedCookie === true && isAuthLoading === false) {
					if (isLoggedIn === true) {
						dispatch(setCookie(cookiesJson));

						dispatch(
							saveCookies({
								Advertising: cookiesJson.Advertising,
								Functional: cookiesJson.Functional,
								Analytical: cookiesJson.Analytical,
								savedCookie: cookiesJson.savedCookie,
							})
						);
					}
				}
			}
		}
	}, [isAuthLoading, isLoggedIn, savedCookie]);

	useEffect(() => {
		if (advertisingEnabled) {
			var s = document.createElement("script");
			s.type = "text/javascript";
			s.src = ("https:" === document.location.protocol ? "https://pi" : "http://cdn") + ".pardot.com/pd.js";

			var c = document.getElementsByTagName("script")[0];
			c.parentNode.insertBefore(s, c);
		}
	}, [window.location.href, advertisingEnabled]);

	useEffect(() => {
		dispatch(getMediaBase());
		dispatch(getGoogleMapKey());
	}, []);

	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	useEffect(() => {
		if (isLoggedIn === true) {
			dispatch(getOrder());
		} else if (isLoggedIn === false) {
			dispatch(clear());
		}
	}, [isLoggedIn]);

	const refreshHandler = useCallback(() => {
		if (!isAuthLoading) {
			dispatch(refresh());
		}
	}, [isAuthLoading]);

	useEffect(() => {
		if (isLoggedIn || !isLoggedOut) {
			logoutTimer = setTimeout(refreshHandler, calculateRemainingTime(accessExp) - 5000);
		} else {
			clearTimeout(logoutTimer);
		}
	}, [refreshHandler, isLoggedIn, isLoggedOut, accessExp]);

	useEffect(() => {
		const lngParm = queryParams.get("lng");

		if (lngParm && lngParm.includes("fr")) {
			window.olark.configure("system.localization", "fr-FR");
		} else {
			window.olark.configure("system.localization", "en-US");
		}
		window.olark?.identify("4202-221-10-2740");
	}, []);

	useEffect(() => {
		if (language && language.includes("fr")) {
			window.olark("api.box.setLocale", "fr-FR");
		} else {
			window.olark("api.box.setLocale", "en-US");
		}
	}, [language]);

	return (
		<Fragment>
			{!loadingLanguage && (
				<>
					{!location.pathname.includes("sign") &&
						!location.pathname.includes("header") &&
						!location.pathname.includes("footer") && (
							<>
								{!hiddenMessage && <LanguageMessage language={language} suggestedLanguage={suggestedLanguage} />}
								<Navbar />
							</>
						)}
					{language && <Routing />}
					{!showSideCart && isAuthLoading === false && savedCookie === false && (
						<PrivacyModal onManageCookiesClick={handleManageCookiesClick} />
					)}
					{
						<PrivacySideCart
							showSideCart={showSideCart && isAuthLoading === false && savedCookie === false}
							onClose={handleCloseSideCart}
						/>
					}
					{!location.pathname.includes("in-construction") &&
						!location.pathname.includes("sign") &&
						!location.pathname.includes("header") &&
						!location.pathname.includes("footer") &&
						!location.pathname.includes("account-confirm-email") && (
							<footer>
								<Footer />
							</footer>
						)}
				</>
			)}
		</Fragment>
	);
}

export default App;
