import React, { Fragment, useState } from "react";
import ReactDOM from "react-dom";

import classes from "./ModalVideo.module.scss";

const ModalVideo = (props) => {
	const [videoLoading, setVideoLoading] = useState(true);

	const spinner = () => {
		setVideoLoading(!videoLoading);
	};

	const protalElement = document.getElementById("overlays");

	return (
		<Fragment>
			{ReactDOM.createPortal(
				<div>
					{props.isOpen && (
						<section
							className={classes.bg}
							onClick={() => {
								props.onClose();
							}}
						>
							<div className={classes.align}>
								<div className={classes.content}>
									<div className={classes.video_align}>
										{!props.img && (
											<iframe
												className={classes.video_style}
												onLoad={spinner}
												loading="lazy"
												width={props.width ? props.width : "800"}
												height={props.height ? props.height : "500"}
												src={`https://www.youtube.com/embed/${props.videoId}?autoplay=1`}
												title={props.title}
												allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											></iframe>
										)}
										{props.img && <img src={props.img} alt={props.img} />}
									</div>
								</div>
							</div>
						</section>
					)}
				</div>,
				protalElement
			)}
		</Fragment>
	);
};

export default ModalVideo;
