import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from "pure-react-carousel";
import { useNavigate } from "react-router-dom";
import { ImageMap } from "@qiuz/react-image-map";
import { Icon } from "@iconify/react";

import useElementSize from "../../../hooks/useElementSize";
import Button from "../../ui/Button/Button";
import ImageMapIcon from "./ImageMapIcon";
import CustomNavLink from "../../ui/customNavLink/CustomNavLink";

import { setQuotedOnly } from "../../../store/productQuery";

import tds_svg from "../../../assets/svg/TDS.svg";
import sds_svg from "../../../assets/svg/SDS.svg";
import fds_svg from "../../../assets/svg/FDS.svg";
import fts_svg from "../../../assets/svg/FTS.svg";
import { keys } from "../../../locales/localeskeys";

import classes from "./ConstructionCarousel.module.scss";

const ConstructionCarousel = (props) => {
	const dispatch = useDispatch();
	const { i18n, t } = useTranslation();

	const [gridRef, { width: gridWidth }] = useElementSize();

	const navigate = useNavigate();

	const lngId = i18n.language;
	const pictures = props.pictures;
	const search = `?lng=${lngId}`;

	const layoutRef = useRef();

	const [picturesToRender, setPicturesToRender] = useState(pictures.slice(1.1));
	const [picture_id, setPicture_id] = useState();
	const [currentMap, setCurrentMap] = useState();

	useEffect(() => {
		if (picture_id) {
			setCurrentMap(pictures.find((picture) => picture.id === picture_id).imgMaps[0]);
			setPicturesToRender(pictures.filter((p) => p.id !== picture_id));
		} else setCurrentMap(pictures[0].imgMaps[0]);
	}, [pictures, picture_id]);

	const itemClickHandler = (itemId, brandId) => {
		dispatch(setQuotedOnly(false));
		if (brandId) navigate(`/adstore/overview/${itemId}/${brandId}/${search}`);
		else navigate(`/adstore/overview/${itemId}/${search}`);
	};

	const productInfo = (itemId) => {
		if (!itemId) return;
		const item = props.curSubSegment?.items?.find((item) => item.itemId === itemId);

		if (item) {
			const featuresRender = item.features_documents?.filter((feature) =>
				feature.language.includes(lngId.substring(0, 2))
			);
			const tds = item.tech_documents?.find(
				(doc) =>
					(doc.title === "Technical Data Sheet" || doc.title === "Fiche technique") &&
					doc.language &&
					doc.language.includes(lngId.substring(0, 2))
			);
			const sds = item.tech_documents?.find(
				(doc) =>
					(doc.title === "Safety Data Sheet" || doc.title === "Fiche de données de sécurité") &&
					doc.language &&
					doc.language.includes(lngId.substring(0, 2))
			);

			return (
				<div className={classes.productInfoSection}>
					<div className={classes.titleContainer}>
						<h1>{item.title}</h1>
						{(lngId.includes("fr") ? item.label_fr : item.label_en) && (
							<h2>{lngId.includes("fr") ? item.label_fr : item.label_en}</h2>
						)}
						{(lngId.includes("fr") ? item.text_fr : item.text_en) && (
							<p>{lngId.includes("fr") ? item.text_fr : item.text_en}</p>
						)}
					</div>
					<div className={classes.productDisplay}>
						<div className={classes.left}>
							<img className={classes.overViewImg} src={item.defaultImage} alt="product-display.svg" />
							<div className={classes.ds}>
								{tds && (
									<CustomNavLink to={tds ? tds.url : "/"} target={"_blank"}>
										<img
											className={classes.ds_img}
											src={lngId.includes("fr") ? fts_svg : tds_svg}
											alt={tds_svg}
										/>
									</CustomNavLink>
								)}
								{sds && (
									<CustomNavLink to={sds ? sds.url : "/"} target={"_blank"}>
										<img
											className={classes.ds_img}
											src={lngId.includes("fr") ? fds_svg : sds_svg}
											alt={sds_svg}
										/>
									</CustomNavLink>
								)}
							</div>
							<div className={classes.productInfoContainer}>
								{featuresRender && featuresRender.length > 0 && (
									<div className={classes.featuresContainer}>
										<h3>{t(keys.PRODUCTS.OVERVIEW.FEATURES)}</h3>
										<ul>
											{featuresRender.map((feature) => (
												<li key={feature.id}>{feature.text}</li>
											))}
										</ul>
									</div>
								)}
							</div>
							<div className={classes.positionBtn}>
								<Button
									className={classes.btn}
									content={classes.content}
									onClick={() => itemClickHandler(item.erp_code, item.brandErpCode)}
								>
									{t(keys.PRODUCTS.OVERVIEW.VIEW_PRODUCT)}
								</Button>
							</div>
						</div>
					</div>
				</div>
			);
		}
	};

	return (
		<div className={classes.layout} ref={layoutRef}>
			<div className={classes.header}>
				<div className={classes.imgContainer} ref={gridRef}>
					<ImageMap
						className={classes.overViewImg}
						src={(picture_id ? pictures.find((picture) => picture.id === picture_id) : pictures[0]).url}
						map={(picture_id
							? pictures.find((picture) => picture.id === picture_id)
							: pictures[0]
						).imgMaps.map((map) => {
							return {
								width: `${map.width}%`,
								height: ``,
								left: `${map.left}%`,
								top: `${map.top}%`,
								href: map.href,
								render: () => (
									<ImageMapIcon
										id={map.id}
										current={currentMap?.id}
										onClick={() => setCurrentMap(map)}
									/>
								),
							};
						})}
					/>
					{picturesToRender && picturesToRender.length > 0 && (
						<CarouselProvider
							className={classes.carousel}
							naturalSlideWidth={100}
							naturalSlideHeight={
								gridWidth >= 900 ? 90 : gridWidth >= 700 ? 90 : gridWidth >= 500 ? 75 : 60
							}
							totalSlides={picturesToRender.length}
							visibleSlides={gridWidth >= 800 ? 4 : gridWidth >= 600 ? 2 : 1.4}
						>
							<ButtonBack className={classes.btn}>
								<Icon className={classes.icon} icon="bi:arrow-left-square-fill" />
							</ButtonBack>
							<Slider className={classes.slider}>
								{picturesToRender.map((picture, index) => (
									<Slide innerClassName={classes.slide} index={index} key={index}>
										<img
											src={picture.url}
											alt="url"
											onClick={() => {
												// setSelected_picture(index);
												setPicture_id(picture.id);
											}}
											className={classes.slideImg}
											key={index}
										/>
									</Slide>
								))}
							</Slider>
							<ButtonNext className={classes.btn}>
								<Icon className={classes.icon} icon="bi:arrow-right-square-fill" />
							</ButtonNext>
						</CarouselProvider>
					)}
				</div>
				{currentMap && productInfo(currentMap.item_id)}
			</div>
		</div>
	);
};

export default ConstructionCarousel;
