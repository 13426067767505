import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";

import { constants as constant } from "../locales/constant";
import encodeSlash from "../hooks/encodeSlash";

/**
 * Fournir la liste des produits
 * utiliser les cookies httpsonly pour s'authentifier
 */
export const getItemCarts = createAsyncThunk(
	"product/getItemCarts",
	/**
	 * Fournir la liste des produits
	 * @param _
	 * @param itemId
	 * @param rejectWithValue
	 * @param getState
	 * @param dispatch
	 */
	async (
		{ itemId, priority, subSegs, pageSize, search_feature, search_document, keep, searchValue, greendguard },
		{ rejectWithValue, getState, dispatch }
	) => {
		try {
			const {
				auth: { access_token },
			} = getState();

			const {
				productQuery: { search },
			} = getState();
			const {
				productQuery: { sort },
			} = getState();
			const {
				productQuery: { filter },
			} = getState();
			const {
				productQuery: { values },
			} = getState();
			let {
				productQuery: { categories },
			} = getState();
			let {
				productQuery: { brands },
			} = getState();
			let {
				productQuery: { subsegments },
			} = getState();
			const {
				productQuery: { favorite },
			} = getState();
			const {
				productQuery: { quotedonly },
			} = getState();
			const {
				productQuery: { page },
			} = getState();
			const {
				auth: { currencyCode },
			} = getState();

			const header = new Headers();
			if (access_token) {
				header.append("Authorization", "Bearer " + access_token);
			}

			if (subsegments && subsegments.length > 0) subsegments = subsegments.map((s) => encodeURIComponent(s));
			if (subSegs && subSegs.length > 0) subSegs = subSegs.map((s) => encodeURIComponent(s));
			if (categories && categories.length > 0) categories = categories.map((s) => encodeURIComponent(s));
			if (brands && brands.length > 0) brands = brands.map((s) => encodeURIComponent(s));

			let queryParam;
			if (itemId) {
				queryParam = `{constant.API_SERVER}/item/$${encodeURIComponent(encodeSlash(itemId))}/?`;
				if (!access_token && currencyCode && currencyCode !== "") {
					queryParam = queryParam + "&currency=" + currencyCode;
				}
			} else {
				queryParam =
					constant.API_SERVER +
					"/item/?" +
					"&page=" +
					(page && page !== "" && page !== "NaN" ? page : 1) +
					(!searchValue && search && search !== ""
						? "&search=" + encodeURIComponent(encodeSlash(search))
						: "") +
					(searchValue && searchValue !== ""
						? "&search=" + encodeURIComponent(encodeSlash(searchValue))
						: "") +
					(sort && sort !== "" ? "&sort=" + sort : "") +
					(filter && filter !== "" ? "&filter=" + filter : "") +
					(priority && priority !== "" ? "&priority=" + priority : "") +
					(pageSize && pageSize !== "" ? "&pageSize=" + pageSize : "") +
					(greendguard && greendguard !== "" ? "&greendguard=" + greendguard : "") +
					(!access_token && currencyCode && currencyCode !== "" ? "&currency=" + currencyCode : "") +
					(values && values.length > 0 ? "&values=" + JSON.stringify(values) : "") +
					(categories && categories.length > 0 ? "&categories=" + JSON.stringify(categories) : "") +
					(brands && brands.length > 0 ? "&brands=" + JSON.stringify(brands) : "") +
					(!subSegs && subsegments && subsegments.length > 0
						? "&subsegments=" + JSON.stringify(subsegments)
						: "") +
					(subSegs && subSegs.length > 0 ? "&subsegments=" + JSON.stringify(subSegs) : "") +
					(favorite && favorite === true ? "&favorite=true" : "") +
					(quotedonly && quotedonly === true ? "&quotedonly=true" : "") +
					(search_feature && search_feature === true ? "&search_feature=true" : "") +
					(search_document && search_document === true ? "&search_document=true" : "");
			}

			const response = await fetch(queryParam, {
				credentials: "include",
				headers: header,
			});
			if (!response.ok) {
				throw new Error("fetching items failed");
			}
			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

/**
 * Fournir l'overview de la carte selectionne
 * utiliser les cookies httpsonly pour s'authentifier
 */
export const getItemOverview = createAsyncThunk(
	"product/getItemOverview",
	/**
	 * Fournir la liste des produits
	 * @param _
	 * @param itemId
	 * @param rejectWithValue
	 * @param getState
	 * @param dispatch
	 */
	async ({ itemId, brandId }, { rejectWithValue, getState, dispatch }) => {
		try {
			const {
				auth: { access_token },
			} = getState();
			const {
				auth: { currencyCode },
			} = getState();

			const header = new Headers();
			if (access_token) {
				header.append("Authorization", "Bearer " + access_token);
			}

			let queryParam =
				`${constant.API_SERVER}/item/${encodeURIComponent(encodeSlash(itemId))}/?` +
				(brandId && brandId !== "" ? "&brand_code=" + brandId : "");
			if (!access_token && currencyCode && currencyCode !== "") {
				queryParam = queryParam + "&currency=" + currencyCode;
			}
			const response = await fetch(queryParam, {
				credentials: "include",
				headers: header,
			});
			if (!response.ok) {
				throw new Error("fetching items failed");
			}
			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

const productAdapter = createEntityAdapter({});

const product = createSlice({
	name: "product",
	initialState: productAdapter.getInitialState({
		loading: "done",
		currentRequestId: undefined,
		error: undefined,
		init: false,
		products: undefined,
		curProduct: undefined,
	}),
	reducers: {
		clearProduct: (state) => {
			state.loading = "done";
			state.currentRequestId = undefined;
			state.error = undefined;
			state.init = false;
			state.products = undefined;
			state.curProduct = undefined;

			productAdapter.removeAll(state);
		},
	},
	extraReducers: {
		[getItemCarts.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;
				state.curProduct = undefined;

				state.count = payload.count;
				state.next = payload.next;
				state.previous = payload.previous;

				if (!meta.arg.keep) productAdapter.removeAll(state);
				if (payload.results) productAdapter.addMany(state, payload.results);
			}
			return state;
		},
		[getItemCarts.pending]: (state, { meta }) => {
			state.currentRequestId = meta.requestId;
			state.loading = "pending";
			state.error = undefined;
			state.init = true;
			state.curProduct = undefined;

			return state;
		},
		[getItemCarts.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
				state.curProduct = undefined;

				productAdapter.removeAll(state);
			}
			return state;
		},
		[getItemOverview.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;

				state.curProduct = payload;
			}
			return state;
		},
		[getItemOverview.pending]: (state, { meta }) => {
			state.currentRequestId = meta.requestId;
			state.loading = "pending";
			state.error = undefined;
			state.init = true;

			state.curProduct = undefined;

			return state;
		},
		[getItemOverview.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;

				state.curProduct = undefined;
			}
			return state;
		},
	},
});

export default product;
export const { clearProduct } = product.actions;
export const productSelectors = productAdapter.getSelectors((state) => state?.product);
export const itemCartsSelector = createSelector(productSelectors.selectAll, (products) => products);
export const itemSelectorById = createSelector(productSelectors.selectById, (product) => product);
export const itemOverView = (state) => state?.product?.curProduct;
export const description_og_fr_selector = (state) => state?.product?.curProduct?.description_og_fr;
export const description_og_en_selector = (state) => state?.product?.curProduct?.description_og_en;
export const title_og_fr_selector = (state) => state?.product?.curProduct?.title_og_fr;
export const title_og_en_selector = (state) => state?.product?.curProduct?.title_og_en;
export const picture_og_selector = (state) =>
	state?.product?.curProduct?.pictures && state?.product?.curProduct?.pictures.length
		? state?.product?.curProduct?.pictures[0]
		: "";
export const itemTechnicalOverview = (state) =>
	state?.product?.curProduct ? state?.product?.curProduct?.tech_documents : [];

// export const itemTechnical = (state) => (state?.product?.curProduct ? state?.product?.curProduct?.tech_documents : []);
export const itemTechnicalSelector = (state) =>
	state?.product?.curProduct
		? state?.product?.curProduct?.tech_documents
				.filter(
					(doc) =>
						doc.title !== "Technical Data Sheet" &&
						doc.title !== "Fiche technique" &&
						doc.title !== "Safety Data Sheet" &&
						doc.title !== "Fiche de données de sécurité"
				)
				.sort((a, b) => (a.title > b.title ? 1 : -1))
		: [];

export const itemPhysicalOverview = (state) =>
	state?.product?.curProduct ? state?.product?.curProduct?.physical_property : [];
export const itemIsLoadingSelector = (state) => state?.product?.loading === "pending";
export const itemHasErrorSelector = (state) => !!state?.product?.error && state.product.error !== "";
export const itemInitSelector = (state) => state?.product?.init;
export const productCountSelector = (state) => state?.product?.count;
export const isSealantSelector = (state) => state?.product?.curProduct.isSealant;
export const technical_overview_enabledSelector = (state) => !!state?.product?.curProduct?.technical_overview;
export const technical_descriptionSelector = (state) => {
	return {
		fr: state?.product?.curProduct?.technical_overview.technical_description_fr,
		en: state?.product?.curProduct?.technical_overview.technical_description_en,
	};
};
export const specificationsSelector = (state) => state?.product?.curProduct?.technical_overview?.specifications;

export const usagesSelector = (state) => state?.product?.curProduct?.technical_overview?.usages;

export const detailsSelector = (state) => state?.product?.curProduct?.technical_overview?.details;
export const applicationsSelector = (state) => state?.product?.curProduct?.technical_overview?.applications;
export const packagingSelector = (state) => state?.product?.curProduct?.technical_overview?.packaging;

export const itemTechnicalSelectorGroupedSelector = createSelector(itemTechnicalSelector, (tech_documents) => {
	let group_ids = [0];
	let technicalDocumentsGrouped = [];
	let technicalDocuments = tech_documents.sort((a, b) =>
		a.group && b.group ? (a.group.order > b.group.order ? 1 : -1) : -1
	);
	for (let technical of technicalDocuments.filter((i) => i.group)) {
		if (group_ids.includes(technical.group.id)) {
			continue;
		}
		if (!group_ids.includes(technical.group.id)) {
			let item_technical_group = {
				group: technical.group,
				documents: tech_documents
					.filter((i) => i.group && i.group.id === technical.group.id)
					.sort((a, b) => (a.order > b.order ? 1 : -1)),
			};
			group_ids.push(technical.group.id);
			technicalDocumentsGrouped.push(item_technical_group);
		}
	}
	technicalDocumentsGrouped.push({
		group: { id: 0, order: 9999, label_fr: "Autre", label_en: "Other" },
		documents: tech_documents.filter((i) => !i.group).sort((a, b) => (a.order > b.order ? 1 : -1)),
	});
	return technicalDocumentsGrouped;
});
