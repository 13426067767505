import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { constants as constant } from "../locales/constant";

/**
 * Fournir la liste des categories
 */
export const getGreenguardCategories = createAsyncThunk(
	"ProductCategory/getGreenguardCategories",
	async (_, { rejectWithValue }) => {
		try {
			const response = await fetch(constant.API_SERVER + "/GgardCategory/");
			if (!response.ok) {
				throw new Error("fetching categories failed");
			}
			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

const greendguardCategoryAdapter = createEntityAdapter({});

const greenguardCategory = createSlice({
	name: "greenguardCategory",
	initialState: greendguardCategoryAdapter.getInitialState({
		loading: "done",
		currentRequestId: undefined,
		error: undefined,
	}),
	reducers: {},
	extraReducers: {
		[getGreenguardCategories.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;

				greendguardCategoryAdapter.removeAll(state);
				greendguardCategoryAdapter.addMany(state, payload);
			}
			return state;
		},
		[getGreenguardCategories.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[getGreenguardCategories.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
	},
});

export default greenguardCategory;
export const greenguardCategorySelectors = greendguardCategoryAdapter.getSelectors(
	(state) => state?.greenguardCategory
);
export const greenguardCategoriesSelector = createSelector(
	greenguardCategorySelectors.selectAll,
	(categories) => categories
);
export const greenguardCategoriesTreeSelector = createSelector(greenguardCategorySelectors.selectAll, (categories) =>
	categories
		.filter((category) => !category.parent)
		.map((parent) => ({
			...{ id: parent.erp_code, value_fr: parent.description_fr, value_en: parent.description_en },
			children: [
				{
					id: parent.erp_code,
					value_fr: parent.description_fr,
					value_en: parent.description_en,
				},
				...categories
					.filter((category) => category.parent === parent.id)
					.map((child) => ({
						id: child.erp_code,
						value_fr: child.description_fr,
						value_en: child.description_en,
					})),
			],
		}))
);
export const greenguardCategoriesLoadingSelector = (state) => state?.greenguardCategory?.loading === "pending";
