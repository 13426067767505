import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import clsx from "clsx";

import { Button } from "../../../atoms/button/Button";
import CustomNavLink from "../../../components/ui/customNavLink/CustomNavLink";

import { useLocalStorage } from "../../../hooks";

import classes from "./LanguageMessage.module.scss";
import { keys } from "../../../locales/localeskeys";

const LanguageMessage = ({ classes, language, suggestedLanguage }) => {
	const { getItem, setItem } = useLocalStorage("localize");
	const localize = getItem();

	const { t, i18n } = useTranslation();

	const [hidden, setHidden] = useState(false);

	const [message, setMessage] = useState("");
	const [buttonLabel, setButtonLabel] = useState("");
	const [linkLabel, setLinkLabel] = useState("");

	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);

	const handleChangeLanguage = () => {
		setItem({ ...localize, language: suggestedLanguage });

		i18n.changeLanguage(suggestedLanguage);
		queryParams.set("lng", suggestedLanguage);
		navigate({
			pathname: location.pathname,
			search: queryParams.toString(),
		});

		handleCloseMessage();
	};

	const handleStayOn = () => {
		setItem({ ...localize, language });
		handleCloseMessage();
	};

	const handleCloseMessage = () => {
		setHidden(true);
	};

	useEffect(() => {
		const setLanguageMessage = (message, buttonLabel, linkLabel) => {
			setMessage(message);
			setButtonLabel(buttonLabel);
			setLinkLabel(linkLabel);
		};

		if (language.includes("ca") && suggestedLanguage.includes("us")) {
			setHidden(false);
			setLanguageMessage(
				t(keys.GLOBAL.NAVBAR.LANGUAGE_MESSAGE.CANADA_TO_USA.MESSAGE),
				t(keys.GLOBAL.NAVBAR.LANGUAGE_MESSAGE.CANADA_TO_USA.BUTTON),
				t(keys.GLOBAL.NAVBAR.LANGUAGE_MESSAGE.CANADA_TO_USA.LINK)
			);
		} else if (language.includes("us") && suggestedLanguage.includes("ca")) {
			setHidden(false);
			setLanguageMessage(
				t(keys.GLOBAL.NAVBAR.LANGUAGE_MESSAGE.USA_TO_CANADA.MESSAGE),
				t(keys.GLOBAL.NAVBAR.LANGUAGE_MESSAGE.USA_TO_CANADA.BUTTON),
				t(keys.GLOBAL.NAVBAR.LANGUAGE_MESSAGE.USA_TO_CANADA.LINK)
			);
		} else {
			handleCloseMessage();
		}
	}, [language, suggestedLanguage, i18n.language]);

	return (
		<div className={clsx(classes.layout, hidden ? classes.hidden : "")}>
			<div className={classes.message}>
				<div>{message}</div>
				<div className={classes.buttons}>
					<Button color="negative" onClick={handleChangeLanguage}>
						{buttonLabel}
					</Button>
					<CustomNavLink to={`?lng=${language}`} onClick={handleStayOn} className={classes.link}>
						{linkLabel}
					</CustomNavLink>
				</div>
			</div>
			<div>
				<Icon icon="material-symbols:close" onClick={handleCloseMessage} className={classes.icon} />
			</div>
		</div>
	);
};

LanguageMessage.defaultProps = {
	classes,
	language: "en-us",
	suggestedLanguage: "en-us",
};

export default LanguageMessage;
