import React, { Fragment, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";

import { cartDataChangedSelector, cartOpenSelector, orderLinesSelector, setCartOpen } from "../../store/cart";

import Button from "../ui/Button/Button";
import SiteCartItem from "./siteCartItem/SiteCartItem";

import { keys } from "../../locales/localeskeys";

import classes from "./SideCart.module.scss";

const SideCart = () => {
	const dispatch = useDispatch();

	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const cartOpen = useSelector(cartOpenSelector);
	const cartDataChanged = useSelector(cartDataChangedSelector);
	const orderLine = useSelector(orderLinesSelector);

	const [subTotal, setSubTotal] = useState("");

	const closeCart = () => {
		dispatch(setCartOpen(false));
	};

	useEffect(() => {
		if (cartOpen) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "";
		}
	}, [cartOpen]);

	useEffect(() => {
		if (orderLine) {
			let subTotalStr = orderLine
				.map((line) => Number(line.amount))
				.reduce((prev, curr) => prev + curr, 0)
				.toFixed(2)
				.toString()
				.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			subTotalStr = lngId.includes("fr") ? `${subTotalStr.replace(".", ",")}$` : `$${subTotalStr}`;
			setSubTotal(subTotalStr);
		}
	}, [lngId, orderLine]);

	const protalElement = document.getElementById("overlays");

	return (
		<Fragment>
			{cartOpen && ReactDOM.createPortal(<div className={classes.backdrop} onClick={closeCart} />, protalElement)}
			{ReactDOM.createPortal(
				<div className={classes.SideCart} data-is-active={cartOpen}>
					<div className={classes.topContainer}>
						<svg
							onClick={closeCart}
							className={classes.close}
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								strokeLinecap="round"
								strokeWidth="2.5"
								className={classes.xClose}
								d="M 1 1 L 18 18 M 18 1 L 1 18"
							/>
						</svg>
						<h2>{t(keys.CART.SIDE.TITLE)}</h2>
					</div>
					{orderLine && orderLine.length > 0 && (
						<div className={classes.content}>
							<div className={classes.resume}>{`${orderLine.length} ${t(
								keys.CART.SIDE.ITEMS_NUMBER
							)}`}</div>
							<div className={classes.details}>
								{cartDataChanged && (
									<div className={classes.confirmation}>
										<Icon className={classes.success} icon="clarity:success-line" />
										<div>{t(keys.CART.SIDE.DESCRIPTION)}</div>
									</div>
								)}
								{orderLine
									.filter((line) => line.item && line.variant)
									.map((line, index) => (
										<div key={line.id}>
											<SiteCartItem
												variant={line.variant}
												item={line.item}
												qty={Number(line.qty).toFixed(0)}
												qty_pack={Number(line.qty_pack).toFixed(0)}
												amount={Number(line.amount)}
												unitAmount={Number(line.unitAmount)}
												images={line.overview_image}
											/>

											{orderLine.length !== index + 1 && <hr />}
										</div>
									))}
							</div>
							<div className={classes.subTotal}>
								<p>{t(keys.CART.SIDE.SUBTOTAL)}</p>
								<p>{subTotal}</p>
							</div>
							<div className={classes.subTotalMobile}>
								<p>{t(keys.CART.OVERVIEW.SUBTOTAL)}</p>
								<p>{subTotal}</p>
							</div>
							<div className={classes.button}>
								<Button
									className={classes.btn}
									size="big"
									weight={"bold"}
									onClick={() => {
										dispatch(setCartOpen(false));
										navigate({ pathname: `/cart`, search: queryParams.toString() });
									}}
								>
									{t(keys.CART.SIDE.VIEW_CART)}
								</Button>
								<Button
									className={classes.btn}
									size="big"
									color="outlineBlack"
									weight={"bold"}
									onClick={closeCart}
								>
									{t(keys.CART.SIDE.CONTINUE_SHOPPING)}
								</Button>
							</div>
						</div>
					)}
				</div>,
				protalElement
			)}
		</Fragment>
	);
};

export default SideCart;
