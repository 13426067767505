import AdfastLogo from "../assets/svg/adfast-logo.svg";
import HomeBanner from "../assets/jpeg/banner-homepage.jpeg";
import ManufacturingBanner from "../assets/png/banner-manufacturingpage.png";
import PrivacyBanner from "../assets/jpeg/banner-privacy.jpg";
import newsletterLogo from "../assets/png/newsletterHeader.png";
import { keys } from "./localeskeys";

export const banners = {
	home: {
		type: "BIG",
		logo: AdfastLogo,
		logo_alt: "adfast-logo.svg",
		caption: keys.HOME.BANNER_CAPTION,
		background: HomeBanner,
		background_alt: "banner-homepage.jpeg",
	},
	privacy: {
		type: "SMALL",
		caption: keys.PRIVACY.BANNER_CAPTION,
		background: PrivacyBanner,
		background_alt: "banner-Term_Privacy.jpg",
	},
	newsletter: {
		type: "BIG",
		logo: AdfastLogo,
		logo_alt: "adfast-logo.svg",
		background: newsletterLogo,
		background_alt: "newsletterHeader.png",
	},
	manufacturing: {
		type: "BIG",
		background: ManufacturingBanner,
		background_alt: "manufacturing-banner.jpeg",
	},
};
