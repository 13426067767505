import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import classes from "./ProductFilterOverlay.module.scss";
import { Icon } from "@iconify/react";
import { clearAll } from "../../../store/productQuery";
import Button from "../../ui/Button/Button";
import { keys } from "../../../locales/localeskeys";
import Category from "../ProductList/ProductSideBar/Category/Category";
import Brand from "../ProductList/ProductSideBar/Brand/Brand";
import Industries from "../ProductList/ProductSideBar/Industries/Industries";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { categoriesLoadingSelector, categoriesSelector } from "../../../store/productCategory";
import { brandSelector, branssLoadingSelector } from "../../../store/productBrand";
import { subsegmentSelector, subsegmentsLoadingSelector } from "../../../store/subSegment";
import { itemIsLoadingSelector } from "../../../store/product";

const ProductFilterOverlay = (props) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const [searchParams, setSearchParams] = useSearchParams();

	const categoriesLoading = useSelector(categoriesLoadingSelector);
	const brandsLoading = useSelector(branssLoadingSelector);
	const industriesLoading = useSelector(subsegmentsLoadingSelector);
	const categorySelector = useSelector(categoriesSelector);
	const brandsSelector = useSelector(brandSelector);
	const segmentsSector = useSelector(subsegmentSelector);
	const itemIsLoading = useSelector(itemIsLoadingSelector);

	const clear = () => {
		dispatch(clearAll());
		searchParams.delete("categories");
		searchParams.delete("brands");
		searchParams.delete("industries");
		setSearchParams(searchParams);
	};

	const protalElement = document.getElementById("overlays");
	return (
		<Fragment>
			{ReactDOM.createPortal(
				<div
					className={`${classes.backdrop} ${props.onOpen ? classes[props.onOpen] : ""}`}
					onClick={() => {
						props.onClose();
					}}
				/>,
				protalElement
			)}
			{ReactDOM.createPortal(
				<div className={`${classes.wrapper} ${props.onOpen ? classes[props.onOpen] : ""}`}>
					<div className={classes.main}>
						<Button
							className={classes.btnApply}
							color={"none"}
							disabled={itemIsLoading}
							onClick={() => {
								props.onClose();
							}}
						>
							{t(keys.PRODUCTS.APPLY)}
						</Button>
						<Button color={"outlineBlue"} className={classes.btnClear} onClick={clear}>
							{t(keys.PRODUCTS.CLEAR)}
						</Button>

						{!categoriesLoading && categorySelector.length > 0 && <Category />}
						{!brandsLoading && brandsSelector.length > 0 && <Brand />}
						{!industriesLoading && segmentsSector.length > 0 && <Industries />}
						<Icon
							className={`${classes.iconClose} ${props.onOpen ? classes[props.onOpen] : ""}`}
							onClick={props.onClose}
							icon="mdi:less-than"
						/>
						<span className={classes.filters} onClick={props.onClose}>
							{t(keys.PRODUCTS.FILTERS)}
						</span>
					</div>
				</div>,
				protalElement
			)}
		</Fragment>
	);
};

export default ProductFilterOverlay;
