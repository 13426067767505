import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import Accordion from "../../../../ui/Accordion/Accordion";
import { filterBrandsSelector, setBrands } from "../../../../../store/productQuery";
import { brandSelector } from "../../../../../store/productBrand";
import { itemIsLoadingSelector } from "../../../../../store/product";

import { keys } from "../../../../../locales/localeskeys";

import classes from "./Brand.module.scss";

const Brand = (props) => {
	const dispatch = useDispatch();

	const { i18n, t } = useTranslation();
	const lngId = i18n.language;

	const [searchParams, setSearchParams] = useSearchParams();
	const brandSearchParm = searchParams.get("brands") ?? "";

	const brands = useSelector(brandSelector);
	const itemIsLoading = useSelector(itemIsLoadingSelector);
	const brandsChecked = useSelector(filterBrandsSelector);

	const [checkedCount, setCheckedCount] = useState(0);

	const valueChecked = (event) => {
		if (event.target.checked) {
			dispatch(setBrands([...brandsChecked, event.target.id]));
		} else {
			dispatch(setBrands(brandsChecked.filter((element) => element !== event.target.id)));
		}
	};

	useEffect(() => {
		if (brandSearchParm && brandSearchParm !== brandsChecked.toString()) {
			const brands = brandSearchParm.split(",");
			dispatch(setBrands(brands));
		}
	}, [brandSearchParm]);

	useEffect(() => {
		searchParams.set("page", 1);
		if (brandSearchParm !== brandsChecked.toString()) {
			if (brandsChecked.length > 0) {
				searchParams.set("brands", brandsChecked.toString());
				setSearchParams(searchParams);
			} else {
				searchParams.delete("brands");
				setSearchParams(searchParams);
			}
		}
	}, [brandsChecked]);

	useEffect(() => {
		setCheckedCount(brandsChecked.length);
	}, [brandsChecked]);

	return (
		<div className={classes.bransContainer}>
			<Accordion
				className={classes.accordionLayout}
				header={t(keys.PRODUCTS.BRANDS)}
				headerClass={classes.header}
				iconClass={classes.icon}
				active={brandSearchParm}
				data={brands}
				checkedCount={checkedCount}
			>
				<div className={classes.list}>
					{brands &&
						brands
							.map((brand) => {
								return {
									id: brand.erp_code,
									label: (lngId.includes("fr") ? brand.description_fr : brand.description_en)
										? lngId.includes("fr")
											? brand.description_fr
											: brand.description_en
										: brand.erp_code,
								};
							})
							.map((brand) => (
								<div className={classes.layout} key={brand.id}>
									<div
										className={`${classes.element} ${
											brandsChecked?.some((el) => el === brand.id) ? classes.checked : ""
										}`}
										key={brand.id}
									>
										<input
											type="checkbox"
											id={brand.id}
											name={brand.id}
											onChange={(event) => valueChecked(event)}
											checked={brandsChecked?.some((el) => el === brand.id)}
											disabled={itemIsLoading}
										/>
										<label htmlFor={brand.id}>{brand.label}</label>
									</div>
								</div>
							))}
				</div>
			</Accordion>
		</div>
	);
};

export default Brand;
