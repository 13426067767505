import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { constants, constants as constant } from "../locales/constant";
import { keys } from "../locales/localeskeys";

/**
 * Fournir la liste des liens de page
 */
export const getPageLinks = createAsyncThunk(
	"pageLinks/getPageLinks",
	async ({ search, page, pageSize, keep }, { rejectWithValue }) => {
		try {
			const queryParam =
				constant.API_SERVER +
				"/links/?" +
				"&page=" +
				(page && page !== "" && page !== "NaN" ? page : 1) +
				(search && search !== "" ? "&search=" + search : "") +
				(pageSize && pageSize !== "" ? "&pageSize=" + pageSize : "");

			const response = await fetch(queryParam);
			if (!response.ok) {
				throw new Error("fetching brands failed");
			}
			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

const pageLinksAdapter = createEntityAdapter({});

const pageLinks = createSlice({
	name: "pageLinks",
	initialState: pageLinksAdapter.getInitialState({
		loading: "done",
		currentRequestId: undefined,
		error: undefined,
	}),
	reducers: {
		clearLinks: (state) => {
			pageLinksAdapter.removeAll(state);
			state.loading = "done";
			state.currentRequestId = undefined;
			state.error = undefined;
		},
	},
	extraReducers: {
		[getPageLinks.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;

				state.count = payload.count;
				state.next = payload.next;
				state.previous = payload.previous;

				if (!meta.arg.keep) pageLinksAdapter.removeAll(state);
				if (payload.results) pageLinksAdapter.addMany(state, payload.results);

				if (meta.arg.search) {
					const adRobotLink =
						searchGracoAdRobot(constants.adrobot, meta.arg.search.toLowerCase()) ||
						searchGracoAdRobot(keys.ADROBOT, meta.arg.search.toLowerCase(), meta.arg.t);
					if (adRobotLink === true)
						pageLinksAdapter.addOne(state, {
							id: "9b1023f5-b347-48a1-a03c-97c9a7b25d7f",
							erp_code: "Adrobot",
							img_overview: "https://www.adfast.store/static/media/kuka.4d2a822f.svg",
							url: "/adrobot/",
							title_fr: "Adrobot",
							title_en: "Adrobot",
							description_fr:
								"Quand les robots et les humains travaillent ensemble, les possibilités sont infinies",
							description_en: "When robots and humans work together, the possibilities are endless",
						});

					if (!payload.results || !payload.results.find((link) => link.erp_code === "Graco")) {
						const gracoLink = searchGracoAdRobot(constants.graco, meta.arg.search.toLowerCase());
						if (gracoLink === true)
							pageLinksAdapter.addOne(state, {
								id: "9b1023f5-b387-48a1-a83c-97c9a7b25d6f",
								erp_code: "Graco",
								img_overview: "https://adfastcorpcdnak.azureedge.net/b2b/Manufacturers/2D/Graco.png",
								url: "/dispensing-technologie/",
								title_fr: "Technologies de distribution",
								title_en: "Dispensing Technologies",
								description_fr:
									"Applications automatisées ou manuelles, les pompes s'adaptent à votre ligne de production",
								description_en:
									"Automated or manual applications, the pumps adapt to your production line",
							});
					}
				}
			}
			return state;
		},
		[getPageLinks.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[getPageLinks.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
	},
});

export default pageLinks;
export const { clearLinks } = pageLinks.actions;
export const pageLinksSelectors = pageLinksAdapter.getSelectors((state) => state?.pageLinks);
export const pageLinksSelector = createSelector(pageLinksSelectors.selectAll, (l) => l);
export const pageLinksIsLoadingSelector = (state) => state?.pageLinks?.loading === "pending";
export const pageLinksCountSelector = (state) => state?.pageLinks?.count;
const searchGracoAdRobot = (obj, search, convertFn) => {
	for (const [key, value] of Object.entries(obj)) {
		if (typeof value === "object") {
			searchGracoAdRobot(value, search);
		} else if (Array.isArray(value) && value.length > 0 && typeof value[0] === "object") {
			value.forEach((item) => searchGracoAdRobot(item, search));
		} else {
			if (convertFn ? convertFn(value).includes(search) : value.toString().toLowerCase().includes(search)) {
				return true;
			}
		}
	}
	return false;
};
