import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";

import CustomNavLink from "../ui/customNavLink/CustomNavLink";

import { customerCodeSelector, customerNameSelector, isLoggedInSelector, setPrecedentUrl } from "../../store/auth";

import { keys } from "../../locales/localeskeys";

import classes from "./Account.module.scss";

const Account = () => {
	const dispatch = useDispatch();

	const { i18n, t } = useTranslation();
	const lngId = i18n.language;

	const search = `?lng=${lngId}`;

	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const isLoggedIn = useSelector(isLoggedInSelector);
	const customerCode = useSelector(customerCodeSelector);
	const customerName = useSelector(customerNameSelector);

	useEffect(() => {
		if (!isLoggedIn) {
			dispatch(setPrecedentUrl({ pathname: location.pathname, search: location.search }));
			navigate({ pathname: `/sign-in`, search: queryParams.toString() });
		}
	}, [isLoggedIn, lngId]);

	return (
		<div className={classes.bodyContainer}>
			<h1 className={classes.title}>{t(keys.ACCOUNT.ACCOUNT.TITLE)}</h1>
			<h2 className={classes.account}>
				{t(keys.ACCOUNT.ACCOUNT.HOLDER)} {customerCode}
			</h2>
			<h2 className={classes.account}>
				{t(keys.ACCOUNT.ACCOUNT.NAME)} {customerName}
			</h2>
			<div className={classes.options}>
				<CustomNavLink to={`/orders${search}`} className={classes.option}>
					<Icon className={classes.icon} icon="bi:cart-check" />
					<div className={classes.description}>
						<h1>{t(keys.ACCOUNT.ACCOUNT.ORDER_TITLE)}</h1>
						<h2>{t(keys.ACCOUNT.ACCOUNT.ORDER_DESCRIPTION)}</h2>
					</div>
				</CustomNavLink>
				<CustomNavLink to={`/profile${search}`} className={classes.option}>
					<Icon className={classes.icon} icon="healthicons:ui-user-profile" />
					<div className={classes.description}>
						<h1>{t(keys.ACCOUNT.ACCOUNT.PROFIL_TITLE)}</h1>
						<h2>{t(keys.ACCOUNT.ACCOUNT.PROFIL_DESCRIPTION)}</h2>
					</div>
				</CustomNavLink>
				<CustomNavLink to={`/addresses${search}`} className={classes.option}>
					<Icon className={classes.icon} icon="icon-park-outline:address-book" />
					<div className={classes.description}>
						<h1>{t(keys.ACCOUNT.ACCOUNT.ADDRESSES_TITLE)}</h1>
						<h2>{t(keys.ACCOUNT.ACCOUNT.ADDRESSES_DESCRIPTION)}</h2>
					</div>
				</CustomNavLink>
				{/*<CustomNavLink to={`/wallet${search}`} className={classes.option}>*/}
				{/*	<Icon className={classes.icon} icon="fluent:payment-16-regular" />*/}
				{/*	<div className={classes.description}>*/}
				{/*		<h1>{t(keys.ACCOUNT.ACCOUNT.PAYMENT_TITLE)}</h1>*/}
				{/*		<h2>{t(keys.ACCOUNT.ACCOUNT.PAYMENT_DESCRIPTION)}</h2>*/}
				{/*	</div>*/}
				{/*</CustomNavLink>*/}
			</div>
		</div>
	);
};

export default Account;
