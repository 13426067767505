import React from "react";
import classes from "./RadioBtn.module.scss";

const RadioBtn = React.forwardRef((props, ref) => {
	return (
		<div className={`${classes.radioButton} ${props.className || ""}`}>
			{props.label && <label htmlFor={props.id}>{props.label}</label>}
			{props.values?.map((value) => (
				<div key={value.id} className={`${classes.radios} ${props.inputCss || ""}`}>
					<input
						ref={ref}
						id={value.id}
						type="radio"
						value={value.value}
						checked={value.checked}
						onChange={props.onChange}
						disabled={props.disabled}
					/>
					<label htmlFor={value.id} className={props.labelClassName || ""}>
						<span /> {value.label}
					</label>
					{value.secondLine}
				</div>
			))}
		</div>
	);
});

export default RadioBtn;
