import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import CustomNavLink from "../ui/customNavLink/CustomNavLink";

import { keys } from "../../locales/localeskeys";

import { isLoggedInSelector, saveCookies, setCookie } from "../../store/auth";

import classes from "./PrivacyModal.module.scss";

const PrivacyModal = (props) => {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();

	const isLoggedIn = useSelector(isLoggedInSelector);

	const lngId = i18n.language;
	const lngParm = `?lng=${lngId}`;

	const handleAcceptAllCookies = () => {
		dispatch(
			setCookie({
				Advertising: true,
				Functional: true,
				Analytical: true,
				savedCookie: true,
			})
		);

		if (isLoggedIn === true)
			dispatch(
				saveCookies({
					Advertising: true,
					Functional: true,
					Analytical: true,
					savedCookie: true,
				})
			);
	};

	return (
		<div className={classes.modal}>
			<div className={classes.top}>
				<h1>{t(keys.GLOBAL.COOKIE.MESSAGE)}</h1>
				<p>{t(keys.GLOBAL.COOKIE.CONTENT)}</p>
			</div>
			<div className={classes.bottom}>
				<p>
					<CustomNavLink to={`/term_privacy${lngParm}`}>{t(keys.GLOBAL.COOKIE.PRIVACY_POLICY)}</CustomNavLink>
				</p>
				<button className={classes.first} onClick={handleAcceptAllCookies}>
					{t(keys.GLOBAL.COOKIE.ACCEPT_BUTTON)}
				</button>
				<button className={classes.second} onClick={props.onManageCookiesClick}>
					{t(keys.GLOBAL.COOKIE.MANAGE_BUTTON)}
				</button>
			</div>
		</div>
	);
};

export default PrivacyModal;
