import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { constants as constant } from "../locales/constant";

/**
 * Fournir les segments avec leurs subsegment
 */
export const getSegments = createAsyncThunk("segment/getSegments", async (_, { rejectWithValue }) => {
	try {
		const response = await fetch(constant.API_SERVER + "/segment/");
		if (!response.ok) {
			throw new Error("fetching webinars failed");
		}
		return await response.json();
	} catch (err) {
		return rejectWithValue(err.message, err);
	}
});
const segmentAdapter = createEntityAdapter({});

const initialState = {
	currentRequestId: undefined,
	error: undefined,
	loading: "done",
	segments: undefined,
	count: 0,
};

const segment = createSlice({
	name: "segment",
	initialState: segmentAdapter.getInitialState(initialState),
	reducers: {},
	extraReducers: {
		[getSegments.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;

				segmentAdapter.removeAll(state);
				if (payload.results) segmentAdapter.addMany(state, payload.results);
			}
		},
		[getSegments.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[getSegments.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
	},
});

export default segment;
export const segmentIsLoadingSelector = (state) => state?.segment?.loading === "pending";
export const segmentHasErrorSelector = (state) => state.segment.error !== "";

export const segmentSelectors = segmentAdapter.getSelectors((state) => state?.segment);
export const segmentSelector = createSelector(segmentSelectors.selectAll, (segment) => segment);
export const segmentTreeSelectror = createSelector(segmentSelectors.selectAll, (segment) =>
	segment.map((segment) => {
		return {
			...segment,
			children: segment.subsegments,
		};
	})
);
