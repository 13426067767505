import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
	addSpecificationFavorite,
	customerFavoriteSpecificationSelector,
	deleteSpecificationFavorite,
	isLoggedInSelector,
} from "../../../../../../store/auth";

import { Icon } from "@iconify/react";

import classes from "./ColorCard.module.scss";
import decodeSlash from "../../../../../../hooks/decodeSlash";
import encodeSlash from "../../../../../../hooks/encodeSlash";

const ColorCard = (props) => {
	const dispatch = useDispatch();

	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const params = useParams();

	const isLoggedIn = useSelector(isLoggedInSelector);
	const favoriteSpecifications = useSelector(customerFavoriteSpecificationSelector);

	const [selected, setSelected] = useState("");

	const clickHandler = () => {
		if (props.specificationid && props.specificationid === props.title) {
			navigate(
				{
					pathname: `/adstore/variants/${encodeSlash(props.itemid)}/${encodeSlash(props.brandid)}/`,
					search: queryParams.toString(),
				},
				{ replace: props.PartnerCall ? false : true }
			);
		} else {
			navigate(
				{
					pathname: `/adstore/variants/${encodeSlash(props.itemid)}/${encodeSlash(
						props.brandid
					)}/${encodeSlash(props.title)}/`,
					search: queryParams.toString(),
				},
				{ replace: props.PartnerCall ? false : true }
			);
		}
	};

	const addFavoriteHandler = () => {
		if (!isLoggedIn) return;

		if (favoriteSpecifications && favoriteSpecifications.includes(props.id)) {
			dispatch(deleteSpecificationFavorite(props.id));
		} else {
			dispatch(addSpecificationFavorite(props.id));
		}
	};

	useEffect(() => {
		setSelected(props.specificationid === props.title ? "selected" : "");
	}, [props.title, props.specificationid]);

	return (
		<div
			className={`${classes.colorCardContainer} ${classes[selected]} ${props.className}`}
			key={props.title}
			style={props.style}
		>
			{isLoggedIn && !props.hideFavorite && (
				<Icon className={classes.favoriteOutlined} icon="ant-design:heart-outlined" />
			)}
			{isLoggedIn && !props.hideFavorite && (
				<Icon
					className={`${classes.favoriteFilled} ${
						classes[favoriteSpecifications && favoriteSpecifications.includes(props.id) ? "active" : ""]
					}`}
					icon="ant-design:heart-filled"
					onClick={() => !props.lockOnClick && addFavoriteHandler()}
				/>
			)}
			{props.image && (!props.hex || props.displayImage) && (
				<img
					className={`${classes.colorCardImageDiv} ${classes[selected]}`}
					src={props.image}
					alt={props.image}
					onClick={() => !props.lockOnClick && clickHandler()}
				/>
			)}
			{props.hex && !props.displayImage && (
				<div
					className={`${classes.colorCardImageDiv} ${classes[selected]}`}
					style={{ backgroundColor: props.hex }}
					onClick={() => !props.lockOnClick && clickHandler()}
				/>
			)}
			{!props.image && (!props.hex || props.displayImage) && (
				<div
					className={`${classes.colorCardImageDiv} ${classes[selected]}`}
					style={{ backgroundColor: "black" }}
					onClick={() => !props.lockOnClick && clickHandler()}
				/>
			)}
			<div className={classes.colorCardDetails} onClick={() => !props.lockOnClick && clickHandler()}>
				{props.title && <p>{props.title}</p>}
				{props.subtitle && <p>{props.subtitle}</p>}
			</div>
		</div>
	);
};

export default ColorCard;
