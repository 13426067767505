import React from "react";

import { Icon } from "@iconify/react";

import classes from "./ImageMapIcon.module.scss";

const ImageMapIcon = (props) => {
	return (
		<div className={classes.layout} onClick={props.onClick}>
			{props.current && props.id === props.current && (
				<Icon className={classes.iconMinus} icon="akar-icons:circle-minus-fill" />
			)}
			{(!props.current || props.id !== props.current) && (
				<Icon className={classes.iconPlus} icon="akar-icons:circle-plus-fill" />
			)}
		</div>
	);
};

export default ImageMapIcon;
