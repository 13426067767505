import React, { useEffect } from "react";
import classes from "./Relocation.module.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RelocationHeaderSelector, RelocationMapSelector } from "../../store/mediaBase";
import Loading from "../ui/loading/Loading";
import HTMLString from "react-html-string";

const Relocation = () => {
	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const relocationHeader = useSelector(RelocationHeaderSelector);
	const relocationMap = useSelector(RelocationMapSelector);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			{(!relocationHeader || !relocationMap) && <Loading type={"bounce"} />}
			<div className={classes.imagLayout}>
				<img src={lngId.includes("fr") ? relocationHeader[0]?.img_fr : relocationHeader[0]?.img_en} />
			</div>
			<div className={classes.description}>
				<HTMLString
					html={
						lngId.includes("fr")
							? relocationHeader[0]?.description_fr.toString()
							: relocationHeader[0]?.description_en.toString()
					}
				/>
			</div>
			<div className={classes.imagMap}>
				<img src={lngId.includes("fr") ? relocationMap[0]?.img_fr : relocationMap[0]?.img_en} />
			</div>
		</>
	);
};

export default Relocation;
