import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import ReCAPTCHA from "react-google-recaptcha";
import RoundCheckbox from "../../ui/Input/RoundCheckbox/RoundCheckbox";
import TextInputOverlay from "../../ui/Input/TextInputOverlay/TextInputOverlay";
import OverlayDropdown from "../../ui/Input/OverlayDropdown/OverlayDropdown";
import NavDrop from "../../navbar/NavDrop/NavDrop";
import CustomNavLink from "../../ui/customNavLink/CustomNavLink";

import Button from "../../ui/Button/Button";
import { authHasErrorSelector, isLoadingSelector, isSignUpSelector, signup } from "../../../store/auth";

import logo_fr from "../../../assets/svg/txt_logo_fr.svg";
import logo_en from "../../../assets/svg/txt_logo_en.svg";
import nologo_fr from "../../../assets/svg/txt_no_logo_fr.svg";
import nologo_en from "../../../assets/svg/txt_no_logo_en.svg";

import { constants } from "../../../locales/constant";
import { keys } from "../../../locales/localeskeys";

import classes from "./Signup.module.scss";

const Signup = () => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const lngParm = `?lng=${lngId}`;

	const [firstNameState, setFirstName] = useState({ value: "", isValid: false });
	const [lastNameState, setLastName] = useState({ value: "", isValid: false });
	const [companyNameState, setCompanyName] = useState({ value: "", isValid: false });
	const [emailState, setEmail] = useState({ value: "", isValid: false });
	const [password1State, setPassword1] = useState({ value: "", isValid: false });
	const [password2State, setPassword2] = useState({ value: "", isValid: false });
	const [phoneNumberState, setPhoneNumber] = useState({ value: "", isValid: false });
	const [addressState, setAddress] = useState({ value: "", isValid: false });
	const [postalCodeState, setPostalCode] = useState({ value: "", isValid: false });
	const [address2State, setAddress2] = useState("");
	const [cityState, setCity] = useState({ value: "", isValid: false });
	const [provinceState, setProvince] = useState({ value: "", isValid: false });
	const [countryState, setCountry] = useState({ value: "", isValid: false });
	const [isDistributorState, setIsDistributor] = useState(false);
	const [distributorState, setDistributor] = useState({ value: "", isValid: true });
	const [sourceState, setSource] = useState("");
	const [acceptTermsState, setAcceptTerms] = useState(true);
	const [displayFormNotValid, setDisplayFormNotValid] = useState(false);
	const [fieldChanged, setFieldChanged] = useState(false);
	const [isCaptchaValid, setIsCaptchaValid] = useState(false);
	const [alreadyCustomer, setAlreadyCustomer] = useState(-1);
	const [selectedOption, setSelectedOption] = useState("Yes");
	const [accountNum, setAccountNum] = useState();

	const [closingDrop, setCLosingDrop] = useState({ id: "", status: false });
	const [activeID, setActiveID] = useState("");

	const isSignUp = useSelector(isSignUpSelector);
	const isLoading = useSelector(isLoadingSelector);
	const signupHasError = useSelector(authHasErrorSelector);

	const isFormValid =
		firstNameState.isValid &&
		lastNameState.isValid &&
		companyNameState.isValid &&
		emailState.isValid &&
		password1State.value === password2State.value &&
		password1State.isValid &&
		password2State.isValid &&
		(alreadyCustomer === "1" ||
			(phoneNumberState.isValid &&
				addressState.isValid &&
				postalCodeState.isValid &&
				cityState.isValid &&
				provinceState.isValid &&
				countryState.isValid &&
				distributorState.isValid &&
				sourceState !== "")) &&
		acceptTermsState;

	const companyNameChangeHandler = (event) => {
		setCompanyName({
			value: event.target.value,
			isValid: event.target.value !== "",
		});
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const firstNameChangeHandler = (event) => {
		setFirstName({
			value: event.target.value,
			isValid: event.target.value !== "",
		});
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const lastNameChangeHandler = (event) => {
		setLastName({
			value: event.target.value,
			isValid: event.target.value !== "",
		});
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const emailChangeHandler = (event) => {
		const emailRegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,15}$/;
		setEmail({
			value: event.target.value,
			isValid: event.target.value !== "" && emailRegExp.test(event.target.value.trim()),
		});
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const password1ChangeHandler = (event) => {
		const passWordRegExp = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
		setPassword1({
			value: event.target.value,
			isValid: event.target.value !== "" && passWordRegExp.test(event.target.value.trim()),
		});
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const password2ChangeHandler = (event) => {
		const passWordRegExp = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
		setPassword2({
			value: event.target.value,
			isValid:
				event.target.value !== "" &&
				event.target.value === password1State.value &&
				passWordRegExp.test(event.target.value.trim()),
		});
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const phoneNumberChangeHandler = (event) => {
		setPhoneNumber({
			value: event.target.value,
			isValid: event.target.value !== "",
		});
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const addressChangeHandler = (event) => {
		setAddress({
			value: event.target.value,
			isValid: event.target.value !== "",
		});
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const postalCodeChangeHandler = (event) => {
		setPostalCode({
			value: event.target.value,
			isValid: event.target.value !== "",
		});
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const address2ChangeHandler = (event) => {
		setAddress2(event.target.value);
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const cityChangeHandler = (event) => {
		setCity({
			value: event.target.value,
			isValid: event.target.value !== "",
		});
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const provinceChangeHandler = (event) => {
		setProvince({
			value: event.target.value,
			isValid: event.target.value !== "",
		});
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const countryChangeHandler = (event) => {
		setCountry({
			value: event.target.value,
			isValid: event.target.value !== "",
		});
		setProvince({ value: "", isValid: false });
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const isDistributorChangeHandler = (event) => {
		setIsDistributor(event.target.value);
		if (event.target.value === 1) {
			setDistributor({ value: distributorState.value, isValid: false });
		} else {
			setDistributor({ value: "", isValid: true });
		}
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const distributorChangeHandler = (event) => {
		setDistributor({
			value: event.target.value,
			isValid: event.target.value !== "" && isDistributorState === "1",
		});
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const sourceChangeHandler = (event) => {
		setSource(event.target.value);
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const acceptTermChangeHandler = (event) => {
		setAcceptTerms(event.target.checked);
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const captchaChangeHandler = (value) => {
		if (value) {
			setIsCaptchaValid(value);
		}
	};

	/**
	 * log in a la soumission du formulaire si tout est valide si non focus sur l'erreur
	 * @param event
	 */
	const submitHandler = (event) => {
		event.preventDefault();
		if (!isCaptchaValid) return;
		if (isFormValid && fieldChanged) {
			dispatch(
				signup({
					email: emailState.value,
					first_name: firstNameState.value,
					last_name: lastNameState.value,
					name: companyNameState.value,
					password1: password1State.value,
					password2: password2State.value,
					phone: phoneNumberState.value,
					address: addressState.value,
					address2: address2State,
					postalCode: postalCodeState.value,
					city: cityState.value,
					province: provinceState.value,
					country: countryState.value,
					distributor: distributorState.value,
					source: sourceState,
					lng_id: lngId,
					alreadyCustomer: alreadyCustomer === "1",
					accountNum: accountNum,
				})
			);
		} else {
			setDisplayFormNotValid(true);
		}
	};

	const hoverHandler = (id) => {
		if (closingDrop.status === false && closingDrop.id !== id) {
			setActiveID(id);
		}
	};

	const outHandler = () => {
		setActiveID("");
		setTimeout(
			() =>
				setCLosingDrop({
					id: "",
					status: false,
				}),
			300
		);
	};

	const onLocaliseChange = (item) => {
		i18n.changeLanguage(item.children);
	};

	const closeDrop = () => {
		setCLosingDrop({ id: activeID, status: true });
		setActiveID("");
	};

	const onValueChange = (value) => {
		setSelectedOption(value);
	};

	useEffect(() => {
		setAlreadyCustomer(selectedOption === "Yes" ? "1" : "0");
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	}, [selectedOption]);

	return (
		<div className={classes.wrapper}>
			<header>
				<div className={classes.innerHeader}>
					<div className={classes.logo}>
						<CustomNavLink to={`/${lngParm}`}>
							<img src={lngId.includes("fr") ? logo_fr : logo_en} alt="logo" />
						</CustomNavLink>
						<div
							id={"8"}
							className={classes.rli}
							onMouseOver={() => {
								hoverHandler("8");
							}}
							onMouseOut={outHandler}
						>
							<p className={activeID === "8" ? classes["active"] : ""}>{lngId}</p>
							<NavDrop
								right={"0"}
								parentId={"8"}
								activeID={activeID}
								height={"9rem"}
								width={"5rem"}
								hide={closeDrop}
								data={[
									{ id: "13", children: "fr-ca" },
									{ id: "14", children: "en-ca" },
									{ id: "15", children: "en-us" },
								]}
								onClick={onLocaliseChange}
							/>
						</div>
					</div>
				</div>
			</header>
			<main>
				<div className={classes.innerMain}>
					<div className={classes.container}>
						<div className={classes.title}>
							<h1>{t(keys.AUTH.SIGN_UP.TITLE)}</h1>
						</div>
						<div className={classes.content}>
							<div className={classes.formWrapper}>
								{!isSignUp && (
									<form onSubmit={submitHandler}>
										<fieldset>
											<legend>{t(keys.AUTH.SIGN_IN.SIGN_IN)}</legend>
											<TextInputOverlay
												id={"FIRST_NAME"}
												label={t(keys.HOME.NEWSLETTER.FIRSTNAME)}
												isValid={firstNameState.isValid}
												value={firstNameState.value}
												onChange={firstNameChangeHandler}
												error={displayFormNotValid && t(keys.AUTH.SIGN_UP.FILL_MANDATORY_FIELD)}
											/>
											<TextInputOverlay
												id={"LAST_NAME"}
												label={t(keys.HOME.NEWSLETTER.LASTNAME)}
												isValid={lastNameState.isValid}
												value={lastNameState.value}
												onChange={lastNameChangeHandler}
												error={displayFormNotValid && t(keys.AUTH.SIGN_UP.FILL_MANDATORY_FIELD)}
											/>
											<TextInputOverlay
												id={"COMPANY_NAME"}
												label={t(keys.AUTH.SIGN_UP.COMPANY_NAME)}
												isValid={companyNameState.isValid}
												value={companyNameState.value}
												onChange={companyNameChangeHandler}
												error={displayFormNotValid && t(keys.AUTH.SIGN_UP.FILL_MANDATORY_FIELD)}
											/>
											<TextInputOverlay
												id={"EMAIL"}
												label={t(keys.AUTH.SIGN_UP.EMAIL)}
												type="email"
												isValid={emailState.isValid}
												value={emailState.value}
												onChange={emailChangeHandler}
												error={displayFormNotValid && t(keys.AUTH.SIGN_UP.FILL_VALID_EMAIL)}
											/>
											<TextInputOverlay
												id={"PASSWORD1"}
												label={t(keys.AUTH.SIGN_IN.PASSWORD)}
												type="password"
												isValid={password1State.isValid}
												value={password1State.value}
												warning={t(keys.AUTH.SIGN_IN.WRONG_PASSWORD)}
												onChange={password1ChangeHandler}
												info={t(keys.AUTH.SIGN_UP.PASSWORD_CONDITIONS)}
												error={displayFormNotValid && t(keys.AUTH.SIGN_UP.FILL_VALID_PASSWORD)}
												strenthBar
											/>
											<TextInputOverlay
												id={"PASSWORD2"}
												label={t(keys.AUTH.FORGOT_PASSWORD.CONFIRM_PASSWORD)}
												type="password"
												isValid={password2State.isValid}
												value={password2State.value}
												warning={t(keys.AUTH.SIGN_IN.WRONG_PASSWORD)}
												onChange={password2ChangeHandler}
												error={
													displayFormNotValid &&
													t(keys.AUTH.SIGN_UP.FILL_VALID_CONFIRM_PASSWORD)
												}
											/>
										</fieldset>
										<div className={classes.adfastCustomer}>
											<label>{t(keys.AUTH.SIGN_UP.DO_HAVE_ADFS_ACC)}</label>
											<div className={classes.radioContainer}>
												<div className={classes.radio}>
													<input
														type="radio"
														value="Yes"
														checked={selectedOption === "Yes"}
														onChange={() => onValueChange("Yes")}
													/>
													<label onClick={() => onValueChange("Yes")}>
														<h3>{t(keys.GLOBAL.COMMON.YES)}</h3>
														<p>{t(keys.AUTH.SIGN_UP.HAVE_ADFS_ACC)}</p>
													</label>
												</div>
												<div className={classes.radio}>
													<input
														type="radio"
														value="No"
														checked={selectedOption === "No"}
														onChange={() => onValueChange("No")}
													/>
													<label onClick={() => onValueChange("No")}>
														<h3>{t(keys.GLOBAL.COMMON.NO)}</h3>
														<p>{t(keys.AUTH.SIGN_UP.DOESNT_HAVE_ADFS_ACC)}</p>
													</label>
												</div>
											</div>
											{alreadyCustomer === "1" && (
												<TextInputOverlay
													id={"accountnum"}
													label={t(keys.AUTH.SIGN_UP.ACCOUNTNUM)}
													value={accountNum}
													onChange={(event) => {
														setAccountNum(event?.target.value);
													}}
													className={classes.accountnum}
												/>
											)}
										</div>
										<fieldset>
											{alreadyCustomer === "0" && (
												<TextInputOverlay
													id={"PHONE"}
													label={t(keys.AUTH.SIGN_UP.PHONE)}
													isValid={alreadyCustomer === "0" && phoneNumberState.isValid}
													value={phoneNumberState.value}
													onChange={phoneNumberChangeHandler}
													error={
														displayFormNotValid && t(keys.AUTH.SIGN_UP.FILL_MANDATORY_FIELD)
													}
												/>
											)}
											{alreadyCustomer === "0" && (
												<TextInputOverlay
													id={"ADDRESS"}
													label={t(keys.AUTH.SIGN_UP.LABEL_ADDRESS1)}
													isValid={alreadyCustomer === "0" && addressState.isValid}
													value={addressState.value}
													onChange={addressChangeHandler}
													error={
														displayFormNotValid && t(keys.AUTH.SIGN_UP.FILL_MANDATORY_FIELD)
													}
												/>
											)}
											{alreadyCustomer === "0" && (
												<TextInputOverlay
													id={"ALREADY_CUST"}
													label={
														lngId.includes("CA") || lngId.includes("ca")
															? t(keys.AUTH.SIGN_UP.POSTALCODE)
															: t(keys.AUTH.SIGN_UP.ZIP_CODE)
													}
													isValid={alreadyCustomer === "0" && postalCodeState.isValid}
													value={postalCodeState.value}
													onChange={postalCodeChangeHandler}
													error={
														displayFormNotValid && t(keys.AUTH.SIGN_UP.FILL_MANDATORY_FIELD)
													}
												/>
											)}
											{alreadyCustomer === "0" && (
												<TextInputOverlay
													id={"ADDRESS2"}
													label={t(keys.AUTH.SIGN_UP.LABEL_ADDRESS2)}
													value={address2State}
													onChange={address2ChangeHandler}
												/>
											)}
											{alreadyCustomer === "0" && (
												<TextInputOverlay
													id={"CITY"}
													label={t(keys.AUTH.SIGN_UP.CITY)}
													isValid={alreadyCustomer === "0" && cityState.isValid}
													value={cityState.value}
													onChange={cityChangeHandler}
													error={
														displayFormNotValid && t(keys.AUTH.SIGN_UP.FILL_MANDATORY_FIELD)
													}
												/>
											)}
											{alreadyCustomer === "0" && (
												<OverlayDropdown
													id={"COUNTRY"}
													label={t(keys.AUTH.SIGN_UP.COUNTRY)}
													onChange={countryChangeHandler}
													value={countryState.value}
													isValid={countryState.value !== ""}
													error={
														displayFormNotValid && t(keys.AUTH.SIGN_UP.FILL_MANDATORY_FIELD)
													}
												>
													<option disabled value={""}>
														{t(keys.AUTH.SIGN_UP.COUNTRY)}
													</option>
													{constants.COUNTRIES.map((country) => (
														<option value={country.id}>{country.name}</option>
													))}
												</OverlayDropdown>
											)}
											{alreadyCustomer === "0" && (
												<OverlayDropdown
													id={"PROVINCE"}
													label={
														lngId.includes("CA") || lngId.includes("ca")
															? t(keys.AUTH.SIGN_UP.PROVINCE)
															: t(keys.AUTH.SIGN_UP.STATE)
													}
													onChange={provinceChangeHandler}
													isValid={provinceState.value !== ""}
													error={
														displayFormNotValid && t(keys.AUTH.SIGN_UP.FILL_MANDATORY_FIELD)
													}
													value={provinceState.value}
													disabled={countryState.value === ""}
												>
													<option disabled value={""}>
														{lngId.includes("CA") || lngId.includes("ca")
															? t(keys.AUTH.SIGN_UP.PROVINCE)
															: t(keys.AUTH.SIGN_UP.STATE)}
													</option>
													{constants.COUNTRIES.find(
														(country) =>
															countryState.value !== "" &&
															country.id === countryState.value
													)?.states.map((state) => (
														<option value={state.id}>{state.name}</option>
													))}
												</OverlayDropdown>
											)}
											{alreadyCustomer === "0" && (
												<OverlayDropdown
													id={"signup_source"}
													label={t(keys.AUTH.SIGN_UP.HEAD_ABOUT)}
													onChange={sourceChangeHandler}
													value={sourceState}
													isValid={sourceState !== ""}
													error={
														displayFormNotValid && t(keys.AUTH.SIGN_UP.FILL_MANDATORY_FIELD)
													}
												>
													<option value="" disabled="disabled">
														{t(keys.AUTH.SIGN_UP.HEAD_ABOUT)}
													</option>
													<option value="Admobile">{t(keys.GLOBAL.COMMON.ADMOBILE)}</option>
													<option value="Recherche Internet">
														{t(keys.GLOBAL.COMMON.INTERNET)}
													</option>
													<option value="Référence Nichiha">
														{t(keys.GLOBAL.COMMON.NICHIA)}
													</option>
													<option value="Courriel promotionnel">
														{t(keys.GLOBAL.COMMON.EMAIL)}
													</option>
													<option value="Radio">{t(keys.GLOBAL.COMMON.RADIO)}</option>
													<option value="Référence">{t(keys.GLOBAL.COMMON.REF)}</option>
													<option value="Représentant Adfast">
														{t(keys.GLOBAL.COMMON.REP)}
													</option>
													<option value="Réseaux Sociaux">
														{t(keys.GLOBAL.COMMON.MEDIAS)}
													</option>
													<option value="Devis">{t(keys.GLOBAL.COMMON.SPEC)}</option>
													<option value="Foire commerciale">
														{t(keys.GLOBAL.COMMON.SHOW)}
													</option>
													<option value="Télévision">{t(keys.GLOBAL.COMMON.TV)}</option>
													<option value="Webinaire">{t(keys.GLOBAL.COMMON.WEB)}</option>
												</OverlayDropdown>
											)}
											{alreadyCustomer === "0" && (
												<OverlayDropdown
													id={"IS_DISTRIBUTOR"}
													label={t(keys.AUTH.SIGN_UP.IS_DISTRIBUTOR)}
													onChange={isDistributorChangeHandler}
													labelClassName={classes.radioLabel}
													value={isDistributorState}
													active={"active"}
												>
													<option value={-1} disabled="disabled">
														{t(keys.AUTH.SIGN_UP.IS_DISTRIBUTOR)}
													</option>
													<option value={0}>{t(keys.GLOBAL.COMMON.NO)}</option>
													<option value={1}>{t(keys.GLOBAL.COMMON.YES)}</option>
												</OverlayDropdown>
											)}
											{alreadyCustomer === "0" && isDistributorState === "1" && (
												<TextInputOverlay
													id={"WITCH_DISTRIBUTOR"}
													label={t(keys.AUTH.SIGN_UP.WITCH_DISTRIBUTOR)}
													isValid={alreadyCustomer === "0" && distributorState.isValid}
													value={distributorState.value}
													onChange={distributorChangeHandler}
													error={
														displayFormNotValid && t(keys.AUTH.SIGN_UP.FILL_MANDATORY_FIELD)
													}
												/>
											)}
										</fieldset>
										<ReCAPTCHA sitekey={constants.sitekey} onChange={captchaChangeHandler} />
										<div className={classes.terms1}>
											<RoundCheckbox
												id={"signup_terms"}
												onChange={acceptTermChangeHandler}
												defaultChecked={true}
												label={t(keys.AUTH.SIGN_UP.ACCEPT)}
											/>
											<CustomNavLink to={`/term_privacy${lngParm}`}>
												{t(keys.AUTH.SIGN_UP.TERMS)}
											</CustomNavLink>
										</div>
										<div className={classes.terms2}>
											<Icon className={classes.icon} icon="bi:exclamation-circle-fill" />
											<p>{t(keys.AUTH.SIGN_UP.MIN_ORDER)}</p>
										</div>
										<div className={classes.signIn}>
											{signupHasError && (
												<div className={classes.error}>
													<Icon
														className={classes.icon}
														style={{ fontSize: "2.5rem" }}
														icon="bi:exclamation-circle-fill"
													/>
													<p>{t(keys.AUTH.SIGN_UP.SIGN_UP_FAILED)}</p>
												</div>
											)}
											{displayFormNotValid && (
												<div className={classes.error}>
													<Icon
														className={classes.icon}
														style={{ fontSize: "1.5rem" }}
														icon="bi:exclamation-circle-fill"
													/>
													<p>{t(keys.AUTH.SIGN_UP.FOR_NOT_VALID)}</p>
												</div>
											)}
											<div className={classes.signIn_btn}>
												<button
													className={classes[!!isLoading ? "disable" : ""]}
													type="submit"
													disabled={!!isLoading}
												>
													{t(keys.AUTH.SIGN_IN.SIGN_UP)}
												</button>
												<ul>
													<li>
														<CustomNavLink to={`/sign-in${lngParm}`}>
															{t(keys.AUTH.SIGN_IN.SIGN_IN)}
														</CustomNavLink>
													</li>
												</ul>
											</div>
										</div>
									</form>
								)}
								{isSignUp && (
									<div className={classes.success}>
										<p>{t(keys.AUTH.SIGN_UP.ACCOUNT_CREATED)}</p>
										<Icon className={classes.icon} icon="clarity:success-standard-solid" />
										<p>{t(keys.AUTH.SIGN_UP.THANKS)}</p>
										<p>
											<span>{t(keys.AUTH.SIGN_UP.MESSAGE1)}</span>
											<span className={classes.email}>{emailState.value}</span>
											<span>{t(keys.AUTH.SIGN_UP.MESSAGE2)}</span>
										</p>
										<CustomNavLink to={`/`}>
											<Button size="big">{t(keys.GLOBAL.NAVBAR.HOME)}</Button>
										</CustomNavLink>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</main>
			<footer>
				<div className={classes.innerFooter}>
					<div className={classes.logoFooter}>
						<img src={lngId.includes("fr") ? nologo_fr : nologo_en} alt="logo" />
					</div>
					<p className={classes.rightFooter}>Copyright © 2023 Adfast. All Rights Reserved.</p>
					<div className={classes.linksFooter}>
						<CustomNavLink to={`/term_privacy${lngParm}`}>{t(keys.AUTH.SIGN_UP.TERMS)}</CustomNavLink>
						<CustomNavLink to={"/contact-us"}>{t(keys.GLOBAL.FOOTER.CONTACT_US)}</CustomNavLink>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default Signup;
