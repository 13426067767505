import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import CustomNavLink from "../../ui/customNavLink/CustomNavLink";

import { homeSegmentPickerSelector } from "../../../store/mediaBase";

import { keys } from "../../../locales/localeskeys";

import classes from "./SegmentPicker.module.scss";
import { Fragment } from "react";

const SegmentPicker = () => {
	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const homeSegmentPicker = useSelector(homeSegmentPickerSelector);

	const toLink = (url) => {
		return url.includes("http")
			? {
					pathname: new URL(url).pathname,
					search: new URL(url).searchParams.toString(),
			  }
			: url.replace("lng_id", lngId);
	};

	return (
		<Fragment>
			<h2 className={classes.segmentPickerTitle}>{t(keys.HOME.SEGMENT_PICKER.TITLE)}</h2>
			<div className={classes.segmentPicker}>
				{homeSegmentPicker.map((segment, index) => (
					<CustomNavLink
						to={toLink(segment?.url)}
						target={segment?.url.includes("http") ? "_blank" : "_self"}
						className={classes.segmentPickerCard}
						key={index}
					>
						{(lngId.includes("fr") ? segment.img_fr : segment.img_en) && (
							<img src={lngId.includes("fr") ? segment.img_fr : segment.img_en} alt="url" />
						)}
						{(lngId.includes("fr") ? segment.title_fr : segment.title_en) && (
							<p>{lngId.includes("fr") ? segment.title_fr : segment.title_en}</p>
						)}
					</CustomNavLink>
				))}
			</div>
		</Fragment>
	);
};

export default SegmentPicker;
