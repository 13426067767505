import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import { ButtonBack, ButtonNext, CarouselProvider, Dot, Slide, Slider } from "pure-react-carousel";

import useElementSize from "../../../hooks/useElementSize";
import CustomNavLink from "../../ui/customNavLink/CustomNavLink";

import { homeDiscoverProductSelector } from "../../../store/mediaBase";

import { keys } from "../../../locales/localeskeys";
import classes from "./DiscoverProduct.module.scss";

const DiscoverProduct = () => {
	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const [gridRef, { width: gridWidth, height: heightWidth }] = useElementSize();

	const discoverProduct = useSelector(homeDiscoverProductSelector);

	const toLink = (url) => {
		return url.includes("http")
			? {
					pathname: new URL(url).pathname,
					search: new URL(url).searchParams.toString(),
			  }
			: url.replace("lng_id", lngId);
	};

	return (
		<div ref={gridRef}>
			<h2 className={classes.title}>{t(keys.HOME.DISCOVER_PRODUCT)}</h2>
			<div className={classes.container}>
				<div>
					<CarouselProvider
						className={classes.carousel}
						naturalSlideWidth={100}
						naturalSlideHeight={
							gridWidth <= 513
								? 135 - gridWidth / 85
								: gridWidth <= 748
								? 150 - gridWidth / 85
								: 150 - gridWidth / 85
						}
						totalSlides={discoverProduct.length}
						visibleSlides={gridWidth <= 513 ? 1.1 : gridWidth <= 748 ? 3 : 4}
						dragEnabled={false}
					>
						<Slider className={classes.slider}>
							{discoverProduct.map((record, index) => (
								<Slide innerClassName={classes.slide} index={index} key={index}>
									<CustomNavLink
										to={toLink(record?.url)}
										target={record.url.includes("http") ? "_blank" : "_self"}
										className={classes.card}
										key={index}
									>
										{(lngId.includes("fr") ? record.img_fr : record.img_en) && (
											<img src={lngId.includes("fr") ? record.img_fr : record.img_en} alt="url" />
										)}
										{(lngId.includes("fr") ? record.title_fr : record.title_en) && (
											<h1>{lngId.includes("fr") ? record.title_fr : record.title_en}</h1>
										)}
										{(lngId.includes("fr") ? record.description_fr : record.description_en) && (
											<p>
												{lngId.includes("fr") ? record.description_fr : record.description_en}
											</p>
										)}
									</CustomNavLink>
								</Slide>
							))}
						</Slider>
						{discoverProduct.length > 1 && (
							<div className={classes.btnLayout}>
								<ButtonBack className={`${classes.btn} ${classes.btnBack}`}>
									<Icon className={classes.icon} icon="bi:arrow-left-square-fill" />
								</ButtonBack>
								<ButtonNext className={`${classes.btn} ${classes.btnNext}`}>
									<Icon className={classes.icon} icon="bi:arrow-right-square-fill" />
								</ButtonNext>
							</div>
						)}
						{discoverProduct.length > 1 && (
							<div className={classes.pickerDots}>
								{discoverProduct.map((record, index) => (
									<Dot className={classes.icon} slide={index} key={index} />
								))}
							</div>
						)}
					</CarouselProvider>
				</div>
			</div>
		</div>
	);
};

export default DiscoverProduct;
