import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { customerIdSelector, defaultAddressSelector, deleteAddress, setDefaultAddress } from "../../../../store/auth";

import { keys } from "../../../../locales/localeskeys";

import classes from "./Address.module.scss";

const Address = (props) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const defaultAddress = useSelector(defaultAddressSelector);
	const customerId = useSelector(customerIdSelector);

	const remove = () => {
		dispatch(deleteAddress(props.id));
	};

	const setDefault = () => {
		dispatch(
			setDefaultAddress({
				oldAddress:
					defaultAddress && defaultAddress.length > 0
						? {
								id: defaultAddress[0].id,
								is_default: false,
								type: 0,
								line1: defaultAddress[0].line1,
								line2: defaultAddress[0].line2,
								city: defaultAddress[0].city,
								postal_code: defaultAddress[0].postalCode,
								state: defaultAddress[0].state,
								country: defaultAddress[0].country,
								name: defaultAddress[0].name,
								phone: defaultAddress[0].phone,
								customer: customerId,
						  }
						: undefined,
				newAddress: {
					id: props.id,
					is_default: true,
					type: 0,
					line1: props.line1,
					line2: props.line2,
					city: props.city,
					postal_code: props.postalCode,
					state: props.state,
					country: props.country,
					name: props.name,
					phone: props.phone,
					customer: customerId,
				},
			})
		);
	};

	return (
		<div className={classes.layout}>
			<div className={classes.details}>
				{props.default && <h2 className={classes.default}>{t(keys.ACCOUNT.ADDRESS.DEFAULT)}</h2>}
				<div className={classes.address}>
					{props.name && <h2>{props.name}</h2>}
					{props.line1 && <p>{props.line1}</p>}
					<p>{`${props.city ? props.city : ""} ${props.state ? props.state : ""} ${
						props.postalCode ? props.postalCode : ""
					}`}</p>
					{props.country && <p>{props.country}</p>}
				</div>
			</div>
			<div className={classes.buttons}>
				<h2 className={classes.editAdress} onClick={props.edit}>
					{t(keys.ACCOUNT.ADDRESS.EDIT)}
				</h2>
				<h2 className={classes.removeAdress} onClick={remove}>
					{t(keys.ACCOUNT.ADDRESS.REMOVE)}
				</h2>
				{!props.default && (
					<h2 className={classes.defaultAdress} onClick={setDefault}>
						{t(keys.ACCOUNT.ADDRESS.SETDEFAULT)}
					</h2>
				)}
			</div>
		</div>
	);
};

export default Address;
