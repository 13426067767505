import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import classes from "./TextInputOverlay.module.scss";
import PasswordStrengthBar from "react-password-strength-bar";
import fts_svg from "../../../../assets/svg/FTS.svg";
import tds_svg from "../../../../assets/svg/TDS.svg";
import fds_svg from "../../../../assets/svg/FDS.svg";
import sds_svg from "../../../../assets/svg/SDS.svg";

const TextInputOverlay = forwardRef((props, ref) => {
	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const inputRef = useRef();

	const [focus, setFocus] = useState("");
	const [active, setActive] = useState("");

	const inputHandler = (e) => {
		if (props.type === "number") {
			const char = String.fromCharCode(e.which);
			if (!/[0-9]/.test(char)) {
				e.preventDefault();
			}
		}
	};

	const onFocus = () => {
		setFocus("focus");
		if (props.onFocus) props.onFocus();
	};

	const onBlur = () => {
		setFocus("");
		if (props.onBlur) props.onBlur();
	};

	const onChange = (event) => {
		if (event.target.value) setActive("active");
		else setActive("");
		if (props.onChange) props.onChange(event);
	};

	useImperativeHandle(ref, () => ({
		focus: () => {
			inputRef.current.focus();
		},
	}));

	useEffect(() => {
		if (inputRef?.current?.value) setActive("active");
		else setActive("");
	}, [inputRef?.current?.value]);

	return (
		<div
			className={`${props.className || ""} ${classes.TextInput} ${
				props.isValid === false ? classes.invalid : ""
			}`}
		>
			{props.label && (
				<label
					className={`${props.labelclass || ""} ${classes.label} ${focus ? classes[focus] : ""} ${
						active ? classes[active] : ""
					}`}
					htmlFor={props.id}
				>
					{props.label}
					{props.mandatory && <Icon className={classes.mandatory} icon="oui:asterisk" />}
				</label>
			)}
			{props.onSearch && (
				<Icon
					className={`${classes.search} ${focus ? classes[focus] : ""} ${active ? classes[active] : ""}`}
					onClick={props.onSearch}
					icon="ant-design:search-outlined"
				/>
			)}
			<input
				className={`${props.inputclass || ""} ${classes.input} ${focus ? classes[focus] : ""} ${
					active ? classes[active] : ""
				}`}
				ref={inputRef}
				type={props.type}
				id={props.id}
				name={props.name}
				value={props.value}
				onChange={onChange}
				placeholder={props.placeholder}
				onKeyPress={inputHandler}
				defaultValue={props.defaultValue}
				disabled={props.disabled}
				required={props.mandatory}
				pattern={props.pattern}
				onFocus={onFocus}
				onBlur={onBlur}
				onKeyDown={props.onKeyDown}
			/>
			{props.onClose && (
				<Icon
					className={`${classes.close} ${focus ? classes[focus] : ""} ${active ? classes[active] : ""}`}
					onClick={props.onClose}
					icon="ei:close-o"
				/>
			)}
			{props.sdsTds && (
				<div className={classes.fileIcon}>
					<img
						className={classes.tds_img}
						src={lngId.includes("fr") ? fts_svg : tds_svg}
						alt={tds_svg}
						onClick={props.onSearch}
					/>
					<img
						className={classes.ds_img}
						src={lngId.includes("fr") ? fds_svg : sds_svg}
						alt={sds_svg}
						onClick={props.onSearch}
					/>
				</div>
			)}
			{props.strenthBar && (
				<PasswordStrengthBar
					password={props.value}
					barColors={["#B83E26", "#FFB829", "#009200", "#009200", "#009200", "#009200"]}
					minLength={8}
				/>
			)}
			{!props.error && props.info && (
				<div className={classes.info}>
					<p>{props.info}</p>
				</div>
			)}
			{props.isValid === false && props.error && (
				<div className={classes.error}>
					<p>{props.error}</p>
				</div>
			)}
		</div>
	);
});

export default TextInputOverlay;
