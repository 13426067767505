import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Icon } from "@iconify/react";

import { setPageSize } from "../../../store/productQuery";

import { DOTS, usePagination } from "./usePagination";

import classes from "./Pagination.module.scss";

const Pagination = (props) => {
	const dispatch = useDispatch();
	const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize } = props;

	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount,
		pageSize,
	});

	useEffect(() => {
		dispatch(setPageSize(parseInt(pageSize)));
	}, [pageSize]);

	if (currentPage === 0 || !paginationRange || paginationRange.length < 2) {
		return null;
	}

	const onNext = () => {
		if (currentPage < lastPage) {
			onPageChange(currentPage + 1);
			window.scrollTo(0, 0);
		}
	};

	const onPrevious = () => {
		if (currentPage > 1) {
			onPageChange(currentPage - 1);
			window.scrollTo(0, 0);
		}
	};

	let lastPage = paginationRange[paginationRange.length - 1];
	return (
		<ul className={`${classes.body} ${props.className}`}>
			<Icon
				icon="material-symbols:arrow-back-ios"
				className={`${classes.icon} ${currentPage === 1 ? classes.disabled : ""}`}
				onClick={onPrevious}
			/>
			{paginationRange.map((pageNumber, index) => {
				if (pageNumber === DOTS) {
					return (
						<li className={`${classes.item} ${classes["dots"]}`} key={index}>
							&#8230;
						</li>
					);
				}
				return (
					<li
						className={`${classes.item} ${classes[currentPage === pageNumber ? "selected" : ""]}`}
						onClick={() => onPageChange(pageNumber)}
						key={index}
					>
						{pageNumber}
					</li>
				);
			})}
			<Icon
				icon="material-symbols:arrow-forward-ios"
				className={`${classes.icon} ${currentPage === lastPage ? classes.disabled : ""}`}
				onClick={onNext}
			/>
		</ul>
	);
};

export default Pagination;
