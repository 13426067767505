import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getVisit, isLoadingSelector, visitsSelector } from "../../../store/architect";
import { visitHeaderSelector } from "../../../store/mediaBase";
import Loading from "../../ui/loading/Loading";
import classes from "./Visit.module.scss";
import HeaderCarousel from "../../ui/HeaderCarousel/HeaderCarousel";
import { keys } from "../../../locales/localeskeys";
import CustomNavLink from "../../ui/customNavLink/CustomNavLink";
import Button from "../../ui/Button/Button";
import Accordion from "../../ui/Accordion/Accordion";
import LinkTree from "../../ui/LinkTree/LinkTree";
import Resources from "../Resources/Resources";

const Visit = () => {
	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const dispatch = useDispatch();

	const visits = useSelector(visitsSelector);
	const visitIsLoading = useSelector(isLoadingSelector);
	const visitHeader = useSelector(visitHeaderSelector);

	useEffect(() => {
		dispatch(getVisit());
	}, []);

	return (
		<Fragment>
			{(!visitHeader || visitIsLoading) && <Loading className={classes.loading} type={"bounce"} />}
			{!visitIsLoading && visits && visits.length > 0 && (
				<Fragment>
					<HeaderCarousel data={visitHeader} className={classes.carousel} />
					<div className={classes.layout}>
						<LinkTree
							className={classes.Tree}
							tree={[
								{ label: t(keys.GLOBAL.NAVBAR.HOME), to: "/" },
								{ label: t(keys.ARCHITECT.TITLE), to: "/architect/" },
								{ label: t(keys.ARCHITECT.VISITTXT), to: "/architect/visit/" },
							]}
						/>
						<h1 className={classes.title}>{t(keys.ARCHITECT.VISIT.TITLE)}</h1>
						{visitHeader && visitHeader.length > 0 && (
							<Fragment>
								{(lngId.includes("fr") ? visitHeader[0].description_fr : visitHeader[0].description_en)
									?.split("#")
									?.map((str, index) => (
										<p className={classes.description} key={index}>
											{str}
										</p>
									))}
							</Fragment>
						)}
						<div className={classes.center}>
							<CustomNavLink to={"/contact-us"}>
								<Button className={classes.btn} style={{ background: "black", borderColor: "black" }}>
									{t(keys.ARCHITECT.VISIT.REQUEST)}
								</Button>
							</CustomNavLink>
						</div>
						{visits &&
							visits.length > 0 &&
							visits.map((visit) => (
								<Accordion
									className={classes.events}
									headerClass={classes.headerClass}
									iconPlusMinus={classes.iconPlusMinus}
									accordionBody={classes.accordionBody}
									header={lngId.includes("fr") ? visit.title_fr : visit.title_en}
									id={visit.id}
									dark
									active={visits.length === 1}
								>
									{visit.events
										.filter((e) => e.zone !== 0)
										.map((event) => (
											<div key={event.id} className={classes.event}>
												<div className={classes.details}>
													<h2 key={event.id}>
														{lngId.includes("fr") ? event.title_fr : event.title_en}
													</h2>
													<p>
														{lngId.includes("fr")
															? event.description_fr
															: event.description_en}
													</p>
												</div>
												<div className={classes.register}>
													<CustomNavLink
														to={lngId.includes("fr") ? event.url_fr : event.url_en}
														target={"_blank"}
													>
														<Button color={"outlineBlack"}>
															{t(keys.ARCHITECT.WEBINAR.REGISTER)}
														</Button>
													</CustomNavLink>
												</div>
											</div>
										))}
								</Accordion>
							))}
						<Resources />
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default Visit;
