import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { Icon } from "@iconify/react";

import Button from "../../../ui/Button/Button";
import TextInput from "../../../ui/Input/TextInput/TextInput";
import Banner from "../../Banner/Banner";
import CustomNavLink from "../../../ui/customNavLink/CustomNavLink";
import RoundCheckbox from "../../../ui/Input/RoundCheckbox/RoundCheckbox";

import { isSubNewsLetterSelector, subscribeNewsLetter } from "../../../../store/auth";

import { banners } from "../../../../locales/banners";

import newsletter1 from "../../../../assets/jpeg/newsletter1.jpeg";
import newsletter2 from "../../../../assets/jpeg/newsletter2.jpeg";
import newsletter3 from "../../../../assets/jpeg/newsletter3.jpeg";
import newsletter4 from "../../../../assets/jpeg/newsletter4.jpeg";
import newsletter5 from "../../../../assets/jpeg/newsletter5.jpeg";
import newsletter6 from "../../../../assets/jpeg/newsletter6.jpeg";
import newsletter7 from "../../../../assets/jpeg/newsletter7.jpeg";
import newsletter8 from "../../../../assets/jpeg/newsletter8.jpeg";

import { constants } from "../../../../locales/constant";
import { keys } from "../../../../locales/localeskeys";

import classes from "./NewsletterForm.module.scss";

const NewsletterForm = () => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const isSubNewsLetter = useSelector(isSubNewsLetterSelector);

	const [firstNameState, setFirstName] = useState({ value: "", isValid: true });
	const [lastNameState, setLastName] = useState({ value: "", isValid: true });
	const [emailState, setEmail] = useState({ value: "", isValid: true });
	const [companyState, setCompany] = useState("");
	const [promotionalState, setPromotionalState] = useState(false);
	const [adfastNewsState, setAdfastNewsState] = useState(false);
	const [displayFormNotValid, setDisplayFormNotValid] = useState(false);
	const [fieldChanged, setFieldChanged] = useState(false);
	const [isCaptchaValid, setIsCaptchaValid] = useState(false);

	const isFormValid = firstNameState.isValid && lastNameState.isValid && emailState.isValid;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (isSubNewsLetter === true) {
			navigate({ pathname: `/`, search: queryParams.toString() });
		}
	}, [isSubNewsLetter, lngId]);

	const emailChangeHandler = (event) => {
		const emailRegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,15}$/;
		setEmail({
			value: event.target.value,
			isValid: event.target.value !== "" && emailRegExp.test(event.target.value.trim()),
		});
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const firstNameChangeHandler = (event) => {
		setFirstName({
			value: event.target.value,
			isValid: event.target.value !== "",
		});
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const lastNameChangeHandler = (event) => {
		setLastName({
			value: event.target.value,
			isValid: event.target.value !== "",
		});
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const companyChangeHandler = (event) => {
		setCompany(event.target.value);
	};

	const promotionalChangeHandler = (event) => {
		setPromotionalState(event.target.checked);
	};

	const adfastNewsChangeHandler = (event) => {
		setAdfastNewsState(event.target.checked);
	};

	const submitHandler = (event) => {
		event.preventDefault();
		if (!isCaptchaValid) return;
		if (isFormValid && fieldChanged) {
			dispatch(
				subscribeNewsLetter({
					first_name: firstNameState.value,
					last_name: lastNameState.value,
					email: emailState.value,
					company_name: companyState,
					promotional: promotionalState,
					adfast_news: adfastNewsState,
				})
			);
		} else {
			setDisplayFormNotValid(true);
		}
	};

	const captchaChangeHandler = (value) => {
		if (value) {
			setIsCaptchaValid(value);
		}
	};

	return (
		<div className={classes.layout}>
			<Banner content={banners.newsletter} />
			<form onSubmit={submitHandler} className={classes.formLayout}>
				<h1 className={classes.padding}>{t(keys.HOME.NEWSLETTER.TITLE)}</h1>
				<p className={classes.padding}>{t(keys.HOME.NEWSLETTER.TEXTE1)}</p>
				<div className={`${classes.inputLayout} ${classes.padding}`}>
					<TextInput
						label={t(keys.HOME.NEWSLETTER.FIRSTNAME)}
						isValid={firstNameState.isValid}
						value={firstNameState.value}
						mandatory={true}
						onChange={firstNameChangeHandler}
						className={classes.input}
					/>
					<TextInput
						label={t(keys.HOME.NEWSLETTER.LASTNAME)}
						isValid={lastNameState.isValid}
						value={lastNameState.value}
						mandatory={true}
						onChange={lastNameChangeHandler}
						className={classes.input}
					/>
					<TextInput
						label={t(keys.HOME.NEWSLETTER.EMAIL)}
						type="email"
						isValid={emailState.isValid}
						value={emailState.value}
						warning={t(keys.AUTH.SIGN_IN.WRONG_EMAIL)}
						mandatory={true}
						onChange={emailChangeHandler}
						className={classes.input}
					/>
					<TextInput
						label={t(keys.HOME.NEWSLETTER.COMPANY)}
						value={companyState}
						onChange={companyChangeHandler}
						className={classes.input}
					/>
				</div>
				<div className={`${classes.inputLayout} ${classes.padding}`}>
					<RoundCheckbox
						id={"promotional"}
						onChange={promotionalChangeHandler}
						label={t(keys.HOME.NEWSLETTER.PROMOTIONAL)}
						defaultChecked={false}
					/>
					<RoundCheckbox
						id={"adfastNews"}
						onChange={adfastNewsChangeHandler}
						label={t(keys.HOME.NEWSLETTER.ADFASTNEWS)}
						defaultChecked={false}
					/>
				</div>
				<p className={classes.padding}>
					{t(keys.HOME.NEWSLETTER.TEXTE2)}{" "}
					<CustomNavLink to={`/term_privacy`} className={classes.link}>
						{t(keys.AUTH.SIGN_UP.TERMS)}
					</CustomNavLink>
					.
				</p>
				<p className={classes.padding}>{t(keys.HOME.NEWSLETTER.TEXTE4)}</p>
				{displayFormNotValid && (
					<div className={classes.warning}>
						<Icon icon="bi:exclamation-circle-fill" />
						<p>{t(keys.AUTH.SIGN_UP.FOR_NOT_VALID)}</p>
					</div>
				)}
				<Button className={classes.button} id="btn_sub" color="primary" size="big" type="submit">
					{t(keys.GLOBAL.ADNEWS.BUTTON)}
				</Button>
				<ReCAPTCHA sitekey={constants.sitekey} onChange={captchaChangeHandler} />

				<h2>{t(keys.HOME.NEWSLETTER.INSTA)}</h2>
			</form>
			<div className={classes.instaLayout}>
				<img src={newsletter1} alt="newsletter1.jpeg" />
				<img src={newsletter2} alt="newsletter2.jpeg" />
				<img src={newsletter3} alt="newsletter3.jpeg" />
				<img src={newsletter4} alt="newsletter4.jpeg" />
				<img src={newsletter5} alt="newsletter5.jpeg" />
				<img src={newsletter6} alt="newsletter6.jpeg" />
				<img src={newsletter7} alt="newsletter7.jpeg" />
				<img src={newsletter8} alt="newsletter8.jpeg" />
			</div>
		</div>
	);
};

export default NewsletterForm;
