import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import ToggleSwitch from "../../../../ui/Input/ToggleSwitch/ToggleSwitch";
import TextInputOverlay from "../../../../ui/Input/TextInputOverlay/TextInputOverlay";

import { isLoggedInSelector } from "../../../../../store/auth";
import { getItemCarts, itemHasErrorSelector, itemIsLoadingSelector } from "../../../../../store/product";
import {
	favoriteSelector,
	filterBrandsSelector,
	filterCategoriesSelector,
	filterLoadingSelector,
	filterSubsegmentsSelector,
	pageSelector,
	quotedonlySelector,
	searchSelector,
	setFavorite,
	setQuotedOnly,
	setSearch,
} from "../../../../../store/productQuery";

import { keys } from "../../../../../locales/localeskeys";

import classes from "./ProductSearchBar.module.scss";

const ProductSearchBar = (props) => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const isLoggedIn = useSelector(isLoggedInSelector);
	const filterIsLoading = useSelector(filterLoadingSelector);
	const isLoading = useSelector(itemIsLoadingSelector);
	const itemHasError = useSelector(itemHasErrorSelector);
	const searchState = useSelector(searchSelector);
	const favoriteState = useSelector(favoriteSelector);
	const currentPageState = useSelector(pageSelector);
	const brandState = useSelector(filterBrandsSelector);
	const categoriyState = useSelector(filterCategoriesSelector);
	const industryState = useSelector(filterSubsegmentsSelector);
	const quoteOnlyState = useSelector(quotedonlySelector);

	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const searchQuery = queryParams.get("search");
	const favoriteQuery = queryParams.get("favorite");

	const searchRef = useRef();
	const [oldSearch, setOldSearch] = useState("");
	const [isSubmited, setIsSubmited] = useState(true);
	const [pageLoading, setPageLoading] = useState(true);

	useEffect(() => {
		const timer = setTimeout(() => {
			setPageLoading(false);
		}, 100);
		return () => clearTimeout(timer);
	}, []);

	useEffect(() => {
		dispatch(setFavorite(favoriteQuery === "true"));
	}, [favoriteQuery]);

	useEffect(() => {
		if (isLoggedIn !== undefined && filterIsLoading === false && pageLoading === false) {
			if (searchQuery !== searchState && !(searchQuery === null || searchQuery === "")) {
				dispatch(setSearch(searchQuery));
			}
			if (isSubmited) {
				if (itemHasError) navigate({ pathname: `/` });
				dispatch(
					getItemCarts({
						pageSize: props.pageSize,
					})
				);
			}
		}
	}, [
		isLoggedIn,
		filterIsLoading,
		isSubmited,
		searchState,
		favoriteState,
		currentPageState,
		brandState,
		categoriyState,
		industryState,
		quoteOnlyState,
		pageLoading,
	]);
	const searchChangeHandler = (event) => {
		dispatch(setSearch(event.target.value));
		/**
		 * enlever le parametre si la valeur du fitre est vide
		 */
		if (event.target.value !== "") {
			queryParams.set("search", event.target.value);
		} else {
			queryParams.delete("search");
		}
		/**
		 * modifier les parametres du lien au changement du filtre
		 * remplacer l'historique
		 */
		setIsSubmited(false);
		if (oldSearch === undefined || oldSearch === "" || isSubmited === true) {
			navigate({
				pathname: location.pathname,
				search: queryParams.toString(),
			});
		} else {
			navigate(
				{
					pathname: location.pathname,
					search: queryParams.toString(),
				},
				{ replace: true }
			);
		}
		setOldSearch(event.target.value);
	};

	/**
	 * Modifier la valeur du store et le parametre query du favorite only
	 */
	const favoriteChangeHandler = () => {
		queryParams.set("page", 1);
		if (favoriteQuery !== "true") {
			queryParams.set("favorite", "true");
		} else {
			queryParams.delete("favorite");
		}
		navigate({
			pathname: location.pathname,
			search: queryParams.toString(),
		});
	};

	const searchSubmitHandler = (event) => {
		event.preventDefault();
		setIsSubmited(true);
		queryParams.set("page", 1);
		navigate({
			pathname: location.pathname,
			search: queryParams.toString(),
		});
	};

	const enterSubmitHandler = (event) => {
		//fire when user press enter
		if (event.key === "Enter") {
			event.preventDefault();
			setIsSubmited(true);
			queryParams.set("page", 1);
			navigate({
				pathname: location.pathname,
				search: queryParams.toString(),
			});
		}
	};

	const quoteOnlyChangeHandler = (checked) => {
		queryParams.set("page", 1);
		navigate({
			pathname: location.pathname,
			search: queryParams.toString(),
		});
		window.scrollTo(0, 0);

		dispatch(setQuotedOnly(checked));
	};

	return (
		<div className={classes.searchBarContainer} style={{ "--displayMobile": !isLoggedIn ? "none" : "block" }}>
			<TextInputOverlay
				ref={searchRef}
				id="search"
				label={t(keys.GLOBAL.SEARCH)}
				value={searchState ? searchState : ""}
				error={t(keys.AUTH.SIGN_IN.WRONG_EMAIL)}
				onChange={searchChangeHandler}
				onKeyDown={enterSubmitHandler}
				onSearch={searchSubmitHandler}
				disabled={isLoading}
				className={classes.searchBar}
			/>
			{isLoggedIn && (
				<ToggleSwitch
					label={t(keys.PRODUCTS.SHOW_ONLY_QUOTED)}
					checked={quoteOnlyState}
					onChange={quoteOnlyChangeHandler}
					onColor="#86d3ff"
					onHandleColor="#2693e6"
					handleDiameter={30}
					uncheckedIcon={false}
					checkedIcon={false}
					boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
					activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
					disabled={isLoading}
					className={classes.onlyCoted}
				/>
			)}
			{isLoggedIn && (
				<ToggleSwitch
					label={t(keys.PRODUCTS.SHOW_ONLY_FAVORITE)}
					checked={favoriteState}
					onChange={favoriteChangeHandler}
					onColor="#86d3ff"
					onHandleColor="#2693e6"
					handleDiameter={30}
					uncheckedIcon={false}
					checkedIcon={false}
					boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
					activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
					disabled={isLoading}
					className={classes.showFavorite}
				/>
			)}
		</div>
	);
};

export default ProductSearchBar;
