import React, { useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import TextInputOverlay from "../../ui/Input/TextInputOverlay/TextInputOverlay";
import NavDrop from "../../navbar/NavDrop/NavDrop";
import CustomNavLink from "../../ui/customNavLink/CustomNavLink";

import { isLoadingSelector, isPasswordFailed, isPasswordReseted, resetPassword } from "../../../store/auth";

import logo_fr from "../../../assets/svg/txt_logo_fr.svg";
import logo_en from "../../../assets/svg/txt_logo_en.svg";
import nologo_fr from "../../../assets/svg/txt_no_logo_fr.svg";
import nologo_en from "../../../assets/svg/txt_no_logo_en.svg";

import { keys } from "../../../locales/localeskeys";
import classes from "./ResetPassword.module.scss";

/**
 * verifier si le mot de passe est valide
 * @param state la valeur du mot de passe dans .val
 * @param action l'action du reducer
 * @returns {{value: *}}
 */
const passwordReducer = (state, action) => {
	const passWordRegExp = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
	if (action.type === "USER_INPUT") {
		return {
			value: action.val,
			// isValid: action.val && passWordRegExp.test(action.val),
		};
	}
	if (action.type === "INPUT_BLUR") {
		return {
			value: state.value,
			isValid: state.value && passWordRegExp.test(state.value),
		};
	}
};

const ResetPassword = () => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const lngParm = `?lng=${lngId}`;

	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const params = useParams();
	const { uid } = params;
	const { token } = params;

	const passwordInputRef = useRef();
	const confirmPasswordInputRef = useRef();

	const [closingDrop, setCLosingDrop] = useState({ id: "", status: false });
	const [activeID, setActiveID] = useState("");

	const isLoading = useSelector(isLoadingSelector);
	const resetSuccessed = useSelector(isPasswordReseted);
	const resetFailed = useSelector(isPasswordFailed);

	const [passwordState, dispatchPassword] = useReducer(passwordReducer, {
		value: "",
		isValid: null,
	});

	const [confirmPasswordState, dispatchConfirmPassword] = useReducer(passwordReducer, {
		value: "",
		isValid: null,
	});

	const { isValid: isPasswordValid } = passwordState;
	const { isValid: isConfirmPasswordValid } = confirmPasswordState;
	const isFormValid = isPasswordValid && isConfirmPasswordValid && passwordState.value === confirmPasswordState.value;

	const passwordChangeHandler = (event) => {
		dispatchPassword({
			type: "USER_INPUT",
			val: event.target.value,
		});
	};

	const validatePasswordHandler = () => {
		dispatchPassword({ type: "INPUT_BLUR" });
	};

	const confirmPasswordChangeHandler = (event) => {
		dispatchConfirmPassword({
			type: "USER_INPUT",
			val: event.target.value,
		});
	};

	const validateConfirmPasswordHandler = () => {
		dispatchConfirmPassword({ type: "INPUT_BLUR" });
	};

	/**
	 * reset password a la soumission du formulaire si tout est valide si non focus sur l'erreur
	 * @param event
	 */
	const submitHandler = (event) => {
		event.preventDefault();
		if (isFormValid) {
			if (!resetSuccessed) {
				dispatch(
					resetPassword({
						new_password1: passwordState.value,
						new_password2: confirmPasswordState.value,
						uid: uid,
						token: token,
					})
				);
			} else {
				navigate({ pathname: `/sign-in`, search: queryParams.toString() }, { replace: true });
			}
		} else if (!isPasswordValid) {
			passwordInputRef.current.focus();
		} else {
			confirmPasswordInputRef.current.focus();
		}
	};

	const hoverHandler = (id) => {
		if (closingDrop.status === false && closingDrop.id !== id) {
			setActiveID(id);
		}
	};

	const outHandler = () => {
		setActiveID("");
		setTimeout(
			() =>
				setCLosingDrop({
					id: "",
					status: false,
				}),
			300
		);
	};

	const onLocaliseChange = (item) => {
		i18n.changeLanguage(item.children);
	};

	const closeDrop = () => {
		setCLosingDrop({ id: activeID, status: true });
		setActiveID("");
	};

	return (
		<div className={classes.wrapper}>
			<header>
				<div className={classes.innerHeader}>
					<div className={classes.logo}>
						<CustomNavLink to={`/${lngParm}`}>
							<img src={lngId.includes("fr") ? logo_fr : logo_en} alt="logo" />
						</CustomNavLink>
						<div
							id={"8"}
							className={classes.rli}
							onMouseOver={() => {
								hoverHandler("8");
							}}
							onMouseOut={outHandler}
						>
							<p className={activeID === "8" ? classes["active"] : ""}>{lngId}</p>
							<NavDrop
								right={"0"}
								parentId={"8"}
								activeID={activeID}
								height={"9rem"}
								width={"5rem"}
								hide={closeDrop}
								data={[
									{ id: "13", children: "fr-ca" },
									{ id: "14", children: "en-ca" },
									{ id: "15", children: "en-us" },
								]}
								onClick={onLocaliseChange}
							/>
						</div>
					</div>
				</div>
			</header>
			<main>
				<div className={classes.innerMain}>
					<div className={classes.container}>
						<div className={classes.title}>
							<h1>{t(keys.AUTH.FORGOT_PASSWORD.PASSWORD_RESET)}</h1>
						</div>
						<div className={classes.content}>
							{resetSuccessed && <p>{t(keys.AUTH.FORGOT_PASSWORD.PASSWORD_RESET_DESCRIPTION)}</p>}
							<div className={classes.formWrapper}>
								<form onSubmit={submitHandler}>
									<fieldset>
										<legend>
											{!resetSuccessed
												? t(keys.AUTH.FORGOT_PASSWORD.REQUEST_RESET_LINK)
												: t(keys.AUTH.FORGOT_PASSWORD.RESEND_REQUEST)}
										</legend>
										{!resetSuccessed && (
											<TextInputOverlay
												ref={passwordInputRef}
												id="password"
												label={t(keys.AUTH.SIGN_IN.PASSWORD)}
												type="password"
												isValid={isPasswordValid}
												value={passwordState.value}
												info={t(keys.AUTH.SIGN_UP.PASSWORD_CONDITIONS)}
												error={t(keys.AUTH.SIGN_IN.WRONG_PASSWORD)}
												onChange={passwordChangeHandler}
												onBlur={validatePasswordHandler}
												strenthBar
											/>
										)}
										{!resetSuccessed && (
											<TextInputOverlay
												ref={confirmPasswordInputRef}
												id="confirm"
												label={t(keys.AUTH.FORGOT_PASSWORD.CONFIRM_PASSWORD)}
												type="password"
												isValid={isConfirmPasswordValid}
												value={confirmPasswordState.value}
												error={t(keys.AUTH.SIGN_IN.WRONG_PASSWORD)}
												onChange={confirmPasswordChangeHandler}
												onBlur={validateConfirmPasswordHandler}
											/>
										)}
										{isPasswordValid === true && isFormValid === false && (
											<div className={classes.error}>
												<p>{t(keys.AUTH.SIGN_IN.SIGN_IN_FAILED)}</p>
											</div>
										)}
										<div className={classes.resetPassword}>
											<div className={classes.resetPassword_btn}>
												<button
													className={classes[!!isLoading ? "disable" : ""]}
													type="submit"
													disabled={!!isLoading}
												>
													{!resetSuccessed
														? t(keys.AUTH.FORGOT_PASSWORD.SUBMIT)
														: t(keys.AUTH.FORGOT_PASSWORD.LOGIN)}
												</button>
											</div>
										</div>
										{resetFailed && (
											<div className={classes.error}>{t(keys.AUTH.FORGOT_PASSWORD.ERROR)}</div>
										)}
									</fieldset>
								</form>
							</div>
						</div>
					</div>
				</div>
			</main>
			<footer>
				<div className={classes.innerFooter}>
					<div className={classes.logoFooter}>
						<img src={lngId.includes("fr") ? nologo_fr : nologo_en} alt="logo" />
					</div>
					<p className={classes.rightFooter}>Copyright © 2023 Adfast. All Rights Reserved.</p>
					<div className={classes.linksFooter}>
						<CustomNavLink to={`/term_privacy${lngParm}`}>{t(keys.AUTH.SIGN_UP.TERMS)}</CustomNavLink>
						<CustomNavLink to={"/contact-us"}>{t(keys.GLOBAL.FOOTER.CONTACT_US)}</CustomNavLink>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default ResetPassword;
