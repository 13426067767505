import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";

import CustomNavLink from "../ui/customNavLink/CustomNavLink";

import { constants } from "../../locales/constant";

import classes from "./Footer.module.scss";

const Footer = () => {
	const data = constants.footer_sections;

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const search = `?lng=${lngId}`;

	const [activeFooter, setActiveFooter] = useState(0);

	/**
	 * modifier la langue actuel de l'application par rapport au choix de l'utilsateur
	 * @param newLngId
	 */
	const changeLanguage = (newLngId) => {
		i18n.changeLanguage(newLngId);
	};

	const activeHandler = (id) => {
		if (id === activeFooter) setActiveFooter(0);
		else setActiveFooter(id);
	};

	return (
		<div className={classes.footerContainer}>
			<div className={classes.footerNavContainer}>
				{data.map((section) => (
					<div key={section.id} className={classes.footerNavSection}>
						<h3 className={classes.footerNavHeader}>{t(section.name)}</h3>
						<div>
							{section.elements.map((element) => (
								<CustomNavLink
									key={element.id}
									className={classes.footerNavElement}
									to={(lngId.includes("fr") ? element.path_fr : element.path_en) ?? element.path}
									target={element.target}
								>
									{t(element.title)}
								</CustomNavLink>
							))}
						</div>
					</div>
				))}
			</div>
			<div className={classes.footerNavContainerMobile}>
				{data.map((section) => (
					<div className={classes.address}>
						<div
							key={section.id}
							className={`${classes.title} ${classes[section.id === activeFooter ? "active" : ""]}`}
							style={{
								borderTop:
									section.id === 1 || activeFooter + 1 === section.id
										? "1px solid #8c8c8c"
										: undefined,
							}}
						>
							<div onClick={() => activeHandler(section.id)} className={classes.description}>
								{t(section.name)}
							</div>
							<Icon
								onClick={() => activeHandler(section.id)}
								className={classes.arrow}
								icon={section.id === activeFooter ? "ic:outline-minus" : "ic:baseline-plus"}
							/>
						</div>
						<div
							className={`${classes.details} ${classes[section.id === activeFooter ? "active" : ""]}`}
							style={{
								height: section.id === activeFooter ? "100%" : "0px",
								display: section.id !== activeFooter ? "none" : "initial",
							}}
						>
							{section.elements.map((element) => (
								<CustomNavLink
									key={element.id}
									className={classes.footerNavElement}
									to={(lngId.includes("fr") ? element.path_fr : element.path_en) ?? element.path}
									target={element.target}
								>
									{t(element.title)}
								</CustomNavLink>
							))}
						</div>
					</div>
				))}
			</div>
			<div className={classes.footerMediaContainer}>
				<div className={classes.footerSocialContainer}>
					<CustomNavLink to={lngId.includes("ca") ? "https://www.facebook.com/AdfastCorp/" : "https://www.facebook.com/Adfastusa"}
									    target={"_blank"}>
						<Icon icon="mdi:facebook" className={classes.footerSocialIcon} />
					</CustomNavLink>
					<CustomNavLink
						to={ lngId.includes("ca") ? "https://www.linkedin.com/company/adfast-corp/posts/?feedView=all&viewAsMember=true" : "https://www.linkedin.com/company/adfast-usa/?viewAsMember=true"}
						target={"_blank"}
					>
						<Icon icon="mdi:linkedin" className={classes.footerSocialIcon} />
					</CustomNavLink>
					<CustomNavLink to="https://www.youtube.com/user/AdfastCorp" target={"_blank"}>
						<Icon icon="mdi:youtube" className={classes.footerSocialIcon} />
					</CustomNavLink>
					<CustomNavLink to={lngId.includes("ca") ? "https://www.instagram.com/adfast_corp/" : "https://www.instagram.com/adfast_store_usa/"}
									   target={"_blank"}>
						<Icon icon="mdi:instagram" className={classes.footerSocialIcon} />
					</CustomNavLink>
					<CustomNavLink to={lngId.includes("ca") ? "https://www.tiktok.com/@adfast_quebec" : "https://www.tiktok.com/@adfast_store"}
									    target={"_blank"}>
						<Icon icon="bx:bxl-tiktok" className={classes.footerSocialIcon} />
					</CustomNavLink>
				</div>
				<div className={`${classes.footerLanguageContainer} ${classes.desktopHidden}`}>
					<p className={classes.footerClickable}>English</p>
					<p className={classes.footerVerticalBar}>|</p>
					<p className={classes.footerClickable}>Français</p>
				</div>
			</div>
			<hr />
			<div className={classes.footerBottomContainer}>
				<div className={classes.footerCopyrightContainer}>
					<p>Copyright © 2023 Adfast All rights reserved.</p>
				</div>
				<div className={`${classes.footerLanguageContainer} ${classes.mobileHidden}`}>
					<p className={classes.footerClickable} onClick={() => changeLanguage("en-ca")} defaultValue="en-ca">
						English
					</p>
					<p className={classes.footerVerticalBar}>|</p>
					<p className={classes.footerClickable} onClick={() => changeLanguage("fr-ca")} defaultValue="fr-ca">
						Français
					</p>
				</div>
			</div>
			<div className={classes.footerAlignLeft}>
				<CustomNavLink to={`term_privacy${search}`} className={classes.footerClickable}>
					{t(constants.footer_options.privacy)}
				</CustomNavLink>
				<p className={classes.footerVerticalBar}>|</p>
				<p className={classes.footerClickable}>{t(constants.footer_options.sitemap)}</p>
			</div>
		</div>
	);
};

export default Footer;
