import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { eventsSelector, getWebinar, isLoadingSelector } from "../../../store/architect";
import { webinarHeaderSelector } from "../../../store/mediaBase";
import React, { Fragment, useEffect } from "react";
import Loading from "../../ui/loading/Loading";
import classes from "./Webinar.module.scss";
import HeaderCarousel from "../../ui/HeaderCarousel/HeaderCarousel";
import { keys } from "../../../locales/localeskeys";
import CustomNavLink from "../../ui/customNavLink/CustomNavLink";
import Button from "../../ui/Button/Button";
import Accordion from "../../ui/Accordion/Accordion";
import LinkTree from "../../ui/LinkTree/LinkTree";
import Resources from "../Resources/Resources";

const Webinar = () => {
	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const dispatch = useDispatch();

	const events = useSelector(eventsSelector);
	const webinarIsLoading = useSelector(isLoadingSelector);
	const webinarHeader = useSelector(webinarHeaderSelector);

	var months = [
		t(keys.GLOBAL.COMMON.JANUARY),
		t(keys.GLOBAL.COMMON.FEBRUARY),
		t(keys.GLOBAL.COMMON.MARCH),
		t(keys.GLOBAL.COMMON.APRIL),
		t(keys.GLOBAL.COMMON.MAY),
		t(keys.GLOBAL.COMMON.JUNE),
		t(keys.GLOBAL.COMMON.JULY),
		t(keys.GLOBAL.COMMON.AUGUST),
		t(keys.GLOBAL.COMMON.SEPTEMBER),
		t(keys.GLOBAL.COMMON.OCTOBER),
		t(keys.GLOBAL.COMMON.NOVEMBER),
		t(keys.GLOBAL.COMMON.DECEMBER),
	];

	useEffect(() => {
		dispatch(getWebinar());
	}, []);

	const timeFormat = (time) => {
		let hours = parseInt(time.substr(0, 2));
		const minutes = parseInt(time.substr(3, 2));
		const ampm = hours >= 12 ? "pm" : "am";
		hours = hours % 12;
		hours = hours ? hours : 12;
		return hours + ":" + (minutes < 10 ? "0" : "") + minutes + " " + ampm;
	};

	return (
		<Fragment>
			{(!webinarHeader || webinarIsLoading) && <Loading className={classes.loading} type={"bounce"} />}
			{!webinarIsLoading && events && events.length > 0 && (
				<Fragment>
					<HeaderCarousel className={classes.header} data={webinarHeader} />
					<div className={classes.layout}>
						<LinkTree
							className={classes.Tree}
							tree={[
								{ label: t(keys.GLOBAL.NAVBAR.HOME), to: "/" },
								{ label: t(keys.ARCHITECT.TITLE), to: "/architect/" },
								{ label: t(keys.ARCHITECT.WEBINARTXT), to: "/architect/webinar/" },
							]}
						/>
						<h1 className={classes.title}>{t(keys.ARCHITECT.WEBINAR.TITLE)}</h1>
						{webinarHeader && webinarHeader.length > 0 && (
							<Fragment>
								{(lngId.includes("fr")
									? webinarHeader[0].description_fr
									: webinarHeader[0].description_en
								)
									?.split("#")
									?.map((str, index) => (
										<p className={classes.description} key={index}>
											{str}
										</p>
									))}
							</Fragment>
						)}
						<div className={classes.center}>
							<h2>{t(keys.ARCHITECT.WEBINAR.UPCOMING)}</h2>
							<hr />
						</div>
						<Accordion
							className={classes.events}
							headerClass={classes.headerClass}
							iconPlusMinus={classes.iconPlusMinus}
							accordionBody={classes.accordionBody}
							header={t(keys.ARCHITECT.WEBINAR.QUEBEC)}
							dark
						>
							{events
								.filter((e) => e.zone === 0)
								.map((event) => (
									<div key={event.id} className={classes.event}>
										{event.date && (
											<div className={classes.date}>
												<p>{Number(event.date.substring(8, 10))}</p>
												<p className={classes.month}>
													{months[Number(event.date.substring(5, 7)) - 1]}
												</p>
											</div>
										)}
										<div className={classes.details}>
											<h2 key={event.id}>
												{lngId.includes("fr") ? event.title_fr : event.title_en}
											</h2>
											{event.date && (
												<div className={classes.mobileDate}>
													<p>{t(keys.ARCHITECT.WEBINAR.DATE)} :</p>
													<p className={classes.value}> {event.date}</p>
												</div>
											)}
											<div className={classes.addInfo}>
												{event.presenter && <p>{t(keys.GLOBAL.COMMON.BY) + event.presenter}</p>}
												{event.from_time && event.to_time && (
													<p>
														{t(keys.GLOBAL.COMMON.TIME) +
															": " +
															timeFormat(event.from_time) +
															" - " +
															timeFormat(event.to_time) +
															" EST"}
													</p>
												)}
												<p>
													{event.location === 0
														? t(keys.ARCHITECT.WEBINAR.ON_LIGNE)
														: t(keys.ARCHITECT.WEBINAR.IN_PERSON)}
												</p>
												<p>
													{t(keys.GLOBAL.COMMON.LANGUAGE) +
														": " +
														(event.language && event.language.includes("fr")
															? t(keys.GLOBAL.COMMON.FRENCH)
															: t(keys.GLOBAL.COMMON.ENGLISH))}
												</p>
											</div>
										</div>
										<div className={classes.register}>
											<CustomNavLink
												to={lngId.includes("fr") ? event.url_fr : event.url_en}
												target={"_blank"}
											>
												<Button className={classes.content} color={"outlineBlack"}>
													{t(keys.ARCHITECT.WEBINAR.REGISTER)}
												</Button>
											</CustomNavLink>
										</div>
									</div>
								))}
						</Accordion>
						<Accordion
							className={classes.events}
							headerClass={classes.headerClass}
							iconPlusMinus={classes.iconPlusMinus}
							accordionBody={classes.accordionBody}
							header={t(keys.ARCHITECT.WEBINAR.CANADA)}
							dark
						>
							{events
								.filter((e) => e.zone === 1)
								.map((event) => (
									<div key={event.id} className={classes.event}>
										{event.date && (
											<div className={classes.date}>
												<p>{Number(event.date.substring(8, 10))}</p>
												<p className={classes.month}>
													{months[Number(event.date.substring(5, 7)) - 1]}
												</p>
											</div>
										)}
										<div className={classes.details}>
											<h2 key={event.id}>
												{lngId.includes("fr") ? event.title_fr : event.title_en}
											</h2>
											{event.date && (
												<div className={classes.mobileDate}>
													<p>Date :</p>
													<p className={classes.value}> {event.date}</p>
												</div>
											)}
											<div className={classes.addInfo}>
												{event.presenter && <p>{t(keys.GLOBAL.COMMON.BY) + event.presenter}</p>}
												{event.from_time && event.to_time && (
													<p>
														{t(keys.GLOBAL.COMMON.TIME) +
															": " +
															timeFormat(event.from_time) +
															" - " +
															timeFormat(event.to_time) +
															" EST"}
													</p>
												)}
												<p>
													{event.location === 0
														? t(keys.ARCHITECT.WEBINAR.ON_LIGNE)
														: t(keys.ARCHITECT.WEBINAR.IN_PERSON)}
												</p>
												<p>
													{t(keys.GLOBAL.COMMON.LANGUAGE) +
														": " +
														(event.language && event.language.includes("fr")
															? t(keys.GLOBAL.COMMON.FRENCH)
															: t(keys.GLOBAL.COMMON.ENGLISH))}
												</p>
											</div>
										</div>
										<div className={classes.register}>
											<CustomNavLink
												to={lngId.includes("fr") ? event.url_fr : event.url_en}
												target={"_blank"}
											>
												<Button className={classes.content} color={"outlineBlack"}>
													{t(keys.ARCHITECT.WEBINAR.REGISTER)}
												</Button>
											</CustomNavLink>
										</div>
									</div>
								))}
						</Accordion>
						<Accordion
							className={classes.events}
							headerClass={classes.headerClass}
							iconPlusMinus={classes.iconPlusMinus}
							accordionBody={classes.accordionBody}
							header={t(keys.ARCHITECT.WEBINAR.USA)}
							dark
						>
							{events
								.filter((e) => e.zone === 2)
								.map((event) => (
									<div key={event.id} className={classes.event}>
										{event.date && (
											<div className={classes.date}>
												<p>{Number(event.date.substring(8, 10))}</p>
												<p className={classes.month}>
													{months[Number(event.date.substring(5, 7)) - 1]}
												</p>
											</div>
										)}
										<div className={classes.details}>
											<h2 key={event.id}>
												{lngId.includes("fr") ? event.title_fr : event.title_en}
											</h2>
											{event.date && (
												<div className={classes.mobileDate}>
													<p>Date :</p>
													<p className={classes.value}> {event.date}</p>
												</div>
											)}
											<div className={classes.addInfo}>
												{event.presenter && <p>{t(keys.GLOBAL.COMMON.BY) + event.presenter}</p>}
												{event.from_time && event.to_time && (
													<p>
														{t(keys.GLOBAL.COMMON.TIME) +
															": " +
															timeFormat(event.from_time) +
															" - " +
															timeFormat(event.to_time) +
															" EST"}
													</p>
												)}
												<p>
													{event.location === 0
														? t(keys.ARCHITECT.WEBINAR.ON_LIGNE)
														: t(keys.ARCHITECT.WEBINAR.IN_PERSON)}
												</p>
												<p>
													{t(keys.GLOBAL.COMMON.LANGUAGE) +
														": " +
														(event.language && event.language.includes("fr")
															? t(keys.GLOBAL.COMMON.FRENCH)
															: t(keys.GLOBAL.COMMON.ENGLISH))}
												</p>
											</div>
										</div>
										<div className={classes.register}>
											<CustomNavLink
												to={lngId.includes("fr") ? event.url_fr : event.url_en}
												target={"_blank"}
											>
												<Button className={classes.content} color={"outlineBlack"}>
													{t(keys.ARCHITECT.WEBINAR.REGISTER)}
												</Button>
											</CustomNavLink>
										</div>
									</div>
								))}
						</Accordion>
						<Accordion
							className={classes.events}
							headerClass={classes.headerClass}
							iconPlusMinus={classes.iconPlusMinus}
							accordionBody={classes.accordionBody}
							header={t(keys.ARCHITECT.WEBINAR.ATS)}
							dark
						>
							{events
								.filter((e) => e.zone === 3)
								.map((event) => (
									<div key={event.id} className={classes.event}>
										{event.date && (
											<div className={classes.date}>
												<p>{Number(event.date.substring(8, 10))}</p>
												<p className={classes.month}>
													{months[Number(event.date.substring(5, 7)) - 1]}
												</p>
											</div>
										)}
										<div className={classes.details}>
											<h2 key={event.id}>
												{lngId.includes("fr") ? event.title_fr : event.title_en}
											</h2>
											{event.date && (
												<div className={classes.mobileDate}>
													<p>Date :</p>
													<p className={classes.value}> {event.date}</p>
												</div>
											)}
											<div className={classes.addInfo}>
												{event.presenter && <p>{t(keys.GLOBAL.COMMON.BY) + event.presenter}</p>}
												{event.from_time && event.to_time && (
													<p>
														{t(keys.GLOBAL.COMMON.TIME) +
															": " +
															timeFormat(event.from_time) +
															" - " +
															timeFormat(event.to_time) +
															" EST"}
													</p>
												)}
												<p>
													{event.location === 0
														? t(keys.ARCHITECT.WEBINAR.ON_LIGNE)
														: t(keys.ARCHITECT.WEBINAR.IN_PERSON)}
												</p>
												<p>
													{t(keys.GLOBAL.COMMON.LANGUAGE) +
														": " +
														(event.language && event.language.includes("fr")
															? t(keys.GLOBAL.COMMON.FRENCH)
															: t(keys.GLOBAL.COMMON.ENGLISH))}
												</p>
											</div>
										</div>
										<div className={classes.register}>
											<CustomNavLink
												to={lngId.includes("fr") ? event.url_fr : event.url_en}
												target={"_blank"}
											>
												<Button className={classes.content} color={"outlineBlack"}>
													{t(keys.ARCHITECT.WEBINAR.REGISTER)}
												</Button>
											</CustomNavLink>
										</div>
									</div>
								))}
						</Accordion>
						<div className={classes.resouces}>
							<Resources />
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default Webinar;
