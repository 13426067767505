import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { categoriesLoadingSelector, categoriesSelector } from "../../../../store/productCategory";
import { brandSelector, branssLoadingSelector } from "../../../../store/productBrand";
import { subsegmentSelector, subsegmentsLoadingSelector } from "../../../../store/subSegment";
import { itemIsLoadingSelector } from "../../../../store/product";
import { clearAll } from "../../../../store/productQuery";

import Button from "../../../ui/Button/Button";
import Category from "./Category/Category";
import Industries from "./Industries/Industries";
import Brand from "./Brand/Brand";

import { keys } from "../../../../locales/localeskeys";

import classes from "./ProductSideBar.module.scss";

const ProductSideBar = () => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const [searchParams, setSearchParams] = useSearchParams();

	const categoriesLoading = useSelector(categoriesLoadingSelector);
	const brandsLoading = useSelector(branssLoadingSelector);
	const industriesLoading = useSelector(subsegmentsLoadingSelector);
	const categorySelector = useSelector(categoriesSelector);
	const brandsSelector = useSelector(brandSelector);
	const segmentsSector = useSelector(subsegmentSelector);
	const itemIsLoading = useSelector(itemIsLoadingSelector);

	const clear = () => {
		dispatch(clearAll());
		searchParams.delete("categories");
		searchParams.delete("brands");
		searchParams.delete("industries");
		searchParams.delete("page");
		setSearchParams(searchParams);
	};

	return (
		<div className={classes.sidebarContainer}>
			<Button className={classes.btn} color={"outlineBlue"} disabled={itemIsLoading} onClick={clear}>
				{t(keys.PRODUCTS.RESET_FILTERS)}
			</Button>
			{!categoriesLoading && categorySelector.length > 0 && <Category />}
			{!brandsLoading && brandsSelector.length > 0 && <Brand />}
			{!industriesLoading && segmentsSector.length > 0 && <Industries />}
		</div>
	);
};

export default ProductSideBar;
