import React, { useEffect, useMemo, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

import { CheckboxInput, Tag } from "../";

const useStyles = makeStyles({
	chips: {
		display: "flex",
		columnGap: "0.625rem",
	},
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
	getContentAnchorEl: null,
	anchorOrigin: {
		vertical: "bottom",
		horizontal: "left",
	},
	transformOrigin: {
		vertical: "top",
		horizontal: "left",
	},
};

export const MultiSelectDropdown = ({ id, label, value, options, onChange }) => {
	const classes = useStyles();

	const [selectedOptions, setSelectedOptions] = useState([]);

	const handleOnChange = (event) => {
		setSelectedOptions(event.target.value);
		onChange(event);
	};

	const additionalTags = useMemo(() => {
		const maxTagsToShow = 2;
		if (selectedOptions.length > maxTagsToShow) {
			return selectedOptions.length - maxTagsToShow;
		}
		return 0;
	});

	useEffect(() => {
		setSelectedOptions(value);
	}, [value, options]);

	return (
		<FormControl className={classes.formControl}>
			<InputLabel id={id}>{label}</InputLabel>
			<Select
				labelId={id}
				multiple
				value={selectedOptions}
				onChange={handleOnChange}
				renderValue={(selected) => (
					<div className={classes.chips}>
						{selected.slice(0, 2).map((item, key) => (
							<Tag key={key} label={item} className={classes.chip} />
						))}
						{additionalTags > 0 && <Tag label={`+${additionalTags}`} className={classes.chip} />}
					</div>
				)}
				MenuProps={MenuProps}
			>
				{options.map((option, key) => (
					<MenuItem key={key} value={option}>
						<CheckboxInput
							id={`${id}_option_${key}`}
							label={option}
							value={option}
							checked={selectedOptions.indexOf(option) > -1}
						/>
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
