import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";

import Button from "../../ui/Button/Button";
import TextInput from "../../ui/Input/TextInput/TextInput";
import CustomNavLink from "../../ui/customNavLink/CustomNavLink";

import { copyLine, deleteLine, isLoadingSelector, updateLine } from "../../../store/cart";

import { keys } from "../../../locales/localeskeys";
import { constants as constant } from "../../../locales/constant";

import classes from "./CartItem.module.scss";

const CartItem = (props) => {
	const dispatch = useDispatch();

	const isCartLoading = useSelector(isLoadingSelector);

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;
	const search = `?lng=${lngId}`;

	const [image, setImage] = useState("");
	const [item, setItem] = useState("");
	const [packaging, setPackaging] = useState("");
	const [qty, setQty] = useState({
		value: Number(props.qty),
		previous: Number(props.qty),
	});
	const [qty_pack, setQty_pack] = useState({
		value: Number(props.qty_pack),
		previous: Number(props.qty_pack),
	});
	const [colorHex, setColorHex] = useState("");
	const [colorName, setColorName] = useState(" ");
	const [amount, setAmount] = useState();
	const [unitAmount, setUnitAmount] = useState();
	const [shippingRange, setShippingRange] = useState("");
	const [packagingFormat, setPackagingFormat] = useState("");
	const [packagingFormatSingulier, setPackagingFormatSingulier] = useState("");
	const [displayFormat, setDisplayFormat] = useState(false);

	useEffect(() => {
		if (props.variant) {
			switch (props.variant.format?.packaging) {
				case 1:
					setPackaging(t(keys.GLOBAL.COMMON.BAG));
					setPackagingFormatSingulier(t(keys.GLOBAL.COMMON.BAG));
					setDisplayFormat(false);
					break;
				case 2:
					setPackaging(t(keys.GLOBAL.COMMON.BARREL));
					setPackagingFormatSingulier(t(keys.GLOBAL.COMMON.BARREL));
					setDisplayFormat(false);
					break;
				case 3:
					setPackaging(t(keys.GLOBAL.COMMON.BOX));
					setPackagingFormatSingulier(t(keys.GLOBAL.COMMON.BOX));
					setDisplayFormat(true);
					break;
				case 4:
					setPackaging(t(keys.GLOBAL.COMMON.EACH));
					setPackagingFormatSingulier(t(keys.GLOBAL.COMMON.EACH));
					setDisplayFormat(false);
					break;
				case 5:
					setPackaging(t(keys.GLOBAL.COMMON.CANE));
					setPackagingFormatSingulier(t(keys.GLOBAL.COMMON.CANE));
					setDisplayFormat(false);
					break;
				case 6:
					setPackaging(t(keys.GLOBAL.COMMON.DRUM));
					setPackagingFormatSingulier(t(keys.GLOBAL.COMMON.DRUM));
					setDisplayFormat(false);
					break;
				case 7:
					setPackaging(t(keys.GLOBAL.COMMON.PAIL));
					setPackagingFormatSingulier(t(keys.GLOBAL.COMMON.PAIL));
					setDisplayFormat(false);
					break;
				case 8:
					setPackaging(t(keys.GLOBAL.COMMON.TOTE));
					setPackagingFormatSingulier(t(keys.GLOBAL.COMMON.TOTE));
					setDisplayFormat(false);
					break;
				default:
					setPackaging(t(keys.GLOBAL.COMMON.BOX));
					setPackagingFormatSingulier(t(keys.GLOBAL.COMMON.BOX));
					setDisplayFormat(true);
					break;
			}

			setColorHex(props.variant?.specification?.hex);
			if (props.variant.specification?.erp_code)
				setColorName(
					lngId.includes("fr")
						? `${props.variant.specification?.erp_code} ${props.variant?.specification?.description_fr}`
						: `${props.variant.specification?.erp_code} ${props.variant?.specification?.description_en}`
				);
		}
	}, [t, lngId, props.variant, props.qty]);

	useEffect(() => {
		if (props.item) {
			setItem(`${props.item?.erp_code} ${props.variant?.brand ? props.variant?.brand?.erp_code : ""}`);
		}
	}, [lngId, props.variant?.item, props.variant?.brand]);

	useEffect(() => {
		if (props.images) {
			let docs = props.images;
			if (docs) {
				let overviewImages = docs.filter((doc) => doc.type === 3);
				if (overviewImages.length > 0) docs = overviewImages;
				if (props.variant?.specification?.id) {
					overviewImages = docs.filter(
						(doc) => doc.specification && doc.specification.id === props.variant?.specification?.id
					);
					if (overviewImages.length > 0) docs = overviewImages;
				}
				if (props.variant?.format?.id) {
					overviewImages = docs.filter((doc) => doc.format && doc.format.id === props.variant?.format?.id);
					if (overviewImages.length > 0) docs = overviewImages;
				}
				if (props.variant?.brand?.id) {
					overviewImages = docs.filter((doc) => doc.brand && doc.brand.id === props.variant?.brand?.id);
				}
				if (overviewImages && overviewImages.length > 0) setImage(overviewImages[0].url);
				else setImage(constant.DEFAULT_IMG);
			}
		}
	}, [props.images, props.variant, lngId]);

	useEffect(() => {
		let amountStr = props.amount
			.toFixed(2)
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		amountStr = lngId.includes("fr") ? `${amountStr.replace(".", ",")}$` : `$${amountStr}`;
		setAmount(amountStr);
	}, [lngId, props.amount]);

	useEffect(() => {
		let unitAmountStr = props.unitAmount
			.toFixed(2)
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		unitAmountStr = lngId.includes("fr") ? `${unitAmountStr.replace(".", ",")}$` : `$${unitAmountStr}`;
		setUnitAmount(unitAmountStr);
	}, [lngId, props.amount]);

	useEffect(() => {
		if (props.hideButtons || qty.value === qty.previous) return;
		const timer = setTimeout(() => {
			dispatch(
				updateLine({
					id: props.id,
					variant_id: props.variant.id,
					qty: Number(qty.value),
					qty_pack: Number(qty_pack.value),
					unitAmount: Number(props.unitAmount),
					amount: (Number(qty.value) * Number(props.unitAmount)).toFixed(2),
					cartOpen: false,
				})
			);
		}, 350);
		return () => clearTimeout(timer);
	}, [qty.value, qty.previous]);

	useEffect(() => {
		const leadtime =
			props.variant &&
			props.variant?.on_hand &&
			props.variant?.on_hand.length > 0 &&
			props.variant?.on_hand[0].leadTime > 0
				? props.variant?.on_hand[0].leadTime
				: 10;

		setShippingRange(`${t(keys.PRODUCTS.VARIANT.AVAILBLE1)} ${leadtime} ${t(keys.PRODUCTS.VARIANT.AVAILBLE2)}`);
	}, [t, props.variant?.on_hand]);

	useEffect(() => {
		if (props.hideButtons || qty_pack.value === qty_pack.previous) return;
		setQty({
			value:
				props.variant &&
				props.variant?.on_hand &&
				props.variant?.on_hand.length > 0 &&
				props.variant?.on_hand[0].multiple !== 0
					? qty_pack.value * props.variant?.on_hand[0]?.multiple
					: qty_pack.value,
			previous: qty,
		});
	}, [qty_pack.value, qty_pack.previous]);

	const changeQtyHandler = (event) => {
		if (event.target.value) {
			setQty_pack({ value: parseInt(event.target.value), previous: qty_pack });
		} else {
			setQty_pack({ value: 1, previous: qty_pack });
		}
	};

	const addQty = () => {
		setQty_pack({ value: qty_pack.value + 1, previous: qty_pack });
	};

	const minusQty = () => {
		if (qty_pack.value > 1) setQty_pack({ value: qty_pack.value - 1, previous: qty_pack });
	};

	useEffect(() => {
		let packagingFormat = "";
		if (props.variant) {
			switch (props.variant.format?.packaging) {
				case 1:
					packagingFormat = t(keys.GLOBAL.COMMON.BAG);
					if (
						props.variant &&
						props.variant?.on_hand.length > 0 &&
						props.variant?.on_hand[0]?.qty > 0 &&
						props.variant?.on_hand[0]?.multiple > 0 &&
						Number(props.variant?.on_hand[0].qty) / Number(props.variant?.on_hand[0]?.multiple) > 1
					)
						setPackagingFormat(t(keys.GLOBAL.COMMON.BAGS));
					else setPackagingFormat(packagingFormat);
					break;
				case 2:
					packagingFormat = t(keys.GLOBAL.COMMON.BARREL);
					if (
						props.variant &&
						props.variant?.on_hand.length > 0 &&
						props.variant?.on_hand[0]?.qty > 0 &&
						props.variant?.on_hand[0]?.multiple > 0 &&
						Number(props.variant?.on_hand[0]?.qty) / Number(props.variant?.on_hand[0]?.multiple) > 1
					)
						setPackagingFormat(t(keys.GLOBAL.COMMON.BARRELS));
					else setPackagingFormat(packagingFormat);
					break;
				case 3:
					packagingFormat = t(keys.GLOBAL.COMMON.BOX);
					if (
						props.variant &&
						props.variant?.on_hand.length > 0 &&
						props.variant?.on_hand[0]?.qty > 0 &&
						props.variant?.on_hand[0]?.multiple > 0 &&
						Number(props.variant?.on_hand[0].qty) / Number(props.variant?.on_hand[0].multiple) > 1
					)
						setPackagingFormat(t(keys.GLOBAL.COMMON.BOXES));
					else setPackagingFormat(packagingFormat);
					break;
				case 4:
					packagingFormat = t(keys.GLOBAL.COMMON.EACH);
					if (
						props.variant &&
						props.variant?.on_hand.length > 0 &&
						props.variant?.on_hand[0]?.qty > 0 &&
						props.variant?.on_hand[0]?.multiple > 0 &&
						Number(props.variant?.on_hand[0]?.qty) / Number(props.variant?.on_hand[0]?.multiple) > 1
					)
						setPackagingFormat(t(keys.GLOBAL.COMMON.EACHS));
					else setPackagingFormat(packagingFormat);
					break;
				case 5:
					packagingFormat = t(keys.GLOBAL.COMMON.CANE);
					if (
						props.variant &&
						props.variant?.on_hand.length > 0 &&
						props.variant?.on_hand[0]?.qty > 0 &&
						props.variant?.on_hand[0]?.multiple > 0 &&
						Number(props.variant?.on_hand[0]?.qty) / Number(props.variant?.on_hand[0]?.multiple) > 1
					)
						setPackagingFormat(t(keys.GLOBAL.COMMON.CANES));
					else setPackagingFormat(packagingFormat);
					break;
				case 6:
					packagingFormat = t(keys.GLOBAL.COMMON.DRUM);
					if (
						props.variant &&
						props.variant?.on_hand.length > 0 &&
						props.variant?.on_hand[0]?.qty > 0 &&
						props.variant?.on_hand[0]?.multiple > 0 &&
						Number(props.variant?.on_hand[0]?.qty) / Number(props.variant?.on_hand[0]?.multiple) > 1
					)
						setPackagingFormat(t(keys.GLOBAL.COMMON.DRUMS));
					else setPackagingFormat(packagingFormat);
					break;
				case 7:
					packagingFormat = t(keys.GLOBAL.COMMON.PAIL);
					if (
						props.variant &&
						props.variant?.on_hand.length > 0 &&
						props.variant?.on_hand[0]?.qty > 0 &&
						props.variant?.on_hand[0]?.multiple > 0 &&
						Number(props.variant?.on_hand[0]?.qty) / Number(props.variant?.on_hand[0]?.multiple) > 1
					)
						setPackagingFormat(t(keys.GLOBAL.COMMON.PAILS));
					else setPackagingFormat(packagingFormat);
					break;
				case 8:
					packagingFormat = t(keys.GLOBAL.COMMON.TOTE);
					if (
						props.variant &&
						props.variant?.on_hand.length > 0 &&
						props.variant?.on_hand[0]?.qty > 0 &&
						props.variant?.on_hand[0]?.multiple > 0 &&
						Number(props.variant?.on_hand[0]?.qty) / Number(props.variant?.on_hand[0]?.multiple) > 1
					)
						setPackagingFormat(t(keys.GLOBAL.COMMON.TOTES));
					else setPackagingFormat(packagingFormat);
					break;
				default:
					props.variant &&
					props.variant?.on_hand &&
					props.variant?.on_hand?.length > 0 &&
					props.variant?.on_hand?.multiple > 1
						? (packagingFormat = t(keys.GLOBAL.COMMON.BOX))
						: (packagingFormat = t(keys.GLOBAL.COMMON.EACH));
					if (
						props.variant &&
						props.variant?.on_hand.length > 0 &&
						props.variant?.on_hand[0]?.qty > 0 &&
						props.variant?.on_hand[0]?.multiple > 0 &&
						Number(props.variant?.on_hand[0]?.qty) / Number(props.variant?.on_hand[0]?.multiple) > 1
					)
						props.variant &&
						props.variant?.on_hand &&
						props.variant?.on_hand?.length > 0 &&
						props.variant?.on_hand?.multiple > 1
							? setPackagingFormat(t(keys.GLOBAL.COMMON.BOXES))
							: setPackagingFormat(t(keys.GLOBAL.COMMON.EACHS));
					else setPackagingFormat(packagingFormat);
					break;
			}
			const multiple =
				props.variant && props.variant?.on_hand && props.variant?.on_hand.length > 0
					? props.variant?.on_hand[0].multiple
					: 1;
			const unit = multiple > 1 ? t(keys.GLOBAL.COMMON.UNITS) : t(keys.GLOBAL.COMMON.UNIT);
			setPackaging(` (${multiple} ${unit}/${packagingFormat})`);
		}
	}, [t, lngId, props.variant]);

	return (
		<Fragment>
			{!props.mobile && (
				<div className={classes.cartItem}>
					<div className={classes.cartTitle}>
						<CustomNavLink
							className={classes.item}
							to={
								props.variant?.brand
									? `/adstore/overview/${props.item?.erp_code}/${props.variant?.brand?.erp_code}/${search}`
									: `/adstore/overview/${props.item?.erp_code}/${search}`
							}
						>
							{item}
						</CustomNavLink>
						{!props.hideAmount && <div className={classes.amount}>{amount}</div>}
					</div>
					<div className={classes.catDetail}>
						{image && (
							<CustomNavLink
								to={
									props.variant?.brand
										? `/adstore/overview/${props.item?.erp_code}/${props.variant?.brand?.erp_code}/${search}`
										: `/adstore/overview/${props.item?.erp_code}/${search}`
								}
							>
								<img className={classes.productImage} src={image} alt={image} />
							</CustomNavLink>
						)}
						{!image && <Icon className={classes.productImage} icon="ant-design:file-image-outlined" />}
						<div className={classes.details}>
							<div className={classes.firstLine}>
								<CustomNavLink
									className={classes.item}
									to={
										props.variant?.brand
											? `/adstore/overview/${props.item?.erp_code}/${props.variant?.brand?.erp_code}/${search}`
											: `/adstore/overview/${props.item?.erp_code}/${search}`
									}
								>
									{item}
								</CustomNavLink>
								{!props.hideAmount && <div className={classes.amount}>{amount}</div>}
							</div>
							<div className={classes.secondLine}>
								<CustomNavLink
									className={classes.dimensions}
									to={
										props.variant?.brand && props.variant?.specification && props.variant?.format
											? `/adstore/variants/${props.item?.erp_code}/${props.variant?.brand?.erp_code}/${props.variant?.specification?.erp_code}/${props.variant?.format?.erp_code}/?lng=${lngId}`
											: `/adstore/overview/${props.item?.erp_code}/${search}`
									}
								>
									{props.variant?.specification && (
										<div className={classes.specification}>
											<div className={classes.colorDesc}>{colorName}</div>
										</div>
									)}
									{!props.resumeOrder && props.variant?.format && (
										<div className={classes.formatDesc}>{`${t(keys.CART.OVERVIEW.FORMAT)}: ${
											props.variant?.format?.erp_code
										}`}</div>
									)}
								</CustomNavLink>
								<div className={classes.price}>
									{props.qty} x {unitAmount}
								</div>
							</div>

							{props.resumeOrder && (
								<Fragment>
									{unitAmount &&
										(props.variant?.format?.description_fr ||
											props.variant?.format?.description_en) && (
											<div className={classes.detail}>
												{unitAmount}/
												{lngId.includes("fr")
													? props.variant?.format?.description_fr
													: props.variant?.format?.description_en}
											</div>
										)}
									{displayFormat && (
										<div className={classes.detail}>{`${props.variant?.on_hand[0]?.multiple} ${
											props.qty > 1 ? t(keys.GLOBAL.COMMON.UNITS) : t(keys.GLOBAL.COMMON.UNIT)
										} ${t(keys.GLOBAL.COMMON.PER)} ${packagingFormatSingulier}`}</div>
									)}
								</Fragment>
							)}
							{!props.hideStock &&
								props.variant &&
								props.variant?.on_hand &&
								props.variant?.on_hand.length > 0 &&
								props.variant?.on_hand[0]?.qty > 0 && (
									<div className={classes.onhand}>{`${(
										Number(props.variant?.on_hand[0]?.qty) /
										Number(props.variant?.on_hand[0]?.multiple)
									).toFixed(0)} ${packagingFormat} ${t(keys.CART.OVERVIEW.AVAILABLE)}`}</div>
								)}
							{!props.hideRange &&
								(!props.variant ||
									!props.variant?.on_hand ||
									props.variant?.on_hand.length <= 0 ||
									props.variant?.on_hand[0]?.qty <= 0) && (
									<div className={classes.range}>{shippingRange}</div>
								)}
							{!props.hidePackaging &&
								props.variant &&
								props.variant?.on_hand &&
								props.variant?.on_hand.length > 0 && (
									<div className={classes.qty}>{`(${packagingFormat} ${t(keys.GLOBAL.COMMON.OF)} ${
										props.variant?.on_hand[0]?.multiple
									})`}</div>
								)}
							{!props.hideButtons && (
								<div className={classes.lastLine}>
									<div className={classes.qtyButtons}>
										<Button
											id="minus"
											className={classes.buttons}
											onClick={minusQty}
											color="outlineBlack"
											size="small"
										>
											-
										</Button>
										<TextInput
											id="value"
											className={classes.textInput}
											inputClassName={classes.input}
											onChange={changeQtyHandler}
											type="number"
											value={qty_pack.value}
										/>
										<Button
											id="add"
											className={classes.buttons}
											onClick={addQty}
											color="outlineBlack"
											size="small"
										>
											+
										</Button>
									</div>
									<div
										className={classes.remove}
										onClick={() => {
											dispatch(deleteLine(props.id));
										}}
									>
										{t(keys.CART.OVERVIEW.REMOVE)}
									</div>
								</div>
							)}
							{props.buyAgain && (
								<Button
									className={classes.btnBuyAgain}
									size="big"
									disabled={isCartLoading}
									onClick={() => {
										dispatch(copyLine(props.id));
									}}
									color={"outlineBlue"}
									content={classes.btn}
								>
									{t(keys.ACCOUNT.ORDERS.BUYAGAIN)}
								</Button>
							)}
						</div>
					</div>
				</div>
			)}
			{props.mobile && (
				<div className={classes.mobileCartItem}>
					<div className={classes.header}>
						<CustomNavLink
							className={classes.item}
							to={
								props.variant.brand
									? `/adstore/overview/${props.item?.erp_code}/${props.variant?.brand?.erp_code}/${search}`
									: `/adstore/overview/${props.item?.erp_code}/${search}`
							}
						>
							{item}
						</CustomNavLink>
						<div className={classes.amount}>{amount}</div>
					</div>
					<div className={classes.product}>
						{image && (
							<CustomNavLink
								to={
									props.variant.brand
										? `/adstore/overview/${props.item?.erp_code}/${props.variant?.brand?.erp_code}/${search}`
										: `/adstore/overview/${props.item?.erp_code}/${search}`
								}
							>
								<img className={classes.productImage} src={image} alt={image} />
							</CustomNavLink>
						)}
						{!image && <Icon className={classes.productImage} icon="ant-design:file-image-outlined" />}
						<div className={classes.description}>
							<CustomNavLink
								className={classes.dimensions}
								to={
									props.variant?.brand && props.variant?.specification && props.variant?.format
										? `/adstore/variants/${props.item?.erp_code}/${props.variant?.brand?.erp_code}/${props.variant?.specification?.erp_code}/${props.variant?.format?.erp_code}/?lng=${lngId}`
										: `/adstore/overview/${props.item?.erp_code}/${search}`
								}
							>
								{props.variant?.specification?.erp_code && props.variant?.specification?.hex && (
									<div className={classes.specification}>
										<Icon
											className={classes.color}
											style={{ color: `${colorHex}` }}
											icon="akar-icons:circle-fill"
										/>
										<div className={classes.colorDesc}>{colorName}</div>
									</div>
								)}
							</CustomNavLink>
							{unitAmount &&
								(props.variant?.format?.description_fr || props.variant?.format?.description_en) && (
									<div className={classes.detail}>
										{unitAmount}/
										{lngId.includes("fr")
											? props.variant?.format?.description_fr
											: props.variant?.format?.description_en}
									</div>
								)}
							{displayFormat && (
								<div className={classes.detail}>{`${props.variant?.on_hand[0]?.multiple} ${
									props.qty > 1 ? t(keys.GLOBAL.COMMON.UNITS) : t(keys.GLOBAL.COMMON.UNIT)
								} ${t(keys.GLOBAL.COMMON.PER)} ${packagingFormatSingulier}`}</div>
							)}
							{props.variant &&
								props.variant?.on_hand &&
								props.variant?.on_hand.length > 0 &&
								props.variant?.on_hand[0]?.qty > 0 && (
									<div className={classes.onhand}>{`${(
										Number(props.variant?.on_hand[0]?.qty) /
										Number(props.variant?.on_hand[0]?.multiple)
									).toFixed(0)} ${packagingFormat} ${t(keys.CART.OVERVIEW.AVAILABLE)}`}</div>
								)}
							<div className={classes.lastLine}>
								<div className={classes.row}>
									<div className={classes.qtyButtons}>
										<Button
											id="minus"
											className={classes.buttons}
											onClick={minusQty}
											color="outlineBlack"
											size="small"
											style={{ borderRight: "0" }}
										>
											-
										</Button>
										<TextInput
											id="value"
											className={classes.textInput}
											inputClassName={classes.input}
											onChange={changeQtyHandler}
											type="number"
											value={qty_pack.value}
										/>
										<Button
											id="add"
											className={classes.buttons}
											onClick={addQty}
											color="outlineBlack"
											size="small"
											style={{ borderLeft: "0" }}
										>
											+
										</Button>
									</div>
									<h1>
										{`${props.qty} ${
											props.qty > 1 ? t(keys.GLOBAL.COMMON.UNITS) : t(keys.GLOBAL.COMMON.UNIT)
										}`}
									</h1>
								</div>
								<div
									className={classes.remove}
									onClick={() => {
										dispatch(deleteLine(props.id));
									}}
								>
									<Icon icon="ion:trash" />
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default CartItem;
