import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ArchitectHeaderSelector } from "../../../store/mediaBase";
import HeaderCarousel from "../../ui/HeaderCarousel/HeaderCarousel";
import classes from "./Architect.module.scss";
import ArchitectPicker from "./ArchitectPicker/ArchitectPicker";
import Loading from "../../ui/loading/Loading";
import React, { Fragment } from "react";
import Resources from "../Resources/Resources";

const Architect = () => {
	const { t } = useTranslation();

	const architectHeader = useSelector(ArchitectHeaderSelector);

	return (
		<Fragment>
			{!architectHeader && <Loading className={classes.loading} type={"bounce"} />}
			{architectHeader && (
				<div className={classes.headerCarousel}>
					<HeaderCarousel data={architectHeader} />
				</div>
			)}
			<div className={classes.layout}>
				<ArchitectPicker />
			</div>
			<Resources />
		</Fragment>
	);
};

export default Architect;
