import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setColorTones } from "../../../../store/variantQuery";

import classes from "./VariantSideBar.module.scss";
import {
	variantDescriptionEnSelector,
	variantDescriptionFrSelector,
	variantTitleSelector,
} from "../../../../store/variant";

const VariantSideBar = () => {
	const dispatch = useDispatch();
	const { i18n } = useTranslation();
	const lngId = i18n.language;

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const colorTonesQuery = queryParams.get("colortones");

	const title = useSelector(variantTitleSelector);
	const description_fr = useSelector(variantDescriptionFrSelector);
	const description_en = useSelector(variantDescriptionEnSelector);

	const [initComp, setInitComp] = useState(false);

	useEffect(() => {
		if (!initComp) {
			dispatch(setColorTones(colorTonesQuery ? JSON.parse(colorTonesQuery) : []));
			setInitComp(true);
		}
	}, [colorTonesQuery, initComp]);

	return (
		<div className={classes.sidebarContainer}>
			{title && (
				<div className={classes.header}>
					<div className={classes.category}>{title}</div>
					<div className={classes.itemDescription}>
						{lngId.includes("fr") ? description_fr : description_en}{" "}
					</div>
				</div>
			)}
		</div>
	);
};

export default VariantSideBar;
