import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import encodeSlash from "../../../../../hooks/encodeSlash";
import { Icon } from "@iconify/react";
import CustomNavLink from "../../../../ui/customNavLink/CustomNavLink";

import {
	addItemFavorite,
	customerFavoriteItemSelector,
	deleteItemFavorite,
	isLoadingSelector,
	isLoggedInSelector,
} from "../../../../../store/auth";
import { setQuotedOnly } from "../../../../../store/productQuery";

import { keys } from "../../../../../locales/localeskeys";

import classes from "./ProductCard.module.scss";

const ProductCard = (props) => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const search = `?lng=${lngId}`;

	const navigate = useNavigate();

	const isLoggedIn = useSelector(isLoggedInSelector);
	const isAuthLoading = useSelector(isLoadingSelector);
	const favoriteItems = useSelector(customerFavoriteItemSelector);

	const itemClickHandler = () => {
		dispatch(setQuotedOnly(false));
	};

	const linkProvider = () => {
		if (props.brandErpCode)
			if (props.searched_specification)
				return `/adstore/variants/${encodeSlash(props.itemErpCode)}/${encodeSlash(
					props.brandErpCode
				)}/${encodeSlash(props.searched_specification)}/${encodeSlash(search)}`;
			else
				return `/adstore/overview/${encodeSlash(props.itemErpCode)}/${encodeSlash(
					props.brandErpCode
				)}/${encodeSlash(search)}`;
		else return `/adstore/overview/${encodeSlash(props.itemErpCode)}/${encodeSlash(search)}`;
	};

	const addFavoriteHandler = () => {
		if (!isLoggedIn || isAuthLoading) return;
		const favoriteItem = favoriteItems.find(
			(fav) => fav.item === props.itemId && (fav.brand === props.brandId || (!props.brandId && !fav.brand))
		);
		if (favoriteItem) {
			dispatch(deleteItemFavorite(favoriteItem.id));
		} else {
			dispatch(addItemFavorite({ itemId: props.itemId, brandId: props.brandId }));
		}
	};

	return (
		<div className={classes.productCardContainer}>
			{isLoggedIn && !props.disableFavorit && (
				<Icon className={classes.productCardFavoriteOutlined} icon="ant-design:heart-outlined" />
			)}
			{isLoggedIn && !props.disableFavorit && (
				<Icon
					className={`${classes.productCardFavoriteFilled} ${
						classes[
							favoriteItems &&
							favoriteItems.some(
								(fav) =>
									fav.item === props.itemId &&
									(fav.brand === props.brandId || (!props.brandId && !fav.brand))
							)
								? "active"
								: ""
						]
					}`}
					icon="ant-design:heart-filled"
					onClick={addFavoriteHandler}
				/>
			)}
			<div>
				<CustomNavLink to={linkProvider()}>
					<img
						className={`${classes.productCardImage} ${props.viewCertificat ? classes.fixHeight : ""}`}
						src={props.defaultImage}
						alt="product.jpeg"
						onClick={itemClickHandler}
					/>
					{!props.viewCertificat && (
						<div className={classes.productCardDetails} onClick={itemClickHandler}>
							<div className={classes.productCardFamily}>
								<p>
									<span>{props.brandErpCode ? props.brandErpCode : ""}</span>
									<span>
										{" "}
										{(
											lngId.includes("fr") && props.subBrandCode_fr
												? props.subBrandCode_fr
												: props.subBrandCode
										)
											? lngId.includes("fr") && props.subBrandCode_fr
												? props.subBrandCode_fr
												: props.subBrandCode
											: ""}
									</span>
									<span> {props.itemErpCode ? props.itemErpCode : ""}</span>
								</p>
							</div>
							{props.price && (
								<div className={classes.productPrice}>
									<span className={classes.productAmount}>
										{lngId.includes("fr")
											? `${props.price.toFixed(2).toString().replace(".", ",")}$`
											: `$${props.price}`}
									</span>
									{`/${t(keys.GLOBAL.COMMON.UNIT)}`}
								</div>
							)}

							<div className={classes.productCardDescription}>
								<p>{props.ProductHeaderLabel}</p>
							</div>
							{props.colors && (
								<div className={classes.productCardColor}>
									<p>{props.colors}</p>
								</div>
							)}
							<div className={classes.productCardFormat}>
								<p>{props.formats}</p>
							</div>
						</div>
					)}
				</CustomNavLink>
				{props.viewCertificat && (
					<div className={classes.productCardDetails} onClick={itemClickHandler}>
						<div className={classes.productCardFamily}>
							<p>
								<span>{props.brandErpCode ? props.brandErpCode : ""}</span>
								<span>
									{" "}
									{(
										lngId.includes("fr") && props.subBrandCode_fr
											? props.subBrandCode_fr
											: props.subBrandCode
									)
										? lngId.includes("fr") && props.subBrandCode_fr
											? props.subBrandCode_fr
											: props.subBrandCode
										: ""}
								</span>
								<span> {props.itemErpCode ? props.itemErpCode : ""}</span>
							</p>
							{props.viewCertificat && (
								<a
									href={props.viewCertificat[0]?.url}
									target="_blank"
									className={classes.viewCertificat}
								>
									{t(keys.GLOBAL.COMMON.VIEW_CERTIFICAT)}
								</a>
							)}
						</div>
						<div className={classes.productCardAllDescription}>
							<p>{props.ProductHeaderLabel}</p>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default ProductCard;
