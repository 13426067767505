import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";

import Address from "./Address/Address";
import AddressEditor from "../../checkout/addressEditor/AddressEditor";

import {
	defaultAddressSelector,
	isLoggedInSelector,
	otherAddressesSelector,
	setPrecedentUrl,
} from "../../../store/auth";

import { keys } from "../../../locales/localeskeys";

import classes from "./Addresses.module.scss";
import LinkTree from "../../ui/LinkTree/LinkTree";

const Addresses = () => {
	const dispatch = useDispatch();

	const { i18n, t } = useTranslation();
	const lngId = i18n.language;

	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const isLoggedIn = useSelector(isLoggedInSelector);
	const defaultAddress = useSelector(defaultAddressSelector);
	const otherAddresses = useSelector(otherAddressesSelector);

	const [editingAddressId, setEditingAddressId] = useState(undefined);
	const [addingAddress, setAddingAddress] = useState(false);

	useEffect(() => {
		if (!isLoggedIn) {
			dispatch(setPrecedentUrl({ pathname: location.pathname, search: location.search }));
			navigate({ pathname: `/sign-in`, search: queryParams.toString() });
		}
	}, [isLoggedIn, lngId]);

	const closeAddressEditHandler = () => {
		setEditingAddressId(undefined);
		setAddingAddress(false);
	};

	return (
		<Fragment>
			{(addingAddress === true || editingAddressId) && (
				<AddressEditor editngAddressId={editingAddressId} closeEditAddress={closeAddressEditHandler} />
			)}
			<div className={classes.bodyContainer}>
				<h1 className={classes.title}>{t(keys.ACCOUNT.ADDRESS.TITLE)}</h1>
				<LinkTree
					className={classes.Tree}
					tree={[
						{ label: t(keys.ACCOUNT.ACCOUNT.TITLE), to: `/account?lng=${lngId}` },
						{ label: t(keys.ACCOUNT.ADDRESS.TITLE), to: `/addresses?lng=${lngId}` },
					]}
				/>
				<div className={classes.addresses}>
					<div className={classes.default} onClick={() => setAddingAddress(true)}>
						<Icon className={classes.icon} icon="carbon:add" />
						<div className={classes.description}>
							<h1>{t(keys.ACCOUNT.ADDRESS.ADD)}</h1>
						</div>
					</div>
					{defaultAddress && defaultAddress.length > 0 && (
						<Address
							{...defaultAddress[0]}
							default={true}
							edit={() => setEditingAddressId(defaultAddress[0].id)}
							close={closeAddressEditHandler}
						/>
					)}
					{otherAddresses &&
						otherAddresses.length > 0 &&
						otherAddresses.map((address) => (
							<Address
								key={address.id}
								{...address}
								edit={() => setEditingAddressId(address.id)}
								close={closeAddressEditHandler}
							/>
						))}
				</div>
			</div>
		</Fragment>
	);
};

export default Addresses;
