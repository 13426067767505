import React, { useMemo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { keys } from "../../../../../locales/localeskeys";
import { constants as constant } from "../../../../../locales/constant";

import { Button } from "../../../../../atoms/button/Button";
import CustomNavLink from "../../../../ui/customNavLink/CustomNavLink";

import { useLocalized } from "../../../../../hooks";

import { copyLine } from "../../../../../store/cart";

import classes from "./OrderLine.module.scss";

const OrderLine = ({ classes, line }) => {
	const { id, amount, qty, unitAmount, item, variant } = line;
	const { specification, format, brand } = variant || {};
	const label = `${item?.erp_code || ""} ${brand?.erp_code || ""}`;

	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;
	const { getLocalizedValueByKeyType } = useLocalized(i18n.language);
	const search = `?lng=${lngId}`;

	const [packagingFormatSingulier, setPackagingFormatSingulier] = useState("");
	const [displayFormat, setDisplayFormat] = useState(false);

	const image = useMemo(() => {
		let docs = line.overview_image || [];

		let overviewImages = docs.filter((image) => image.type === 3);
		if (overviewImages.length > 0) docs = overviewImages;

		if (variant) {
			if (specification?.id) {
				overviewImages = docs.filter((doc) => doc.specification?.id === specification.id);
				if (overviewImages.length > 0) docs = overviewImages;
			}

			if (format?.id) {
				overviewImages = docs.filter((doc) => doc.format?.id === format.id);
				if (overviewImages.length > 0) docs = overviewImages;
			}

			if (brand?.id) {
				overviewImages = docs.filter((doc) => doc.brand?.id === brand.id);
				if (overviewImages.length > 0) docs = overviewImages;
			}
		}

		return overviewImages.length > 0 ? overviewImages[0].url : constant.DEFAULT_IMG;
	}, [line]);

	const formatCurrency = (amount) => {
		const amountStr = Number(amount)
			.toFixed(2)
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return lngId.includes("fr") ? `${amountStr.replace(".", ",")}$` : `$${amountStr}`;
	};

	useEffect(() => {
		if (variant) {
			switch (format?.packaging) {
				case 1:
					setPackagingFormatSingulier(t(keys.GLOBAL.COMMON.BAG));
					setDisplayFormat(false);
					break;
				case 2:
					setPackagingFormatSingulier(t(keys.GLOBAL.COMMON.BARREL));
					setDisplayFormat(false);
					break;
				case 3:
					setPackagingFormatSingulier(t(keys.GLOBAL.COMMON.BOX));
					setDisplayFormat(true);
					break;
				case 4:
					setPackagingFormatSingulier(t(keys.GLOBAL.COMMON.EACH));
					setDisplayFormat(false);
					break;
				case 5:
					setPackagingFormatSingulier(t(keys.GLOBAL.COMMON.CANE));
					setDisplayFormat(false);
					break;
				case 6:
					setPackagingFormatSingulier(t(keys.GLOBAL.COMMON.DRUM));
					setDisplayFormat(false);
					break;
				case 7:
					setPackagingFormatSingulier(t(keys.GLOBAL.COMMON.PAIL));
					setDisplayFormat(false);
					break;
				case 8:
					setPackagingFormatSingulier(t(keys.GLOBAL.COMMON.TOTE));
					setDisplayFormat(false);
					break;
				default:
					setPackagingFormatSingulier(t(keys.GLOBAL.COMMON.BOX));
					setDisplayFormat(true);
					break;
			}
		}
	}, [t]);

	return (
		<div className={classes.detail}>
			<div className={classes.product}>
				<CustomNavLink
					to={
						brand
							? `/adstore/overview/${item.erp_code}/${brand?.erp_code}/${search}`
							: `/adstore/overview/${item.erp_code}/${search}`
					}
				>
					<img className={classes.productImage} src={image} alt={label} />
				</CustomNavLink>

				<div className={classes.info}>
					<CustomNavLink
						to={
							brand
								? `/adstore/overview/${item.erp_code}/${brand?.erp_code}/${search}`
								: `/adstore/overview/${item.erp_code}/${search}`
						}
					>
						<div className={classes.name}>{label}</div>
					</CustomNavLink>
					{specification?.erp_code && specification?.hex && (
						<a
							href={
								brand && specification && format
									? `/adstore/variants/${item?.erp_code}/${brand?.erp_code}/${specification?.erp_code}/${format?.erp_code}/${search}`
									: `/adstore/overview/${item?.erp_code}/${search}`
							}
						>
							{`${specification?.erp_code} ${getLocalizedValueByKeyType(specification, "description")}`}
						</a>
					)}
					{unitAmount && (format?.description_fr || format?.description_en) && (
						<div>
							{formatCurrency(unitAmount)} / {getLocalizedValueByKeyType(format, "description")}
						</div>
					)}
					{displayFormat && (
						<div>{`${variant?.on_hand[0]?.multiple} ${
							qty > 1 ? t(keys.GLOBAL.COMMON.UNITS) : t(keys.GLOBAL.COMMON.UNIT)
						} ${t(keys.GLOBAL.COMMON.PER)} ${packagingFormatSingulier}`}</div>
					)}
				
					<Button color="outlined" onClick={() => dispatch(copyLine(id))}>
						{t(keys.ACCOUNT.ORDERS.BUYAGAIN)}
					</Button>
				</div>

			</div>
			<div className={classes.subtotal}>
				<div>
					<div className={`${classes.number} ${classes.subtitle}`}>{formatCurrency(amount)}</div>
					<div className={`${classes.number} ${classes.caption}`}>
						{`${qty} x ${formatCurrency(unitAmount)}`}
					</div>
				</div>
				<Button color="outlined" onClick={() => dispatch(copyLine(id))}>
					{t(keys.ACCOUNT.ORDERS.BUYAGAIN)}
				</Button>
			</div>
		</div>
	);
};

OrderLine.defaultProps = {
	classes,
};

export default OrderLine;
