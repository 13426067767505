import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { constants as constant } from "../locales/constant";

/**
 * Fournir la liste des libarie
 */
export const getLibraries = createAsyncThunk(
	"library/getLibraries",
	async ({ category, search, page, pageSize, keep }, { rejectWithValue }) => {
		try {
			const queryParam =
				constant.API_SERVER +
				"/libraries/?" +
				"&page=" +
				(page && page !== "" && page !== "NaN" ? page : 1) +
				(search && search !== "" ? "&search=" + search : "") +
				(category && category !== "" ? "&category=" + category : "") +
				(pageSize && pageSize !== "" ? "&pageSize=" + pageSize : "");

			const response = await fetch(queryParam);
			if (!response.ok) {
				throw new Error("fetching brands failed");
			}
			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

const libraryAdapter = createEntityAdapter({});

const library = createSlice({
	name: "library",
	initialState: libraryAdapter.getInitialState({
		loading: "done",
		currentRequestId: undefined,
		error: undefined,
	}),
	reducers: {
		clearLibrary: (state) => {
			libraryAdapter.removeAll(state);
			state.loading = "done";
			state.currentRequestId = undefined;
			state.error = undefined;
		},
	},
	extraReducers: {
		[getLibraries.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;

				state.count = payload.count;
				state.next = payload.next;
				state.previous = payload.previous;

				if (!meta.arg.keep) libraryAdapter.removeAll(state);
				libraryAdapter.addMany(state, payload.results);
			}
			return state;
		},
		[getLibraries.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[getLibraries.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
	},
});

export default library;
export const { clearLibrary } = library.actions;
export const librarySelectors = libraryAdapter.getSelectors((state) => state?.library);
export const librarySelector = createSelector(librarySelectors.selectAll, (l) => l);
export const libraryIsLoadingSelector = (state) => state?.library?.loading === "pending";
export const libraryCountSelector = (state) => state?.library?.count;
