import { useEffect, useRef, useState } from "react";
import HTMLString from "react-html-string";
import { Icon } from "@iconify/react";

import classes from "./Accordion.module.scss";
import ToggleSwitch from "../Input/ToggleSwitch/ToggleSwitch";

const Accordion = (props) => {
	const bodyRef = useRef(null);
	const [active, setActive] = useState("");
	const [height, setHeight] = useState("0px");

	const accordionHandler = () => {
		setActive(active === "" ? "active" : "");
		setHeight(active === "active" ? "0px" : `${bodyRef.current.scrollHeight}px`);
	};
	const baseStyle = { color: props.active && props.changeColor ? "#007AFF" : "" };
	const checkedCountStyle = props.checkedCount > 0 ? { color: "#007AFF" } : {};
	const combinedStyle = { ...baseStyle, ...checkedCountStyle };

	useEffect(() => {
		if (props.data && props.data.length > 0 && props.active && props.active !== "") {
			setActive("active");
			setHeight(`${bodyRef.current.scrollHeight}px`);
		} else if (!props.data && props.active && props.active !== "") {
			setActive("active");
			setHeight(`${bodyRef.current.scrollHeight}px`);
		}
	}, [bodyRef?.current?.scrollHeight, props.data, props.data?.length, props.active]);

	return (
		<div key={props.id} className={`${classes.accordionContainer} ${props.className || ""}`}>
			<div
				onClick={accordionHandler}
				className={`${classes.accordionHeader} ${props.headerClass || ""} ${props.dark ? classes.dark : ""} ${
					active ? `${classes[active]} ${props.headerClassActive || ""}` : ""
				} ${props.toggle ? classes.toggleHeader : ""}
				`}
			>
				<h1 style={combinedStyle}>
					{props.header ? <HTMLString html={props.header} /> : ""}
					{props.checkedCount > 0 && <span> ({props.checkedCount})</span>}
				</h1>
				<Icon
					className={`${classes.iconMinus} ${props.iconPlusMinus} ${active ? classes[active] : ""} ${
						props.iconClass || ""
					}`}
					icon="bx:bx-minus"
				/>
				<Icon
					className={`${classes.iconPlus} ${props.iconPlusMinus} ${active ? classes[active] : ""} ${
						props.iconClass || ""
					}`}
					icon="bx:bx-plus"
				/>
				{props.toggle && (
					<ToggleSwitch
						onChange={props.handleToggleChange || props.onChange || (() => {})}
						checked={props.checked}
						onColor="#86d3ff"
						onHandleColor="#2693e6"
						handleDiameter={30}
						uncheckedIcon={false}
						checkedIcon={false}
						boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
						activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
						className={classes.toggle}
						disabled={props.disabled}
					/>
				)}
			</div>
			<div
				ref={bodyRef}
				style={{ height: `${height}` }}
				className={`${classes.accordionBody} ${props.accordionBody}`}
			>
				{props.children}
			</div>
		</div>
	);
};
export default Accordion;
