import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Accordion from "../ui/Accordion/Accordion";
import CustomNavLink from "../ui/customNavLink/CustomNavLink";

import { isLoggedInSelector, saveCookies, setCookie } from "../../store/auth";

import { keys } from "../../locales/localeskeys";

import classes from "./PrivacySideCart.module.scss";

const PrivacySideCart = (props) => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();

	const isLoggedIn = useSelector(isLoggedInSelector);

	const [categoryStates, setCategoryStates] = useState({
		advertising: true,
		functional: true,
		analytical: true,
	});

	const lngId = i18n.language;
	const lngParm = `?lng=${lngId}`;
	const accordionData = [
		{
			value: "advertising",
			header: t(keys.GLOBAL.COOKIE.COOKIE_CATEGORIES.ADVERTISING.HEADER),
			paragraph: t(keys.GLOBAL.COOKIE.COOKIE_CATEGORIES.ADVERTISING.DESCRIPTION),
		},
		{
			value: "functional",
			header: t(keys.GLOBAL.COOKIE.COOKIE_CATEGORIES.FUNCTIONAL.HEADER),
			paragraph: t(keys.GLOBAL.COOKIE.COOKIE_CATEGORIES.FUNCTIONAL.DESCRIPTION),
		},
		{
			value: "analytical",
			header: t(keys.GLOBAL.COOKIE.COOKIE_CATEGORIES.ANALYTICAL.HEADER),
			paragraph: t(keys.GLOBAL.COOKIE.COOKIE_CATEGORIES.ANALYTICAL.DESCRIPTION),
		},
		{
			value: "ESSENTIAL",
			header: t(keys.GLOBAL.COOKIE.COOKIE_CATEGORIES.ESSENTIAL.HEADER),
			paragraph: t(keys.GLOBAL.COOKIE.COOKIE_CATEGORIES.ESSENTIAL.DESCRIPTION),
		},
	];

	const handleToggleChange = (category) => {
		setCategoryStates((prevStates) => {
			return { ...prevStates, [category]: !prevStates[category] };
		});
	};

	const handleAcceptAllCookies = () => {
		dispatch(
			setCookie({
				Advertising: true,
				Functional: true,
				Analytical: true,
				savedCookie: true,
			})
		);

		if (isLoggedIn === true)
			dispatch(
				saveCookies({
					Advertising: true,
					Functional: true,
					Analytical: true,
					savedCookie: true,
				})
			);
	};

	const handleSavePreferences = () => {
		dispatch(
			setCookie({
				Advertising: categoryStates.advertising,
				Functional: categoryStates.functional,
				Analytical: categoryStates.analytical,
				savedCookie: true,
			})
		);

		if (isLoggedIn === true)
			dispatch(
				saveCookies({
					Advertising: categoryStates.advertising,
					Functional: categoryStates.functional,
					Analytical: categoryStates.analytical,
					savedCookie: true,
				})
			);
	};

	return (
		<Fragment>
			{props.showSideCart && (
				<div>
					<div className={classes.backdrop} />
					<div className={classes.modal}>
						<button className={classes.closeButton} onClick={props.onClose}>
							X
						</button>
						<div className={classes.manage}>
							<h1>{t(keys.GLOBAL.COOKIE.MANAGE_COOKIES)}</h1>
							<p>{t(keys.GLOBAL.COOKIE.RESPECT_PRIVACY)}</p>
						</div>
						<div className={classes.privacy}>
							<p>
								<CustomNavLink to={`/term_privacy${lngParm}`}>
									{t(keys.GLOBAL.COOKIE.PRIVACY_POLICY)}
								</CustomNavLink>
							</p>
						</div>

						{accordionData.slice(0, accordionData.length - 1).map((item, index) => (
							<Accordion
								key={index}
								className={classes.documentsSection}
								header={item.header}
								headerClass={classes.header}
								toggle={true}
								checked={categoryStates[item.value.toLowerCase()]}
								handleToggleChange={() => handleToggleChange(item.value.toLowerCase())}
							>
								<p>{item.paragraph}</p>
							</Accordion>
						))}
						<Accordion
							key={accordionData.length - 1}
							className={classes.documentsSection}
							header={accordionData[accordionData.length - 1].header}
							headerClass={classes.header}
							toggle={true}
							checked={true}
							disabled={true}
						>
							<p>{t(accordionData[accordionData.length - 1].paragraph)}</p>
						</Accordion>

						<div className={classes.button}>
							<button className={classes.first} onClick={handleSavePreferences}>
								{t(keys.GLOBAL.COOKIE.SAVE_PREFERENCES)}
							</button>
							<button className={classes.second} onClick={handleAcceptAllCookies}>
								{t(keys.GLOBAL.COOKIE.ACCEPT_ALL_COOKIES)}
							</button>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default PrivacySideCart;
