import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	search: "",
	sort: "",
	filter: "",
	values: [],
	categories: [],
	parentCategories: [],
	childCategories: [],
	brands: [],
	subsegments: [],
	favorite: false,
	quotedonly: false,
	page: 1,
	pageSize: 16,
	isLoading: true,
};

const productQuery = createSlice({
	name: "productQuery",
	initialState,
	reducers: {
		setSearch(state, action) {
			state.isLoading = true;
			state.page = 1;
			state.search = action.payload;
			state.isLoading = false;
		},
		setSort(state, action) {
			state.isLoading = true;
			state.page = 1;
			state.sort = action.payload;
			state.isLoading = false;
		},
		setFilter(state, action) {
			state.isLoading = true;
			state.page = 1;
			state.filter = action.payload;
			state.values = [];
			state.isLoading = false;
		},
		setValues(state, action) {
			state.isLoading = true;
			state.page = 1;
			state.values = action.payload;
			state.isLoading = false;
		},
		setFavorite(state, action) {
			state.isLoading = true;
			state.page = 1;
			state.favorite = action.payload;
			state.isLoading = false;
		},
		setQuotedOnly(state, action) {
			state.isLoading = true;
			state.page = 1;
			state.quotedonly = action.payload;
			state.isLoading = false;
		},
		setPage(state, action) {
			state.isLoading = true;
			if (action.payload && action.payload !== "" && action.payload !== "NaN") state.page = action.payload;
			else state.page = 1;
			state.isLoading = false;
		},
		setPageSize(state, action) {
			state.isLoading = true;
			if (action.payload && action.payload !== "" && action.payload !== "NaN") state.pageSize = action.payload;
			state.isLoading = false;
		},
		setChildCategories(state, action) {
			state.isLoading = true;
			state.page = 1;
			state.childCategories = action.payload.childCategories;

			const parents = action.payload.categoriesTree
				.filter((parent) => {
					return (
						state.parentCategories.some((el) => el === "p_" + parent.id) &&
						!state.childCategories?.some((child) =>
							state.childCategories.some((el) => el === "c_" + child.id)
						)
					);
				})
				.map((el) => el.children.map((child) => child.id))
				.flat();

			let categories = [];
			if (parents.length > 0) categories = [...parents];
			if (state.childCategories.length > 0)
				categories = [...categories, ...state.childCategories.map((el) => el.substring(2))];

			state.categories = categories;
			state.isLoading = false;
		},
		setParentCategories(state, action) {
			state.isLoading = true;
			state.page = 1;
			state.parentCategories = action.payload.parentCategories;

			const categoriesTree = action.payload.categoriesTree ?? [];
			const parents = categoriesTree
				.filter((parent) => {
					return (
						state.parentCategories.some((el) => el === "p_" + parent.id) &&
						!state.childCategories?.some((child) =>
							state.childCategories.some((el) => el === "c_" + child.id)
						)
					);
				})
				.map((el) => el.children.map((child) => child.id))
				.flat();

			let categories = [];
			if (parents.length > 0) categories = [...parents];
			if (state.childCategories.length > 0)
				categories = [
					...categories,
					...state.childCategories
						.map((el) => el.substring(2))
						.filter((el) => !categories.some((e) => e === el)),
				];

			state.categories = categories;
			state.isLoading = false;
		},
		setCategories(state, action) {
			state.isLoading = true;
			state.page = 1;
			state.categories = action.payload;
			state.isLoading = false;
		},
		setBrands(state, action) {
			state.isLoading = true;
			state.page = 1;
			state.brands = action.payload;
			state.isLoading = false;
		},
		setSubSegments(state, action) {
			state.isLoading = true;
			state.page = 1;
			state.subsegments = action.payload;
			state.isLoading = false;
		},
		clearAll(state) {
			state.isLoading = true;
			state.search = "";
			state.sort = "";
			state.filter = "";
			state.values = [];
			state.parentCategories = [];
			state.childCategories = [];
			state.categories = [];
			state.brands = [];
			state.subsegments = [];
			state.favorite = false;
			state.quotedonly = false;
			state.page = 1;
			state.pageSize = 16;
			state.isLoading = false;
		},
	},
});

export const {
	setSearch,
	setSort,
	setValues,
	setFavorite,
	setQuotedOnly,
	setPage,
	setPageSize,
	setChildCategories,
	setParentCategories,
	setCategories,
	setBrands,
	setSubSegments,
	clearAll,
	keepBrandOnly,
} = productQuery.actions;
export default productQuery;

export const searchSelector = (state) => state?.productQuery?.search;
export const sortSelector = (state) => state?.productQuery?.sort;
export const favoriteSelector = (state) => state?.productQuery?.favorite;
export const quotedonlySelector = (state) => state?.productQuery?.quotedonly;
export const filterCategoriesSelector = (state) => state?.productQuery?.categories;
export const childCategoriesSelector = (state) => state?.productQuery?.childCategories;
export const parentCategoriesSelector = (state) => state?.productQuery?.parentCategories;
export const filterBrandsSelector = (state) => state?.productQuery?.brands;
export const filterSubsegmentsSelector = (state) => state?.productQuery?.subsegments;
export const pageSelector = (state) => state?.productQuery?.page;
export const filterLoadingSelector = (state) => state?.productQuery?.isLoading;
