import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import { getDay } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import DatePicker from "react-datepicker";
import fr from "date-fns/locale/fr";
import en from "date-fns/locale/en-US";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";

import RadioBtn from "../../ui/Input/RadioBtn/RadioBtn";
import TextInput from "../../ui/Input/TextInput/TextInput";
import Button from "../../ui/Button/Button";
import CheckoutItem from "../checkoutItem/CheckoutItem";
import AddressEditor from "../addressEditor/AddressEditor";
import CreditCardForm from "../CreditCard/CreditCardForm";
import CustomNavLink from "../../ui/customNavLink/CustomNavLink";
import TextArea from "../../ui/Input/TextArea/TextArea";

import {
	defaultAddressSelector,
	isLoggedInSelector,
	otherAddressesSelector,
	selectedAddressSelector,
	setPrecedentUrl,
	taxPercentSelector,
	walletSelector,
} from "../../../store/auth";
import { cartHasErrorSelector, isLoadingSelector, orderLinesSelector, updateOrder } from "../../../store/cart";
import CartItem from "../../cart/cartItem/CartItem";
import logo from "../../../assets/svg/adfast-logo-black.svg";
import carts from "../../../assets/svg/cart-items.svg";
import amex from "../../../assets/jpeg/amex.jpeg";
import dankort from "../../../assets/jpeg/dankort.jpeg";
import diners from "../../../assets/jpeg/diners.jpeg";
import discover from "../../../assets/jpeg/discover.jpeg";
import maestro from "../../../assets/jpeg/maestro.jpeg";
import mastercard from "../../../assets/jpeg/mastercard.jpeg";
import unknown from "../../../assets/jpeg/unkown.jpeg";
import visa from "../../../assets/jpeg/visa.jpeg";

import { keys } from "../../../locales/localeskeys";
import PlaceSwitch from "../../../assets/svg/Loading/PlaceSwitch.svg";

import "react-datepicker/dist/react-datepicker.css";
import "../Checkout.scss";
import classes from "./CheckoutWeb.module.scss";

const CheckoutWeb = () => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const isLoading = useSelector(isLoadingSelector);
	const hasError = useSelector(cartHasErrorSelector);
	const isLoggedIn = useSelector(isLoggedInSelector);
	const taxPercent = useSelector(taxPercentSelector);
	const defaultAddress = useSelector(defaultAddressSelector);
	const otherAddresses = useSelector(otherAddressesSelector);
	const orderLine = useSelector(orderLinesSelector);
	const wallets = useSelector(walletSelector);

	const [refValid, setRefValid] = useState(false);
	const [deliveryValid, setDeliveryValid] = useState(false);
	const [paymentValid, setPaymentValid] = useState(false);

	const [currentTab, setCurrentTab] = useState(1);
	const [currentTabTmp, setCurrentTabTmp] = useState(1);
	const [successOrder, setSuccessOrder] = useState(false);

	const [custRef, setCustRef] = useState("");
	const [fullName, setFullName] = useState("");
	const [deliveryMode, setDeliveryMode] = useState("delivery");
	const [deliveryDate, setDeliveryDate] = useState(undefined);
	const [selectedAddressId, setSelectedAddressId] = useState(undefined);
	const [editingAddressId, setEditingAddressId] = useState(undefined);
	const [addingAddress, setAddingAddress] = useState(false);
	const [addingCreditCard, setAddingCreditCard] = useState(false);
	const [selectedCreditCardId, setSelectedCreditCardId] = useState(undefined);
	const [subTotal, setSubTotal] = useState(0);
	const [tax, setTax] = useState(0);
	const [total, setTotal] = useState(0);
	const [paymTerm, setPaymTerm] = useState("netterms"); //TODO "creditcard");
	const [custNote, setCustNotte] = useState("");
	const [isValidReference, setIsValidReference] = useState();
	const [isValidfullName, setIsValidfullName] = useState();

	const dateRef = useRef();
	const dateTimeRef = useRef();
	const sucessRef = useRef();

	const selectedAddress = useSelector((state) => selectedAddressSelector(state, selectedAddressId));

	useEffect(() => {
		if (!isLoading && currentTabTmp !== currentTab) {
			setCurrentTab(currentTabTmp);
		}
	}, [isLoading, currentTabTmp, currentTab]);

	useEffect(() => {
		if (!isLoading && !orderLine) {
			if (currentTab === 1) navigate({ pathname: `/`, search: queryParams.toString() });
		}
	}, [lngId, isLoading, orderLine, currentTab]);

	useEffect(() => {
		if (defaultAddress && defaultAddress.length > 0 && !selectedAddressId) {
			setSelectedAddressId(defaultAddress[0].id);
		} else {
			if (otherAddresses && otherAddresses.length > 0 && !selectedAddressId) {
				setSelectedAddressId(otherAddresses[0].id);
			}
		}
	}, [defaultAddress, selectedAddressId]);

	useEffect(() => {
		setDeliveryValid((selectedAddressId || deliveryMode !== "delivery") && deliveryDate !== undefined);
	}, [selectedAddressId, deliveryDate, deliveryMode]);

	useEffect(() => {
		if (wallets && wallets.length > 0 && !selectedCreditCardId) {
			setSelectedCreditCardId(wallets[0].id);
		}
	}, [wallets, selectedCreditCardId]);

	useEffect(() => {
		setPaymentValid(
			((selectedCreditCardId && paymTerm === "creditcard") || paymTerm === "netterms") && currentTab >= 3
		);
	}, [selectedCreditCardId, paymTerm, currentTab]);

	useEffect(() => {
		if (!isLoggedIn) {
			dispatch(setPrecedentUrl({ pathname: location.pathname, search: location.search }));
			navigate({ pathname: `/sign-in`, search: queryParams.toString() });
		}
	}, [isLoggedIn, lngId]);

	useEffect(() => {
		if (hasError && paymentValid && currentTab === 3) setCurrentTabTmp(2);
		if (!hasError && currentTab === 4) setSuccessOrder(true);
	}, [hasError, paymentValid, currentTab]);

	useEffect(() => {
		if (orderLine && orderLine.length > 0) {
			const subAmount = orderLine.map((line) => Number(line.amount)).reduce((prev, curr) => prev + curr, 0);
			const taxAmount = (subAmount * taxPercent) / 100;
			const amount = subAmount + taxAmount;

			setSubTotal(subAmount);
			setTax(taxAmount);
			setTotal(amount);
		}
	}, [orderLine, taxPercent]);

	useEffect(() => {
		if (hasError) {
			setCurrentTabTmp(currentTabTmp - 1);
		}
	}, [hasError]);

	useEffect(() => {
		if (successOrder === true) {
			sucessRef.current.scrollIntoView({ behavior: "instant", block: "center" });
		}
	}, [successOrder]);

	const custRefChangeHandler = (event) => {
		setCustRef(event.target.value.trim());
		setRefValid(!!event.target.value && event.target.value.trim() !== "" && !!fullName && fullName.trim() !== "");
		setIsValidReference(!!event.target.value && event.target.value.trim() !== "");
	};

	const fullNameChangeHandler = (event) => {
		setFullName(event.target.value.trim());
		setRefValid(!!event.target.value && event.target.value.trim() !== "" && !!custRef && custRef.trim() !== "");
		setIsValidfullName(!!event.target.value && event.target.value.trim() !== "");
	};

	const deliveryAddressIdChangeHandler = (event) => {
		setSelectedAddressId(event.target.id);
	};

	const creditCardIdChangeHandler = (event) => {
		setSelectedCreditCardId(event.target.id);
	};

	const deliveryModeChangeHandler = (event) => {
		let date = new Date();
		if (date.getHours() + 1 <= 6 && date.getHours() + 1 >= 17) {
			date.setHours(date.getHours() + 1);
			date.setMinutes(0);
			date.setSeconds(0);
		} else {
			date.setHours(6);
			date.setMinutes(0);
			date.setSeconds(0);
			date.setDate(date.getDate() + 1);
		}
		setDeliveryMode(event.target.value);
		setDeliveryDate(date);
	};

	const paymTermChangeHandler = (event) => {
		setPaymTerm(event.target.value);
	};

	const isWeekday = (date) => {
		const day = getDay(date);
		return day !== 0 && day !== 6;
	};

	const dateChangeHandler = (date) => {
		setDeliveryDate(date);
	};

	const editAddressHandler = (id) => {
		if (currentTab !== 2) return;
		setEditingAddressId(id);
	};

	const addAddressHandler = () => {
		if (currentTab !== 2) return;
		setAddingAddress(true);
	};

	const closeAddressEditHandler = () => {
		setEditingAddressId(undefined);
		setAddingAddress(false);
	};

	const addCreditCardHandler = () => {
		if (currentTab !== 3) return;
		setAddingCreditCard(true);
	};

	const closeAddCreditCardHandler = () => {
		setAddingCreditCard(false);
	};

	const saveOrderRefs = (event) => {
		event.preventDefault();

		setCurrentTabTmp(2);
		dispatch(updateOrder({ purchase_order_ref: custRef, customer_ref: fullName }));
	};

	const saveDelivery = (event) => {
		event.preventDefault();

		setCurrentTabTmp(3);
		if (deliveryMode === "delivery") {
			dispatch(
				updateOrder({
					order_date: zonedTimeToUtc(deliveryDate, "America/New_York"),
					delivery_line1: selectedAddress.line1,
					delivery_line2: selectedAddress.line2,
					delivery_city: selectedAddress.city,
					delivery_postal_code: selectedAddress.postal_code,
					delivery_state: selectedAddress.state,
					delivery_country: selectedAddress.country,
					delivery_name: selectedAddress.name,
					delivery_phone: selectedAddress.phone,
					customer_note: custNote,
				})
			);
		} else {
			dispatch(
				updateOrder({
					order_date: zonedTimeToUtc(deliveryDate, "America/New_York"),
					is_pickup: true,
					customer_note: custNote,
				})
			);
		}
	};

	const processPayment = (event) => {
		event.preventDefault();

		setCurrentTabTmp(4);
		dispatch(
			updateOrder({
				wallet_id: selectedCreditCardId,
				is_processed: true,
				paid_by_card: paymTerm === "creditcard",
			})
		);
	};

	const filterPassedTime = (time) => {
		const currentDate = new Date();
		const selectedDate = new Date(time);

		return currentDate.getTime() < selectedDate.getTime();
	};

	const cartBrand = (brand) => {
		switch (brand) {
			case "amex":
				return amex;
			case "dankort":
				return dankort;
			case "diners":
				return diners;
			case "discover":
				return discover;
			case "maestro":
				return maestro;
			case "mastercard":
				return mastercard;
			case "visa":
				return visa;
			default:
				return unknown;
		}
	};

	function getCurrentDate() {
		var currentDate = new Date();
		if (currentDate.getDay() === 0) {
			currentDate.setDate(currentDate.getDate() + 1);
		} else if (currentDate.getDay() === 6) {
			currentDate.setDate(currentDate.getDate() + 2);
		}

		return currentDate;
	}

	const protalElement = document.getElementById("overlays");
	return (
		<div className={`${classes.layout} ${classes[successOrder ? "successOrder" : ""]}`}>
			{isLoading && ReactDOM.createPortal(<div className={classes.backdrop} />, protalElement)}
			{isLoading &&
				ReactDOM.createPortal(
					<object type="image/svg+xml" data={PlaceSwitch} className={classes.loading}>
						svg-animation
					</object>,
					protalElement
				)}
			{successOrder && (
				<div className={classes.success} ref={sucessRef}>
					<div className={classes.content}>
						<h1>{t(keys.CHECKOUT.PAYMENT.SUCCESS_TITLE)}</h1>
						<Icon className={classes.icon} icon="clarity:success-standard-solid" />
						<h2>{t(keys.CHECKOUT.PAYMENT.SUCCESS_SUB_TITLE)}</h2>
						<div>
							<h4>{t(keys.CHECKOUT.PAYMENT.SUCCESS_TEXT1)}</h4>
							<h4>{t(keys.CHECKOUT.PAYMENT.SUCCESS_TEXT2)}</h4>
						</div>
						<CustomNavLink to={`/`}>
							<Button className={classes.button} size="big">
								{t(keys.CHECKOUT.PAYMENT.SUCCESS_BTN)}
							</Button>
						</CustomNavLink>
					</div>
				</div>
			)}
			{!successOrder && (addingAddress === true || editingAddressId) && (
				<AddressEditor editngAddressId={editingAddressId} closeEditAddress={closeAddressEditHandler} />
			)}
			{!successOrder && addingCreditCard === true && (
				<CreditCardForm closeAddCreditCard={closeAddCreditCardHandler} />
			)}
			{!successOrder && (
				<div className={classes.title}>
					<object type="image/svg+xml" data={logo}>
						logo
					</object>
					<h1>{t(keys.CHECKOUT.MAIN.TITLE)}</h1>
					<h2>
						{t(keys.CHECKOUT.MAIN.ASSISTANCE)}{" "}
						<span className={classes.phone}>{t(keys.CHECKOUT.MAIN.PHONENUMBER)}</span>
					</h2>
				</div>
			)}
			{!successOrder && <hr />}
			{!successOrder && (
				<div className={classes.body}>
					<div className={classes.left}>
						<CheckoutItem
							title={t(keys.CHECKOUT.MAIN.POREFTITLE)}
							isValid={refValid}
							isOpen={currentTab === 1 || refValid}
							onSubmit={saveOrderRefs}
						>
							<TextInput
								label={t(keys.CHECKOUT.MAIN.POREF)}
								mandatory={true}
								value={custRef.value}
								onChange={custRefChangeHandler}
								disabled={currentTab !== 1}
								maxLength={20}
							/>
							<TextInput
								label={t(keys.CHECKOUT.MAIN.FULLNAME)}
								mandatory={true}
								value={fullName.value}
								onChange={fullNameChangeHandler}
								disabled={currentTab !== 1}
								maxLength={60}
							/>
							{hasError && (
								<div className={classes.error}>
									<label>
										<Icon className={classes.mandatory} icon="oui:asterisk" />
										{t(keys.CHECKOUT.MAIN.ERROR)}
									</label>
								</div>
							)}
							{currentTab === 1 && (
								<Button
									type="submit"
									size="big"
									disabled={!refValid || isLoading === true || successOrder}
								>
									{t(keys.CHECKOUT.MAIN.CONTINUE)}
								</Button>
							)}
						</CheckoutItem>
						<CheckoutItem
							title={t(keys.CHECKOUT.MAIN.DLVMODETITLE)}
							className={classes.deliveryAddress}
							isValid={deliveryValid}
							isOpen={currentTab === 2 || deliveryValid}
							onSubmit={saveDelivery}
						>
							{currentTab !== 2 && deliveryMode === "delivery" && deliveryValid && selectedAddress && (
								<h2>{selectedAddress.name}</h2>
							)}
							{currentTab !== 2 && deliveryMode === "delivery" && deliveryValid && selectedAddress && (
								<p>{`${selectedAddress.line1 ? selectedAddress.line1 : selectedAddress.line1}  ${
									selectedAddress.line2 ? selectedAddress.line2 : ""
								}`}</p>
							)}
							{currentTab !== 2 && deliveryMode === "delivery" && deliveryValid && selectedAddress && (
								<p>{`${selectedAddress.city ? selectedAddress.city : ""}, ${
									selectedAddress.state ? selectedAddress.state : ""
								} ${selectedAddress.country ? selectedAddress.country : ""}`}</p>
							)}
							{currentTab !== 2 && deliveryMode === "delivery" && deliveryValid && selectedAddress && (
								<p style={{ marginBottom: "2rem" }}>
									{selectedAddress.postal_code ? selectedAddress.postal_code : ""}
								</p>
							)}
							{currentTab !== 2 && deliveryValid && selectedAddress && (
								<p>
									{deliveryMode === "pickup"
										? t(keys.CHECKOUT.MAIN.PICKUPTIME)
										: t(keys.CHECKOUT.MAIN.DLVDAY)}
								</p>
							)}
							{currentTab !== 2 && deliveryValid && selectedAddress && (
								<DatePicker
									selected={deliveryDate}
									minDate={getCurrentDate()}
									filterDate={isWeekday}
									showDisabledMonthNavigation
									showTimeSelect={deliveryMode === "pickup"}
									dateFormat={deliveryMode === "pickup" ? "MMMM d, yyyy h:mm aa" : "MMMM d, yyyy"}
									locale={lngId.includes("fr") ? fr : en}
									disabled={true}
									ref={dateRef}
								/>
							)}
							{currentTab === 2 && (
								<RadioBtn
									state={deliveryMode}
									values={[
										{
											id: 0,
											value: "delivery",
											label: t(keys.CHECKOUT.MAIN.DELIVERY),
											checked: deliveryMode === "delivery",
										},
										{
											id: 1,
											value: "pickup",
											label: t(keys.CHECKOUT.MAIN.PICKUPLABEL),
											checked: deliveryMode === "pickup",
										},
									]}
									onChange={deliveryModeChangeHandler}
									disabled={currentTab !== 2}
									labelClassName={classes.radioLabel}
								/>
							)}
							{currentTab === 2 &&
								deliveryMode === "delivery" &&
								defaultAddress &&
								defaultAddress.length > 0 && (
									<div className={classes.defaultAddress}>
										<p>{t(keys.CHECKOUT.MAIN.DEFAULTADDRESS)}</p>
										<hr />
										<RadioBtn
											id={"defaultAddress"}
											name={"defaultAddress"}
											values={[
												{
													id: defaultAddress[0].id,
													value: defaultAddress[0].id,
													checked: selectedAddressId === defaultAddress[0].id,
													label: defaultAddress[0].name,
													secondLine: (
														<div className={classes.radioAddress}>
															<label>{defaultAddress[0].address}</label>
															<div
																className={classes.editAddress}
																onClick={() => editAddressHandler(defaultAddress[0].id)}
															>
																{t(keys.CHECKOUT.MAIN.EDITADDRESS)}
															</div>
														</div>
													),
												},
											]}
											onChange={deliveryAddressIdChangeHandler}
											disabled={currentTab !== 2}
											labelClassName={classes.radioLabel}
										/>
									</div>
								)}
							{currentTab === 2 &&
								deliveryMode === "delivery" &&
								otherAddresses &&
								otherAddresses.length > 0 && (
									<div className={classes.otherAddresses}>
										<p>{t(keys.CHECKOUT.MAIN.OTHERADDRESS)}</p>
										<hr />
										<RadioBtn
											id={"otherAddress"}
											name={"otherAddress"}
											inputCss={classes.radio}
											values={otherAddresses.map((address) => ({
												id: address.id,
												value: address.id,
												checked: selectedAddressId === address.id,
												label: address.name,
												secondLine: (
													<div className={classes.radioAddress}>
														<label>{address.address}</label>
														<div
															className={classes.editAddress}
															onClick={() => editAddressHandler(address.id)}
														>
															{t(keys.CHECKOUT.MAIN.EDITADDRESS)}
														</div>
													</div>
												),
											}))}
											onChange={deliveryAddressIdChangeHandler}
											disabled={currentTab !== 2}
											labelClassName={classes.radioLabel}
										/>
									</div>
								)}
							{currentTab === 2 && deliveryMode === "delivery" && (
								<div className={classes.add} onClick={addAddressHandler}>
									<Icon className={classes.addIcon} icon="akar-icons:plus" />
									<div className={classes.label}>{t(keys.CHECKOUT.MAIN.ADDADDRESS)}</div>
								</div>
							)}
							{currentTab === 2 && deliveryMode === "delivery" && <hr />}
							{currentTab === 2 && (
								<div className={classes.custNote}>
									<p>{t(keys.CHECKOUT.MAIN.CUSTNOTE)}</p>
									<TextArea
										value={custNote.value}
										onChange={(event) => {
											setCustNotte(event.target.value);
										}}
										disabled={currentTab !== 2}
										inputClassName={classes.notCss}
										rows={5}
									/>
								</div>
							)}
							{currentTab === 2 && <hr />}
							{currentTab === 2 && (
								<div className={classes.datePicker}>
									<div className={classes.label}>
										<label>
											{t(keys.CHECKOUT.MAIN.DATEWARNING)}
											<Icon className={classes.mandatory} icon="oui:asterisk" />
										</label>
									</div>
									<DatePicker
										selected={deliveryDate}
										onChange={(date) => dateChangeHandler(date)}
										minDate={getCurrentDate()}
										filterDate={isWeekday}
										showDisabledMonthNavigation
										showTimeSelect={deliveryMode === "pickup"}
										dateFormat={deliveryMode === "pickup" ? "MMMM d, yyyy h:mm aa" : "MMMM d, yyyy"}
										minTime={setHours(setMinutes(getCurrentDate(), 0), 6)}
										maxTime={setHours(setMinutes(getCurrentDate(), 0), 17)}
										locale={lngId.includes("fr") ? fr : en}
										disabled={currentTab !== 2}
										filterTime={filterPassedTime}
										ref={dateTimeRef}
									/>
									{hasError && (
										<div className={classes.error}>
											<label>
												<Icon className={classes.mandatory} icon="oui:asterisk" />
												{t(keys.CHECKOUT.MAIN.ERROR)}
											</label>
										</div>
									)}
								</div>
							)}
							{currentTab === 2 && (
								<div className={classes.buttons}>
									<Button
										size="big"
										color="outlineBlack"
										onClick={() => {
											setCurrentTabTmp(1);
										}}
										disabled={successOrder}
									>
										{t(keys.CHECKOUT.MAIN.BACK)}
									</Button>
									<Button
										size="big"
										type="submit"
										disabled={!deliveryValid || isLoading === true || successOrder}
									>
										{t(keys.CHECKOUT.MAIN.CONTINUE)}
									</Button>
								</div>
							)}
						</CheckoutItem>
						<CheckoutItem
							title={t(keys.CART.OVERVIEW.ORDERSUMMARY)}
							className={classes.summary}
							isValid={true}
							isOpen={true}
							hideIcon
						>
							<div className={classes.field}>
								<div>{t(keys.CHECKOUT.SUMMARY.SUBTOTAL)}</div>
								<div>
									{lngId.includes("fr")
										? `${subTotal.toFixed(2).replace(".", ",")}$`
										: `$${subTotal.toFixed(2)}`}
								</div>
							</div>
							<div className={classes.field}>
								<div>{t(keys.CHECKOUT.SUMMARY.TAX)}</div>
								<div>
									{lngId.includes("fr")
										? `${tax.toFixed(2).replace(".", ",")}$`
										: `$${tax.toFixed(2)}`}
								</div>
							</div>
							<hr />
							<div className={classes.field}>
								<div className={classes.total}>{t(keys.CHECKOUT.SUMMARY.TOTAL)}</div>
								<div className={classes.total}>
									{lngId.includes("fr")
										? `${total.toFixed(2).replace(".", ",")}$`
										: `$${total.toFixed(2)}`}
								</div>
							</div>
							<p className={classes.note}>{t(keys.CHECKOUT.SUMMARY.REVIEW)}</p>
							{hasError && (
								<div className={classes.error}>
									<label>
										<Icon className={classes.mandatory} icon="oui:asterisk" />
										{t(keys.CHECKOUT.MAIN.ERROR)}
									</label>
								</div>
							)}
							<div className={classes.buttons}>
								<Button
									size="big"
									color="outlineBlack"
									onClick={() => {
										setCurrentTabTmp(currentTab - 1);
									}}
									disabled={currentTab <= 1}
								>
									{t(keys.CHECKOUT.MAIN.BACK)}
								</Button>
								<Button
									disabled={
										!isValidReference ||
										!isValidfullName ||
										!refValid ||
										!deliveryValid ||
										// !paymentValid ||
										currentTab !== 3 ||
										isLoading === true
									}
									onClick={processPayment}
								>
									{t(keys.CHECKOUT.SUMMARY.ORDER)}
								</Button>
							</div>
						</CheckoutItem>
					</div>
					<div className={classes.right}>
						<CheckoutItem
							title={t(keys.CART.OVERVIEW.TITLE)}
							className={classes.cart}
							isValid={true}
							isOpen={true}
							hideIcon
						>
							{orderLine && (
								<div className={classes.linesNumber}>
									<object type="image/svg+xml" data={carts}>
										deliveryIcon
									</object>
									{orderLine.length > 1
										? ` ${t(keys.CHECKOUT.MAIN.DELIVERY)} : ${orderLine.length} ${t(
												keys.CART.OVERVIEW.ITEMS
										  )}`
										: ` ${t(keys.CHECKOUT.MAIN.DELIVERY)} : ${orderLine.length} ${t(
												keys.CART.OVERVIEW.ITEM
										  )}`}
								</div>
							)}
							{orderLine && (
								<div className={classes.orderLines}>
									{orderLine
										.filter((line) => line.item && line.variant)
										.map((line, index) => (
											<div key={line.id}>
												<CartItem
													id={line.id}
													variant={line.variant}
													item={line.item}
													qty={Number(line.qty).toFixed(0)}
													qty_pack={Number(line.qty_pack).toFixed(0)}
													amount={Number(line.amount)}
													unitAmount={Number(line.unitAmount)}
													images={line.overview_image}
													hidePackaging
													hideButtons
												/>

												{orderLine.length !== index + 1 && <hr />}
											</div>
										))}
								</div>
							)}
						</CheckoutItem>
					</div>
				</div>
			)}
		</div>
	);
};
export default CheckoutWeb;
