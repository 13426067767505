import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { constants } from "../locales/constant";

export const getNuanciers = createAsyncThunk("colorMatch/getNuanciers", async (_, { rejectWithValue }) => {
	try {
		const response = await fetch(`${constants.API_SERVER}/nuancier/`);

		if (!response.ok) {
			throw new Error("fetching nuancier failed");
		}

		return await response.json();
	} catch (err) {
		return rejectWithValue(err.message, err);
	}
});

export const getItemColors = createAsyncThunk("colorMatch/getItemColors", async (_, { rejectWithValue }) => {
	try {
		const response = await fetch(`${constants.API_SERVER}/itemColorsDetail/`);

		if (!response.ok) {
			throw new Error("fetching itemColorsDetail failed");
		}

		return await response.json();
	} catch (err) {
		return rejectWithValue(err.message, err);
	}
});

export const getColorNuanciers = createAsyncThunk("colorMatch/getColorNuanciers", async (_, { rejectWithValue }) => {
	try {
		const response = await fetch(`${constants.API_SERVER}/colorNuancier/`);

		if (!response.ok) {
			throw new Error("fetching colorNuancier failed");
		}

		return await response.json();
	} catch (err) {
		return rejectWithValue(err.message, err);
	}
});

export const getColorMatch = createAsyncThunk("colorMatch/getColorMatch", async (_, { rejectWithValue }) => {
	try {
		const response = await fetch(`${constants.API_SERVER}/colorMatch/`);

		if (!response.ok) {
			throw new Error("fetching colorMatch failed");
		}

		return await response.json();
	} catch (err) {
		return rejectWithValue(err.message, err);
	}
});

export const getColors = createAsyncThunk("colorMatch/getColors", async (_, { rejectWithValue }) => {
	try {
		const response = await fetch(`${constants.API_SERVER}/colors/`);

		if (!response.ok) {
			throw new Error("fetching colorMatch failed");
		}

		return await response.json();
	} catch (err) {
		return rejectWithValue(err.message, err);
	}
});
export const getItemColor = createAsyncThunk("colorMatch/getItemColor", async ({ erp_code }, { rejectWithValue }) => {
	try {
		const response = await fetch(`${constants.API_SERVER}/itemColor/${erp_code}/`);

		if (!response.ok) {
			throw new Error("fetching colorMatch failed");
		}

		return await response.json();
	} catch (err) {
		return rejectWithValue(err.message, err);
	}
});

const colorMatchAdapter = createEntityAdapter({});

const colorMatch = createSlice({
	name: "colorMatch",
	initialState: colorMatchAdapter.getInitialState({
		loadingItemsColors: "done",
		loadingNuaniers: "done",
		loadingColorsNuanciers: "done",
		loadingColorsMatch: "done",
		loadingColors: "done",
		loadingItemColor: "done",
		currentRequestitemsColors: undefined,
		currentRequestnuaniers: undefined,
		currentRequestcolorsNuanciers: undefined,
		currentRequestcolorsMatch: undefined,
		currentRequestColors: undefined,
		currentRequestItemColor: undefined,
		erroritemsColors: undefined,
		errornuaniers: undefined,
		errorcolorsNuanciers: undefined,
		errorcolorsMatch: undefined,
		errorColors: undefined,
		errorItemColor: undefined,
		itemsColors: undefined,
		nuaniers: undefined,
		colorsNuanciers: undefined,
		colorsMatch: undefined,
		colors: undefined,
		itemColor: undefined,
	}),
	reducers: {},
	extraReducers: {
		[getNuanciers.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestnuaniers) {
				state.currentRequestnuaniers = undefined;
				state.loadingNuaniers = "done";
				state.errornuaniers = undefined;
				state.nuaniers = payload;
			}
		},
		[getNuanciers.pending]: (state, { meta }) => {
			if (!state.loadingNuaniers || state.loadingNuaniers === "done") {
				state.currentRequestnuaniers = meta.requestId;
				state.loadingNuaniers = "pending";
				state.errornuaniers = undefined;
				state.nuaniers = undefined;
			}
		},
		[getNuanciers.rejected]: (state, { meta, payload }) => {
			if (state.loadingNuaniers === "pending" && state.currentRequestnuaniers === meta.requestId) {
				state.currentRequestnuaniers = undefined;
				state.loadingNuaniers = "done";
				state.errornuaniers = payload;
			}
		},
		[getItemColors.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestitemsColors) {
				state.currentRequestitemsColors = undefined;
				state.loadingItemsColors = "done";
				state.erroritemsColors = undefined;
				state.itemsColors = payload;
			}
		},
		[getItemColors.pending]: (state, { meta }) => {
			if (!state.loadingItemsColors || state.loadingItemsColors === "done") {
				state.currentRequestitemsColors = meta.requestId;
				state.loadingItemsColors = "pending";
				state.erroritemsColors = undefined;
				state.itemsColors = undefined;
			}
		},
		[getItemColors.rejected]: (state, { meta, payload }) => {
			if (state.loadingItemsColors === "pending" && state.currentRequestitemsColors === meta.requestId) {
				state.currentRequestitemsColors = undefined;
				state.loadingItemsColors = "done";
				state.erroritemsColors = payload;
			}
		},
		[getColorNuanciers.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestcolorsNuanciers) {
				state.currentRequestcolorsNuanciers = undefined;
				state.loadingColorsNuanciers = "done";
				state.errorcolorsNuanciers = undefined;
				state.colorsNuanciers = payload;
			}
		},
		[getColorNuanciers.pending]: (state, { meta }) => {
			if (!state.loadingColorsNuanciers || state.loadingColorsNuanciers === "done") {
				state.currentRequestcolorsNuanciers = meta.requestId;
				state.loadingColorsNuanciers = "pending";
				state.errorcolorsNuanciers = undefined;
				state.colorsNuanciers = undefined;
			}
		},
		[getColorNuanciers.rejected]: (state, { meta, payload }) => {
			if (state.loadingColorsNuanciers === "pending" && state.currentRequestcolorsNuanciers === meta.requestId) {
				state.currentRequestcolorsNuanciers = undefined;
				state.loadingColorsNuanciers = "done";
				state.errorcolorsNuanciers = payload;
			}
		},
		[getColorMatch.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestcolorsMatch) {
				state.currentRequestcolorsMatch = undefined;
				state.loadingColorsMatch = "done";
				state.errorcolorsMatch = undefined;
				state.colorsMatch = payload;
			}
		},
		[getColorMatch.pending]: (state, { meta }) => {
			if (!state.loadingColorsMatch || state.loadingColorsMatch === "done") {
				state.currentRequestcolorsMatch = meta.requestId;
				state.loadingColorsMatch = "pending";
				state.errorcolorsMatch = undefined;
				state.colorsMatch = undefined;
			}
		},
		[getColorMatch.rejected]: (state, { meta, payload }) => {
			if (state.loadingColorsMatch === "pending" && state.currentRequestcolorsMatch === meta.requestId) {
				state.currentRequestcolorsMatch = undefined;
				state.loadingColorsMatch = "done";
				state.errorcolorsMatch = payload;
			}
		},
		[getColors.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestColors) {
				state.currentRequestColors = undefined;
				state.loadingColors = "done";
				state.errorColors = undefined;
				state.colors = payload;
			}
		},
		[getColors.pending]: (state, { meta }) => {
			if (!state.loadingColors || state.loadingColors === "done") {
				state.currentRequestColors = meta.requestId;
				state.loadingColors = "pending";
				state.errorColors = undefined;
				state.colors = undefined;
			}
		},
		[getColors.rejected]: (state, { meta, payload }) => {
			if (state.loadingColors === "pending" && state.currentRequestColors === meta.requestId) {
				state.currentRequestColors = undefined;
				state.loadingColors = "done";
				state.errorColors = payload;
			}
		},
		[getItemColor.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestItemColor) {
				state.currentRequestItemColor = undefined;
				state.loadingItemColor = "done";
				state.errorItemColor = undefined;
				state.itemColor = payload;
			}
		},
		[getItemColor.pending]: (state, { meta }) => {
			if (!state.loadingItemColor || state.loadingItemColor === "done") {
				state.currentRequestItemColor = meta.requestId;
				state.loadingItemColor = "pending";
				state.errorItemColor = undefined;
				state.itemColor = undefined;
			}
		},
		[getItemColor.rejected]: (state, { meta, payload }) => {
			if (state.loadingItemColor === "pending" && state.currentRequestItemColor === meta.requestId) {
				state.currentRequestItemColor = undefined;
				state.loadingItemColor = "done";
				state.errorItemColor = payload;
			}
		},
	},
});

export default colorMatch;
export const itemsColorsSelector = (state) => state.colorMatch.itemsColors;
export const nuaniersSelector = (state) => state.colorMatch.nuaniers;
export const colorsNuanciersSelector = (state) => state.colorMatch.colorsNuanciers;
export const colorsMatchSelector = (state) => state.colorMatch.colorsMatch;
export const colorsSelector = (state) => state.colorMatch.colors;
export const itemColorSelector = (state) => state.colorMatch.itemColor;
export const loadingItemsColorsSelector = (state) => state.colorMatch.loadingItemsColors === "pending";
export const loadingNuaniersSelector = (state) => state.colorMatch.loadingNuaniers === "pending";
export const loadingColorsNuanciersSelector = (state) => state.colorMatch.loadingColorsNuanciers === "pending";
export const loadingColorsMatchSelector = (state) => state.colorMatch.loadingColorsMatch === "pending";
export const loadingColorsSelector = (state) => state.colorMatch.loadingColors === "pending";
export const loadingItemColorSelector = (state) => state.colorMatch.loadingItemColor === "pending";
