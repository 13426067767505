import { useTranslation } from "react-i18next";
import { useEffect } from "react";

import Banner from "../home/Banner/Banner";

import { banners } from "../../locales/banners";
import { keys } from "../../locales/localeskeys";

import classes from "./Term_Privacy.module.scss";

const Term_Privacy = () => {
	const { t } = useTranslation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div>
			<Banner content={banners.privacy} />
			<div className={classes.textContainer}>
				<div className={classes.headerContainer}>
					<div className={classes.hLine} />
					<h1>{t(keys.PRIVACY.TITLE)}</h1>
					<div className={classes.hLine} />
				</div>
				<p>{t(keys.PRIVACY.INTRO_1)}</p>
				<p>{t(keys.PRIVACY.INTRO_2)}</p>
				<h2>{t(keys.PRIVACY.HEADER_1)}</h2>
				<p>{t(keys.PRIVACY.PAR_1)}</p>
				<h2>{t(keys.PRIVACY.HEADER_2)}</h2>
				<p>{t(keys.PRIVACY.PAR_2)}</p>
				<h2>{t(keys.PRIVACY.HEADER_3)}</h2>
				<h3>{t(keys.PRIVACY.SUBHEADER_3_A)}</h3>
				<p>{t(keys.PRIVACY.PAR_3_A_1)}</p>
				<p>{t(keys.PRIVACY.PAR_3_A_2)}</p>
				<p>{t(keys.PRIVACY.PAR_3_A_3)}</p>
				<p>{t(keys.PRIVACY.PAR_3_A_4)}</p>
				<h2>{t(keys.PRIVACY.HEADER_4)}</h2>
				<h3>{t(keys.PRIVACY.SUBHEADER_4_A)}</h3>
				<p>{t(keys.PRIVACY.PAR_4_A)}</p>
				<h3>{t(keys.PRIVACY.SUBHEADER_4_B)}</h3>
				<p>{t(keys.PRIVACY.PAR_4_B)}</p>
				<h2>{t(keys.PRIVACY.HEADER_5)}</h2>
				<p>{t(keys.PRIVACY.PAR_5)}</p>
				<h2>{t(keys.PRIVACY.HEADER_6)}</h2>
				<p>{t(keys.PRIVACY.PAR_6_1)}</p>
				<p>{t(keys.PRIVACY.PAR_6_2)}</p>
				<h2>{t(keys.PRIVACY.HEADER_7)}</h2>
				<p>{t(keys.PRIVACY.PAR_7)}</p>
				<h2>{t(keys.PRIVACY.HEADER_8)}</h2>
				<p>{t(keys.PRIVACY.PAR_8)}</p>
				<h2>{t(keys.PRIVACY.HEADER_9)}</h2>
				<p>{t(keys.PRIVACY.PAR_9)}</p>
				<h2>{t(keys.PRIVACY.HEADER_10)}</h2>
				<p>{t(keys.PRIVACY.PAR_10_1)}</p>
				<p>{t(keys.PRIVACY.PAR_10_2)}</p>
				<p>{t(keys.PRIVACY.PAR_10_3)}</p>
			</div>
		</div>
	);
};

export default Term_Privacy;
