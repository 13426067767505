import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
	root: {
		"&": {
			display: "flex",
			alignItems: "center",
		},
	},
	icon: {
		borderRadius: 0,
		border: `1px solid ${theme.palette.neutral.grey.main}`,
		width: 20,
		height: 20,
		backgroundColor: theme.palette.neutral.light,
		"input:hover ~ &": {
			backgroundColor: theme.palette.neutral.grey.light,
		},
		"input:disabled ~ &": {
			background: theme.palette.neutral.grey.light,
		},
	},
	checkedIcon: {
		"&:before": {
			display: "block",
			width: 20,
			height: 20,
			backgroundImage:
				"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
				" d='M12.25 3.76263L5.05 10.9626L1.75 7.66263L2.596 6.81663L5.05 9.26463L11.404 2.91663L12.25 3.76263Z' fill='%23007AFF'/%3E%3C/svg%3E\")",
			content: '""',
		},
	},
}));

export const CheckboxInput = React.forwardRef(({ id, label, value, checked, onChange }, ref) => {
	const classes = useStyles();

	return (
		<label htmlFor={id} className={classes.root}>
			<Checkbox
				id={id}
				value={value}
				inputRef={ref}
				checked={checked}
				onChange={onChange}
				color="default"
				checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
				icon={<span className={classes.icon} />}
				inputProps={{ "aria-label": label }}
			/>
			<span>{label}</span>
		</label>
	);
});
