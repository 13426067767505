import React, { useImperativeHandle, useRef } from "react";

import classes from "./TextArea.module.scss";
import { Icon } from "@iconify/react";

const TextArea = React.forwardRef((props, ref) => {
	const inputRef = useRef();

	const inputHandler = (e) => {
		if (props.type === "number") {
			const char = String.fromCharCode(e.which);
			if (!/[0-9]/.test(char)) {
				e.preventDefault();
			}
		}
	};
	const activate = () => {
		inputRef.current.focus();
	};

	useImperativeHandle(ref, () => {
		return {
			focus: activate,
		};
	});

	return (
		<div
			className={`${props.className || ""} ${classes.TextInput} ${
				props.isValid === false ? classes.invalid : ""
			}`}
		>
			{props.label && (
				<div className={classes.label}>
					<label htmlFor={props.id}>
						{props.label}
						{props.mandatory && <Icon className={classes.mandatory} icon="oui:asterisk" />}
					</label>
				</div>
			)}
			<textarea
				className={`${classes[props.color] || ""} ${props.inputClassName || ""}`}
				ref={inputRef}
				id={props.id}
				name={props.name}
				value={props.value}
				onChange={props.onChange}
				onFocus={props.onFocus}
				onBlur={props.onBlur}
				placeholder={props.placeholder}
				onKeyPress={inputHandler}
				defaultValue={props.defaultValue}
				disabled={props.disabled}
				required={props.mandatory}
				cols={props.cols}
				rows={props.rows}
			/>
			{props.isValid === false && props.warning && (
				<div className={classes.warning}>
					<Icon icon="bi:exclamation-circle-fill" />
					<p>{props.warning}</p>
				</div>
			)}
		</div>
	);
});

export default TextArea;
