import React from "react";

import classes from "./Dropdown.module.scss";
import { Icon } from "@iconify/react";

const Dropdown = React.forwardRef((props, ref) => {
	return (
		<div
			className={`${classes.dropdown} ${classes[props.color] || ""} ${props.dropdown || ""} ${
				classes[props.labelPosition] || ""
			} ${props.isValid === false ? classes.invalid : ""} ${props.className}`}
		>
			{props.label && (
				<label htmlFor={props.id}>
					{props.label}
					{props.mandatory && <Icon className={classes.mandatory} icon="oui:asterisk" />}
				</label>
			)}
			<div className={`${classes.wrapper} ${props.wrapperClassName || ""}`}>
				<select
					ref={ref}
					id={props.id}
					value={props.value}
					onChange={props.onChange}
					defaultValue={props.defaultValue}
					disabled={props.disabled}
				>
					{props.children}
				</select>
			</div>
		</div>
	);
});

export default Dropdown;
