import React, { useState } from "react";
import useElementSize from "../../hooks/useElementSize";
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from "pure-react-carousel";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import p31 from "../../assets/svg/31.svg";
import p45 from "../../assets/svg/45.svg";
import p47 from "../../assets/svg/47.svg";
import p60 from "../../assets/svg/60.svg";
import p63 from "../../assets/svg/63.svg";

import { keys } from "../../locales/localeskeys";
import classes from "./AboutUs.module.scss";
import CustomNavLink from "../ui/customNavLink/CustomNavLink";

const AboutUs = () => {
	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const [windowRef, { width: gridWidth }] = useElementSize();

	const [isExpanded, setIsExpanded] = useState(false);

	const brandLogos = [
		"https://adfastcorpcdnak.azureedge.net/b2b/About%20Us/Adseal_Logo_Black.png",
		"https://adfastcorpcdnak.azureedge.net/b2b/About%20Us/Adfoam_Logo_Black.png",
		"https://adfastcorpcdnak.azureedge.net/b2b/About%20Us/Adbond_Logo_Black.png",
		"https://adfastcorpcdnak.azureedge.net/b2b/About%20Us/Adsolve_Logo_Black.png",
		"https://adfastcorpcdnak.azureedge.net/b2b/About%20Us/Thermobond_Logo_Black.png",
		"https://adfastcorpcdnak.azureedge.net/b2b/About%20Us/Eagle_Logo_Black.png",
	];

	const partnersLogo = [
		"https://adfastcorpcdnak.azureedge.net/b2b/About%20Us/asc-logo-eps.png",
		"https://adfastcorpcdnak.azureedge.net/b2b/About%20Us/asso-entrepreneurs-maconnerie-small.png",
		"https://adfastcorpcdnak.azureedge.net/b2b/About%20Us/avfq-logo.png",
		"https://adfastcorpcdnak.azureedge.net/b2b/About%20Us/BFI_logo_2018_couleurs.jpg",
		"https://adfastcorpcdnak.azureedge.net/b2b/About%20Us/fencan-logo.png",
		"https://adfastcorpcdnak.azureedge.net/b2b/About%20Us/fenman-logo.png",
		"https://adfastcorpcdnak.azureedge.net/b2b/About%20Us/logo-aermq.png",
		"https://adfastcorpcdnak.azureedge.net/b2b/About%20Us/logo-amcq.png",
		"https://adfastcorpcdnak.azureedge.net/b2b/About%20Us/logo-apchq.png",
		"https://adfastcorpcdnak.azureedge.net/b2b/About%20Us/logo-cebq.png",
		"https://adfastcorpcdnak.azureedge.net/b2b/About%20Us/logo-dcc-mtl.jpg",
		"https://adfastcorpcdnak.azureedge.net/b2b/About%20Us/mcgill_small.png",
		"https://adfastcorpcdnak.azureedge.net/b2b/About%20Us/shrinerscanadalogo-proud-supporter-bilingual-e1682603995714.png",
	];

	const spotlignts = [
		{
			id: 1,
			url: "https://www.lapresse.ca/affaires/portfolio/manufacturiers-innovants/201812/07/01-5207178-grace-aux-investissements-en-technologie-adfast-a-quadruple-ses-profits-depuis-2012.php",
			img: "https://adfastcorpcdnak.azureedge.net/b2b/Spotlight/1599319-yves-dandurand-president-dadfast.jpg",
			title_fr: "Manufacturiers innovants | Adfast",
			title_en: "Innovative manufacturers | Adfast",
			desc_fr:
				"Chez le fabricant de scellants et d'adhésifs Adfast, les machines sont programmables et communiquent entre elles depuis belle lurette afin de fabriquer des produits sur mesure pour les clients de l'industrie de la construction.",
			desc_en:
				"Adfast, a manufacturer of sealants and adhesives, has been programming and communicating with each other for many years to produce custom products for customers in the construction industry.",
		},
		{
			id: 2,
			url: "https://www.avfq.ca/evenements/congres-2023",
			img: "https://adfastcorpcdnak.azureedge.net/b2b/Spotlight/AVFQ.jpg",
			title_fr: "Gala Prix Lumières 2023 organisé par l'AVFQ",
			title_en: "Prix Lumières 2023 Gala organized by AVFQ",
			desc_fr:
				"Adfast est fier d'avoir remporté le prix de l'Innovation au gala des Prix Lumières organisé par l'AVFQ, pour nos Adrobots (Robot Kuka) sur les lignes de production.",
			desc_en:
				"Adfast is proud to have won the Innovation Award at the Prix Lumières gala organized by the AVFQ, for our Adrobots (Kuka robot) on production lines.",
		},
		{
			id: 3,
			url: "https://www.lesaffaires.com/dossiers-partenaires/exportation-des-pme-dici-se-demarquent/a-la-decouverte-dentreprises-dici-qui-ont-perce-le-globe--partie-3/629822",
			img: "https://adfastcorpcdnak.azureedge.net/b2b/Spotlight/Mercador.png",
			title_fr: "À la découverte d’entreprises d’ici qui ont percé le globe",
			title_en: "Discovering local companies that have crossed the globe",
			desc_fr:
				"Dans le cadre du Gala MercadOr Québec, qui a eu lieu le 4 novembre 2021, 10 entreprises d’ici se sont démarquées. Adfast remporte le Prix Leader à l'export pour sa forte croissance en Amérique du Nord.",
			desc_en:
				"During the MercadOr Quebec Gala, that took place on November 4, 2021, 10 local companies stood out. Adfast won the Export Leader Award for its strong growth in North America.",
		},
		{
			id: 4,
			url: "https://www.lapresse.ca/affaires/portfolio/virage-numerique/201803/27/01-5158880-adfast-quand-les-machines-travaillent-en-equipe.php",
			img: "https://adfastcorpcdnak.azureedge.net/b2b/Spotlight/VirageNumerique.jpg",
			title_fr: "Quand les machines travaillent en équipe",
			title_en: "When machines work as a team",
			desc_fr:
				"Les clients d'Adfast pourront bientôt activer à distance ses chaînes de production et suivre l'état de leur commande de bout en bout sans l'intervention d'un seul humain.",
			desc_en:
				"Adfast's customers will soon be able to remotely activate its production lines and track the status of their order from start to finish without the intervention of a single human.",
		},
		{
			id: 5,
			url: "https://www.lesaffaires.com/strategie-d-entreprise/management/cette-equipe-na-jamais-aussi-bien-communique-quen-teletravail/631978",
			img: "https://adfastcorpcdnak.azureedge.net/b2b/Spotlight/Teletravail.png",
			title_fr: "Télétravail| Adfast",
			title_en: "Remote working | Adfast",
			desc_fr:
				"Après deux ans en télétravail,  l’équipe administrative d’Adfast continue de travailler à distance. Non seulement le niveau de stress des employés est réduit, mais ils n’ont jamais si bien communiqué qu’à distance.",
			desc_en:
				"After two years of working from home, Adfast's administrative team continues to work remotely. Not only is the stress level of the employees reduced, but they have never communicated as well as they do remotely.",
		},
	];

	const getFirst30Words = (text) => {
		const words = text.split(" ");
		return words.length > 30 ? words.slice(0, 30).join(" ") + " ... " : text;
	};

	const content = (
		<div className={classes.text}>
			<p>
				{t(keys.ABOUTUS.CEO_P1_1)}
				<span>{t(keys.ABOUTUS.CEO_P1_SPAN1)}</span>
				{t(keys.ABOUTUS.CEO_P1_2)}
				<span>{t(keys.ABOUTUS.CEO_P1_SPAN2)}</span>
				{t(keys.ABOUTUS.CEO_P1_3)}
			</p>
			<p>
				{t(keys.ABOUTUS.CEO_P2)}{" "}
				{gridWidth <= 833 && (
					<span onClick={() => setIsExpanded(false)} className={classes.seenMoreButton}>
						{t(keys.HOME.BUTTON_SEE_LESS)}
					</span>
				)}
			</p>
		</div>
	);

	return (
		<div className={classes.layout} ref={windowRef}>
			<h1 className={classes.title}>{t(keys.ABOUTUS.TITLE)}</h1>
			<p className={classes.intro}>{t(keys.ABOUTUS.INTRO)}</p>
			<CarouselProvider
				className={classes.carousel}
				naturalSlideWidth={10}
				naturalSlideHeight={12}
				totalSlides={brandLogos.length}
				visibleSlides={gridWidth <= 513 ? 2.1 : gridWidth <= 748 ? 3 : 4}
				isPlaying
				interval={3000}
			>
				<ButtonBack className={classes.btn}>
					<Icon className={classes.icon} icon="bi:arrow-left" />
				</ButtonBack>
				<Slider className={classes.slider}>
					{brandLogos.map((picture, index) => (
						<Slide innerClassName={classes.slide} index={index} key={index}>
							<img src={picture} alt="url" className={classes.slideImg} key={index} />
						</Slide>
					))}
				</Slider>
				<ButtonNext className={classes.btn}>
					<Icon className={classes.icon} icon="bi:arrow-right" />
				</ButtonNext>
			</CarouselProvider>
			<div className={classes.ceoIntro}>
				<div className={classes.img}>
					<img
						src="https://adfastcorpcdnak.azureedge.net/b2b/About%20Us/Yves_Claude.png"
						alt="Adfast fondators"
					/>
					<div className={classes.slogans}>
						<h2>{t(keys.ABOUTUS.CEO_H2)}</h2>
						<h1>{t(keys.ABOUTUS.CEO_H1)}</h1>
					</div>
				</div>
				{gridWidth > 833 || isExpanded ? (
					<>{content}</>
				) : (
					<div className={classes.text}>
						<p>
							{getFirst30Words(t(keys.ABOUTUS.CEO_P1_1))}
							<span onClick={() => setIsExpanded(true)} className={classes.seenMoreButton}>
								{t(keys.HOME.BUTTON_SEE_MORE)}
							</span>
						</p>
					</div>
				)}
			</div>
			<h1 className={classes.valuesTitle}>{t(keys.ABOUTUS.VALUE)}</h1>
			<div className={classes.values}>
				<div className={classes.value}>
					<h1>{t(keys.ABOUTUS.AGILITY)}</h1>
					<img src="https://adfastcorpcdnak.azureedge.net/b2b/About%20Us/Agility.png" alt="agility sign" />
					<h2>{t(keys.ABOUTUS.ADJUST)}</h2>
					<p>{t(keys.ABOUTUS.AGILITY_P)}</p>
				</div>
				<div className={classes.value}>
					<h1>{t(keys.ABOUTUS.INGENIOSITY)}</h1>
					<img
						src="https://adfastcorpcdnak.azureedge.net/b2b/About%20Us/Ingeniosity.png"
						alt="ingeniosity sign"
					/>
					<h2>{t(keys.ABOUTUS.INNOVATE)}</h2>
					<p>{t(keys.ABOUTUS.INGENIOSITY_P)}</p>
				</div>
				<div className={classes.value}>
					<h1>{t(keys.ABOUTUS.AUDACITY)}</h1>
					<img src="https://adfastcorpcdnak.azureedge.net/b2b/About%20Us/Audacity.png" alt="audacity sign" />
					<h2>{t(keys.ABOUTUS.DARE)}</h2>
					<p>{t(keys.ABOUTUS.AUDACITY_P)}</p>
				</div>
				<div className={classes.value}>
					<h1>{t(keys.ABOUTUS.GOODWILL)}</h1>
					<img src="https://adfastcorpcdnak.azureedge.net/b2b/About%20Us/Goodwill.png" alt="goodwill sign" />
					<h2>{t(keys.ABOUTUS.HELP)}</h2>
					<p>{t(keys.ABOUTUS.GOODWILL_P)}</p>
				</div>
			</div>
			<h1 className={classes.cultureTitle}>{t(keys.ABOUTUS.CULTURE_TITLE)}</h1>
			<p className={classes.cultureDescription}>{t(keys.ABOUTUS.CULTURE_DESCRIPTION)}</p>
			<div className={classes.cultures}>
				<div className={classes.culture}>
					<div className={classes.img}>
						<div>
							<h1>{t(keys.ABOUTUS.CULTURE1_H1)}</h1>
							<h2>{t(keys.ABOUTUS.CULTURE1_H2)}</h2>
						</div>
						<img
							src="https://aceafcstorageprod01.blob.core.windows.net/public-images/b2b/About%20Us/Femme_usine.png"
							alt="women in production"
						/>
					</div>
					<div className={classes.details}>
						<p>{t(keys.ABOUTUS.CULTURE1_P1)}</p>
						<h3>{t(keys.ABOUTUS.CULTURE1_H3)}</h3>
						<div className={classes.statistic}>
							<div>
								<img src={p47} alt="" />
								<p>{t(keys.ABOUTUS.MINORITY)}</p>
							</div>
							<div>
								<img src={p31} alt="" />
								<p>{t(keys.ABOUTUS.WOMEN)}</p>
							</div>
							<div>
								<img src={p63} alt="" />
								<p>{t(keys.ABOUTUS.WOMEN_ENG)}</p>
							</div>
							<div>
								<img src={p60} alt="" />
								<p>{t(keys.ABOUTUS.WOMEN_VP)}</p>
							</div>
							<div>
								<img src={p45} alt="" />
								<p>{t(keys.ABOUTUS.WOMEN_D)}</p>
							</div>
							<div>
								<img src={p45} alt="" />
								<p>{t(keys.ABOUTUS.YOUTH)}</p>
							</div>
						</div>
						<p className={classes.description}>{t(keys.ABOUTUS.CULTURE_DESCRIPTION)}</p>
					</div>
				</div>
				<div className={classes.culture}>
					<div className={classes.img}>
						<div>
							<h1>{t(keys.ABOUTUS.CULTURE2_H1)}</h1>
							<h2>{t(keys.ABOUTUS.CULTURE2_H2)}</h2>
						</div>
						<img
							src="https://adfastcorpcdnak.azureedge.net/b2b/About%20Us/Employ%C3%A9_Photo.png"
							alt="collaboration"
						/>
					</div>
					<div className={classes.details}>
						<p>{t(keys.ABOUTUS.CULTURE2_P)}</p>
						<ul className={classes.colaboration}>
							<li>
								<p>{t(keys.ABOUTUS.CULTURE2_LI1)}</p>
							</li>
							<li>
								<p>{t(keys.ABOUTUS.CULTURE2_LI2)}</p>
							</li>
							<li>
								<p>{t(keys.ABOUTUS.CULTURE2_LI3)}</p>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<h1 className={classes.cultureTitle}>{t(keys.ABOUTUS.SPOTLIGHT)}</h1>
			<CarouselProvider
				className={classes.spotlightCarousel}
				naturalSlideWidth={10}
				naturalSlideHeight={12}
				totalSlides={spotlignts.length}
				visibleSlides={gridWidth <= 513 ? 1.1 : gridWidth <= 748 ? 3 : 4}
			>
				<Slider className={classes.slider}>
					{spotlignts.map((s, index) => (
						<Slide innerClassName={classes.slide} index={s.id} key={s.id}>
							<CustomNavLink to={s.url} target={"_blank"} className={classes.spotlight}>
								<div className={classes.imgContainer}>
									<img src={s.img} alt="url" key={index} />
								</div>
								<div className={classes.textContainer}>
									<h2>{lngId.includes("fr") ? s.title_fr : s.title_en}</h2>
									<p>{lngId.includes("fr") ? s.desc_fr : s.desc_en}</p>
								</div>
							</CustomNavLink>
						</Slide>
					))}
				</Slider>
				<div className={classes.buttons}>
					<ButtonBack className={classes.btn}>
						<Icon className={classes.icon} icon="bi:arrow-left" />
					</ButtonBack>
					<ButtonNext className={classes.btn}>
						<Icon className={classes.icon} icon="bi:arrow-right" />
					</ButtonNext>
				</div>
			</CarouselProvider>
			<h1 className={classes.cultureTitle}>{t(keys.ABOUTUS.PARTNERS)}</h1>
			<CarouselProvider
				className={classes.carousel}
				naturalSlideWidth={10}
				naturalSlideHeight={12}
				totalSlides={partnersLogo.length}
				visibleSlides={gridWidth >= 1000 ? 5 : gridWidth >= 800 ? 4 : 2}
				isPlaying
				interval={3000}
			>
				<ButtonBack className={classes.btn}>
					<Icon className={classes.icon} icon="bi:arrow-left" />
				</ButtonBack>
				<Slider className={classes.slider}>
					{partnersLogo.map((picture, index) => (
						<Slide innerClassName={classes.slide} index={index} key={index}>
							<img src={picture} alt="url" className={classes.slideImg} key={index} />
						</Slide>
					))}
				</Slider>
				<ButtonNext className={classes.btn}>
					<Icon className={classes.icon} icon="bi:arrow-right" />
				</ButtonNext>
			</CarouselProvider>
		</div>
	);
};

export default AboutUs;
