import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";

import classes from "./CustDropDown.module.scss";

const CustDropDown = (props) => {
	const dropDownRef = useRef();

	const [isOpen, setIsOPen] = useState(false);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
				close();
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const renderElements = (elements, group, grpIdx) => {
		return (
			<div className={classes.list}>
				{!props.multiSelection && (
					<div
						className={`${classes.element} ${props.elementClassName || ""}`}
						key={-1}
						onClick={() => selectElement(undefined)}
					>
						{" "}
					</div>
				)}
				{elements.map((element, index) =>
					!props.multiSelection ? (
						<div
							className={`${classes.element} ${props.elementClassName || ""}`}
							key={element.id || index}
							onClick={() => selectElement(element)}
						>
							{element.value || element}
						</div>
					) : element.values && element.label ? (
						<div className={`${classes.group} ${props.groupClassName || ""}`} key={element.id || index}>
							<h1>{element.label}</h1>
							{renderElements(element.values, true, index)}
						</div>
					) : (
						<div
							className={`${classes.element} ${group ? classes.group : ""} ${classes.multiselection} ${
								props.elementClassName || ""
							}`}
							key={element.id}
						>
							<input
								type="checkbox"
								id={element.id}
								name={element.id}
								onChange={(event) => checkboxChangeHandler(event, elements)}
								checked={props.checked?.some((el) => el.id === element.id)}
							/>
							<label htmlFor={element.id}>{element.value}</label>
						</div>
					)
				)}
			</div>
		);
	};

	const selectElement = (value) => {
		props.onCheck(value);
		setIsOPen(false);
		if (props.close) props.close(value);
	};

	const checkboxChangeHandler = (event, list) => {
		if (event.target.checked) {
			props.onCheck([...props.checked, list.filter((element) => element.id === event.target.id)[0]]);
		} else {
			props.onCheck(props.checked.filter((element) => element.id !== event.target.id));
		}
	};

	const close = () => {
		setIsOPen(false);
		if (props.close) props.close();
	};

	return (
		<div className={`${classes.layout} ${props.className || ""}`} ref={dropDownRef}>
			<div
				className={`${classes.header} ${props.disable ? classes.disable : ""} ${props.headerClassName || ""}`}
				onClick={() => (!props.disable ? setIsOPen(!isOpen) : setIsOPen(false))}
			>
				{props.label && (
					<label>
						{!props.multiSelection && props.checked ? props.checked.value || props.checked : props.label}
					</label>
				)}
				{!isOpen && (
					<Icon
						className={`${classes.icon} ${isOpen ? classes.open : ""}`}
						icon="material-symbols:arrow-drop-down"
					/>
				)}
				{isOpen && (
					<Icon
						className={`${classes.icon} ${isOpen ? classes.open : ""}`}
						icon="material-symbols:arrow-drop-up"
					/>
				)}
			</div>

			<div className={`${classes.listLayout} ${props.listLayoutClassName || ""} ${isOpen ? classes.open : ""}`}>
				{props.data && renderElements(props.data)}
			</div>
		</div>
	);
};

export default CustDropDown;
