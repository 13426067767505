import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import { ImageMap } from "@qiuz/react-image-map";
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from "pure-react-carousel";

import useElementSize from "../../../hooks/useElementSize";
import Loading from "../../ui/loading/Loading";
import ModalVideo from "../ModalVideo/ModalVideo";
import ProductCard from "../../products/ProductList/ProductGrid/ProductCard/ProductCard";
import Pagination from "../../ui/Pagination/Pagination";
import SegProductCard from "../SegProductCard/SegProductCard";
import Button from "../../ui/Button/Button";
import CustomNavLink from "../../ui/customNavLink/CustomNavLink";

import {
	getSubsegment,
	isLoadingSelector,
	subsegmentsSelector,
	subSegmentVideoSelector,
} from "../../../store/subSegment";
import { getItemCarts, itemCartsSelector, itemIsLoadingSelector, productCountSelector } from "../../../store/product";
import { pageSelector, setPage } from "../../../store/productQuery";

import { keys } from "../../../locales/localeskeys";
import logo from "../../../assets/svg/adfast-logo-black.svg";
import adrobot from "../../../assets/jpeg/adrobot-image.jpg";
import adrobot_logo from "../../../assets/png/adrobot-logo.png";

import { constants as constant } from "../../../locales/constant";
import classes from "./SubManufacturing.module.scss";

const SubManufacturing = () => {
	const dispatch = useDispatch();

	const { i18n, t } = useTranslation();
	const lngId = i18n.language;

	const navigate = useNavigate();

	const params = useParams();
	const { subSegemtId } = params;

	const itemList = useSelector(itemCartsSelector);

	const itemsLoading = useSelector(itemIsLoadingSelector);
	const isLoading = useSelector(isLoadingSelector);
	const subSegments = useSelector(subsegmentsSelector);
	const subSegmentVideos = useSelector((state) => subSegmentVideoSelector(state, subSegemtId));
	const currentPage = useSelector(pageSelector);
	const productStore = useSelector(productCountSelector);

	const [gridRef, { width: gridWidth, height: heightWidth }] = useElementSize();

	const [curSubSegment, setCurSubSegment] = useState();
	const [subSegmentVideosRenderAdd, setSubSegmentVideosRenderAdd] = useState();
	const [subSegmentVideosRenderDefault, setSubSegmentVideosRenderDefault] = useState();
	const [isOpen, setOpen] = useState(false);
	const [curVideoId, setCurVideoId] = useState();
	const [curImg, setImg] = useState();
	const [isExpanded, setIsExpanded] = useState(false);

	useEffect(() => {
		dispatch(setPage(1));
	}, []);

	const changePageHandler = (page) => {
		dispatch(setPage(page));
	};

	const openModal = (videoId, img) => {
		setOpen(true);
		setCurVideoId(videoId);
		setImg(img);
	};

	const closeModal = () => {
		setOpen(false);
		setCurVideoId(undefined);
		setImg(undefined);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		if ((isLoading === false && !subSegments) || subSegments.length === 0)
			dispatch(
				getSubsegment({
					type: 2,
					lng: lngId,
					subSegment: subSegemtId,
				})
			);
	}, []);

	useEffect(() => {
		if (curSubSegment)
			dispatch(
				getItemCarts({
					priority: 1,
					subSegs: [curSubSegment.erp_code],
					pageSize: 12,
				})
			);
	}, [currentPage, curSubSegment]);

	useEffect(() => {
		if (subSegmentVideos) {
			setSubSegmentVideosRenderAdd(
				subSegmentVideos.filter(
					(video) =>
						((lngId.includes("fr")
							? video.language.code.includes("fr")
							: video.language.code.includes("en")) ||
							video.language === undefined) &&
						!video.primary
				)
			);
			setSubSegmentVideosRenderDefault(
				subSegmentVideos.filter(
					(video) =>
						((lngId.includes("fr")
							? video.language.code.includes("fr")
							: video.language.code.includes("en")) ||
							video.language === undefined) &&
						video.primary
				)
			);
		}
	}, [subSegmentVideos, lngId]);

	useEffect(() => {
		if (subSegments) {
			const sub = subSegments.find((sub) => sub.erp_code === subSegemtId);
			if (sub) setCurSubSegment(sub);
		}
	}, [subSegments]);

	const onMapClick = (area) => {
		navigate({ pathname: "/adstore/overview/" + area.href });
	};

	const toggleExpand = () => setIsExpanded(!isExpanded);

	function splitParagraph(paragraph) {
		const words = paragraph.split(" ");
		const first20Words = words.slice(0, 20).join(" ");
		const remainingWords = words.slice(20).join(" ");

		return {
			first20Words,
			remainingWords,
		};
	}

	return (
		<Fragment>
			<ModalVideo videoId={curVideoId} img={curImg} isOpen={isOpen} onClose={closeModal} />
			{(!curSubSegment || isLoading) && <Loading className={classes.layout} type={"linear"} />}
			{curSubSegment && !isLoading && (
				<Fragment>
					<img className={classes.bannerLogo} src={curSubSegment.img} alt={curSubSegment.img} />
					<div className={classes.layout} ref={gridRef}>
						<h1 className={classes.title}>
							{lngId.includes("fr") ? curSubSegment.title_fr : curSubSegment.title_en}
						</h1>

						<p className={classes.description}>
							{(() => {
								const description = lngId?.includes("fr")
									? curSubSegment.description_fr
									: curSubSegment.description_en;
								const { first20Words, remainingWords } = splitParagraph(description);

								if (gridWidth > 833 || !remainingWords) {
									return description;
								} else {
									return (
										<>
											{isExpanded ? description : `${first20Words}...`}
											<span onClick={toggleExpand} className={classes.seenMoreButton}>
												{isExpanded
													? t(keys.HOME.BUTTON_SEE_LESS)
													: t(keys.HOME.BUTTON_SEE_MORE)}
											</span>
										</>
									);
								}
							})()}
						</p>
						<div className={classes.videoLayout}>
							<div
								className={classes.left}
								style={{
									display: !subSegmentVideos || subSegmentVideos.length === 0 ? "none" : undefined,
								}}
							>
								{subSegmentVideosRenderDefault.map((video) => (
									<div
										key={video.id}
										className={classes.defaultSubSegmentVideo}
										onClick={() => openModal(video.url, video.img)}
									>
										<img
											src={`https://img.youtube.com/vi/${video.url}/hqdefault.jpg`}
											alt={video.title}
										/>
										<Icon className={classes.iconPlay} icon="fa:play" />
									</div>
								))}
								{subSegmentVideosRenderAdd && subSegmentVideosRenderAdd.length > 0 && (
									<div className={classes.additionalVideosLayout}>
										{subSegmentVideosRenderAdd.map((video) => (
											<div
												key={video.id}
												className={classes.subSegmentVideo}
												onClick={() => openModal(video.url, video.img)}
											>
												<img
													src={`https://img.youtube.com/vi/${video.url}/hqdefault.jpg`}
													alt={video.title}
												/>
											</div>
										))}
									</div>
								)}
							</div>
							<div
								className={classes.right}
								style={{
									width: !subSegmentVideos || subSegmentVideos.length === 0 ? "100%" : undefined,
								}}
							>
								{subSegmentVideosRenderDefault && subSegmentVideosRenderDefault.length > 0 && (
									<div className={classes.defaultVideosText}>
										<h1>{subSegmentVideosRenderDefault[0].title}</h1>
										<hr />
										{subSegmentVideosRenderDefault[0].description && (
											<p className={classes.details}>
												{subSegmentVideosRenderDefault[0].description}
											</p>
										)}
										<div
											className={classes.mobileVideo}
											style={{
												display:
													!subSegmentVideos || subSegmentVideos.length === 0
														? "none"
														: undefined,
											}}
										>
											{subSegmentVideosRenderDefault.map((video) => (
												<div
													key={video.id}
													className={classes.defaultSubSegmentVideo}
													onClick={() => openModal(video.url, video.img)}
												>
													<img
														src={`https://img.youtube.com/vi/${video.url}/hqdefault.jpg`}
														alt={video.title}
													/>
													<Icon className={classes.iconPlay} icon="fa:play" />
												</div>
											))}
											{subSegmentVideosRenderAdd && subSegmentVideosRenderAdd.length > 0 && (
												<div className={classes.additionalVideosLayout}>
													{subSegmentVideosRenderAdd.map((video) => (
														<div
															key={video.id}
															className={classes.subSegmentVideo}
															onClick={() => openModal(video.url, video.img)}
														>
															<img
																src={`https://img.youtube.com/vi/${video.url}/hqdefault.jpg`}
																alt={video.title}
															/>
														</div>
													))}
												</div>
											)}
										</div>
										{subSegmentVideosRenderDefault[0].detail && (
											<h1>{t(keys.GLOBAL.COMMON.DESCRIPTION)}</h1>
										)}
										{subSegmentVideosRenderDefault[0].detail && (
											<p className={classes.details}>
												{(() => {
													const description = subSegmentVideosRenderDefault[0].detail;
													const { first20Words, remainingWords } = splitParagraph(description);

													if (gridWidth > 833 || !remainingWords) {
														return description;
													} else {
														return (
															<>
																{isExpanded ? description : `${first20Words}...`}
																<span onClick={toggleExpand} className={classes.seenMoreButton}>
												{isExpanded
													? t(keys.HOME.BUTTON_SEE_LESS)
													: t(keys.HOME.BUTTON_SEE_MORE)}
											</span>
															</>
														);
													}
												})()}
											</p>
										)}
									</div>
								)}
								{curSubSegment &&
									(lngId.includes("fr")
										? curSubSegment.sheet_url_fr
										: curSubSegment.sheet_url_en) && (
										<div className={classes.sheet}>
											<h1 onClick={() => {
												window.open(
													lngId.includes("fr")
														? curSubSegment.sheet_url_fr
														: curSubSegment.sheet_url_en,
													"_blank"
												);
											}}>{t(keys.MANUFACTURING.DOWNLOAD_SHEET)} </h1>
											<Icon
												className={classes.icon}
												icon="bxs:file-pdf"
												onClick={() => {
													window.open(
														lngId.includes("fr")
															? curSubSegment.sheet_url_fr
															: curSubSegment.sheet_url_en,
														"_blank"
													);
												}}
											/>
										</div>
									)}
								{curSubSegment.items && curSubSegment.items.length > 0 && (
									<h1 className={classes.productTitle}>{t(keys.MANUFACTURING.PRODUCT_USED)}</h1>
								)}
								{curSubSegment && curSubSegment.imgs && curSubSegment.imgs.length > 0 && (
									<div className={classes.imgMapMobile}>
										<ImageMap
											src={curSubSegment.imgs[0].url}
											map={curSubSegment.imgs[0].imgMaps.map((map) => {
												return {
													width: `${map.width}%`,
													height: `${map.height}%`,
													left: `${map.left}%`,
													top: `${map.top}%`,
													href: map.href,
												};
											})}
											onMapClick={onMapClick}
										/>
									</div>
								)}
								{curSubSegment.items && curSubSegment.items.length > 0 && (
									<div >
										<CarouselProvider
											className={classes.carouselDesktop}
											naturalSlideWidth={10}
											naturalSlideHeight={12}
											totalSlides={curSubSegment.items.length}
											visibleSlides={gridWidth <= 513 ? 1.4 : gridWidth <= 748 ? 2.5 : 2.5}
										>
											<Slider className={classes.slider}>
												{curSubSegment.items.map((item, index) => (
													<Slide innerClassName={classes.slide} index={index} key={index}>
														<SegProductCard
															id={item.id}
															num={index + 1}
															itemId={item.erp_code}
															itemErpCode={item.erp_code}
															brandId={item.brandErpCode}
															brandErpCode={item.brandErpCode}
															key={item.id}
															defaultImage={
																item.defaultImage
																	? item.defaultImage
																	: constant.DEFAULT_IMG
															}
															title={item.title}
															description={
																lngId.includes("fr")
																	? item.description_fr
																	: item.description_en
															}
															ProductHeaderLabel={
																lngId.includes("fr") ? item.label_fr : item.label_en
															}
															hex={item.hex}
															formats={item.formats}
															width={"14rem"}
															height={"25rem"}
														/>
													</Slide>
												))}
											</Slider>
											<div className={classes.btns}>
												<ButtonBack className={classes.btn}>
													<Icon className={classes.icon} icon="bi:arrow-left-square-fill" />
												</ButtonBack>
												<ButtonNext className={classes.btn}>
													<Icon className={classes.icon} icon="bi:arrow-right-square-fill" />
												</ButtonNext>
											</div>
										</CarouselProvider>
									</div>
								)}
							</div>
						</div>
						{curSubSegment && curSubSegment.imgs && curSubSegment.imgs.length > 0 && (
							<div className={classes.imgMapWeb}>
								<ImageMap
									src={curSubSegment.imgs[0].url}
									map={curSubSegment.imgs[0].imgMaps.map((map) => {
										return {
											width: `${map.width}%`,
											height: `${map.height}%`,
											left: `${map.left}%`,
											top: `${map.top}%`,
											href: map.href,
										};
									})}
									onMapClick={onMapClick}
								/>
							</div>
						)}
						{itemsLoading && <Loading type={"bounce"} />}
						{!itemsLoading && itemList && itemList.length > 0 && (
							<div className={classes.productsList}>
								<h1>{t(keys.MANUFACTURING.PRODUCT_GRID_TITLE)}</h1>
								<div className={classes.gridContainer}>
									{itemList.map((item) => (
										<ProductCard
											id={item.id}
											itemId={item.itemId}
											itemErpCode={item.erp_code}
											brandId={item.brandId}
											brandErpCode={item.brandErpCode}
											key={item.id}
											defaultImage={item.defaultImage ? item.defaultImage : constant.DEFAULT_IMG}
											title={item.title}
											description={
												lngId.includes("fr") ? item.description_fr : item.description_en
											}
											ProductHeaderLabel={lngId.includes("fr") ? item.label_fr : item.label_en}
											formats={item.formats}
										/>
									))}
								</div>
								<Pagination
									className={classes.pagination}
									currentPage={currentPage}
									totalCount={productStore}
									pageSize={12}
									onPageChange={(page) => changePageHandler(page)}
								/>
							</div>
						)}
						<div className={classes.adRobotLayout}>
							<h1>{t(keys.ARCHITECT.EVENT.ADROBOT_TITLE)}</h1>
							<p>{t(keys.ARCHITECT.EVENT.ADROBOT_DESCRIPTION)}</p>
							<div className={classes.bDetails}>
								<img src={adrobot} alt="adrobot image" />
								<div className={classes.bRight}>
									<div className={classes.imgContainer}>
										<img src={adrobot_logo} alt="adrobot logo" />
									</div>
									<p>{t(keys.ARCHITECT.EVENT.ADROBOT_TEXT)}</p>
									<div className={classes.iconContainer}>
										<CustomNavLink className={classes.btnLink} to={"/adrobot"}>
											<Button className={classes.btn}>
												<Icon
													className={classes.icon}
													icon="material-symbols:arrow-right-alt-rounded"
												/>
											</Button>
										</CustomNavLink>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default SubManufacturing;
