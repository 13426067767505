import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import DTech from "../components/automation/DTech";

const DTechPage = () => {
	const { t } = useTranslation();
	return (
		<>
			<Helmet>
				<title>{"Adfast | Dispensing Technologies"}</title>
				<meta name="description" content={"Adfast Dispensing Technologies"} />
				<meta property="og:title" content={"Adfast | Dispensing Technologies"} />
				<meta property="og:description" content={"Adfast Dispensing Technologies"} />
				<meta name="robots" content="index, follow" />
				<meta property="og:type" content="website" />
				<link rel="apple-touch-icon" sizes="180x180" href="/static/favicon/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/static/favicon/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/static/favicon/favicon-16x16.png" />
				<link rel="manifest" href="/static/favicon/site.webmanifest" />
			</Helmet>
			<DTech />
		</>
	);
};

export default DTechPage;
