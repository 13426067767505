import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactDOM from "react-dom";

import TextInputOverlay from "../ui/Input/TextInputOverlay/TextInputOverlay";
import Button from "../ui/Button/Button";

import classes from "./ModalOrderColor.module.scss";
import { keys } from "../../locales/localeskeys";

const ModalOrderColor = (props) => {
	const { t } = useTranslation();

	const protalElement = document.getElementById("overlays");

	const [firstNameState, setFirstName] = useState({ value: "", isValid: false });
	const [lastNameState, setLastName] = useState({ value: "", isValid: false });
	const [companyNameState, setCompanyName] = useState({ value: "", isValid: false });
	const [emailState, setEmail] = useState({ value: "", isValid: false });
	const [phoneNumberState, setPhoneNumber] = useState({ value: "", isValid: false });
	const [etatState, setEtatState] = useState({ value: "", isValid: false });
	const [displayFormNotValid, setDisplayFormNotValid] = useState(false);
	const [fieldChanged, setFieldChanged] = useState(false);
	const [formSubmitted, setFormSubmitted] = useState(false);

	const isFormValid =
		firstNameState.isValid &&
		lastNameState.isValid &&
		companyNameState.isValid &&
		emailState.isValid &&
		phoneNumberState.isValid &&
		etatState.isValid;

	const firstNameChangeHandler = (event) => {
		setFirstName({
			value: event.target.value,
			isValid: event.target.value !== "",
		});
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const lastNameChangeHandler = (event) => {
		setLastName({
			value: event.target.value,
			isValid: event.target.value !== "",
		});
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const companyNameChangeHandler = (event) => {
		setCompanyName({
			value: event.target.value,
			isValid: event.target.value !== "",
		});
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const emailChangeHandler = (event) => {
		const emailRegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,15}$/;
		setEmail({
			value: event.target.value,
			isValid: event.target.value !== "" && emailRegExp.test(event.target.value.trim()),
		});
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const phoneNumberChangeHandler = (event) => {
		setPhoneNumber({
			value: event.target.value,
			isValid: event.target.value !== "",
		});
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};

	const stateChangeHandler = (event) => {
		setEtatState({
			value: event.target.value,
			isValid: event.target.value !== "",
		});
		setDisplayFormNotValid(false);
		setFieldChanged(true);
	};
	const resetFormFields = () => {
		setFirstName({ value: "", isValid: false });
		setLastName({ value: "", isValid: false });
		setCompanyName({ value: "", isValid: false });
		setEmail({ value: "", isValid: false });
		setPhoneNumber({ value: "", isValid: false });
		setEtatState({ value: "", isValid: false });
	};
	const submitHandler = (event) => {
		if (!isFormValid || !fieldChanged) {
			event.preventDefault();
			return;
		}
		setFormSubmitted(true);
		resetFormFields();
	};

	useEffect(() => {
		if (formSubmitted) {
			const timer = setTimeout(() => {
				// Fermer le formulaire après 5 secondes
				props.onClose(); // Appeler la méthode onClose fournie en tant que prop
				// Réinitialiser l'état après le traitement
				setFormSubmitted(false);
				setFieldChanged(false);
			}, 5000);

			return () => clearTimeout(timer); // Nettoyer le timer si le composant est démonté avant que le délai soit écoulé
		}
	}, [formSubmitted]);

	return (
		<Fragment>
			{ReactDOM.createPortal(
				<div>
					{props.isOpen && (
						<section className={classes.bg}>
							<div className={classes.align}>
								<div className={classes.content}>
									<button className={classes.closeButton} onClick={props.onClose}>
										X
									</button>
									<main>
										<div className={classes.innerMain}>
											<div className={classes.container}>
												{!formSubmitted && (
													<div>
														<div className={classes.title}>
															<h1>{t(keys.PARTNERS.MODAL.TITLE)}</h1>
														</div>
														<div className={classes.formWrapper}>
															<form onSubmit={submitHandler} action={props.action}>
																<fieldset>
																	<TextInputOverlay
																		id={"FirstName"}
																		label={t(keys.PARTNERS.MODAL.FIRSTNAME)}
																		name={"First_Name"}
																		isValid={firstNameState.isValid}
																		value={firstNameState.value}
																		onChange={firstNameChangeHandler}
																		error={
																			displayFormNotValid &&
																			t(keys.PARTNERS.MODAL.FILL_MANDATORY_FIELD)
																		}
																		mandatory
																	/>
																	<TextInputOverlay
																		id={"LastName"}
																		label={t(keys.PARTNERS.MODAL.LASTNAME)}
																		name={"Last_Name"}
																		isValid={lastNameState.isValid}
																		value={lastNameState.value}
																		onChange={lastNameChangeHandler}
																		error={
																			displayFormNotValid &&
																			t(keys.PARTNERS.MODAL.FILL_MANDATORY_FIELD)
																		}
																		mandatory
																	/>
																	<TextInputOverlay
																		id={"Company"}
																		label={t(keys.PARTNERS.MODAL.COMPANY)}
																		name={"Company"}
																		isValid={companyNameState.isValid}
																		value={companyNameState.value}
																		onChange={companyNameChangeHandler}
																		error={
																			displayFormNotValid &&
																			t(keys.PARTNERS.MODAL.FILL_MANDATORY_FIELD)
																		}
																		mandatory
																	/>
																	<TextInputOverlay
																		id={"Email"}
																		label={t(keys.PARTNERS.MODAL.EMAIL)}
																		type={"email"}
																		name={"Email"}
																		isValid={emailState.isValid}
																		value={emailState.value}
																		onChange={emailChangeHandler}
																		error={
																			displayFormNotValid &&
																			t(keys.PARTNERS.MODAL.FILL_MANDATORY_FIELD)
																		}
																		mandatory
																	/>
																	<TextInputOverlay
																		id={"Phone"}
																		label={t(keys.PARTNERS.MODAL.PHONE)}
																		name={"Phone"}
																		isValid={phoneNumberState.isValid}
																		value={phoneNumberState.value}
																		onChange={phoneNumberChangeHandler}
																		error={
																			displayFormNotValid &&
																			t(keys.PARTNERS.MODAL.FILL_MANDATORY_FIELD)
																		}
																		mandatory
																	/>
																	<TextInputOverlay
																		id={"State"}
																		label={t(keys.PARTNERS.MODAL.STATE)}
																		name={"State"}
																		isValid={etatState.isValid}
																		value={etatState.value}
																		onChange={stateChangeHandler}
																		error={
																			displayFormNotValid &&
																			t(keys.PARTNERS.MODAL.FILL_MANDATORY_FIELD)
																		}
																		mandatory
																	/>
																</fieldset>
																<Button
																	type="submit"
																	value="submit"
																	textStyle={{ whiteSpace: "break-spaces" }}
																	style={{
																		background: "#007AFF",
																		borderColor: "#007AFF",
																		borderRadius: "1rem",
																		height: "2.5rem",
																		marginTop: "2rem",
																		margin: "auto",
																		display: "block",
																		width: "100%",
																		maxWidth: "35rem",
																		fontSize: ".9rem",
																	}}
																>
																	{t(keys.PARTNERS.MODAL.BUTTON)}
																</Button>
															</form>
														</div>
													</div>
												)}
												{formSubmitted && (
													<div className={classes.success}>
														<div className={classes.successIcon}></div>
														<div className={classes.successTitle}>
															<h1>{t(keys.PARTNERS.MODAL.SUCCESS_TITLE)}</h1>
														</div>
														<div className={classes.successSubTitle}>
															<p>{t(keys.PARTNERS.MODAL.SUCCESS_FIRST_SUBTITLE)}</p>
															<p>{t(keys.PARTNERS.MODAL.SUCCESS_SECOND_SUBTITLE)}</p>
														</div>
													</div>
												)}
											</div>
										</div>
									</main>
								</div>
							</div>
						</section>
					)}
				</div>,
				protalElement
			)}
		</Fragment>
	);
};

export default ModalOrderColor;
