import classes from "./ItemCard.module.scss";

const ItemCard = (props) => {
	function invertColor(hex) {
		if (hex.indexOf("#") === 0) {
			hex = hex.slice(1);
		}
		if (hex.length === 3) {
			hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
		}
		if (hex.length !== 6) {
			return "#000000";
		}
		const r = 255 - parseInt(hex.slice(0, 2), 16);
		const g = 255 - parseInt(hex.slice(2, 4), 16);
		const b = 255 - parseInt(hex.slice(4, 6), 16);
		if (r * 0.299 + g * 0.587 + b * 0.114 < 186) return "#000000";
		else return "#ffffff";
	}

	return (
		<div className={`${props.className} ${classes.colorCardContainer}`} key={props.id}>
			{props.title && (
				<div
					className={classes.colorCardTitle}
					style={{
						backgroundColor: props.hex ? props.hex : "",
						color: props.hex ? invertColor(props.hex) : "",
					}}
				>
					{props.title}
				</div>
			)}
			{props.image && <img src={props.image} alt={props.image} />}
		</div>
	);
};

export default ItemCard;
