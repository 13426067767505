import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import encodeSlash from "../../../hooks/encodeSlash";
import CustomNavLink from "../../ui/customNavLink/CustomNavLink";

import { keys } from "../../../locales/localeskeys";

import classes from "./SegProductCard.module.scss";

const SegProductCard = (props) => {
	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const search = `?lng=${lngId}`;

	const navigate = useNavigate();

	const linkProvider = () => {
		if (props.brandErpCode)
			if (props.searched_specification)
				return `/adstore/variants/${encodeSlash(props.itemErpCode)}/${encodeSlash(
					props.brandErpCode
				)}/${encodeSlash(props.searched_specification)}/${encodeSlash(search)}`;
			else
				return `/adstore/overview/${encodeSlash(props.itemErpCode)}/${encodeSlash(
					props.brandErpCode
				)}/${encodeSlash(search)}`;
		else return `/adstore/overview/${encodeSlash(props.itemErpCode)}/${encodeSlash(search)}`;
	};

	return (
		<CustomNavLink
			to={linkProvider()}
			className={classes.productCardContainer}
			style={{ width: props.width, height: props.height }}
		>
			{props.hex && (
				<div style={{ backgroundColor: props.hex, height: "3rem" }} className={classes.productCardFamily}>
					{props.title && (
						<p>{props.title.length <= 14 ? props.title : props.title.substring(0, 12) + "..."}</p>
					)}
				</div>
			)}
			<img className={classes.productCardImage} src={props.defaultImage} alt="product.jpeg" />
			<div className={classes.productCardDetails}>
				<div className={classes.productCardDescription}>
					{props.ProductHeaderLabel && <p className={classes.description}>Description</p>}
					<p>{props.ProductHeaderLabel}</p>
				</div>
				{props.colors && (
					<div className={classes.productCardColor}>
						<p>{props.colors}</p>
					</div>
				)}
				{props.formats && (
					<div className={classes.productCardFormat}>
						<p>{props.formats}</p>
					</div>
				)}
				<div className={classes.button}>
					<p>{t(keys.HOME.BUTTON_ADSTORE)}</p>
				</div>
			</div>
		</CustomNavLink>
	);
};

export default SegProductCard;
