import React, { useEffect } from "react";
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from "pure-react-carousel";
import { useTranslation } from "react-i18next";

import useElementSize from "../../../hooks/useElementSize";

import classes from "./Partner.module.scss";
import { keys } from "../../../locales/localeskeys";
import { Icon } from "@iconify/react";
import { getPartnerLogo, PartnerLogoLoadingSelector, PartnerLogoSelector } from "../../../store/partnerLogo";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../ui/loading/Loading";

const Partner = () => {
	const { i18n, t } = useTranslation();
	const lngId = i18n.language;

	const [gridRef, { width: gridWidth }] = useElementSize();

	const dispatch = useDispatch();

	const PartnerLogo = useSelector(PartnerLogoSelector);
	const PartnerLogoLoading = useSelector(PartnerLogoLoadingSelector);

	useEffect(() => {
		dispatch(getPartnerLogo());
	}, []);

	return (
		<div className={classes.container}>
			{PartnerLogoLoading && <Loading type={"bounce"} />}
			{!PartnerLogoLoading && PartnerLogo?.length > 0 && (
				<>
					<h2 className={classes.title}>{t(keys.HOME.PARTNER)}</h2>
					<div ref={gridRef}>
						<CarouselProvider
							className={classes.carousel}
							naturalSlideWidth={100}
							naturalSlideHeight={
								gridWidth >= 1000 ? 180 : gridWidth >= 800 && gridWidth <= 1000 ? 150 : 145
							}
							totalSlides={PartnerLogo?.length}
							visibleSlides={gridWidth >= 1200 ? 7 : gridWidth >= 1000 ? 5 : gridWidth >= 800 ? 4 : 2}
							dragEnabled={false}
							isPlaying
							interval={3000}
						>
							<Slider className={classes.slider}>
								{PartnerLogo?.map((item, index) =>
									item?.certificateUrlFR || item?.certificateUrlEN ? (
										<Slide innerClassName={classes.slide} index={index} key={index}>
											{lngId.includes("fr") ? (
												<img
													onClick={() => {
														const certificateUrl = lngId.includes("fr")
															? item?.certificateUrlFR
															: item?.certificateUrlEN;
														if (certificateUrl) {
															window.open(certificateUrl, "_blank");
														}
													}}
													src={item.logoUrlFR}
													alt="logo"
													className={`${classes.logoImg} ${classes.hasCertificat}`}
												/>
											) : (
												<img
													onClick={() => {
														const certificateUrl = lngId.includes("fr")
															? item?.certificateUrlFR
															: item?.certificateUrlEN;
														if (certificateUrl) {
															window.open(certificateUrl, "_blank");
														}
													}}
													src={item.logoUrlEN}
													alt="logo"
													className={`${classes.logoImg} ${classes.hasCertificat}`}
												/>
											)}
										</Slide>
									) : (
										<Slide innerClassName={classes.slide} index={index} key={index}>
											{lngId.includes("fr") ? (
												<img src={item.logoUrlFR} alt="logo" className={classes.logoImg} />
											) : (
												<img src={item.logoUrlEN} alt="logo" className={classes.logoImg} />
											)}
										</Slide>
									)
								)}
							</Slider>
							<div className={classes.btnLayout}>
								<ButtonBack className={`${classes.btn} ${classes.btnBack}`}>
									<Icon className={classes.icon} icon="bi:arrow-left" />
								</ButtonBack>
								<ButtonNext className={`${classes.btn} ${classes.btnNext}`}>
									<Icon className={classes.icon} icon="bi:arrow-right" />
								</ButtonNext>
							</div>
						</CarouselProvider>
					</div>
				</>
			)}
		</div>
	);
};

export default Partner;
