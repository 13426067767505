import { useTranslation } from "react-i18next";

import classes from "./Banner.module.scss";

const Banner = (props) => {
	const { t } = useTranslation();
	const content = props.content;

	return (
		<div
			className={`${classes.bannerContainer} ${props.className || ""}`}
			style={{
				backgroundImage: `url(${content.background})`,
			}}
		>
			{content.type === "BIG" && <img className={classes.bannerLogo} src={content.logo} alt={content.logo_alt} />}
			{content.introduction && (
				<p className={`${classes.bannerIntroduction} ${props.introductionClassName || ""}`}>
					{t(content.introduction)}
				</p>
			)}
			{content.title && (
				<p className={`${classes.bannerCaption} ${props.titleClassName || ""}`}>{t(content.title)}</p>
			)}
			{content.caption && (
				<p className={`${classes.bannerCaption} ${props.captionClassName}`}>{t(content.caption)}</p>
			)}
			{content.description && <p className={classes.bannerDescription}>{t(content.description)}</p>}
		</div>
	);
};

export default Banner;
