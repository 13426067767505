import React from "react";
import { Icon } from "@iconify/react";

import CustomNavLink from "../customNavLink/CustomNavLink";

import classes from "./LinkTree.module.scss";

const LinkTree = (props) => {
	return (
		<div className={`${props.className || ""} ${classes.Layout}`}>
			{props.tree.map((node, index) => (
				<div className={classes.Node} key={node.to}>
					{index !== 0 && <Icon icon="ep:arrow-right" />}
					<CustomNavLink className={index === props.tree.length - 1 ? classes.Bold : ""} to={node.to}>
						{node.label}
					</CustomNavLink>
				</div>
			))}
		</div>
	);
};

export default LinkTree;
