import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";

const CustomNavLink = React.forwardRef((props, ref) => {
	return (
		<Fragment>
			{props.target !== "_blank" && (
				<NavLink
					to={props.to}
					className={props.className}
					id={props.id}
					style={props.style}
					onClick={props.onClick}
					onMouseOver={props.onMouseOver}
					onMouseOut={props.onMouseOut}
					key={props.key}
				>
					{props.children}
				</NavLink>
			)}
			{props.target === "_blank" && (
				<a
					href={props.to}
					target="_blank"
					className={props.className}
					id={props.id}
					style={props.style}
					onClick={props.onClick}
					onMouseOver={props.onMouseOver}
					onMouseOut={props.onMouseOut}
					key={props.key}
				>
					{props.children}
				</a>
			)}
		</Fragment>
	);
});

export default CustomNavLink;
