import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
	displayImageSelector,
	variantIsLoadingSelector,
	variantSpecificationsNonStock,
	variantSpecificationsStock,
} from "../../../../store/variant";

import VariantSearchBar from "./variantSearchBar/VariantSearchBar";
import VariantInfo from "./VariantInfo/VariantInfo";
import ColorLine from "./colorLigne/ColorLine";
import Loading from "../../../ui/loading/Loading";
import decodeSlash from "../../../../hooks/decodeSlash";
import encodeSlash from "../../../../hooks/encodeSlash";

import { keys } from "../../../../locales/localeskeys";

import classes from "./VariantGrid.module.scss";

const VariantGrid = () => {
	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const params = useParams();
	const { itemid, brandid } = decodeSlash(params);

	const specificationsStock = useSelector(variantSpecificationsStock());
	const specificationsNonStock = useSelector(variantSpecificationsNonStock());
	const variantsLoading = useSelector(variantIsLoadingSelector);
	const displayImage = useSelector(displayImageSelector);

	const matchesHug = useMediaQuery("(min-width:1770px)");
	const matchesXxl = useMediaQuery("(min-width:1600px)");
	const matchesXl = useMediaQuery("(min-width:1430px)");
	const matchesLg = useMediaQuery("(min-width:1250px)");
	const matchesMd = useMediaQuery("(min-width:1070px)");
	const matchesMdXl = useMediaQuery("(min-width:981px)");
	const matchesSm = useMediaQuery("(min-width:940px)");
	const matchesMb = useMediaQuery("(min-width:570px)");

	const [itemsPerLine, setItemsPerLine] = useState(8);
	const [specificationStockRend, setSpecificationStockRend] = useState([]);
	const [specificationNonStockRend, setSpecificationNonStockRend] = useState([]);

	useEffect(() => {
		if (matchesHug) {
			setItemsPerLine(8);
		} else if (matchesXxl) {
			setItemsPerLine(7);
		} else if (matchesXl) {
			setItemsPerLine(6);
		} else if (matchesLg) {
			setItemsPerLine(5);
		} else if (matchesMd) {
			setItemsPerLine(4);
		} else if (matchesMdXl) {
			setItemsPerLine(3);
		} else if (matchesSm) {
			setItemsPerLine(5);
		} else if (matchesMb) {
			setItemsPerLine(4);
		}
	}, [matchesHug, matchesXxl, matchesXl, matchesLg, matchesMd, matchesMdXl, matchesSm, matchesMb]);

	useEffect(() => {
		if (!variantsLoading && specificationsStock && specificationsStock.length > 0) {
			let from = 0;
			let to = itemsPerLine;
			let buff = [];
			while (from < specificationsStock.length && itemsPerLine > 0) {
				buff.push(specificationsStock.slice(from, to));
				from = from + itemsPerLine;
				to =
					from + itemsPerLine >= specificationsStock.length
						? specificationsStock.length
						: from + itemsPerLine;
			}
			setSpecificationStockRend(buff);
		} else {
			setSpecificationStockRend([]);
		}
	}, [specificationsStock.length, variantsLoading, itemsPerLine, lngId]);

	useEffect(() => {
		if (!variantsLoading && specificationsNonStock && specificationsNonStock.length > 0) {
			let from = 0;
			let to = itemsPerLine;
			let buff = [];
			while (from < specificationsNonStock.length && itemsPerLine > 0) {
				buff.push(specificationsNonStock.slice(from, to));
				from = from + itemsPerLine;
				to =
					from + itemsPerLine >= specificationsNonStock.length
						? specificationsNonStock.length
						: from + itemsPerLine;
			}
			setSpecificationNonStockRend(buff);
		} else {
			setSpecificationNonStockRend([]);
		}
	}, [specificationsNonStock.length, variantsLoading, itemsPerLine, lngId]);

	useEffect(() => {
		if (specificationStockRend && specificationStockRend.length === 1 && specificationStockRend[0].length === 1) {
			navigate(
				{
					pathname: `/adstore/variants/${encodeSlash(itemid)}/${encodeSlash(brandid)}/${encodeSlash(
						specificationStockRend[0][0].title
					)}/`,
					search: queryParams.toString(),
				},
				{ replace: true }
			);
		}
	}, [specificationStockRend.length]);

	return (
		<Fragment>
			<div className={classes.variantGridBody}>
				<VariantSearchBar />
				<h1 className={classes.title}>
					{!variantsLoading && specificationNonStockRend && specificationNonStockRend.length > 0
						? lngId.includes("en-us")
							? "Standard Colors"
							: t(keys.PRODUCTS.VARIANT.STANDARD_COLOR)
						: t(keys.PRODUCTS.VARIANT.SELECT_COLOR)}
				</h1>
				<hr />
				{variantsLoading && <Loading type={"linear"} />}
				{!variantsLoading && specificationStockRend && (
					<div className={classes.colorLineGrid}>
						{specificationStockRend.map((line) => (
							<ColorLine
								key={line.map((el) => el.id).join("-")}
								specifications={line}
								length={itemsPerLine}
								stock={true}
								displayImage={displayImage}
							/>
						))}
					</div>
				)}
				{!variantsLoading && specificationNonStockRend && specificationNonStockRend.length > 0 && (
					<div>
						<h1 className={classes.title}>{t(keys.PRODUCTS.VARIANT.ON_DEMAND_COLOR)}</h1>
						<hr />
						{!variantsLoading && specificationNonStockRend && (
							<div className={classes.colorLineGrid}>
								{specificationNonStockRend.map((line) => (
									<ColorLine
										key={line.map((el) => el.id).join("-")}
										specifications={line}
										length={itemsPerLine}
										stock={false}
										displayImage={displayImage}
									/>
								))}
							</div>
						)}
					</div>
				)}
			</div>
			{!variantsLoading && <VariantInfo />}
		</Fragment>
	);
};

export default VariantGrid;
