import React, { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import ReCAPTCHA from "react-google-recaptcha";

import TextInputOverlay from "../../ui/Input/TextInputOverlay/TextInputOverlay";
import NavDrop from "../../navbar/NavDrop/NavDrop";
import CustomNavLink from "../../ui/customNavLink/CustomNavLink";

import { authHasErrorSelector, forgotPassword, isLoadingSelector, isLoggedInSelector } from "../../../store/auth";

import logo_fr from "../../../assets/svg/txt_logo_fr.svg";
import logo_en from "../../../assets/svg/txt_logo_en.svg";
import nologo_fr from "../../../assets/svg/txt_no_logo_fr.svg";
import nologo_en from "../../../assets/svg/txt_no_logo_en.svg";

import { constants } from "../../../locales/constant";
import { keys } from "../../../locales/localeskeys";
import classes from "./ForgotPassword.module.scss";

/**
 * verifier si l'email est valide
 * @param state la valeur de l'email dans .val
 * @param action l'action du reducer
 * @returns {{isValid: boolean, value: *}|{isValid: boolean, value}|{isValid: boolean, value: string}}
 */
const emailReducer = (state, action) => {
	const emailRegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,15}$/;
	if (action.type === "USER_INPUT") {
		return {
			value: action.val,
			// isValid: state.value && emailRegExp.test(state.value.trim()),
		};
	}
	if (action.type === "INPUT_BLUR") {
		return {
			value: state.value,
			isValid: state.value && emailRegExp.test(state.value.trim()),
		};
	}
};

const ForgotPassword = () => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const lngParm = `?lng=${lngId}`;

	const navigate = useNavigate();

	const emailInputRef = useRef();

	const isLoggedIn = useSelector(isLoggedInSelector);
	const isLoading = useSelector(isLoadingSelector);
	const loginHasError = useSelector(authHasErrorSelector);

	const [emailState, dispatchEmail] = useReducer(emailReducer, {
		value: "",
		isValid: null,
	});

	const [emailSent, setEmailSent] = useState(false);
	const [isCaptchaValid, setIsCaptchaValid] = useState(false);

	const [closingDrop, setCLosingDrop] = useState({ id: "", status: false });
	const [activeID, setActiveID] = useState("");

	const { isValid: isEmailValid } = emailState;

	useEffect(() => {
		if (loginHasError) {
			emailInputRef.current.focus();
		}
	}, [loginHasError]);

	const emailChangeHandler = (event) => {
		dispatchEmail({
			type: "USER_INPUT",
			val: event.target.value,
		});
	};

	const validateEmailHandler = () => {
		dispatchEmail({ type: "INPUT_BLUR" });
	};

	const captchaChangeHandler = (value) => {
		if (value) {
			setIsCaptchaValid(value);
		}
	};

	useEffect(() => {
		if (isLoggedIn) {
			navigate(-1);
		}
	}, [isLoggedIn]);

	/**
	 * log in a la soumission du formulaire si tout est valide si non focus sur l'erreur
	 * @param event
	 */
	const submitHandler = (event) => {
		event.preventDefault();
		if (!isCaptchaValid) return;
		if (!emailSent) {
			if (isEmailValid) {
				dispatch(forgotPassword({ email: emailState.value, lngId: lngId }));
				setEmailSent(true);
			} else if (!isEmailValid) {
				emailInputRef.current.focus();
			}
		} else {
			setEmailSent(false);
		}
	};

	const hoverHandler = (id) => {
		if (closingDrop.status === false && closingDrop.id !== id) {
			setActiveID(id);
		}
	};

	const outHandler = () => {
		setActiveID("");
		setTimeout(
			() =>
				setCLosingDrop({
					id: "",
					status: false,
				}),
			300
		);
	};

	const onLocaliseChange = (item) => {
		i18n.changeLanguage(item.children);
	};

	const closeDrop = () => {
		setCLosingDrop({ id: activeID, status: true });
		setActiveID("");
	};

	return (
		<div className={classes.wrapper}>
			<header>
				<div className={classes.innerHeader}>
					<div className={classes.logo}>
						<CustomNavLink to={`/${lngParm}`}>
							<img src={lngId.includes("fr") ? logo_fr : logo_en} alt="logo" />
						</CustomNavLink>
						<div
							id={"8"}
							className={classes.rli}
							onMouseOver={() => {
								hoverHandler("8");
							}}
							onMouseOut={outHandler}
						>
							<p className={activeID === "8" ? classes["active"] : ""}>{lngId}</p>
							<NavDrop
								right={"0"}
								parentId={"8"}
								activeID={activeID}
								height={"9rem"}
								width={"5rem"}
								hide={closeDrop}
								data={[
									{ id: "13", children: "fr-ca" },
									{ id: "14", children: "en-ca" },
									{ id: "15", children: "en-us" },
								]}
								onClick={onLocaliseChange}
							/>
						</div>
					</div>
				</div>
			</header>
			<main>
				<div className={classes.innerMain}>
					<div className={classes.container}>
						<div className={classes.title}>
							<h1>
								{!emailSent
									? t(keys.AUTH.FORGOT_PASSWORD.FORGOT_PASSWORD_QUESTION)
									: t(keys.AUTH.FORGOT_PASSWORD.FORGOT_PASSWORD_SENT)}
							</h1>
						</div>
						<div className={classes.content}>
							<p>
								{!emailSent
									? t(keys.AUTH.FORGOT_PASSWORD.FORGOT_PASSWORD_Description)
									: t(keys.AUTH.FORGOT_PASSWORD.FORGOT_PASSWORD_SENT_Description1)}
							</p>
							{emailSent && (
								<p className={classes.description}>
									{t(keys.AUTH.FORGOT_PASSWORD.FORGOT_PASSWORD_SENT_Description2)}
								</p>
							)}
							{!emailSent && (
								<CustomNavLink className={classes.backLogin} to={`/sign-in${lngParm}`}>
									<Icon className={classes.icon} icon="cil:arrow-left" />
									<p>{t(keys.AUTH.FORGOT_PASSWORD.BACK_TO_LOGIN)}</p>
								</CustomNavLink>
							)}
							<div className={classes.formWrapper}>
								<form onSubmit={submitHandler}>
									<fieldset>
										<legend>
											{!emailSent
												? t(keys.AUTH.FORGOT_PASSWORD.REQUEST_RESET_LINK)
												: t(keys.AUTH.FORGOT_PASSWORD.RESEND_REQUEST)}
										</legend>
										{!emailSent && (
											<TextInputOverlay
												ref={emailInputRef}
												id="email"
												label={t(keys.AUTH.SIGN_IN.EMAIL)}
												type="email"
												isValid={isEmailValid}
												value={emailState.value}
												error={t(keys.AUTH.SIGN_IN.WRONG_EMAIL)}
												onChange={emailChangeHandler}
												onBlur={validateEmailHandler}
											/>
										)}
										<ReCAPTCHA sitekey={constants.sitekey} onChange={captchaChangeHandler} />
										<div className={classes.forgotPassword}>
											<div className={classes.forgotPassword_btn}>
												<button
													className={
														classes[
															!!isLoading || !!isLoggedIn || !isEmailValid
																? "disable"
																: ""
														]
													}
													type="submit"
													disabled={!!isLoading || !!isLoggedIn || !isEmailValid}
												>
													{!emailSent
														? t(keys.AUTH.FORGOT_PASSWORD.REQUEST_RESET_LINK)
														: t(keys.AUTH.FORGOT_PASSWORD.RESEND_REQUEST)}
												</button>
											</div>
										</div>
									</fieldset>
								</form>
							</div>
						</div>
					</div>
				</div>
			</main>
			<footer>
				<div className={classes.innerFooter}>
					<div className={classes.logoFooter}>
						<img src={lngId.includes("fr") ? nologo_fr : nologo_en} alt="logo" />
					</div>
					<p className={classes.rightFooter}>Copyright © 2023 Adfast. All Rights Reserved.</p>
					<div className={classes.linksFooter}>
						<CustomNavLink to={`/term_privacy${lngParm}`}>{t(keys.AUTH.SIGN_UP.TERMS)}</CustomNavLink>
						<CustomNavLink to={"/contact-us"}>{t(keys.GLOBAL.FOOTER.CONTACT_US)}</CustomNavLink>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default ForgotPassword;
