import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import Button from "../ui/Button/Button";
import CartItem from "./cartItem/CartItem";
import Loading from "../ui/loading/Loading";

import { isLoadingSelector, orderLinesSelector, setCartOpen } from "../../store/cart";
import { isLoggedInSelector } from "../../store/auth";

import { keys } from "../../locales/localeskeys";

import classes from "./Cart.module.scss";

const Cart = () => {
	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const isLoading = useSelector(isLoadingSelector);
	const isLoggedIn = useSelector(isLoggedInSelector);
	const orderLine = useSelector(orderLinesSelector);

	const [subTotal, setSubTotal] = useState("");

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (!isLoggedIn) {
			navigate({ pathname: `/sign-in/`, search: queryParams.toString() });
		}
	}, [isLoggedIn, lngId]);

	useEffect(() => {
		if (orderLine && orderLine.length > 0) {
			let subTotalStr = orderLine
				.map((line) => Number(line.amount))
				.reduce((prev, curr) => prev + curr, 0)
				.toFixed(2)
				.toString()
				.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			subTotalStr = lngId.includes("fr") ? `${subTotalStr.replace(".", ",")}$` : `$${subTotalStr}`;
			setSubTotal(subTotalStr);
		} else {
			navigate({ pathname: `/` });
		}
	}, [lngId, orderLine]);

	return (
		<Fragment>
			<div className={classes.layout}>
				{orderLine && (
					<div className={classes.title}>
						{orderLine.length > 1
							? `${t(keys.CART.OVERVIEW.TITLE)} ${orderLine.length} ${t(keys.CART.OVERVIEW.ITEMS)}`
							: `${t(keys.CART.OVERVIEW.TITLE)} ${orderLine.length} ${t(keys.CART.OVERVIEW.ITEM)}`}
					</div>
				)}
				{isLoading && <Loading type={"linear"} />}
				{orderLine && (
					<div className={classes.body}>
						<div className={classes.order}>
							<div className={classes.title}>{t(keys.CART.OVERVIEW.TITLE)}</div>
							<div className={classes.orderLines}>
								{orderLine
									.filter((line) => line.item && line.variant)
									.map((line, index) => (
										<div key={line.id}>
											<CartItem
												id={line.id}
												variant={line.variant}
												item={line.item}
												qty={Number(line.qty).toFixed(0)}
												qty_pack={Number(line.qty_pack).toFixed(0)}
												amount={Number(line.amount)}
												unitAmount={Number(line.unitAmount)}
												images={line.overview_image}
												// hideStock
												// hideRange
											/>

											{orderLine.length !== index + 1 && <hr />}
										</div>
									))}
							</div>
						</div>
						<div className={classes.total}>
							<div className={classes.title}>{t(keys.CART.OVERVIEW.ORDERSUMMARY)}</div>
							<div className={classes.subtotal}>
								<div>{t(keys.CART.OVERVIEW.SUBTOTAL)}</div>
								<div className={classes.price}>{subTotal}</div>
							</div>
							<Button
								className={classes.checkout}
								onClick={() => {
									navigate({ pathname: `/checkout`, search: queryParams.toString() });
								}}
							>
								{t(keys.CART.OVERVIEW.CHECKOUT)}
							</Button>
							<Button
								className={classes.checkout}
								size="big"
								color="outlineBlack"
								weight={"bold"}
								onClick={() => {
									navigate({ pathname: `/adstore`, search: queryParams.toString() });
								}}
							>
								{t(keys.CART.SIDE.CONTINUE_SHOPPING)}
							</Button>
						</div>
					</div>
				)}
			</div>
			<div className={classes.mobileLayout}>
				{orderLine ? (
					<div className={classes.title}>
						{orderLine.length > 1
							? `${t(keys.CART.OVERVIEW.TITLE)} (${orderLine.length} ${t(keys.CART.OVERVIEW.ITEMS)})`
							: `${t(keys.CART.OVERVIEW.TITLE)} (${orderLine.length} ${t(keys.CART.OVERVIEW.ITEM)})`}
					</div>
				) : (
					<div className={classes.title}>{`${t(keys.CART.OVERVIEW.TITLE)} `}</div>
				)}
				<div className={classes.orderLines}>
					{orderLine &&
						orderLine
							.filter((line) => line.item && line.variant)
							.map((line, index) => (
								<div key={line.id}>
									<CartItem
										id={line.id}
										variant={line.variant}
										item={line.item}
										qty={Number(line.qty).toFixed(0)}
										qty_pack={Number(line.qty_pack).toFixed(0)}
										amount={Number(line.amount)}
										unitAmount={Number(line.unitAmount)}
										images={line.overview_image}
										mobile
									/>

									{orderLine.length !== index && <hr />}
								</div>
							))}
				</div>
				<div className={classes.subTotal}>
					<p>{t(keys.CART.SIDE.SUBTOTAL)}</p>
					<p>{subTotal}</p>
				</div>
				<div className={classes.subTotalMobile}>
					<p>{t(keys.CART.OVERVIEW.SUBTOTAL)}</p>
					<p>{subTotal}</p>
				</div>
				<div className={classes.button}>
					<Button
						className={classes.btn}
						size="big"
						weight={"bold"}
						onClick={() => {
							navigate({ pathname: `/checkout`, search: queryParams.toString() });
						}}
					>
						{t(keys.CART.OVERVIEW.CHECKOUT)}
					</Button>
					<Button
						className={classes.btn}
						size="big"
						color="outlineBlack"
						weight={"bold"}
						onClick={() => {
							navigate({ pathname: `/adstore`, search: queryParams.toString() });
						}}
					>
						{t(keys.CART.SIDE.CONTINUE_SHOPPING)}
					</Button>
				</div>
			</div>
		</Fragment>
	);
};

export default Cart;
