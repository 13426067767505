import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { constants } from "../locales/constant";

export const getPartnerLogo = createAsyncThunk("offices/getPartnerLogo", async (_, { rejectWithValue }) => {
	try {
		const response = await fetch(constants.API_SERVER + "/partnerlogo/");
		if (!response.ok) {
			throw new Error("fetching offices failed");
		}
		return await response.json();
	} catch (err) {
		return rejectWithValue(err.message);
	}
});

const partnerlogoAdapter = createEntityAdapter({});

const partnerLogo = createSlice({
	name: "partnerLogo",
	initialState: partnerlogoAdapter.getInitialState({
		loading: "done",
		currentRequestId: undefined,
		error: undefined,
		PartnerLogo: undefined,
	}),
	reducers: {},
	extraReducers: {
		[getPartnerLogo.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[getPartnerLogo.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;
				state.PartnerLogo = payload;
			}
			return state;
		},
		[getPartnerLogo.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.loading = "done";
				state.error = payload;
				state.currentRequestId = undefined;
			}
			return state;
		},
	},
});

export default partnerLogo;
export const PartnerLogoSelector = (state) => state.partnerLogo.PartnerLogo;
export const PartnerLogoLoadingSelector = (state) => state.partnerLogo.loading === "pending";
