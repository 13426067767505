import { Helmet } from "react-helmet";

import Variant from "../components/products/variant/Variant";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
	description_og_en_selector,
	description_og_fr_selector,
	picture_og_selector,
	title_og_en_selector,
	title_og_fr_selector,
} from "../store/product";

const VariantPage = () => {
	const { i18n } = useTranslation();
	const lngId = i18n.language;

	const description_og_fr = useSelector(description_og_fr_selector);
	const description_og_en = useSelector(description_og_en_selector);
	const title_og_fr = useSelector(title_og_fr_selector);
	const title_og_en = useSelector(title_og_en_selector);
	const picture_og = useSelector(picture_og_selector);

	return (
		<>
			<Helmet>
				<title>{lngId.includes("fr") ? title_og_fr : title_og_en ?? "Adfast | Products"}</title>
				<meta property="og:locale" content={lngId} />
				<meta property="og:type" content="article" />
				<meta property="og:url" content={window.location.href} />
				<meta property="og:site_name" content="ADFAST" />
				<meta
					property="og:image"
					content={picture_og ?? "https://adfastcorp.com/wp-content/uploads/Images/adfast_logo_colors.png"}
				/>
				<meta property="og:image:type" content="image/png" />
				<meta
					property="og:title"
					content={lngId.includes("fr") ? title_og_fr : title_og_en ?? "Adfast | Variant"}
				/>
				<meta
					property="title"
					content={lngId.includes("fr") ? title_og_fr : title_og_en ?? "Adfast | Variant"}
				/>
				<meta
					property="og:description"
					content={
						lngId.includes("fr")
							? description_og_fr
							: description_og_en ?? "List of variants of the selected product on Adfast Store"
					}
				/>
				<meta
					name="description"
					content={
						lngId.includes("fr")
							? description_og_fr
							: description_og_en ?? "List of variants of the selected product on Adfast Store"
					}
				/>
				<meta name="robots" content="index, follow" />
				<link rel="apple-touch-icon" sizes="180x180" href="/static/favicon/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/static/favicon/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/static/favicon/favicon-16x16.png" />
				<link rel="manifest" href="/static/favicon/site.webmanifest" />
			</Helmet>
			<Variant />
		</>
	);
};

export default VariantPage;
