import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { keys } from "../../../locales/localeskeys";

import classes from "./Characteristics.module.scss";

const Characteristics = (props) => {
	const { i18n, t } = useTranslation();
	const lngId = i18n.language;

	const overview_ref = useRef();
	const advantage_ref = useRef();
	const application_ref = useRef();
	const chars_ref = useRef();

	const [currentSegment, setCurrentSegment] = useState();

	useEffect(() => {
		if (currentSegment === 1) overview_ref?.current.scrollIntoView({ behavior: "smooth" });
		if (currentSegment === 2) advantage_ref?.current.scrollIntoView({ behavior: "smooth" });
		if (currentSegment === 3) application_ref?.current.scrollIntoView({ behavior: "smooth" });
		if (currentSegment === 4) chars_ref?.current.scrollIntoView({ behavior: "smooth" });
	}, [currentSegment]);

	return (
		<div className={classes.layout}>
			<div className={classes.header}>
				<h1
					id={1}
					style={{
						textAlign: "left",
						paddingLeft: "1rem",
						paddingBottom: currentSegment === 1 ? "0" : undefined,
						borderBottom: currentSegment === 1 ? "1rem solid #007AFF" : undefined,
					}}
					onClick={() => setCurrentSegment(1)}
				>
					{t(keys.ADROBOT.OVERVIEW)}
				</h1>
				<h1
					id={2}
					style={{
						textAlign: "center",
						paddingBottom: currentSegment === 2 ? "0" : undefined,
						borderBottom: currentSegment === 2 ? "1rem solid #007AFF" : undefined,
					}}
					onClick={() => setCurrentSegment(2)}
				>
					{t(keys.ADROBOT.ADVANTAGES)}
				</h1>
				<h1
					id={3}
					style={{
						textAlign: "center",
						paddingBottom: currentSegment === 3 ? "0" : undefined,
						borderBottom: currentSegment === 3 ? "1rem solid #007AFF" : undefined,
					}}
					onClick={() => setCurrentSegment(3)}
				>
					{t(keys.ADROBOT.APPLICATIONS)}
				</h1>
				<h1
					id={4}
					style={{
						textAlign: "right",
						paddingRight: "1rem",
						paddingBottom: currentSegment === 4 ? "0" : undefined,
						borderBottom: currentSegment === 4 ? "1rem solid #007AFF" : undefined,
					}}
					onClick={() => setCurrentSegment(4)}
				>
					{t(keys.ADROBOT.MAIN_CHAR)}
				</h1>
			</div>
			<div className={classes.body}>
				<div className={classes.segment} ref={overview_ref}>
					<h1
						onClick={() => setCurrentSegment(1)}
						style={{
							backgroundColor: currentSegment === 1 ? "#007AFF" : undefined,
							color: currentSegment === 1 ? "white" : undefined,
						}}
					>
						{t(keys.ADROBOT.OVERVIEW)}
					</h1>
					<div className={classes.content}>
						<div>
							{(lngId.includes("fr")
								? props.data.overview.description_fr_1
								: props.data.overview.description_en_1
							)
								.split("\n")
								.map((str, index) => (
									<p key={index}>{str ?? " "}</p>
								))}
						</div>
						{props.data.overview.benefits.displayTitle && (
							<p className={classes.include}>{t(keys.ADROBOT.INCLUDE)}</p>
						)}
						<ul>
							{props.data.overview.benefits.data.map((b) => (
								<li key={b.id}>{lngId.includes("fr") ? b.fr : b.en}</li>
							))}
						</ul>
					</div>
				</div>
				<div className={classes.segment} ref={advantage_ref}>
					<h1
						onClick={() => setCurrentSegment(2)}
						style={{
							backgroundColor: currentSegment === 2 ? "#007AFF" : undefined,
							color: currentSegment === 2 ? "white" : undefined,
						}}
					>
						{t(keys.ADROBOT.ADVANTAGES)}
					</h1>
					<div className={classes.content}>
						{props.data.advantages.map((a) => (
							<div key={a.id} className={classes.advElement}>
								<h2>{lngId.includes("fr") ? a.fr.title : a.en.title}</h2>
								<div>
									{(lngId.includes("fr") ? a.fr.description : a.en.description)
										.split("\n")
										.map((str, index) => (
											<p key={index}>{str ?? " "}</p>
										))}
								</div>
							</div>
						))}
					</div>
				</div>
				<div className={classes.segment} ref={application_ref}>
					<h1
						onClick={() => setCurrentSegment(3)}
						style={{
							backgroundColor: currentSegment === 3 ? "#007AFF" : undefined,
							color: currentSegment === 3 ? "white" : undefined,
						}}
					>
						{t(keys.ADROBOT.APPLICATIONS)}
					</h1>
					<div className={classes.content}>
						<ul className={classes.application}>
							{props.data.applications.map((a) => (
								<li key={a.id}>{lngId.includes("fr") ? a.fr : a.en}</li>
							))}
						</ul>
					</div>
				</div>
				<div className={classes.segment} ref={chars_ref}>
					<h1
						onClick={() => setCurrentSegment(4)}
						style={{
							backgroundColor: currentSegment === 4 ? "#007AFF" : undefined,
							color: currentSegment === 4 ? "white" : undefined,
						}}
					>
						{t(keys.ADROBOT.MAIN_CHAR)}
					</h1>
					{props.data.characteristics.tech && (
						<div className={classes.content}>
							<div className={classes.element}>
								<p>{t(keys.ADROBOT.PAYLOAD)}</p>
								<p>{props.data.characteristics.tech.payload}</p>
							</div>
							<div className={classes.element}>
								<p>{t(keys.ADROBOT.MAX_REACH)}</p>
								<p>{props.data.characteristics.tech.max_reach}</p>
							</div>
							<div className={classes.element}>
								<p>{t(keys.ADROBOT.CONSTRUCTION_TYPE)}</p>
								<p>
									{lngId.includes("fr")
										? props.data.characteristics.tech.construction_type_fr
										: props.data.characteristics.tech.construction_type_en}
								</p>
							</div>
							<div className={classes.element}>
								<p>{t(keys.ADROBOT.VERSION_ENV)}</p>
								<p>
									{lngId.includes("fr")
										? props.data.characteristics.tech.version_env_fr
										: props.data.characteristics.tech.version_env_en}
								</p>
							</div>
							<div className={classes.element}>
								<p>{t(keys.ADROBOT.MOUNT_POS)}</p>
								<p>
									{lngId.includes("fr")
										? props.data.characteristics.tech.mounting_pos_fr
										: props.data.characteristics.tech.mounting_pos_en}
								</p>
							</div>
							<div className={classes.element}>
								<p>{t(keys.ADROBOT.CLASS)}</p>
								<p>{props.data.characteristics.tech.class}</p>
							</div>
							<div className={classes.element}>
								<p>{t(keys.ADROBOT.TECH_DATA_SHEET)}</p>
								{props.data.characteristics.tech.contact_us && (
									<NavLink
										to={
											lngId.includes("fr")
												? props.data.characteristics.tech.tech_data_sheet_fr
												: props.data.characteristics.tech.tech_data_sheet_en
										}
									>
										{t(props.data.characteristics.tech.tech_data_sheet_txt)}
									</NavLink>
								)}
								{!props.data.characteristics.tech.contact_us && (
									<a
										href={
											lngId.includes("fr")
												? props.data.characteristics.tech.tech_data_sheet_fr
												: props.data.characteristics.tech.tech_data_sheet_en
										}
										target="_blank"
									>
										{t(props.data.characteristics.tech.tech_data_sheet_txt)}
									</a>
								)}
							</div>
						</div>
					)}
					{props.data.characteristics.desc && (
						<div className={classes.content}>
							<ul>
								{props.data.characteristics.desc.map((a) => (
									<li key={a.id}>{lngId.includes("fr") ? a.fr : a.en}</li>
								))}
							</ul>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Characteristics;
