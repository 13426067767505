import { useTranslation } from "react-i18next";
import classes from "./Resources.module.scss";
import React from "react";
import { keys } from "../../../locales/localeskeys";
import CustomNavLink from "../../ui/customNavLink/CustomNavLink";

const Resources = () => {
	const { t } = useTranslation();

	return (
		<div className={classes.actionNavBar}>
			<div className={classes.titleBar}>
				<h1>{t(keys.ARCHITECT.HELP.TITLE)}</h1>
			</div>
			<div className={classes.options}>
				<div className={classes.line}>
					<CustomNavLink className={classes.option} to="https://www.adfast.store/contact-us" target="_blank">
						<p>{t(keys.ARCHITECT.HELP.SALESREP)}</p>
					</CustomNavLink>
					<CustomNavLink
						className={classes.option}
						to="https://www.adfast.store/architect/library/"
						target="_blank"
					>
						<p>{t(keys.ARCHITECT.HELP.FINDDOC)}</p>
					</CustomNavLink>
				</div>
				<div className={classes.line}>
					<CustomNavLink
						className={classes.option}
						to="https://www.adfast.store/architect/webinar/"
						target="_blank"
					>
						<p>{t(keys.ARCHITECT.HELP.WEBINAIRES)}</p>
					</CustomNavLink>
					<CustomNavLink
						className={classes.option}
						to="https://adfastcorp.com/en/find-a-store/"
						target="_blank"
					>
						<p>{t(keys.ARCHITECT.HELP.DISTRIBUTOR)}</p>
					</CustomNavLink>
				</div>
			</div>
		</div>
	);
};

export default Resources;
