import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import { ButtonBack, ButtonNext, CarouselProvider, Dot, Slide, Slider } from "pure-react-carousel";

import useElementSize from "../../../hooks/useElementSize";
import Button from "../Button/Button";
import CustomNavLink from "../customNavLink/CustomNavLink";

import { keys } from "../../../locales/localeskeys";
import classes from "./HeaderCarousel.module.scss";

const HeaderCarousel = (props) => {
	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const [containerRef, { width: gridWidth, height: gridHeight }] = useElementSize();

	const toLink = (url) => {
		return url.includes("http")
			? {
					pathname: new URL(url).pathname,
					search: new URL(url).searchParams.toString(),
			  }
			: url.replace("lng_id", lngId);
	};

	return (
		<div ref={containerRef}>
			<CarouselProvider
				className={`${classes.carousel} ${props.className}`}
				naturalSlideWidth={100}
				naturalSlideHeight={30}
				totalSlides={props.data.length}
				visibleSlides={1}
				dragEnabled={false}
				isPlaying
				interval={5000}
			>
				<Slider className={classes.slider}>
					{props.data.map((record, index) => (
						<Slide innerClassName={classes.slide} index={index} key={index}>
							{(lngId.includes("fr") ? record.title_fr : record.title_en) && (
								<h1>{lngId.includes("fr") ? record.title_fr : record.title_en}</h1>
							)}
							{record && record?.url && (
								<CustomNavLink to={toLink(record.url)}>
									<Button
										className={classes.viewMoreBtn}
										color={"grey"}
										style={{
											top: isNaN(
												gridHeight * 0.85 + 40 < gridHeight
													? gridHeight * 0.85
													: gridHeight - 50
											)
												? undefined
												: gridHeight * 0.85 + 40 < gridHeight
												? gridHeight * 0.85
												: gridHeight - 50,
										}}
									>
										{t(keys.PRODUCTS.VIEWMORE)}
									</Button>
								</CustomNavLink>
							)}
							{(lngId.includes("fr") ? record.img_fr : record.img_en) && (
								<img
									src={lngId.includes("fr") ? record.img_fr : record.img_en}
									alt="url"
									className={classes.slideImg}
									key={index}
								/>
							)}
						</Slide>
					))}
				</Slider>
				{props.data.length > 1 && (
					<div className={classes.btnLayout}>
						<ButtonBack className={`${classes.btn} ${classes.btnBack}`}>
							<Icon className={classes.icon} icon="bi:arrow-left-square-fill" />
						</ButtonBack>
						<ButtonNext className={`${classes.btn} ${classes.btnNext}`}>
							<Icon className={classes.icon} icon="bi:arrow-right-square-fill" />
						</ButtonNext>
					</div>
				)}
				{props.data.length > 1 && (
					<div className={classes.pickerDots}>
						{props.data.map((record, index) => (
							<Dot className={classes.icon} slide={index} key={index} />
						))}
					</div>
				)}
			</CarouselProvider>
		</div>
	);
};

export default HeaderCarousel;
