import React, { Fragment, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";

import {
	addNewAddress,
	customerIdSelector,
	defaultAddressSelector,
	selectedAddressSelector,
	setDefaultAddress,
	updateNewAddress,
} from "../../../store/auth";

import TextInput from "../../ui/Input/TextInput/TextInput";
import Dropdown from "../../ui/Input/Dropdown/Dropdown";
import Button from "../../ui/Button/Button";

import { keys } from "../../../locales/localeskeys";

import classes from "./AddressEditor.module.scss";
import { constants } from "../../../locales/constant";

const AddressEditor = (props) => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const customerId = useSelector(customerIdSelector);
	const defaultAddress = useSelector(defaultAddressSelector);
	const selectedAddress = useSelector((state) => selectedAddressSelector(state, props.editngAddressId));

	const [country, setCountry] = useState(
		selectedAddress ? selectedAddress.country : lngId.slice(-2) === "CA" ? "CAN" : "USA"
	);
	const [addressName, setAddressName] = useState(selectedAddress ? selectedAddress.name : "");
	const [addressPhone, setAddressPhone] = useState(selectedAddress ? selectedAddress.phone : "");
	const [addressLine1, setAddressLine1] = useState(selectedAddress ? selectedAddress.line1 : "");
	const [addressLine2, setAddressLine2] = useState(selectedAddress ? selectedAddress.line2 : "");
	const [addressCity, setAddressCity] = useState(selectedAddress ? selectedAddress.city : "");
	const [addressState, setAddressState] = useState(selectedAddress ? selectedAddress.state : "");
	const [addressZipCode, setAddressZipCode] = useState(selectedAddress ? selectedAddress.postal_code : "");
	const [addressDefault, setAddressAddressDefault] = useState(
		defaultAddress[0] ? defaultAddress[0].id === props.editngAddressId : false
	);

	const isFormValid =
		!!addressName && !!addressPhone && !!addressLine1 && !!addressCity && !!addressState && !!addressZipCode;

	useEffect(() => {
		document.body.style.overflow = "hidden";
	}, []);

	const closeEditAddress = () => {
		props.closeEditAddress();
		document.body.style.overflow = "";
	};

	const save = (event) => {
		event.preventDefault();

		if (isFormValid !== true) {
			return;
		}
		if (!props.editngAddressId) {
			dispatch(
				addNewAddress({
					type: 0,
					line1: addressLine1,
					line2: addressLine2,
					city: addressCity,
					postal_code: addressZipCode,
					state: addressState,
					country: country,
					name: addressName,
					phone: addressPhone,
					is_default: defaultAddress === undefined || defaultAddress.length === 0,
					customer: customerId,
				})
			);
		} else {
			if (defaultAddress[0] && defaultAddress[0].id !== props.editngAddressId && addressDefault) {
				dispatch(
					updateNewAddress({
						id: defaultAddress[0].id,
						type: defaultAddress[0].type,
						line1: defaultAddress[0].line1,
						line2: defaultAddress[0].line2,
						city: defaultAddress[0].city,
						postal_code: defaultAddress[0].postal_code,
						state: defaultAddress[0].state,
						country: defaultAddress[0].country,
						name: defaultAddress[0].name,
						phone: defaultAddress[0].phone,
						is_default: false,
						customer: defaultAddress[0].customer,
					})
				);
			}
			dispatch(
				updateNewAddress({
					id: props.editngAddressId,
					type: 0,
					line1: addressLine1,
					line2: addressLine2,
					city: addressCity,
					postal_code: addressZipCode,
					state: addressState,
					country: country,
					name: addressName,
					phone: addressPhone,
					is_default: addressDefault,
					customer: customerId,
				})
			);
		}
		props.closeEditAddress();
		document.body.style.overflow = "";
	};

	const changeAddressDefaultHandler = () => {
		setAddressAddressDefault(!addressDefault);
	};

	const protalElement = document.getElementById("overlays");
	return (
		<Fragment>
			{ReactDOM.createPortal(<div className={classes.backdrop} onClick={closeEditAddress} />, protalElement)}
			{ReactDOM.createPortal(
				<div className={classes.address} data-is-active={true}>
					<div className={classes.title}>
						<p>{props.editngAddressId ? t(keys.CHECKOUT.ADDRESS.EDIT) : t(keys.CHECKOUT.ADDRESS.ADD)}</p>
						<Icon className={classes.icon} onClick={closeEditAddress} icon="bi:x-lg" />
					</div>
					<form className={classes.inputLayout} onSubmit={save}>
						<Dropdown
							label={t(keys.CHECKOUT.ADDRESS.COUNTRY)}
							dropdown={classes.dropdown}
							wrapperClassName={classes.wrapperClassName}
							value={country ? country : ""}
							onChange={(event) => {
								setCountry(event.target.value);
							}}
							mandatory
						>
							<option disabled value={""} />
							{constants.COUNTRIES.map((country) => (
								<option key={country.id} value={country.id}>
									{country.name}
								</option>
							))}
						</Dropdown>
						<Dropdown
							label={`${t(keys.CHECKOUT.ADDRESS.STATE)} / ${t(keys.CHECKOUT.ADDRESS.PROVINCE)}`}
							dropdown={classes.dropdown}
							wrapperClassName={classes.wrapperClassName}
							onChange={(event) => {
								setAddressState(event.target.value);
							}}
							disabled={country === ""}
							mandatory
						>
							<option disabled value={""} />
							{constants.COUNTRIES.find((x) => country !== "" && x.id === country)?.states.map(
								(state) => (
									<option key={state.id} value={state.id}>
										{state.name}
									</option>
								)
							)}
						</Dropdown>
						<TextInput
							className={classes.text}
							classLabel={classes.classLabel}
							classMandatory={classes.classMandatory}
							label={t(keys.CHECKOUT.ADDRESS.NAME)}
							value={addressName ? addressName : ""}
							onChange={(event) => {
								setAddressName(event.target.value);
							}}
							mandatory
						/>
						<TextInput
							className={classes.text}
							classLabel={classes.classLabel}
							classMandatory={classes.classMandatory}
							label={t(keys.CHECKOUT.ADDRESS.PHONE)}
							value={addressPhone ? addressPhone : ""}
							onChange={(event) => {
								setAddressPhone(event.target.value);
							}}
							mandatory
						/>
						<TextInput
							className={classes.text}
							classLabel={classes.classLabel}
							classMandatory={classes.classMandatory}
							label={t(keys.CHECKOUT.ADDRESS.ADDRESS)}
							value={addressLine1 ? addressLine1 : ""}
							onChange={(event) => {
								setAddressLine1(event.target.value);
							}}
							mandatory
						/>
						<TextInput
							className={classes.text}
							classLabel={classes.classLabel}
							classMandatory={classes.classMandatory}
							label={t(keys.CHECKOUT.ADDRESS.UNIT)}
							value={addressLine2 ? addressLine2 : ""}
							onChange={(event) => {
								setAddressLine2(event.target.value);
							}}
						/>
						<TextInput
							className={classes.text}
							classLabel={classes.classLabel}
							classMandatory={classes.classMandatory}
							label={t(keys.CHECKOUT.ADDRESS.CITY)}
							value={addressCity ? addressCity : ""}
							onChange={(event) => {
								setAddressCity(event.target.value);
							}}
							mandatory
						/>
						<TextInput
							className={classes.text}
							classLabel={classes.classLabel}
							classMandatory={classes.classMandatory}
							label={
								lngId.includes("CA") || lngId.includes("ca")
									? t(keys.CHECKOUT.ADDRESS.POSTALCODE)
									: t(keys.CHECKOUT.ADDRESS.ZIP)
							}
							value={addressZipCode ? addressZipCode : ""}
							onChange={(event) => {
								setAddressZipCode(event.target.value);
							}}
							mandatory
						/>
						{props.editngAddressId && (
							<div className={classes.editDefault}>
								<input
									type="checkbox"
									onChange={changeAddressDefaultHandler}
									checked={addressDefault}
								/>
								<label>{t(keys.CHECKOUT.ADDRESS.DEFAULT)}</label>
							</div>
						)}
						<Button className={classes.btn} size="big" type="submit">
							{t(keys.CHECKOUT.ADDRESS.CONFIRM)}
						</Button>
					</form>
				</div>,
				protalElement
			)}
		</Fragment>
	);
};

export default AddressEditor;
