import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { subsegmentsSelector } from "../store/subSegment";

import SubManufacturing from "../components/Segment/Manufacturing/SubManufacturing";

import { keys } from "../locales/localeskeys";
import { constants } from "../locales/constant";

const ManufacturingSubSegmentPage = () => {
	const { t, lngId } = useTranslation();

	const params = useParams();
	const { subSegemtId } = params;

	const subSegments = useSelector(subsegmentsSelector);
	const [curSubSegment, setCurSubSegment] = useState();

	useEffect(() => {
		if (subSegments && subSegemtId) {
			const sub = subSegments.find((sub) => sub.erp_code === subSegemtId);
			if (sub) setCurSubSegment(sub);
		}
	}, [subSegments]);

	return (
		<>
			<Helmet>
				<title>{t(keys.MANUFACTURING.META.TITLE)}</title>
				<meta property="og:locale" content={lngId} />
				<meta property="og:type" content="website" />
				<meta property="og:url" content={window.location.href} />
				<meta property="og:site_name" content="ADFAST" />
				<meta property="og:image" content={constants.IMG_PREVIEW} />
				<meta property="og:image:type" content="image/png" />
				<meta
					property="og:title"
					content={
						lngId && curSubSegment
							? lngId.includes("fr")
								? curSubSegment.title_fr
								: curSubSegment.title_en
							: t(keys.MANUFACTURING.META.OG_TITLE)
					}
				/>
				<meta
					property="title"
					content={
						lngId && curSubSegment
							? lngId.includes("fr")
								? curSubSegment.title_fr
								: curSubSegment.title_en
							: t(keys.MANUFACTURING.META.OG_TITLE)
					}
				/>
				<meta
					property="og:description"
					content={
						lngId && curSubSegment
							? lngId.includes("fr")
								? curSubSegment.description_fr
								: curSubSegment.description_en
							: t(keys.MANUFACTURING.META.OG_DESCRIPTION)
					}
				/>
				<meta
					name="description"
					content={
						lngId && curSubSegment
							? lngId.includes("fr")
								? curSubSegment.description_fr
								: curSubSegment.description_en
							: t(keys.MANUFACTURING.META.DESCRIPTION)
					}
				/>
				<meta name="robots" content="index, follow" />
				<link rel="apple-touch-icon" sizes="180x180" href="/static/favicon/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/static/favicon/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/static/favicon/favicon-16x16.png" />
				<link rel="manifest" href="/static/favicon/site.webmanifest" />
			</Helmet>
			<SubManufacturing />
		</>
	);
};

export default ManufacturingSubSegmentPage;
