import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import "react-datepicker/dist/react-datepicker.css";
import "./Checkout.scss";
import CheckoutMobile from "./checkoutMobile/CheckoutMobile";
import useElementSize from "../../hooks/useElementSize";
import CheckoutWeb from "./checkoutWeb/CheckoutWeb";

const Checkout = () => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const [gridRef, { width: gridWidth, height: heightWidth }] = useElementSize();

	return (
		<div ref={gridRef}>
			{gridWidth < 833 && <CheckoutMobile />}
			{gridWidth >= 833 && <CheckoutWeb />}
		</div>
	);
};
export default Checkout;
