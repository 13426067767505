import React from "react";
import { constants } from "../../../locales/constant";
import { keys } from "../../../locales/localeskeys";
import TemplatePartner from "../TemplatePartner";
import { useTranslation } from "react-i18next";
import classes from "./Nichiha.module.scss";

const Nichiha = () => {
	const { t } = useTranslation();
	return (
		<TemplatePartner
			itemId={constants.nichiha.itemId}
			brandId={constants.nichiha.brandId}
			action={constants.nichiha.action}
			bannerFr={constants.nichiha.images.banner_fr}
			bannerEn={constants.nichiha.images.banner_en}
			description={t(keys.PARTNERS.NICHIHA.DESCRIPTION)}
			subDescription={t(keys.PARTNERS.NICHIHA.SUBDESCIPTION)}
			titleColor={t(keys.PARTNERS.NICHIHA.TITLECOLOR)}
			sections={constants.nichiha.sections}
			colorChart={constants.nichiha.images.colorChart}
			DigitalChart={constants.nichiha.images.DigitalChart}
			orderColorDescription={t(keys.PARTNERS.NICHIHA.ORDERCOLORDESCRIPTION)}
			orderColorButton={t(keys.PARTNERS.NICHIHA.ORDERCOLORBUTTON)}
			ChartUrlFr={constants.nichiha.pdf.chart_url_fr}
			ChartUrlEn={constants.nichiha.pdf.chart_url_en}
			digitalColorDescription={t(keys.PARTNERS.NICHIHA.DIGITALCOLORDESCRIPTION)}
			orderColorMobileDescription={t(keys.PARTNERS.NICHIHA.DIGITALCOLORMOBILEDESCRIPTION)}
			style={classes.cardStyle}
		/>
	);
};

export default Nichiha;
