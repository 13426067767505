import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { constants } from "../locales/constant";


export const getOffices = createAsyncThunk(
	"offices/getOffices",
	async (_, { rejectWithValue }) => {
		try {
			const response = await fetch(constants.API_SERVER + "/office/");
			if (!response.ok) {
				throw new Error("fetching offices failed");
			}
			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message);
		}
	}
);

export const calculateDirections = async (origin, destination) => {
	if (!window.google || !window.google.maps) {
		throw new Error("Google Maps API not loaded");
	}
	const directionsService = new window.google.maps.DirectionsService();

	try {
		return await directionsService.route({
			origin,
			destination,
			travelMode: window.google.maps.TravelMode.DRIVING,
		});
	} catch (status) {
		throw new Error(`La demande de directions a échoué en raison de ${status}`);
	}
};

export const getStoreDirection = createAsyncThunk(
	"offices/getStoreDirection",
	async ({ origin, destination }, { rejectWithValue }) => {
		try {
			if (!origin.trim() || !destination.trim()) {
				throw new Error("Origin and destination cannot be empty.");
			}
			return await calculateDirections(origin, destination);
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);
export const getOfficeDirection = createAsyncThunk(
	"offices/getOfficeDirection",
	async ({ origin, destination }, { rejectWithValue }) => {
		try {
			if (!origin.trim() || !destination.trim()) {
				throw new Error("Origin and destination cannot be empty.");
			}
			return await calculateDirections(origin, destination);
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);



export const filteredAndSortedStores = createAsyncThunk(
	"offices/filteredAndSortedStores",
	async ({ searchStore }, { getState, rejectWithValue }) => {
		try {
			const state = getState();
			const stores = officesSelectors.selectAll(state)
				.filter(office => office.type === 1 && office.address);

			const addresses = stores.map(store => store.address);
			const chunkSize = 25;
			let results = [];

			for (let i = 0; i < addresses.length; i += chunkSize) {
				const chunk = addresses.slice(i, i + chunkSize);
				const storeChunk = stores.slice(i, i + chunkSize);

				const distanceMatrixService = new window.google.maps.DistanceMatrixService();
				const chunkResult = await new Promise((resolve, reject) => {
					distanceMatrixService.getDistanceMatrix({
						origins: [searchStore],
						destinations: chunk,
						travelMode: window.google.maps.TravelMode.DRIVING,
					}, (response, status) => {
						if (status === window.google.maps.DistanceMatrixStatus.OK) {
							resolve(response.rows[0].elements.map((element, index) => ({
								...storeChunk[index],
								distance: element.status === 'OK' ? (element.distance.value / 1000).toFixed(2) : null,
							})));
						} else {
							reject(new Error(`DistanceMatrixService failed due to: ${status}`));
						}
					});
				});

				results.push(...chunkResult);
			}

			results.sort((a, b) => (a.distance === null) - (b.distance === null) || a.distance - b.distance);


			return results;

		} catch (err) {
			return rejectWithValue(err.message);
		}
	}
);




export const filteredAndSortedOffices = createAsyncThunk(
	"offices/filteredAndSortedOffices",
	async ({ searchOffice }, { getState, rejectWithValue }) => {
		try {
			const state = getState();
			const offices = officesSelectors.selectAll(state).filter(office => !office.type || office.type === 0);

			const addresses = offices.map(office => office.address);
			const chunkSize = 25;
			let results = [];

			for (let i = 0; i < addresses.length; i += chunkSize) {
				const chunk = addresses.slice(i, i + chunkSize);
				const officeChunk = offices.slice(i, i + chunkSize);

				const distanceMatrixService = new window.google.maps.DistanceMatrixService();
				const chunkResult = await new Promise((resolve, reject) => {
					distanceMatrixService.getDistanceMatrix({
						origins: [searchOffice],
						destinations: chunk,
						travelMode: window.google.maps.TravelMode.DRIVING,
					}, (response, status) => {
						if (status === window.google.maps.DistanceMatrixStatus.OK) {
							resolve(response.rows[0].elements.map((element, index) => ({
								...officeChunk[index],
								distance: element.status === 'OK' ? (element.distance.value / 1000).toFixed(2) : null,
							})));
						} else {
							reject(new Error(`DistanceMatrixService failed due to: ${status}`));
						}
					});
				});

				results.push(...chunkResult);
			}

			results.sort((a, b) => (a.distance === null) - (b.distance === null) || a.distance - b.distance);

			return results;

		} catch (err) {
			return rejectWithValue(err.message);
		}
	}
);

export const getGoogleMapKey = createAsyncThunk(
	"offices/getGoogleMapKey",
	async (_, { rejectWithValue }) => {
		try {
			const response = await fetch(constants.API_SERVER + "/google-map-key/");
			if (!response.ok) {
				throw new Error("Fetching Google Map key failed");
			}
			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

const officesAdapter = createEntityAdapter({});

const offices = createSlice({
	name: "offices",
	initialState: officesAdapter.getInitialState({
		loading: "done",
		loadingFilteredStores: "done",
		loadingFilteredOffices: "done",
		loadingStoreDirection: "done",
		loadingOfficeDirection: "done",
		loadingGoogleMapKey: "done",
		currentRequestId: undefined,
		currentStoreRequestId: undefined,
		currentOfficeRequestId: undefined,
		currentStoreDirectionRequestId: undefined,
		currentOfficeDirectionRequestId: undefined,
		currentGoogleMapKeyRequestId: undefined,
		error: undefined,
		storesWithDistance: undefined,
		officesWithDistance: undefined,
		storeDirection: undefined,
		officeDirection: undefined,
		googleMapKey: undefined,
	}),
	reducers: {},
	extraReducers: {
		[getOffices.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[getOffices.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;
				officesAdapter.setAll(state, payload);
			}
			return state;
		},
		[getOffices.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.loading = "done";
				state.error = payload;
				state.currentRequestId = undefined;
			}
			return state;
		},
		[filteredAndSortedStores.pending]: (state, { meta }) => {
			if (!state.loadingFilteredStores || state.loadingFilteredStores === "done") {
				state.currentStoreRequestId = meta.requestId;
				state.loadingFilteredStores = "pending";
				state.error = undefined;
				state.storesWithDistance = undefined;
			}
			return state;
		},
		[filteredAndSortedStores.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentStoreRequestId) {
				state.currentStoreRequestId = undefined;
				state.loadingFilteredStores = "done";
				state.error = undefined;
				state.storesWithDistance = payload;
			}
			return state;
		},
		[filteredAndSortedStores.rejected]: (state, { meta, payload }) => {
			if (state.loadingFilteredStores === "pending" && state.currentStoreRequestId === meta.requestId) {
				state.loadingFilteredStores = "done";
				state.error = payload;
				state.currentStoreRequestId = undefined;
			}
			return state;
		},
		[filteredAndSortedOffices.pending]: (state, { meta }) => {
			if (!state.loadingFilteredOffices || state.loadingFilteredOffices === "done") {
				state.currentOfficeRequestId = meta.requestId;
				state.loadingFilteredOffices = "pending";
				state.error = undefined;
				state.officesWithDistance = undefined;
			}
			return state;
		},
		[filteredAndSortedOffices.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentOfficeRequestId) {
				state.currentOfficeRequestId = undefined;
				state.loadingFilteredOffices = "done";
				state.error = undefined;
				state.officesWithDistance = payload;
			}
			return state;
		},
		[filteredAndSortedOffices.rejected]: (state, { meta, payload }) => {
			if (state.loadingFilteredOffices === "pending" && state.currentOfficeRequestId === meta.requestId) {
				state.loadingFilteredOffices = "done";
				state.error = payload;
				state.currentOfficeRequestId = undefined;
			}
			return state;
		},
		[getStoreDirection.pending]: (state, { meta }) => {
			if (!state.loadingStoreDirection || state.loadingStoreDirection === "done") {
				state.currentStoreDirectionRequestId = meta.requestId;
				state.loadingStoreDirection = "pending";
				state.error = undefined;
				state.storeDirection = undefined;
			}
			return state;
		},
		[getStoreDirection.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentStoreDirectionRequestId) {
				state.currentStoreDirectionRequestId = undefined;
				state.loadingStoreDirection = "done";
				state.error = undefined;
				state.storeDirection = payload;
			}
			return state;
		},
		[getStoreDirection.rejected]: (state, { meta, payload }) => {
			if (state.loadingStoreDirection === "pending" && state.currentStoreDirectionRequestId === meta.requestId) {
				state.loadingStoreDirection = "done";
				state.error = payload;
				state.currentStoreDirectionRequestId = undefined;
			}
			return state;
		},
		[getOfficeDirection.pending]: (state, { meta }) => {
			if (!state.loadingOfficeDirection || state.loadingOfficeDirection === "done") {
				state.currentOfficeDirectionRequestId = meta.requestId;
				state.loadingOfficeDirection = "pending";
				state.error = undefined;
				state.officeDirection = undefined;
			}
			return state;
		},
		[getOfficeDirection.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentOfficeDirectionRequestId) {
				state.currentOfficeDirectionRequestId = undefined;
				state.loadingOfficeDirection = "done";
				state.error = undefined;
				state.officeDirection = payload;
			}
			return state;
		},
		[getOfficeDirection.rejected]: (state, { meta, payload }) => {
			if (state.loadingOfficeDirection === "pending" && state.currentOfficeDirectionRequestId === meta.requestId) {
				state.loadingOfficeDirection = "done";
				state.error = payload;
				state.currentOfficeDirectionRequestId = undefined;
			}
			return state;
		},
		[getGoogleMapKey.pending]: (state, { meta }) => {
			if (!state.loadingGoogleMapKey || state.loadingGoogleMapKey === "done") {
				state.currentGoogleMapKeyRequestId = meta.requestId;
				state.loadingGoogleMapKey = "pending";
				state.error = undefined;
				state.googleMapKey = undefined;
			}
			return state;
		},
		[getGoogleMapKey.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentGoogleMapKeyRequestId) {
				state.currentGoogleMapKeyRequestId = undefined;
				state.loadingGoogleMapKey = "done";
				state.error = undefined;
				state.googleMapKey = payload.GOOGLE_MAP_KEY;
			}
			return state;
		},
		[getGoogleMapKey.rejected]: (state, { meta, payload }) => {
			if (state.loadingGoogleMapKey === "pending" && state.currentGoogleMapKeyRequestId === meta.requestId) {
				state.currentGoogleMapKeyRequestId = undefined;
				state.loadingGoogleMapKey = "done";
				state.error = payload;
			}
			return state;
		},
	},
});

export default offices;
export const officesSelectors = officesAdapter.getSelectors((state) => state.offices);
export const officesSelector = createSelector(officesSelectors.selectAll, (offices) => offices.filter(office => !office.type || office.type === 0));
export const storesSelector = createSelector(officesSelectors.selectAll, (offices) => offices.filter(office => office.type && office.type === 1));
export const storesWithDistanceSelectors = {
	selectAll: (state) => (state.offices.storesWithDistance || []).filter(store => store.type && store.type === 1)
};
export const officesWithDistanceSelectors = {
	selectAll: (state) => (state.offices.officesWithDistance || []).filter(office => !office.type || office.type === 0)
};
export const storesWithDistanceSelector = createSelector(storesWithDistanceSelectors.selectAll, (stores) => stores);
export const officesWithDistanceSelector = createSelector(officesWithDistanceSelectors.selectAll, (offices) => offices);
export const storeDirectionSelector = (state) => state.offices.storeDirection;
export const officeDirectionSelector = (state) => state.offices.officeDirection;
export const googleMapKeySelector = (state) => state.offices.googleMapKey;
export const officeLoadingSelector = (state) => state.offices.loading === "pending";
export const loadingFilteredStoresSelector = (state) => state.offices.loadingFilteredStores === "pending";
export const loadingFilteredOfficesSelector = (state) => state.offices.loadingFilteredOffices === "pending";
export const loadingGoogleMapKeySelector = (state) => state.offices.loadingGoogleMapKey === "pending";
