import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import NavDrop from "../../navbar/NavDrop/NavDrop";
import CustomNavLink from "../../ui/customNavLink/CustomNavLink";

import { emailConfirmation } from "../../../store/auth";

import logo_fr from "../../../assets/svg/txt_logo_fr.svg";
import logo_en from "../../../assets/svg/txt_logo_en.svg";
import nologo_fr from "../../../assets/svg/txt_no_logo_fr.svg";
import nologo_en from "../../../assets/svg/txt_no_logo_en.svg";

import { keys } from "../../../locales/localeskeys";
import classes from "./EmailConfirmation.module.scss";

const Signup = () => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const lngParm = `?lng=${lngId}`;

	const params = useParams();
	const { token } = params;

	const [closingDrop, setCLosingDrop] = useState({ id: "", status: false });
	const [activeID, setActiveID] = useState("");

	useEffect(() => {
		dispatch(emailConfirmation(token));
	}, [token]);

	const hoverHandler = (id) => {
		if (closingDrop.status === false && closingDrop.id !== id) {
			setActiveID(id);
		}
	};

	const outHandler = () => {
		setActiveID("");
		setTimeout(
			() =>
				setCLosingDrop({
					id: "",
					status: false,
				}),
			300
		);
	};

	const onLocaliseChange = (item) => {
		i18n.changeLanguage(item.children);
	};

	const closeDrop = () => {
		setCLosingDrop({ id: activeID, status: true });
		setActiveID("");
	};

	return (
		<div className={classes.wrapper}>
			<header>
				<div className={classes.innerHeader}>
					<div className={classes.logo}>
						<CustomNavLink to={`/${lngParm}`}>
							<img src={lngId.includes("fr") ? logo_fr : logo_en} alt="logo" />
						</CustomNavLink>
						<div
							id={"8"}
							className={classes.rli}
							onMouseOver={() => {
								hoverHandler("8");
							}}
							onMouseOut={outHandler}
						>
							<p className={activeID === "8" ? classes["active"] : ""}>{lngId}</p>
							<NavDrop
								right={"0"}
								parentId={"8"}
								activeID={activeID}
								height={"9rem"}
								width={"5rem"}
								hide={closeDrop}
								data={[
									{ id: "13", children: "fr-ca" },
									{ id: "14", children: "en-ca" },
									{ id: "15", children: "en-us" },
								]}
								onClick={onLocaliseChange}
							/>
						</div>
					</div>
				</div>
			</header>
			<main>
				<div className={classes.innerMain}>
					<div className={classes.container}>
						<div className={classes.title}>
							<h1>{t(keys.AUTH.EMAIL_CONFIRMATION.VERIFIED)}</h1>
						</div>
						<div className={classes.content}>
							<p>{t(keys.AUTH.EMAIL_CONFIRMATION.TANKS)}</p>
							<div className={classes.formWrapper}>
								<form>
									<fieldset>
										<legend>{t(keys.AUTH.SIGN_IN.SIGN_IN)}</legend>
										<div className={classes.emailConfirmation}>
											<div className={classes.emailConfirmation_btn}>
												<CustomNavLink to={`/`}>
													<button>{t(keys.GLOBAL.NAVBAR.HOME)}</button>
												</CustomNavLink>
											</div>
										</div>
									</fieldset>
								</form>
							</div>
						</div>
					</div>
				</div>
			</main>
			<footer>
				<div className={classes.innerFooter}>
					<div className={classes.logoFooter}>
						<img src={lngId.includes("fr") ? nologo_fr : nologo_en} alt="logo" />
					</div>
					<p className={classes.rightFooter}>Copyright © 2023 Adfast. All Rights Reserved.</p>
					<div className={classes.linksFooter}>
						<CustomNavLink to={`/term_privacy`}>{t(keys.AUTH.SIGN_UP.TERMS)}</CustomNavLink>
						<CustomNavLink to={"/contact-us"}>{t(keys.GLOBAL.FOOTER.CONTACT_US)}</CustomNavLink>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default Signup;
