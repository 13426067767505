import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { constants as constant } from "../locales/constant";

/**
 * Fournir la liste des categories
 */
export const getCategories = createAsyncThunk("ProductCategory/getCategories", async (_, { rejectWithValue }) => {
	try {
		const response = await fetch(constant.API_SERVER + "/category/");
		if (!response.ok) {
			throw new Error("fetching categories failed");
		}
		return await response.json();
	} catch (err) {
		return rejectWithValue(err.message, err);
	}
});

const productCategoryAdapter = createEntityAdapter({});

const productCategory = createSlice({
	name: "productCategory",
	initialState: productCategoryAdapter.getInitialState({
		loading: "done",
		currentRequestId: undefined,
		error: undefined,
	}),
	reducers: {},
	extraReducers: {
		[getCategories.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;

				productCategoryAdapter.removeAll(state);
				productCategoryAdapter.addMany(state, payload);
			}
			return state;
		},
		[getCategories.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[getCategories.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
	},
});

export default productCategory;
export const categorySelectors = productCategoryAdapter.getSelectors((state) => state?.productCategory);
export const categoriesSelector = createSelector(categorySelectors.selectAll, (categories) => categories);
export const categoriesTreeSelector = createSelector(categorySelectors.selectAll, (categories) =>
	categories
		.filter((category) => !category.parent)
		.map((parent) => ({
			...{ id: parent.erp_code, value_fr: parent.description_fr, value_en: parent.description_en },
			children: [
				{
					id: parent.erp_code,
					value_fr: parent.description_fr,
					value_en: parent.description_en,
				},
				...categories
					.filter((category) => category.parent === parent.id)
					.map((child) => ({
						id: child.erp_code,
						value_fr: child.description_fr,
						value_en: child.description_en,
					})),
			],
		}))
);
export const categoriesLoadingSelector = (state) => state?.productCategory?.loading === "pending";
