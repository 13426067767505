import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useElementSize from "../../../../hooks/useElementSize";

import CustomNavLink from "../../../ui/customNavLink/CustomNavLink";

import { ArchitectHeaderSelector, ArchitectPickerSelector } from "../../../../store/mediaBase";

import classes from "./ArchitectPicker.module.scss";
import React, { Fragment } from "react";

const ArchitectPicker = () => {
	const { i18n } = useTranslation();
	const lngId = i18n.language;
	const [gridRef, { width: gridWidth }] = useElementSize();

	const architectPicker = useSelector(ArchitectPickerSelector);
	const architectHeader = useSelector(ArchitectHeaderSelector);

	const toLink = (url) => {
		return url.includes("http")
			? {
					pathname: new URL(url).pathname,
					search: new URL(url).searchParams.toString(),
			  }
			: url.replace("lng_id", lngId);
	};

	return (
		<div className={classes.architectPickerContainer} ref={gridRef}>
			{architectHeader && architectHeader.length > 0 && (
				<h2 className={classes.architectPickerTitle}>
					{lngId.includes("fr") ? architectHeader[0].title_fr : architectHeader[0].title_en}
				</h2>
			)}
			{architectHeader && architectHeader.length > 0 && (
				<p className={classes.architectPickerDescription}>
					{lngId.includes("fr") ? architectHeader[0].description_fr : architectHeader[0].description_en}
				</p>
			)}
			<ul>
				{architectPicker.map((seg, index) => (
					<li>
						<CustomNavLink key={index} to={toLink(seg.url)}>
							{(lngId.includes("fr") ? seg.title_fr : seg.title_en).replace("#", " ")}
						</CustomNavLink>
					</li>
				))}
			</ul>
			<div className={classes.discoverLayout}>
				{architectPicker.map((seg, index) => (
					<CustomNavLink
						to={toLink(seg.url)}
						target={seg?.url?.includes("http") ? "_blank" : "_self"}
						className={classes.card}
						key={index}
					>
						{gridWidth > 769 && index % 2 === 0 && (
							<div
								className={classes.left}
								style={{
									backgroundImage: `url(${lngId.includes("fr") ? seg.img_fr : seg.img_en})`,
								}}
							/>
						)}
						{gridWidth <= 769 && (
							<div
								className={classes.left}
								style={{
									backgroundImage: `url(${lngId.includes("fr") ? seg.img_fr : seg.img_en})`,
								}}
							/>
						)}
						<div className={classes.right}>
							{seg.icon && (
								<div className={classes.icon} style={{ backgroundImage: `url(${seg.icon})` }} />
							)}
							<div className={classes.details}>
								{(lngId.includes("fr") ? seg.title_fr : seg.title_en) && (
									<div>
										{gridWidth > 769 &&
											(lngId.includes("fr") ? seg.title_fr : seg.title_en)
												.split("#")
												.map((str, index) => (
													<Fragment key={index}>
														{index === 0 && <h1 key={index}>{str}</h1>}
														{index !== 0 && <h1 key={index}>{str}</h1>}
													</Fragment>
												))}
										{gridWidth <= 769 &&
											(lngId.includes("fr") ? (
												<h1 key={index}>{seg.title_fr.replace("#", " ")}</h1>
											) : (
												<h1 key={index}>{seg.title_en.replace("#", " ")}</h1>
											))}
									</div>
								)}
								{(lngId.includes("fr") ? seg.description_fr : seg.description_en) && (
									<p>{lngId.includes("fr") ? seg.description_fr : seg.description_en}</p>
								)}
							</div>
						</div>
						{gridWidth > 769 && index % 2 === 1 && (
							<div
								className={classes.left}
								style={{
									backgroundImage: `url(${lngId.includes("fr") ? seg.img_fr : seg.img_en})`,
								}}
							/>
						)}
					</CustomNavLink>
				))}
			</div>
		</div>
	);
};

export default ArchitectPicker;
