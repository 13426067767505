import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import Newsletter from "../../home/Newsletter/Newsletter";
import SealantCalculator from "./SealantCalculator/SealantCalculator";
import ProductInfo from "./ProductInfo/ProductInfo";
import Loading from "../../ui/loading/Loading";
import LinkTree from "../../ui/LinkTree/LinkTree";
import decodeSlash from "../../../hooks/decodeSlash";

import {
	getItemOverview,
	itemHasErrorSelector,
	itemIsLoadingSelector,
	itemOverView,
	itemPhysicalOverview,
	itemTechnicalOverview,
	technical_overview_enabledSelector,
} from "../../../store/product";
import { currencySelector, isLoggedInSelector } from "../../../store/auth";
import { clear } from "../../../store/variant";

import { keys } from "../../../locales/localeskeys";
import classes from "./ProductOverview.module.scss";
import "pure-react-carousel/dist/react-carousel.es.css";
import TechnicalOverview from "../../ui/TechnicalOverview/TechnicalOverview";
import CustomNavLink from "../../ui/customNavLink/CustomNavLink";

const ProductOverview = () => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const search = `?lng=${lngId}`;

	const isLoggedIn = useSelector(isLoggedInSelector);

	const params = useParams();
	const { itemid, brandid } = decodeSlash(params);

	const navigate = useNavigate();

	const itemsLoading = useSelector(itemIsLoadingSelector);
	const itemHasError = useSelector(itemHasErrorSelector);
	const item = useSelector(itemOverView);
	const technical_overview_enabled = useSelector(technical_overview_enabledSelector);
	const currency = useSelector(currencySelector);

	const documents = useSelector(itemTechnicalOverview);
	const [documentsRender, setDocumentsRender] = useState(documents);

	const properties = useSelector(itemPhysicalOverview);

	useEffect(() => {
		dispatch(clear());
		dispatch(getItemOverview({ itemId: itemid, brandId: brandid }));
	}, [currency]);

	useEffect(() => {
		if (itemHasError) navigate({ pathname: `/` });
	}, [itemHasError]);

	useEffect(() => {
		setDocumentsRender(documents?.filter((doc) => doc.language && doc.language.includes(lngId.substring(0, 2))));
	}, [documents, lngId]);

	return (
		<div className={classes.productOverView}>
			{itemsLoading && <Loading type={"bounce"} />}
			{!isLoggedIn && (
				<div className={classes.header}>
					<CustomNavLink className={classes.link} to={`/sign-in${search}`}>
						{t(keys.PRODUCTS.VARIANT.LOG_IN)}
					</CustomNavLink>
					<span> {t(keys.PRODUCTS.LOGIN_TXT)}</span>
				</div>
			)}
			{item && !itemsLoading && (
				<LinkTree
					className={classes.Tree}
					tree={[
						{ label: t(keys.GLOBAL.NAVBAR.HOME), to: `/?lng=${lngId}` },
						{ label: "AdStore", to: `/adstore?lng=${lngId}` },
						{
							label: lngId.includes("fr") && item.title_fr ? item.title_fr : item.title_en,
							to: `/adstore/overview/${itemid}${brandid ? "/" + brandid : ""}/${search}`,
						},
					]}
				/>
			)}
			{item && !itemsLoading && <ProductInfo />}
			{item && !itemsLoading && item.calculate_sealant === true && <SealantCalculator />}
			{item && !itemsLoading && technical_overview_enabled && <TechnicalOverview />}
			<Newsletter />
		</div>
	);
};
export default ProductOverview;
