import { Helmet } from "react-helmet";

import { useTranslation } from "react-i18next";
import { keys } from "../locales/localeskeys";
import { constants } from "../locales/constant";
import ColorMatch from "../components/colorMatch/ColorMatch";
import React from "react";

const ColorMatchPage = () => {
	const { t, lngId } = useTranslation();
	return (
		<>
			<Helmet>
				<title>{t(keys.COLOR_MATCH.Meta.TITLE)}</title>
				<meta property="og:locale" content={lngId} />
				<meta property="og:type" content="website" />
				<meta property="og:url" content={window.location.href} />
				<meta property="og:site_name" content="ADFAST" />
				<meta property="og:image" content={constants.IMG_PREVIEW} />
				<meta property="og:image:type" content="image/png" />
				<meta property="og:title" content={t(keys.COLOR_MATCH.Meta.OG_TITLE)} />
				<meta property="title" content={t(keys.COLOR_MATCH.Meta.TITLE)} />
				<meta property="og:description" content={t(keys.COLOR_MATCH.Meta.OG_DESCRIPTION)} />
				<meta name="description" content={t(keys.COLOR_MATCH.Meta.DESCRIPTION)} />
				<meta name="robots" content="index, follow" />
				<link rel="apple-touch-icon" sizes="180x180" href="/static/favicon/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/static/favicon/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/static/favicon/favicon-16x16.png" />
				<link rel="manifest" href="/static/favicon/site.webmanifest" />
			</Helmet>
			<ColorMatch />
		</>
	);
};

export default ColorMatchPage;
