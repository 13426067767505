import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import Accordion from "../../../../ui/Accordion/Accordion";

import { filterSubsegmentsSelector, setSubSegments } from "../../../../../store/productQuery";
import { subsegmentSelector } from "../../../../../store/subSegment";
import { itemIsLoadingSelector } from "../../../../../store/product";

import { keys } from "../../../../../locales/localeskeys";

import classes from "./Industries.module.scss";

const Industries = (props) => {
	const dispatch = useDispatch();

	const { i18n, t } = useTranslation();
	const lngId = i18n.language;

	const [searchParams, setSearchParams] = useSearchParams();
	const industrySearchParm = searchParams.get("industries") ?? "";

	const industries = useSelector(subsegmentSelector);
	const itemIsLoading = useSelector(itemIsLoadingSelector);
	const subSegmentsChecked = useSelector(filterSubsegmentsSelector);

	const [checkedCount, setCheckedCount] = useState(0);

	const valueChecked = (event) => {
		if (event.target.checked) {
			dispatch(setSubSegments([...subSegmentsChecked, event.target.id]));
		} else {
			dispatch(setSubSegments(subSegmentsChecked.filter((element) => element !== event.target.id)));
		}
	};

	useEffect(() => {
		if (industrySearchParm && industrySearchParm !== subSegmentsChecked.toString()) {
			const industries = industrySearchParm.split(",");
			dispatch(setSubSegments(industries));
		}
	}, [industrySearchParm]);

	useEffect(() => {
		searchParams.set("page", 1);
		if (industrySearchParm !== subSegmentsChecked.toString()) {
			if (subSegmentsChecked.length > 0) {
				searchParams.set("industries", subSegmentsChecked.toString());
				setSearchParams(searchParams);
			} else {
				searchParams.delete("industries");
				setSearchParams(searchParams);
			}
		}
	}, [subSegmentsChecked]);

	useEffect(() => {
		setCheckedCount(subSegmentsChecked.length);
	}, [subSegmentsChecked]);

	return (
		<div className={classes.industriesContainer}>
			<Accordion
				className={classes.accordionLayout}
				header={t(keys.PRODUCTS.INDUSTRIES)}
				headerClass={classes.header}
				iconClass={classes.icon}
				active={industrySearchParm}
				data={industries}
				checkedCount={checkedCount}
			>
				<div className={classes.list}>
					{industries &&
						industries
							.filter((industry) => industry.segment.segment_type === 2)
							.map((industry) => {
								return {
									id: industry.erp_code,
									label: (lngId.includes("fr") ? industry.title_fr : industry.title_en)
										? lngId.includes("fr")
											? industry.title_fr
											: industry.title_en
										: industry.erp_code,
								};
							})
							.map((industry) => (
								<div className={classes.layout} key={industry.id}>
									<div
										className={`${classes.element} ${
											subSegmentsChecked?.some((el) => el === industry.id) ? classes.checked : ""
										}`}
										key={industry.id}
									>
										<input
											type="checkbox"
											id={industry.id}
											name={industry.id}
											onChange={(event) => valueChecked(event)}
											checked={subSegmentsChecked?.some((el) => el === industry.id)}
											disabled={itemIsLoading}
										/>
										<label htmlFor={industry.id}>{industry.label}</label>
									</div>
								</div>
							))}
				</div>
			</Accordion>
		</div>
	);
};

export default Industries;
