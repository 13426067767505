import React, { useImperativeHandle, useRef, useState } from "react";

import classes from "./OverlayDropdown.module.scss";

const OverlayDropdown = React.forwardRef((props, ref) => {
	const inputRef = useRef();

	const [focus, setFocus] = useState("");
	const [active, setActive] = useState(props.active ? props.active : "");

	const inputHandler = (e) => {
		if (props.type === "number") {
			const char = String.fromCharCode(e.which);
			if (!/[0-9]/.test(char)) {
				e.preventDefault();
			}
		}
	};

	const onFocus = () => {
		setFocus("focus");
		if (props.onFocus) props.onFocus();
	};

	const onBlur = () => {
		setFocus("");
		if (props.onBlur) props.onBlur();
	};

	const onChange = (event) => {
		if (event.target.value) setActive("active");
		else setActive("");
		if (props.onChange) props.onChange(event);
	};

	useImperativeHandle(ref, () => ({
		focus: () => {
			inputRef.current.focus();
		},
	}));

	return (
		<div className={`${classes.layout}  ${props.className} `}>
			<div
				className={`${classes.dropdown} ${props.dropdown} ${active ? classes[focus] : ""} ${
					active ? classes[active] : ""
				}`}
			>
				{props.label && (
					<label
						className={`${classes.label}  ${active ? classes[focus] : ""} ${active ? classes[active] : ""}`}
						htmlFor={props.id}
					>
						{props.label}
					</label>
				)}
				<select
					ref={inputRef}
					id={props.id}
					value={props.value}
					defaultValue={props.defaultValue}
					disabled={props.disabled}
					className={`${classes.select}  ${active ? classes[focus] : ""} ${active ? classes[active] : ""}`}
					onKeyPress={inputHandler}
					onChange={onChange}
					onFocus={onFocus}
					onBlur={onBlur}
					name={props.name}
				>
					{props.children}
				</select>
			</div>
			{props.isValid === false && props.error && (
				<div className={classes.error}>
					<p>{props.error}</p>
				</div>
			)}
		</div>
	);
});

export default OverlayDropdown;
