import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { constants as constant } from "../locales/constant";

/**
 * Fournir la liste des document categorie pour filtrer
 */
export const getLibraryCategories = createAsyncThunk(
	"documentCategory/getLibraryCategories",
	async (_, { rejectWithValue }) => {
		try {
			const response = await fetch(constant.API_SERVER + "/library/?simple=true");
			if (!response.ok) {
				throw new Error("fetching webinars failed");
			}
			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message, err);
		}
	}
);

const documentCategoryAdapter = createEntityAdapter({});

const initialState = {
	currentRequestId: undefined,
	error: undefined,
	loading: "done",
	library: undefined,
	count: 0,
};

const documentCategory = createSlice({
	name: "documentCategory",
	initialState: documentCategoryAdapter.getInitialState(initialState),
	reducers: {},
	extraReducers: {
		[getLibraryCategories.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = undefined;

				documentCategoryAdapter.removeAll(state);
				documentCategoryAdapter.addMany(state, payload);
			}
		},
		[getLibraryCategories.pending]: (state, { meta }) => {
			if (!state.loading || state.loading === "done") {
				state.currentRequestId = meta.requestId;
				state.loading = "pending";
				state.error = undefined;
			}
			return state;
		},
		[getLibraryCategories.rejected]: (state, { meta, payload }) => {
			if (state.loading === "pending" && state.currentRequestId === meta.requestId) {
				state.currentRequestId = undefined;
				state.loading = "done";
				state.error = payload;
			}
			return state;
		},
	},
});

export default documentCategory;
export const libraryCategoryIsLoadingSelector = (state) => state?.documentCategory?.loading === "pending";
export const libraryCategoryHasErrorSelector = (state) => state.documentCategory.error !== "";
export const libraryCategorySelectors = documentCategoryAdapter.getSelectors((state) => state?.documentCategory);
export const libraryCategorySelector = createSelector(libraryCategorySelectors.selectAll, (library) => library);
