import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";

import CustomNavLink from "../../ui/customNavLink/CustomNavLink";

import { constants as constant } from "../../../locales/constant";
import { keys } from "../../../locales/localeskeys";

import classes from "./SiteCartItem.module.scss";

const SiteCartItem = (props) => {
	const { t, i18n } = useTranslation();
	const lngId = i18n.language;
	const search = `?lng=${lngId}`;

	const [image, setImage] = useState("");
	const [title, setTitle] = useState("");
	const [detail, setDetail] = useState("");
	const [colorHex, setColorHex] = useState("");
	const [colorName, setColorName] = useState(" ");
	const [amount, setAmount] = useState();
	const [unitAmount, setUnitAmount] = useState();
	const [displayFormat, setDisplayFormat] = useState(false);

	useEffect(() => {
		if (props.variant) {
			let packagingFormat;
			switch (props.variant.format?.packaging) {
				case 1:
					if (props.qty_pack > 1) packagingFormat = t(keys.GLOBAL.COMMON.BAGS);
					else packagingFormat = t(keys.GLOBAL.COMMON.BAG);
					setDisplayFormat(false);
					break;
				case 2:
					if (props.qty_pack > 1) packagingFormat = t(keys.GLOBAL.COMMON.BARRELS);
					else packagingFormat = t(keys.GLOBAL.COMMON.BARREL);
					setDisplayFormat(false);
					break;
				case 3:
					if (props.qty_pack > 1) packagingFormat = t(keys.GLOBAL.COMMON.BOXES);
					else packagingFormat = t(keys.GLOBAL.COMMON.BOX);
					setDisplayFormat(true);
					break;
				case 4:
					if (props.qty_pack > 1) packagingFormat = t(keys.GLOBAL.COMMON.EACHS);
					else packagingFormat = t(keys.GLOBAL.COMMON.EACH);
					setDisplayFormat(false);
					break;
				case 5:
					if (props.qty_pack > 1) packagingFormat = t(keys.GLOBAL.COMMON.CANES);
					else packagingFormat = t(keys.GLOBAL.COMMON.CANE);
					setDisplayFormat(false);
					break;
				case 6:
					if (props.qty_pack > 1) packagingFormat = t(keys.GLOBAL.COMMON.DRUMS);
					else packagingFormat = t(keys.GLOBAL.COMMON.DRUM);
					setDisplayFormat(false);
					break;
				case 7:
					if (props.qty_pack > 1) packagingFormat = t(keys.GLOBAL.COMMON.PAILS);
					else packagingFormat = t(keys.GLOBAL.COMMON.PAIL);
					setDisplayFormat(false);
					break;
				case 8:
					if (props.qty_pack > 1) packagingFormat = t(keys.GLOBAL.COMMON.TOTES);
					else packagingFormat = t(keys.GLOBAL.COMMON.TOTE);
					setDisplayFormat(false);
					break;
				default:
					if (props.qty_pack > 1) packagingFormat = t(keys.GLOBAL.COMMON.BOXES);
					else packagingFormat = t(keys.GLOBAL.COMMON.BOX);
					setDisplayFormat(true);
					break;
			}
			setDetail(
				`${props.qty_pack} ${packagingFormat} (${props.qty} ${
					props.qty > 1 ? t(keys.GLOBAL.COMMON.UNITS) : t(keys.GLOBAL.COMMON.UNIT)
				})`
			);
			setColorHex(props.variant?.specification?.hex);
			if (props.variant?.specification?.erp_code)
				setColorName(
					lngId.includes("fr")
						? `${props.variant?.specification?.erp_code} ${props.variant?.specification?.description_fr}`
						: `${props.variant?.specification?.erp_code} ${props.variant?.specification?.description_en}`
				);
		}
	}, [t, lngId, props.variant, props.qty_pack]);

	useEffect(() => {
		setTitle(`${props.item?.erp_code} ${props.variant?.brand ? props.variant?.brand?.erp_code : ""}`);
	}, [lngId, props.variant?.item, props.variant?.brand]);

	useEffect(() => {
		if (props.images) {
			let docs = props.images;
			if (docs) {
				let overviewImages = docs.filter((doc) => doc.type === 3);
				if (overviewImages.length > 0) docs = overviewImages;
				if (props.variant?.specification?.id) {
					overviewImages = docs.filter(
						(doc) => doc.specification && doc.specification.id === props.variant?.specification?.id
					);
					if (overviewImages.length > 0) docs = overviewImages;
				}
				if (props.variant?.format?.id) {
					overviewImages = docs.filter((doc) => doc.format && doc.format.id === props?.variant.format?.id);
					if (overviewImages.length > 0) docs = overviewImages;
				}
				if (props.variant?.brand?.id) {
					overviewImages = docs.filter((doc) => doc.brand && doc.brand.id === props.variant?.brand?.id);
				}
				if (overviewImages && overviewImages.length > 0) setImage(overviewImages[0].url);
				else setImage(constant.DEFAULT_IMG);
			}
		}
	}, [props.images, props.variant, lngId]);

	useEffect(() => {
		let amountStr = props.amount
			.toFixed(2)
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		amountStr = lngId.includes("fr") ? `${amountStr.replace(".", ",")}$` : `$${amountStr}`;
		setAmount(amountStr);
	}, [lngId, props.amount]);

	useEffect(() => {
		let unitAmountStr = props.unitAmount
			.toFixed(2)
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		unitAmountStr = lngId.includes("fr") ? `${unitAmountStr.replace(".", ",")}$` : `$${unitAmountStr}`;
		setUnitAmount(unitAmountStr);
	}, [lngId, props.amount]);

	return (
		<div className={classes.cartItem}>
			<div className={classes.header}>
				<CustomNavLink
					className={classes.title}
					to={
						props.variant?.brand
							? `/adstore/overview/${props.item?.erp_code}/${props.variant?.brand?.erp_code}/${search}`
							: `/adstore/overview/${props.item?.erp_code}/${search}`
					}
				>
					{title}
				</CustomNavLink>
				<div className={classes.amount}>{amount}</div>
			</div>
			<div className={classes.product}>
				{image && (
					<CustomNavLink
						className={classes.image}
						to={
							props.variant?.brand
								? `/adstore/overview/${props.item?.erp_code}/${props.variant?.brand?.erp_code}/${search}`
								: `/adstore/overview/${props.item?.erp_code}/${search}`
						}
					>
						<img className={classes.productImage} src={image} alt={image} />
					</CustomNavLink>
				)}
				<div className={classes.description}>
					{colorHex && colorName && (
						<CustomNavLink
							className={classes.specification}
							to={
								props.variant?.brand && props.variant?.specification && props.variant?.format
									? `/adstore/variants/${props.item?.erp_code}/${props.variant?.brand?.erp_code}/${props.variant?.specification?.erp_code}/${props.variant?.format?.erp_code}/?lng=${lngId}`
									: `/adstore/overview/${props.item?.erp_code}/${search}`
							}
						>
							<Icon
								className={classes.color}
								style={{ color: `${colorHex}`, border: "0.05 rem solid var(--bg-nav)" }}
								icon="akar-icons:circle-fill"
							/>
							<div className={classes.colorDesc}>{colorName}</div>
						</CustomNavLink>
					)}
					{unitAmount && (props.variant?.format?.description_fr || props.variant?.format?.description_en) && (
						<div className={classes.detail}>
							{unitAmount}/
							{lngId.includes("fr")
								? props.variant?.format?.description_fr
								: props.variant?.format?.description_en}
						</div>
					)}
					{displayFormat && <div className={classes.detail}>{detail}</div>}
				</div>
			</div>
		</div>
	);
};

export default SiteCartItem;
