import React from "react";
import { useTranslation } from "react-i18next";

import CustomNavLink from "../ui/customNavLink/CustomNavLink";

import AdstoreLogoGray from "../../assets/svg/adfast-logo-grey.svg";
import AdstoreLogoBackground from "../../assets/svg/adfast-logo-background.svg";
import IconCartrige from "../../assets/svg/icon-cartrige.svg";

import { keys } from "../../locales/localeskeys";
import classes from "./InConstruction.module.scss";

const InConstruction = () => {
	const { t } = useTranslation();

	return (
		<div className={classes.layout}>
			<img className={classes.left} src={AdstoreLogoBackground} alt="adstore-logo-grey.svg" />
			<div className={classes.right}>
				<img className={classes.icon} src={IconCartrige} alt="icon-cartrige.svg" />
				<img className={classes.logo} src={AdstoreLogoGray} alt="adstore-logo.svg" />
			</div>
			<div className={classes.text}>
				<h1>{t(keys.INCONSTRUCTION.TITLE)}</h1>
				<p>{t(keys.INCONSTRUCTION.TEXT0)}</p>
				<p>{t(keys.INCONSTRUCTION.TEXT35)}</p>
				<p>
					{t(keys.INCONSTRUCTION.TEXT5)}
				</p>
			</div>
		</div>
	);
};

export default InConstruction;
