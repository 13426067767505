import React, { Fragment, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import { getDay } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import DatePicker from "react-datepicker";
import fr from "date-fns/locale/fr";
import en from "date-fns/locale/en-US";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";

import RadioBtn from "../../ui/Input/RadioBtn/RadioBtn";
import Button from "../../ui/Button/Button";
import AddressEditor from "../addressEditor/AddressEditor";
import TextArea from "../../ui/Input/TextArea/TextArea";

import {
	defaultAddressSelector,
	isLoggedInSelector,
	otherAddressesSelector,
	selectedAddressSelector,
	setPrecedentUrl,
	taxPercentSelector,
	walletSelector,
} from "../../../store/auth";
import {
	cartHasErrorSelector,
	isLoadingSelector,
	orderLinesSelector,
	orderSelector,
	updateOrder,
} from "../../../store/cart";

import { keys } from "../../../locales/localeskeys";
import PlaceSwitch from "../../../assets/svg/Loading/PlaceSwitch.svg";

import "react-datepicker/dist/react-datepicker.css";
import classes from "./CheckoutMobile.module.scss";
import ShoppingCart from "../shoppingCart/ShoppingCart";
import TextInput from "../../ui/Input/TextInput/TextInput";
import CustomNavLink from "../../ui/customNavLink/CustomNavLink";

const CheckoutMobile = () => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const isLoading = useSelector(isLoadingSelector);
	const hasError = useSelector(cartHasErrorSelector);
	const isLoggedIn = useSelector(isLoggedInSelector);
	const taxPercent = useSelector(taxPercentSelector);
	const defaultAddress = useSelector(defaultAddressSelector);
	const otherAddresses = useSelector(otherAddressesSelector);
	const orderLine = useSelector(orderLinesSelector);
	const wallets = useSelector(walletSelector);
	const order = useSelector(orderSelector);

	const [refValid, setRefValid] = useState(false);
	const [deliveryValid, setDeliveryValid] = useState(false);
	const [paymentValid, setPaymentValid] = useState(false);

	const [successOrder, setSuccessOrder] = useState();

	const [custRef, setCustRef] = useState("");
	const [fullName, setFullName] = useState("");
	const [deliveryMode, setDeliveryMode] = useState("delivery");
	const [deliveryDate, setDeliveryDate] = useState(undefined);
	const [deliveryTime, setdeliveryTime] = useState(undefined);
	const [selectedAddressId, setSelectedAddressId] = useState(undefined);
	const [editingAddressId, setEditingAddressId] = useState(undefined);
	const [addingAddress, setAddingAddress] = useState(false);
	const [selectedCreditCardId, setSelectedCreditCardId] = useState(undefined);
	const [shoppingCart, setShoppingCart] = useState(false);
	const [subTotal, setSubTotal] = useState(0);
	const [tax, setTax] = useState(0);
	const [total, setTotal] = useState(0);
	const [paymTerm, setPaymTerm] = useState("netterms"); //TODO "creditcard");
	const [custNote, setCustNotte] = useState("");
	const [isValidReference, setIsValidReference] = useState();
	const [isValidfullName, setIsValidfullName] = useState();

	const selectedAddress = useSelector((state) => selectedAddressSelector(state, selectedAddressId));

	const dateTimeRef = useRef();
	const sucessRef = useRef();

	useEffect(() => {
		window.scrollTo(0, 0);
		if(!isLoggedIn){
			navigate({ pathname: `/sign-in`, search: queryParams.toString() });
		}
	}, []);

	useEffect(() => {
		if (isLoggedIn && order === undefined && !hasError) {
			setSuccessOrder(true);
		}
	}, [order, hasError]);

	useEffect(() => {
		if (successOrder === true) {
			sucessRef.current.scrollIntoView({ behavior: "instant", block: "center" });
		}
	}, [successOrder]);

	useEffect(() => {
		if (defaultAddress && defaultAddress.length > 0 && !selectedAddressId) {
			setSelectedAddressId(defaultAddress[0].id);
		} else {
			if (otherAddresses && otherAddresses.length > 0 && !selectedAddressId) {
				setSelectedAddressId(otherAddresses[0].id);
			}
		}
	}, [defaultAddress, selectedAddressId]);

	useEffect(() => {
		setDeliveryValid((selectedAddressId || deliveryMode !== "delivery")  && (deliveryMode === "pickup" ? (deliveryDate !== undefined && deliveryTime !== undefined) : (deliveryDate !== undefined)));
	}, [selectedAddressId, deliveryDate, deliveryMode]);

	useEffect(() => {
		if (wallets && wallets.length > 0 && !selectedCreditCardId) {
			setSelectedCreditCardId(wallets[0].id);
		}
	}, [wallets, selectedCreditCardId]);

	useEffect(() => {
		setPaymentValid((selectedCreditCardId && paymTerm === "creditcard") || paymTerm === "netterms");
	}, [selectedCreditCardId, paymTerm]);

	useEffect(() => {
		if (!isLoggedIn) {
			dispatch(setPrecedentUrl({ pathname: location.pathname, search: location.search }));
			navigate({ pathname: `/sign-in`, search: queryParams.toString() });
		}
	}, [isLoggedIn, lngId]);

	useEffect(() => {
		if (orderLine && orderLine.length > 0) {
			const subAmount = orderLine.map((line) => Number(line.amount)).reduce((prev, curr) => prev + curr, 0);
			const taxAmount = (subAmount * taxPercent) / 100;
			const amount = subAmount + taxAmount;

			setSubTotal(subAmount);
			setTax(taxAmount);
			setTotal(amount);
		}
	}, [orderLine, taxPercent]);

	const shopingCartHandler = () => {
		setShoppingCart(true);
	};
	const closeshopingCartHandler = () => {
		window.scrollTo(0, 0);
		setShoppingCart(false);
	};

	const custRefChangeHandler = (event) => {
		setCustRef(event.target.value.trim());
		setRefValid(!!event.target.value && event.target.value.trim() !== "" && !!fullName && fullName.trim() !== "");
		setIsValidReference(!!event.target.value && event.target.value.trim() !== "");
	};

	const fullNameChangeHandler = (event) => {
		setFullName(event.target.value.trim());
		setRefValid(!!event.target.value && event.target.value.trim() !== "" && !!custRef && custRef.trim() !== "");
		setIsValidfullName(!!event.target.value && event.target.value.trim() !== "");
	};

	const deliveryAddressIdChangeHandler = (event) => {
		setSelectedAddressId(event.target.id);
	};

	const deliveryModeChangeHandler = (event) => {
		let date = new Date();
		if (date.getHours() + 1 <= 6 && date.getHours() + 1 >= 17) {
			date.setHours(date.getHours() + 1);
			date.setMinutes(0);
			date.setSeconds(0);
		} else {
			date.setHours(6);
			date.setMinutes(0);
			date.setSeconds(0);
			date.setDate(date.getDate() + 1);
		}
		setDeliveryMode(event.target.value);
		setdeliveryTime(date);
	};

	const isWeekday = (date) => {
		const day = getDay(date);
		return day !== 0 && day !== 6;
	};

	const dateChangeHandler = (date) => {
		setDeliveryDate(date);
		if (date && !isNaN(date.getTime())) {
			setRefValid(true);
		}
	};
	const timeChangeHandler = (time) => {
		setdeliveryTime(time);
		if (time && !isNaN(time.getTime())) {
			setRefValid(true);
		}
	};

	const editAddressHandler = (id) => {
		setEditingAddressId(id);
	};

	const addAddressHandler = () => {
		setAddingAddress(true);
	};

	const closeAddressEditHandler = () => {
		window.scrollTo(0, 0);
		setEditingAddressId(undefined);
		setAddingAddress(false);
	};

	const processPayment = (event) => {
		event.preventDefault();
		if (deliveryMode === "delivery") {
			dispatch(
				updateOrder({
					order_date: zonedTimeToUtc(deliveryDate, "America/New_York"),
					delivery_line1: selectedAddress.line1,
					delivery_line2: selectedAddress.line2,
					delivery_city: selectedAddress.city,
					delivery_postal_code: selectedAddress.postal_code,
					delivery_state: selectedAddress.state,
					delivery_country: selectedAddress.country,
					delivery_name: selectedAddress.name,
					delivery_phone: selectedAddress.phone,
					customer_note: custNote,
					wallet_id: selectedCreditCardId,
					is_processed: true,
					paid_by_card: paymTerm === "creditcard",
					purchase_order_ref: custRef,
					customer_ref: fullName,
				})
			);
		} else {
			const date = combineDateTime(deliveryTime,deliveryDate);
			dispatch(
				updateOrder({
					order_date: zonedTimeToUtc(date, "America/New_York"),
					is_pickup: true,
					customer_note: custNote,
					wallet_id: selectedCreditCardId,
					is_processed: true,
					paid_by_card: paymTerm === "creditcard",
					purchase_order_ref: custRef,
					customer_ref: fullName,
				})
			);
		}
	};

	const filterPassedTime = (time) => {
		const currentDate = new Date();
		const selectedDate = new Date(time);

		return currentDate.getTime() < selectedDate.getTime();
	};

	function getCurrentDate() {
		var currentDate = new Date();
		if (currentDate.getDay() === 0) {
			currentDate.setDate(currentDate.getDate() + 1);
		} else if (currentDate.getDay() === 6) {
			currentDate.setDate(currentDate.getDate() + 2);
		}

		return currentDate;
	}

	const combineDateTime = (time, date) => {
		const hours = time.getHours();
		const minutes = time.getMinutes();

		const combinedDateTime = new Date(date);
		combinedDateTime.setHours(hours);
		combinedDateTime.setMinutes(minutes);

		return combinedDateTime;
	};

	const protalElement = document.getElementById("overlays");
	return (
		<Fragment>
			<div className={classes.mobileLayout}>
				{isLoading && ReactDOM.createPortal(<div className={classes.backdrop} />, protalElement)}
				{isLoading &&
					ReactDOM.createPortal(
						<object type="image/svg+xml" data={PlaceSwitch} className={classes.loading}>
							svg-animation
						</object>,
						protalElement
					)}
				{isLoggedIn && successOrder && (
					<Fragment>
						<div className={classes.backdrop} />
						<div className={classes.success} ref={sucessRef}>
							<div className={classes.content}>
								<h1>{t(keys.CHECKOUT.PAYMENT.SUCCESS_TITLE)}</h1>
								<Icon className={classes.icon} icon="clarity:success-standard-solid" />
								<h2>{t(keys.CHECKOUT.PAYMENT.SUCCESS_SUB_TITLE)}</h2>
								<div>
									<h4>{t(keys.CHECKOUT.PAYMENT.SUCCESS_TEXT1)}</h4>
									<h4>{t(keys.CHECKOUT.PAYMENT.SUCCESS_TEXT2)}</h4>
								</div>
								<CustomNavLink to={`/`}>
									<Button className={classes.button} size="big">
										{t(keys.CHECKOUT.PAYMENT.SUCCESS_BTN)}
									</Button>
								</CustomNavLink>
							</div>
						</div>
					</Fragment>
				)}
				{!successOrder && (addingAddress === true || editingAddressId) && (
					<AddressEditor editngAddressId={editingAddressId} closeEditAddress={closeAddressEditHandler} />
				)}
				{!successOrder && shoppingCart === true && orderLine && orderLine.length > 0 && (
					<ShoppingCart orderLine={orderLine} closeShoppingCart={closeshopingCartHandler} />
				)}
				<div className={classes.header}>{t(keys.CHECKOUT.MAIN.TITLE)}</div>
				<div className={classes.title}>{t(keys.CHECKOUT.MAIN.POREFTITLE)}</div>
				<TextInput
					label={t(keys.CHECKOUT.MAIN.REFERENCE)}
					mandatory={true}
					value={custRef.value}
					onChange={custRefChangeHandler}
					disabled={false}
					maxLength={20}
					className={classes.textOverlay}
					inputclass={classes.inputclass}
					labelclass={classes.labelclass}
					isValid={isValidReference}
					error={t(keys.CHECKOUT.MAIN.FILL_MANDATORY_FIELD)}
				/>
				<TextInput
					label={t(keys.CHECKOUT.MAIN.FULLNAME)}
					mandatory={true}
					value={fullName.value}
					onChange={fullNameChangeHandler}
					disabled={false}
					maxLength={60}
					className={classes.textOverlay}
					inputclass={classes.inputclass}
					labelclass={classes.labelclass}
					isValid={isValidfullName}
					error={t(keys.CHECKOUT.MAIN.FILL_MANDATORY_FIELD)}
				/>
				<div className={classes.title}>{t(keys.CHECKOUT.MAIN.DLVMODETITLEMOBILE)}</div>
				<RadioBtn
					state={deliveryMode}
					values={[
						{
							id: 0,
							value: "delivery",
							label: t(keys.CHECKOUT.MAIN.DELIVERY),
							checked: deliveryMode === "delivery",
						},
						{
							id: 1,
							value: "pickup",
							label: t(keys.CHECKOUT.MAIN.PICKUPLABEL),
							checked: deliveryMode === "pickup",
						},
					]}
					onChange={deliveryModeChangeHandler}
					labelClassName={classes.radioLabel}
				/>
				{deliveryMode === "delivery" && defaultAddress && defaultAddress.length > 0 && (
					<div className={classes.defaultAddress}>
						<p>{t(keys.CHECKOUT.MAIN.DLVAT)}</p>
						<RadioBtn
							id={"defaultAddress"}
							name={"defaultAddress"}
							values={[
								{
									id: defaultAddress[0].id,
									value: defaultAddress[0].id,
									checked: selectedAddressId === defaultAddress[0].id,
									label: defaultAddress[0].name,
									secondLine: (
										<div className={classes.radioAddress}>
											<label>{defaultAddress[0].address}</label>
											<div
												className={classes.editAddress}
												onClick={() => editAddressHandler(defaultAddress[0].id)}
											>
												{t(keys.CHECKOUT.MAIN.EDITADDRESS)}
											</div>
										</div>
									),
								},
							]}
							onChange={deliveryAddressIdChangeHandler}
							disabled={false}
							labelClassName={classes.radioLabel}
						/>
					</div>
				)}{" "}
				{deliveryMode === "delivery" && otherAddresses && otherAddresses.length > 0 && (
					<div className={classes.otherAddresses}>
						<p>{t(keys.CHECKOUT.MAIN.CHOSEDLV)}</p>
						<RadioBtn
							id={"otherAddress"}
							name={"otherAddress"}
							values={otherAddresses.map((address) => ({
								id: address.id,
								value: address.id,
								checked: selectedAddressId === address.id,
								label: address.name,
								secondLine: (
									<div className={classes.radioAddress}>
										<label>{address.address}</label>
										<div
											className={classes.editAddress}
											onClick={() => editAddressHandler(address.id)}
										>
											{t(keys.CHECKOUT.MAIN.EDITADDRESS)}
										</div>
									</div>
								),
							}))}
							onChange={deliveryAddressIdChangeHandler}
							disabled={false}
							labelClassName={classes.radioLabel}
						/>
					</div>
				)}
				{deliveryMode === "delivery" && (
					<div className={classes.add} onClick={addAddressHandler}>
						<Icon className={classes.addIcon} icon="akar-icons:plus" />
						<div className={classes.label}>{t(keys.CHECKOUT.MAIN.ADDADDRESS)}</div>
					</div>
				)}
				<div className={classes.datePicker}>
					<div className={classes.label}>
						{t(keys.CHECKOUT.MAIN.SELECTDATEDLV)}
						<Icon className={classes.mandatory} icon="oui:asterisk" />
					</div>
					<div className={classes.inputDate}>
						<div className={deliveryMode !== "pickup" ? `${classes.date} ${classes.pickupDate}` : classes.date}>
							<Icon className={classes.calander} icon="uiw:date" />
							<DatePicker
								selected={deliveryDate}
								onChange={(date) => dateChangeHandler(date)}
								minDate={getCurrentDate()}
								filterDate={isWeekday}
								showDisabledMonthNavigation
								dateFormat={deliveryMode === "pickup" ? "MMMM d, yyyy h:mm aa" : "MMMM d, yyyy"}
								minTime={setHours(setMinutes(getCurrentDate(), 0), 6)}
								maxTime={setHours(setMinutes(getCurrentDate(), 0), 17)}
								locale={lngId.includes("fr") ? fr : en}
								disabled={false}
								filterTime={filterPassedTime}
								ref={dateTimeRef}
								placeholderText={t(keys.CHECKOUT.MAIN.SELECTDATE)}
							/>
						</div>
						<div className={deliveryMode !== "pickup" ? `${classes.time} ${classes.pickupTime}` : classes.time}>
							<DatePicker
								selected={deliveryTime}
								onChange={(time) => timeChangeHandler(time)}
								showTimeSelect
								showTimeSelectOnly
								dateFormat="h:mm aa"
								minTime={setHours(setMinutes(getCurrentDate(), 0), 6)}
								maxTime={setHours(setMinutes(getCurrentDate(), 0), 17)}
								placeholderText="Time"
							/>
						</div>
					</div>
				</div>
				<div className={classes.custNote}>
					<p>{t(keys.CHECKOUT.MAIN.CUSTNOTE)}</p>
					<TextArea
						value={custNote.value}
						onChange={(event) => {
							setCustNotte(event.target.value);
						}}
						disabled={false}
						inputClassName={classes.notCss}
						className={classes.noteClassName}
						rows={3}
					/>
				</div>
				<div className={classes.title}>3- {t(keys.CART.OVERVIEW.ORDERSUMMARY)}</div>
				<div className={classes.field}>
					<div>{t(keys.CHECKOUT.SUMMARY.SUBTOTAL)}</div>
					<div>
						{lngId.includes("fr") ? `${subTotal.toFixed(2).replace(".", ",")}$` : `$${subTotal.toFixed(2)}`}
					</div>
				</div>
				<div className={classes.field}>
					<div>{t(keys.CHECKOUT.SUMMARY.TAX)}</div>
					<div>{lngId.includes("fr") ? `${tax.toFixed(2).replace(".", ",")}$` : `$${tax.toFixed(2)}`}</div>
				</div>
				<hr />
				<div className={classes.field}>
					<div className={classes.total}>{t(keys.CHECKOUT.SUMMARY.TOTAL)}</div>
					<div className={classes.total}>
						{lngId.includes("fr") ? `${total.toFixed(2).replace(".", ",")}$` : `$${total.toFixed(2)}`}
					</div>
				</div>
				<hr />
				<p className={classes.note}>{t(keys.CHECKOUT.SUMMARY.REVIEW)}</p>
				{orderLine && orderLine.length > 0 && (
					<div className={classes.shoppingCart} onClick={shopingCartHandler}>
						<div className={classes.left}>{t(keys.CART.OVERVIEW.TITLE)}</div>{" "}
						<div className={classes.right}>
							<Icon className={classes.shopingCart} icon="ant-design:shopping-cart-outlined" />
							<div className={classes.order}>{orderLine.length}</div>
						</div>
					</div>
				)}
				{hasError && (
					<div className={classes.error}>
						<label>
							<Icon className={classes.mandatory} icon="oui:asterisk" />
							{t(keys.CHECKOUT.MAIN.ERROR)}
						</label>
					</div>
				)}
				<div className={classes.btnOrder}>
					<Button disabled={!isValidReference || !isValidfullName || !refValid || !deliveryValid || isLoading === true} onClick={processPayment}>
						{t(keys.CHECKOUT.SUMMARY.ORDER)}
					</Button>
				</div>
			</div>
		</Fragment>
	);
};
export default CheckoutMobile;
