import React, { useImperativeHandle, useRef } from "react";

import { Icon } from "@iconify/react";
import classes from "./TextInput.module.scss";

const TextInput = React.forwardRef((props, ref) => {
	const inputRef = useRef();

	const inputHandler = (e) => {
		if (props.type === "number") {
			const char = String.fromCharCode(e.which);
			if (!/[0-9]/.test(char)) {
				e.preventDefault();
			}
		}
	};
	const activate = () => {
		inputRef.current.focus();
	};

	useImperativeHandle(ref, () => {
		return {
			focus: activate,
		};
	});

	return (
		<div
			className={`${props.className || ""} ${classes.TextInput} ${
				props.isValid === false ? classes.invalid : ""
			}`}
		>
			{props.label && (
				<div className={`${props.labelclass || ""} ${classes.label} ${props.classLabel || ""}`}>
					<label htmlFor={props.id}>
						{props.label}
						{props.mandatory && <Icon className={classes.mandatory} icon="oui:asterisk" />}
					</label>
				</div>
			)}
			<input
				className={`${props.inputclass || ""} ${classes[props.color] || ""} ${props.inputClassName || ""} ${
					props.shakeError === true ? classes.shakeError : ""
				}`}
				ref={inputRef}
				type={props.type}
				id={props.id}
				name={props.name}
				value={props.value}
				onChange={props.onChange}
				onFocus={props.onFocus}
				onBlur={props.onBlur}
				placeholder={props.placeholder}
				onKeyPress={inputHandler}
				defaultValue={props.defaultValue}
				disabled={props.disabled}
				required={props.mandatory}
				pattern={props.pattern}
				maxLength={props.maxLength}
			/>
			{props.isValid === false && props.warning && (
				<div className={classes.warning}>
					<Icon icon="bi:exclamation-circle-fill" />
					<p>{props.warning}</p>
				</div>
			)}
			{props.search && <Icon className={`${ classes.search } ${props.searchClass || ""}`} onClick={props.search} icon="ic:baseline-search" />}
			{props.isValid === false && props.error && (
				<div className={classes.error}>
					<p>{props.error}</p>
				</div>
			)}
		</div>
	);
});

export default TextInput;
