import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { Fragment, useEffect, useState } from "react";
import { Icon } from "@iconify/react";

import encodeSlash from "../../hooks/encodeSlash";
import CustomNavLink from "../ui/customNavLink/CustomNavLink";
import TextInput from "../ui/Input/TextInput/TextInput";
import CustDropDown from "../ui/Input/CustDropDown/CustDropDown";
import Button from "../ui/Button/Button";

import { categoriesLoadingSelector, categoriesTreeSelector, getCategories } from "../../store/productCategory";
import {
	getLibraryCategories,
	libraryCategoryIsLoadingSelector,
	libraryCategorySelector,
} from "../../store/documentCategory";
import { getSegments, segmentIsLoadingSelector, segmentTreeSelectror } from "../../store/segment";
import {
	clearProduct,
	getItemCarts,
	itemCartsSelector,
	itemIsLoadingSelector,
	productCountSelector,
} from "../../store/product";
import { pageSelector, setCategories, setPage, setSearch, setSubSegments } from "../../store/productQuery";
import {
	clearLibrary,
	getLibraries,
	libraryCountSelector,
	libraryIsLoadingSelector,
	librarySelector,
} from "../../store/library";
import {
	clearLinks,
	getPageLinks,
	pageLinksCountSelector,
	pageLinksIsLoadingSelector,
	pageLinksSelector,
} from "../../store/pageLinks";

import { keys } from "../../locales/localeskeys";

import classes from "./Search.module.scss";

const Search = () => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const search = `?lng=${lngId}`;

	const [searchParams, setSearchParams] = useSearchParams();
	const searchState = searchParams.get("searchValue") ?? "";

	const [isSubmitted, setIsSubmitted] = useState(false);
	const [checkedCategories, setCheckedCategories] = useState([]);
	const [checkedLibrary, setCheckedLibrary] = useState();
	const [checkedSegment, setCheckedSegment] = useState([]);
	const [currentTab, setCurrentTab] = useState(0);
	const [curProductPage, setCurProductPage] = useState(1);
	const [libraryPage, setLibraryPage] = useState(1);
	const [linkPage, setLinkPage] = useState(1);

	const categoriesLoading = useSelector(categoriesLoadingSelector);
	const categoriesTree = useSelector(categoriesTreeSelector);
	const segmentIsLoading = useSelector(segmentIsLoadingSelector);
	const segmentsTree = useSelector(segmentTreeSelectror);
	const libraryCategoryIsLoading = useSelector(libraryCategoryIsLoadingSelector);
	const libraryCategory = useSelector(libraryCategorySelector);
	const products = useSelector(itemCartsSelector);
	const itemIsLoading = useSelector(itemIsLoadingSelector);
	const libraryIsLoading = useSelector(libraryIsLoadingSelector);
	const library = useSelector(librarySelector);
	const pageLinksIsLoading = useSelector(pageLinksIsLoadingSelector);
	const pageLinks = useSelector(pageLinksSelector);
	const productCount = useSelector(productCountSelector);
	const libraryCount = useSelector(libraryCountSelector);
	const pageLinksCount = useSelector(pageLinksCountSelector);
	const productPage = useSelector(pageSelector);

	useEffect(() => {
		dispatch(clearLibrary());
		dispatch(clearProduct());
		dispatch(clearLinks());
		dispatch(getCategories());
		dispatch(getLibraryCategories());
		dispatch(getSegments());
	}, []);

	useEffect(() => {
		dispatch(
			getItemCarts({
				search_feature: true,
				search_document: false,
				keep: false,
				searchValue: searchState,
			})
		);
		dispatch(
			getLibraries({
				category: checkedLibrary?.id,
				search: searchState,
				page: libraryPage,
				pageSize: 16,
				keep: true,
			})
		);
		dispatch(
			getPageLinks({
				search: searchState,
				page: linkPage,
				pageSize: 16,
				keep: true,
				t,
			})
		);
	}, []);

	useEffect(() => {
		dispatch(setSearch(searchState));
	}, [searchState]);

	useEffect(() => {
		dispatch(setCategories(checkedCategories.map((el) => el.id)));
	}, [checkedCategories]);

	useEffect(() => {
		dispatch(setSubSegments(checkedSegment.map((el) => el.id)));
	}, [checkedSegment]);

	useEffect(() => {
		if (isSubmitted) dispatch(getItemCarts({ search_feature: true, search_document: false, keep: true }));
	}, [productPage]);

	useEffect(() => {
		if (isSubmitted)
			dispatch(
				getLibraries({
					category: checkedLibrary?.id,
					search: searchState,
					page: libraryPage,
					pageSize: 16,
					keep: true,
				})
			);
	}, [libraryPage]);

	useEffect(() => {
		if (isSubmitted) dispatch(getPageLinks({ search: searchState, page: linkPage, pageSize: 16, keep: true, t }));
	}, [linkPage]);

	const searchChangeHandler = (event) => {
		if (event.target.value) {
			searchParams.set("searchValue", event.target.value);
			setSearchParams(searchParams);
		} else {
			searchParams.delete("searchValue");
			setSearchParams(searchParams);
		}
	};

	const productLinkProvider = (item) => {
		if (item.brandErpCode)
			if (item.searched_specification)
				return `/adstore/variants/${encodeSlash(item.erp_code)}/${encodeSlash(item.brandErpCode)}/${encodeSlash(
					item.searched_specification
				)}/${encodeSlash(search)}`;
			else
				return `/adstore/overview/${encodeSlash(item.erp_code)}/${encodeSlash(item.brandErpCode)}/${encodeSlash(
					search
				)}`;
		else return `/adstore/overview/${encodeSlash(item.erp_code)}/${encodeSlash(search)}`;
	};

	const searchSubmit = (event) => {
		event.preventDefault();
		setIsSubmitted(true);
		if (!checkedLibrary) {
			dispatch(getItemCarts({ search_feature: true, search_document: false, keep: false }));
			dispatch(getPageLinks({ search: searchState, page: 1, pageSize: 16, keep: false, t }));
		} else {
			dispatch(clearProduct());
			dispatch(clearLinks());
		}
		dispatch(
			getLibraries({
				category: checkedLibrary?.id,
				search: searchState,
				page: 1,
				pageSize: 16,
				keep: false,
			})
		);
	};

	const removeFilter = (el) => {
		if (checkedCategories.includes(el)) {
			setCheckedCategories(checkedCategories.filter((e) => e.id !== el.id));
		} else if (checkedLibrary.id === el.id) {
			setCheckedLibrary(undefined);
		} else if (checkedSegment.includes(el)) {
			setCheckedSegment(checkedSegment.filter((e) => e.id !== el.id));
		}
	};

	const clearFilters = () => {
		searchParams.delete("searchValue");
		setSearchParams(searchParams);
		setCheckedCategories([]);
		setCheckedLibrary(undefined);
		setCheckedSegment([]);
		dispatch(clearLibrary());
		dispatch(clearProduct());
	};

	const loadMore = () => {
		if (currentTab === 0) {
			if (productCount > curProductPage * 16) {
				dispatch(setPage(curProductPage + 1));
				setCurProductPage(curProductPage + 1);
			}
			if (libraryCount > libraryPage * 16) setLibraryPage(libraryPage + 1);
			if (pageLinksCount > linkPage * 16) setLinkPage(linkPage + 1);
		} else if (currentTab === 1) {
			if (productCount > curProductPage * 16) {
				dispatch(setPage(curProductPage + 1));
				setCurProductPage(curProductPage + 1);
			}
		} else if (currentTab === 2) {
			if (libraryCount > libraryPage * 16) setLibraryPage(libraryPage + 1);
		} else if (currentTab === 3) {
			if (pageLinksCount > linkPage * 16) setLinkPage(linkPage + 1);
		}
	};

	const totalResult =
		(products?.length ?? 0) +
		(library?.filter((document) => document.lng.includes(lngId.substring(0, 2)))?.length ?? 0) +
		(pageLinks?.length ?? 0);

	const productResult = products?.length ?? 0;
	const documentResult = library?.filter((document) => document.lng.includes(lngId.substring(0, 2)))?.length ?? 0;
	const linkResult = pageLinks?.length ?? 0;

	const seachExistsInProductsTitle =
		products.filter((product) => product.title.toLowerCase().includes(searchState.toLowerCase())).length !== 0 ??
		false;

	return (
		<div className={classes.layout}>
			<div className={classes.headerLayout}>
				<h1 className={classes.title}>{t(keys.SEARCH.TITLE)}</h1>
				<form onSubmit={searchSubmit}>
					<div className={classes.searchLayout}>
						<TextInput
							className={classes.searchInput}
							placeholder={t(keys.SEARCH.SEARCH_BY_KEYWORD)}
							onChange={searchChangeHandler}
							value={searchState}
							search={searchSubmit}
						/>
					</div>
					<div>
						<label className={classes.label}>{t(keys.SEARCH.REFINE)}</label>
						<div className={classes.filter}>
							<CustDropDown
								className={classes.dropDown}
								label={t(keys.SEARCH.PRODUCT_CATEGORY)}
								data={categoriesTree.map((parent) => {
									return {
										id: parent.id,
										label: lngId.includes("fr") ? parent.value_fr : parent.value_en,
										values: parent.children.map((child) => {
											return {
												id: child.id,
												value: lngId.includes("fr") ? child.value_fr : child.value_en,
											};
										}),
									};
								})}
								multiSelection
								checked={checkedCategories}
								onCheck={setCheckedCategories}
								disable={categoriesLoading}
							/>
							<CustDropDown
								className={classes.dropDown}
								label={t(keys.SEARCH.DOCUMENT_TYPE)}
								data={[
									{ id: 1, value: t(keys.SEARCH.SDS) },
									{ id: 2, value: t(keys.SEARCH.TDS) },
									...libraryCategory.map((library) => {
										return {
											id: library.id,
											value: lngId.includes("fr") ? library.title_fr : library.title_en,
										};
									}),
								]}
								checked={checkedLibrary}
								onCheck={setCheckedLibrary}
								disable={libraryCategoryIsLoading}
							/>
							<CustDropDown
								className={classes.dropDown}
								label={t(keys.SEARCH.APPLICATION)}
								data={segmentsTree.map((segment) => {
									return {
										id: segment.id,
										label: segment.erp_code,
										values: segment.children.map((child) => {
											return {
												id: child.erp_code,
												value: lngId.includes("fr") ? child.title_fr : child.title_en,
											};
										}),
									};
								})}
								multiSelection
								checked={checkedSegment}
								onCheck={setCheckedSegment}
								disable={segmentIsLoading}
							/>
						</div>
					</div>
					{[...checkedCategories, checkedLibrary, ...checkedSegment] &&
						[...checkedCategories, checkedLibrary, ...checkedSegment].filter((el) => el !== undefined)
							.length > 0 && (
							<div className={classes.selectedFiltersLayout}>
								<div className={classes.selectedFilters}>
									{[...checkedCategories, checkedLibrary, ...checkedSegment]
										.filter((el) => el !== undefined)
										.map((el, index) => (
											<div key={index} className={classes.element}>
												<p>{el.value}</p>
												<Icon
													className={classes.icon}
													onClick={() => removeFilter(el)}
													icon="ph:x-circle"
												/>
											</div>
										))}
								</div>
								<p className={classes.clear} onClick={clearFilters}>
									{t(keys.SEARCH.CLEAR)}
								</p>
							</div>
						)}
					<Button
						type="submit"
						value="submit"
						size="big"
						className={classes.applyBtn}
						disabled={itemIsLoading || libraryIsLoading || pageLinksIsLoading}
					>
						{t(keys.SEARCH.APPLY)}
					</Button>
				</form>
			</div>
			<div className={classes.resultLabel}>
				<h1>{t(keys.SEARCH.SEARCH_RESULT)}</h1>
				<div className={classes.resultTabMenu}>
					<div
						className={classes.tab}
						style={{
							textAlign: "center",
							paddingBottom: currentTab === 0 ? "0" : undefined,
							borderBottom: currentTab === 0 ? "1rem solid #007AFF" : undefined,
						}}
						onClick={() => setCurrentTab(0)}
					>
						<p>
							{t(keys.SEARCH.ALL)}
							<span>{` (${totalResult})`}</span>
						</p>
					</div>
					<div
						className={classes.tab}
						style={{
							textAlign: "center",
							paddingBottom: currentTab === 1 ? "0" : undefined,
							borderBottom: currentTab === 1 ? "1rem solid #007AFF" : undefined,
						}}
						onClick={() => setCurrentTab(1)}
					>
						<p>
							{t(keys.SEARCH.PRODUCTS)}
							<span>{` (${productResult})`}</span>
						</p>
					</div>
					<div
						className={classes.tab}
						style={{
							textAlign: "center",
							paddingBottom: currentTab === 2 ? "0" : undefined,
							borderBottom: currentTab === 2 ? "1rem solid #007AFF" : undefined,
						}}
						onClick={() => setCurrentTab(2)}
					>
						<p>
							{t(keys.SEARCH.DOCUMENTS)}
							<span>{` (${documentResult})`}</span>
						</p>
					</div>
					<div
						className={classes.tab}
						style={{
							textAlign: "center",
							paddingBottom: currentTab === 3 ? "0" : undefined,
							borderBottom: currentTab === 3 ? "1rem solid #007AFF" : undefined,
						}}
						onClick={() => setCurrentTab(3)}
					>
						<p>
							{t(keys.SEARCH.PAGES)}
							<span>{`(${linkResult})`}</span>
						</p>
					</div>
				</div>
			</div>
			<div className={classes.searchResult}>
				{!seachExistsInProductsTitle &&
					(currentTab === 0 || currentTab === 2) &&
					library &&
					library.length > 0 &&
					library
						.filter((document) => document.lng.includes(lngId.substring(0, 2)))
						.map((document) => (
							<div key={document.id} className={classes.document}>
								<div>
									<h1>{document.title}</h1>
									<p>{document.description}</p>
								</div>
								<CustomNavLink to={document.url} target={"_blank"}>
									<Icon className={classes.icon} icon="mdi:download-box-outline" />
								</CustomNavLink>
							</div>
						))}
				{(currentTab === 0 || currentTab === 1 || currentTab === 2) &&
					products &&
					products.length > 0 &&
					products.map((item, index) => (
						<Fragment key={index}>
							{currentTab <= 1 && (
								<CustomNavLink
									to={productLinkProvider(item)}
									key={index}
									className={classes.productContainer}
								>
									<img
										className={classes.img}
										src={
											(lngId.includes("fr") ? item.overViewImageFr : item.overViewImageEn) ??
											item.defaultImage
										}
										alt=""
									/>
									<div className={classes.detailsContainer}>
										<div className={classes.title}>
											<h1>{item.title}</h1>
											{(lngId.includes("fr") ? item.label_fr : item.label_en) && (
												<h2>{lngId.includes("fr") ? item.label_fr : item.label_en}</h2>
											)}
											{(lngId.includes("fr") ? item.text_fr : item.text_en) && (
												<p>{lngId.includes("fr") ? item.text_fr : item.text_en}</p>
											)}
										</div>
										{item?.features_documents?.filter((feature) =>
											feature.language.includes(lngId.substring(0, 2))
										) &&
											item?.features_documents?.filter((feature) =>
												feature.language.includes(lngId.substring(0, 2))
											).length > 0 && (
												<div className={classes.features}>
													<ul>
														{item?.features_documents
															?.filter((feature) =>
																feature.language.includes(lngId.substring(0, 2))
															)
															.slice(0, 3)
															.map((feature) => (
																<li key={feature.id}>{feature.text}</li>
															))}
													</ul>
												</div>
											)}
									</div>
								</CustomNavLink>
							)}
						</Fragment>
					))}
				{seachExistsInProductsTitle &&
					(currentTab === 0 || currentTab === 2) &&
					library &&
					library.length > 0 &&
					library
						.filter((document) => document.lng.includes(lngId.substring(0, 2)))
						.map((document) => (
							<div key={document.id} className={classes.document}>
								<div>
									<h1>{document.title}</h1>
									<p>{document.description}</p>
								</div>
								<CustomNavLink to={document.url} target={"_blank"}>
									<Icon className={classes.icon} icon="mdi:download-box-outline" />
								</CustomNavLink>
							</div>
						))}
				{(currentTab === 0 || currentTab === 3) &&
					pageLinks &&
					pageLinks.length > 0 &&
					pageLinks.map((link) => (
						<CustomNavLink to={link.url} key={link.id} className={classes.linkContainer}>
							<img className={classes.img_overview} src={link.img_overview} alt="" />
							<div className={classes.details}>
								<h1>{lngId.includes("fr") ? link.title_fr : link.title_en}</h1>
								<p>{lngId.includes("fr") ? link.description_fr : link.description_en}</p>
							</div>
						</CustomNavLink>
					))}
				{((currentTab === 0 &&
					(productCount > productPage * 16 ||
						libraryCount > libraryPage * 16 ||
						pageLinksCount > linkPage * 16)) ||
					(currentTab === 1 && productCount > productPage * 16) ||
					(currentTab === 2 && libraryCount > libraryPage * 16) ||
					(currentTab === 3 && pageLinksCount > linkPage * 16)) && (
					<Button
						color="outlineBlue"
						size="big"
						onClick={loadMore}
						className={classes.loadBtn}
						disable={itemIsLoading || libraryIsLoading || pageLinksIsLoading}
					>
						{t(keys.SEARCH.LOAD)}
					</Button>
				)}
			</div>
		</div>
	);
};

export default Search;
