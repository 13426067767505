import React from "react";
import { Icon } from "@iconify/react";

import classes from "./CheckoutItem.module.scss";

const CheckoutItem = (props) => {
	return (
		<div className={`${classes.layout} ${props.className || ""}`}>
			<div
				className={`${classes.title} ${classes[props.isValid ? "valide" : ""]} ${
					classes[props.isOpen ? "open" : ""]
				}`}
			>
				<p>{props.title}</p>
				{props.isValid && !props.hasError && !props.hideIcon && (
					<Icon className={classes.iconSuccess} icon="clarity:success-standard-solid" />
				)}
				{props.hasError && !props.hideIcon && <Icon className={classes.iconError} icon="ic:round-error" />}
			</div>
			{props.isOpen && (
				<form className={`${classes.body} ${classes[props.isOpen ? "open" : ""]}`} onSubmit={props.onSubmit}>
					{props.children}
				</form>
			)}
		</div>
	);
};
export default CheckoutItem;
