import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
	deleteWallet,
	isLoadingSelector,
	isLoggedInSelector,
	setPrecedentUrl,
	walletSelector,
} from "../../../store/auth";
import React, { Fragment, useEffect, useState } from "react";
import classes from "./Wallet.module.scss";
import { keys } from "../../../locales/localeskeys";
import Button from "../../ui/Button/Button";
import amex from "../../../assets/jpeg/amex.jpeg";
import dankort from "../../../assets/jpeg/dankort.jpeg";
import diners from "../../../assets/jpeg/diners.jpeg";
import discover from "../../../assets/jpeg/discover.jpeg";
import maestro from "../../../assets/jpeg/maestro.jpeg";
import mastercard from "../../../assets/jpeg/mastercard.jpeg";
import visa from "../../../assets/jpeg/visa.jpeg";
import unknown from "../../../assets/jpeg/unkown.jpeg";
import RadioBtn from "../../ui/Input/RadioBtn/RadioBtn";
import LinkTree from "../../ui/LinkTree/LinkTree";

const Wallet = () => {
	const dispatch = useDispatch();

	const { i18n, t } = useTranslation();
	const lngId = i18n.language;

	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const isLoggedIn = useSelector(isLoggedInSelector);
	const isLoading = useSelector(isLoadingSelector);
	const wallets = useSelector(walletSelector);

	const [selectedCreditCardId, setSelectedCreditCardId] = useState(undefined);
	const [addingCreditCard, setAddingCreditCard] = useState(false);

	useEffect(() => {
		if (!isLoggedIn) {
			dispatch(setPrecedentUrl({ pathname: location.pathname, search: location.search }));
			navigate({ pathname: `/sign-in`, search: queryParams.toString() });
		}
	}, [isLoggedIn, lngId]);

	useEffect(() => {
		if (wallets && wallets.length > 0 && !selectedCreditCardId) {
			setSelectedCreditCardId(wallets[0].id);
		}
	}, [wallets, selectedCreditCardId]);

	const creditCardIdChangeHandler = (event) => {
		setSelectedCreditCardId(event.target.id);
	};

	const addCreditCardHandler = () => {
		setAddingCreditCard(true);
	};

	const closeAddCreditCardHandler = () => {
		setAddingCreditCard(false);
	};

	const removeCreditCartHandler = () => {
		dispatch(deleteWallet(selectedCreditCardId));
	};

	const cartBrand = (brand) => {
		switch (brand) {
			case "amex":
				return amex;
			case "dankort":
				return dankort;
			case "diners":
				return diners;
			case "discover":
				return discover;
			case "maestro":
				return maestro;
			case "mastercard":
				return mastercard;
			case "visa":
				return visa;
			default:
				return unknown;
		}
	};

	return (
		<Fragment>
			{/*{addingCreditCard === true && <CreditCardForm closeAddCreditCard={closeAddCreditCardHandler} />}*/}
			<div className={classes.bodyContainer}>
				<h1 className={classes.title}>{t(keys.ACCOUNT.WALLET.TITLE)}</h1>
				<LinkTree
					className={classes.Tree}
					tree={[
						{ label: t(keys.GLOBAL.NAVBAR.HOME), to: "/" },
						{ label: "ACCOUNT", to: "/adstore/" },
						{ label: "ACCOUNT", to: "/adstore/" },
					]}
				/>
				{wallets && (
					<div className={classes.wallet}>
						<RadioBtn
							id={"wallet"}
							name={"wallet"}
							inputCss={classes.radio}
							label={
								<div className={classes.cardsHeader}>
									<p className={classes.yourcard}>{t(keys.CHECKOUT.PAYMENT.YOURCREDITCARD)}</p>
									<p className={classes.namecard}>{t(keys.CHECKOUT.PAYMENT.NAMECREDITCARD)}</p>
									<p className={classes.expcard}>{t(keys.CHECKOUT.PAYMENT.EXPCREDITCARD)}</p>
								</div>
							}
							values={wallets.map((wallet, index) => ({
								id: wallet.id,
								value: wallet.id,
								defaultChecked: index === 0,
								label: (
									<div className={classes.radioLabel}>
										<div className={classes.card}>
											<img src={cartBrand(wallet.card_brand)} alt={wallet.card_brand} />
											<p>
												{`${
													wallet.card_brand.charAt(0).toUpperCase() +
													wallet.card_brand.slice(1)
												} ${t(keys.CHECKOUT.PAYMENT.ENDING)} ${wallet.card_number}`}
											</p>
										</div>
										<p className={classes.name}>{wallet.card_name}</p>
										<p className={classes.exp}>{`${wallet.expiry_date.substring(
											0,
											2
										)}/20${wallet.expiry_date.substring(2)}`}</p>
									</div>
								),
							}))}
							onChange={creditCardIdChangeHandler}
						/>
						<div className={classes.buttons}>
							<Button size="big" color="red" disabled={!!isLoading} onClick={removeCreditCartHandler}>
								{t(keys.ACCOUNT.WALLET.REMOVE)}
							</Button>
						</div>
					</div>
				)}
				<div className={classes.add}>
					<h1>{t(keys.ACCOUNT.WALLET.ADDTITLE)}</h1>
					<hr />
					<h2>{t(keys.ACCOUNT.WALLET.ADDSUBTITLE)}</h2>
					<p>{t(keys.ACCOUNT.WALLET.ADDTEXTE)}</p>
					<Button size="big" disabled={!!isLoading} className={classes.addBtn} onClick={addCreditCardHandler}>
						{t(keys.ACCOUNT.WALLET.ADD)}
					</Button>
				</div>
			</div>
		</Fragment>
	);
};

export default Wallet;
