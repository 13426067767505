import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import classes from "./FindStore.module.scss";
import Offices from "../Offices/Offices";
import Stores from "../Offices/Stores/Stores";
import { keys } from "../../locales/localeskeys";
import { LoadScript } from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import { getGoogleMapKey, googleMapKeySelector, loadingGoogleMapKeySelector } from "../../store/offices";
import Loading from "../ui/loading/Loading";

const FindStore = () => {
	const { t, i18n } = useTranslation();
	const storesRef = useRef(null);
	const googleMapKey = useSelector(googleMapKeySelector);
	const loadingGoogleMapKey = useSelector(loadingGoogleMapKeySelector);

	const dispatch = useDispatch();

	useEffect(() => {
		if (!googleMapKey) {
			dispatch(getGoogleMapKey());
		}
	}, []);

	const scrollToStores = () => {
		if (storesRef.current) {
			storesRef.current.scrollIntoView({ behavior: "smooth" });
		}
	};

	return (
		<div className={classes.layout}>
			<h1 className={classes.title}>{t(keys.FIND_STORE.META.PAGE_TITLE)}</h1>
			<div className={classes.description}>
				{t(keys.FIND_STORE.META.PAGE_DESCRIPTION)}{" "}
				<span onClick={scrollToStores}>
          {t(keys.FIND_STORE.META.HERE)}
        </span>.
			</div>
			{loadingGoogleMapKey && <Loading type={"bounce"} />}
			{!loadingGoogleMapKey && (<LoadScript googleMapsApiKey={googleMapKey} libraries={["places"]}>
				<Offices />
				<Stores ref={storesRef} />
			</LoadScript>)}
		</div>
	);
};

export default FindStore;
