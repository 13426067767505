import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";

import ModalVideo from "../Segment/ModalVideo/ModalVideo";
import Characteristics from "../ui/Characteristics/Characteristics";
import Button from "../ui/Button/Button";
import CustomNavLink from "../ui/customNavLink/CustomNavLink";

import fr_background from "../../assets/png/adrobot_background_fr.png";
import en_background from "../../assets/png/adrobot_background_en.png";
import kuka from "../../assets/svg/kuka.svg";

import { constants } from "../../locales/constant";
import { keys } from "../../locales/localeskeys";
import classes from "./ADRobot.module.scss";
import useElementSize from "../../hooks/useElementSize";

const ADRobot = () => {
	const { i18n, t } = useTranslation();
	const lngId = i18n.language;

	const [gridRef, { width: gridWidth, height: heightWidth }] = useElementSize();

	const [isOpen, setOpen] = useState(false);
	const [isExpanded, setIsExpanded] = useState(false);
	const [isExpanded2, setIsExpanded2] = useState(false);
	const openModal = () => {
		setOpen(true);
	};

	const closeModal = () => {
		setOpen(false);
	};

	const toggleExpand = () => setIsExpanded(!isExpanded);

	const toggleExpand2 = () => setIsExpanded2(!isExpanded2);

	function splitParagraph(paragraph) {
		const words = paragraph.split(" ");
		const first20Words = words.slice(0, 20).join(" ");
		const remainingWords = words.slice(20).join(" ");

		return {
			first20Words,
			remainingWords,
		};
	}

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Fragment>
			<ModalVideo videoId="acCE_dIBUOU" isOpen={isOpen} onClose={closeModal} />
			<div className={classes.header}  ref={gridRef}>
				<img
					className={classes.background}
					src={lngId.includes("en") ? en_background : fr_background}
					alt="background"
				/>
				<img className={classes.robot} src={kuka} alt="kuka" />
			</div>
			<div className={classes.layout}>
				<div className={classes.body}>
					<div className={classes.description}>
						<div className={classes.texte}>
							<h1>{t(keys.ADROBOT.TITLE1)}</h1>
							<div className={classes.longTexte}>
								<p>
									{(() => {
										const description = t(keys.ADROBOT.DESCRIPTION1);
										const { first20Words, remainingWords } = splitParagraph(description);

										if (gridWidth > 833 || !remainingWords) {
											return description;
										} else {
											return (
												<>
													{isExpanded ? description : `${first20Words}...`}
													<span onClick={toggleExpand} className={classes.seenMoreButton}>
												{isExpanded
													? ""
													: t(keys.HOME.BUTTON_SEE_MORE)}
											</span>
												</>
											);
										}
									})()}
								</p>
								{isExpanded && <p>{t(keys.ADROBOT.DESCRIPTION2)}
									<span onClick={toggleExpand} className={classes.seenMoreButton}>
												{isExpanded
													? t(keys.HOME.BUTTON_SEE_LESS)
													: ""}
									</span>
								</p>}
							</div>
						</div>
						<div className={classes.picker}>
							<div className={classes.element}>
								<h1>{t(keys.ADROBOT.MORE)}</h1>
								<h1>{t(keys.ADROBOT.PICKER_TITLE1)}</h1>
								<p>{t(keys.ADROBOT.PICKER_DESCRIPTION1)}</p>
								<p>{t(keys.ADROBOT.PICKER_DESCRIPTION4)}</p>
							</div>
							<div className={classes.element}>
								<h1>{t(keys.ADROBOT.MORE)}</h1>
								<h1>{t(keys.ADROBOT.PICKER_TITLE2)}</h1>
								<p>{t(keys.ADROBOT.PICKER_DESCRIPTION2)}</p>
							</div>
							<div className={classes.element}>
								<h1>{t(keys.ADROBOT.LESS)}</h1>
								<h1>{t(keys.ADROBOT.PICKER_TITLE3)}</h1>
								<p>{t(keys.ADROBOT.PICKER_DESCRIPTION3)}</p>
							</div>
						</div>
						<div className={classes.video}>
							<h1>{t(keys.ADROBOT.TITLE3)}</h1>
							<div className={classes.container}>
								<p>
									{(() => {
										const description = t(keys.ADROBOT.DESCRIPTION3);
										const { first20Words, remainingWords } = splitParagraph(description);

										if (gridWidth > 833 || !remainingWords) {
											return description;
										} else {
											return (
												<>
													{isExpanded2 ? description : `${first20Words}...`}
													<span onClick={toggleExpand2} className={classes.seenMoreButton}>
												{isExpanded2
													? t(keys.HOME.BUTTON_SEE_LESS)
													: t(keys.HOME.BUTTON_SEE_MORE)}
											</span>
												</>
											);
										}
									})()}
								</p>
								<div className={classes.imgContainer}>
									<img
										src="https://img.youtube.com/vi/acCE_dIBUOU/hqdefault.jpg"
										alt="youtube cover"
										onClick={openModal}
									/>
									<Icon className={classes.iconPlay} onClick={openModal} icon="fa:play" />
								</div>
							</div>
						</div>
					</div>
					<Characteristics data={constants.adrobot} />
				</div>
				<div className={classes.contact_layout}>
					<img className={classes.robot} src={kuka} alt="kuka" />
					<div className={classes.contact_body}>
						<h2>{t(keys.ADROBOT.TITLE2)}</h2>
						<p>{t(keys.ADROBOT.CONTACT_TXT)}</p>
						<CustomNavLink className={classes.btnLink} to={"/contact-us"}>
							<Button className={classes.btn}>{t(keys.ADROBOT.CONTACT)}</Button>
						</CustomNavLink>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default ADRobot;
