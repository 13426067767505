import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import TextInputOverlay from "../../ui/Input/TextInputOverlay/TextInputOverlay";
import RoundCheckbox from "../../ui/Input/RoundCheckbox/RoundCheckbox";
import NavDrop from "../../navbar/NavDrop/NavDrop";
import CustomNavLink from "../../ui/customNavLink/CustomNavLink";

import logo_fr from "../../../assets/svg/txt_logo_fr.svg";
import nologo_fr from "../../../assets/svg/txt_no_logo_fr.svg";
import logo_en from "../../../assets/svg/txt_logo_en.svg";
import nologo_en from "../../../assets/svg/txt_no_logo_en.svg";

import {
	authHasErrorSelector,
	isLoadingSelector,
	isLoggedInSelector,
	login,
	precedentUrlSelector,
} from "../../../store/auth";

import { keys } from "../../../locales/localeskeys";
import classes from "./Signin.module.scss";
import { clear } from "../../../store/variant";

const Signin = () => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const lngParm = `?lng=${lngId}`;

	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const emailInputRef = useRef();
	const passwordInputRef = useRef();
	const stayConnected = useRef(true);

	const isLoggedIn = useSelector(isLoggedInSelector);
	const precedentUrl = useSelector(precedentUrlSelector);
	const isLoading = useSelector(isLoadingSelector);
	const loginHasError = useSelector(authHasErrorSelector);

	const [closingDrop, setCLosingDrop] = useState({ id: "", status: false });
	const [activeID, setActiveID] = useState("");
	const [emailState, setEmail] = useState({ value: "", isValid: true });
	const [passwordState, setPassword] = useState({ value: "", isValid: true });

	const { isValid: isEmailValid } = emailState;
	const { isValid: isPasswordValid } = passwordState;

	useEffect(() => {
		if (loginHasError) {
			emailInputRef.current.focus();
		}
	}, [loginHasError]);

	const emailChangeHandler = (event) => {
		setEmail({ value: event.target.value, isValid: emailState.isValid });
	};

	const passwordChangeHandler = (event) => {
		setPassword({ value: event.target.value, isValid: passwordState.isValid });
	};

	const validateEmailHandler = () => {
		const emailRegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,15}$/;
		setEmail({ value: emailState.value, isValid: emailState.value && emailRegExp.test(emailState.value.trim()) });
	};

	const validatePasswordHandler = () => {
		const passWordRegExp = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
		setPassword({
			value: passwordState.value,
			isValid: passwordState.value && passWordRegExp.test(passwordState.value),
		});
	};

	useEffect(() => {
		if (isLoggedIn) {
			dispatch(clear());
			if (precedentUrl) {
				if (precedentUrl.pathname.includes("sign")) {
					navigate("/");
				} else {
					navigate(precedentUrl);
				}
			} else {
				navigate("/");
			}
		}
	}, [isLoggedIn, lngId]);

	/**
	 * log in a la soumission du formulaire si tout est valide si non focus sur l'erreur
	 * @param event
	 */
	const submitHandler = (event) => {
		event.preventDefault();

		if (isEmailValid && isPasswordValid) {
			dispatch(
				login({
					email: emailState.value,
					password: passwordState.value,
					stayConnected: stayConnected.current.checked,
				})
			);
		} else if (!isEmailValid) {
			emailInputRef.current.focus();
		} else {
			passwordInputRef.current.focus();
		}
	};

	const hoverHandler = (id) => {
		if (closingDrop.status === false && closingDrop.id !== id) {
			setActiveID(id);
		}
	};

	const outHandler = () => {
		setActiveID("");
		setTimeout(
			() =>
				setCLosingDrop({
					id: "",
					status: false,
				}),
			300
		);
	};

	const onLocaliseChange = (item) => {
		i18n.changeLanguage(item.children);
	};

	const closeDrop = () => {
		setCLosingDrop({ id: activeID, status: true });
		setActiveID("");
	};

	return (
		<div className={classes.wrapper}>
			<header>
				<div className={classes.innerHeader}>
					<div className={classes.logo}>
						<CustomNavLink to={`/${lngParm}`}>
							<img src={lngId.includes("fr") ? logo_fr : logo_en} alt="logo" />
						</CustomNavLink>
						<div
							id={"8"}
							className={classes.rli}
							onMouseOver={() => {
								hoverHandler("8");
							}}
							onMouseOut={outHandler}
						>
							<p className={activeID === "8" ? classes["active"] : ""}>{lngId}</p>
							<NavDrop
								right={"0"}
								parentId={"8"}
								activeID={activeID}
								height={"9rem"}
								width={"5rem"}
								hide={closeDrop}
								data={[
									{ id: "13", children: "fr-ca" },
									{ id: "14", children: "en-ca" },
									{ id: "15", children: "en-us" },
								]}
								onClick={onLocaliseChange}
							/>
						</div>
					</div>
				</div>
			</header>
			<main>
				<div className={classes.innerMain}>
					<div className={classes.container}>
						<div className={classes.title}>
							<h1>{t(keys.AUTH.SIGN_IN.SIGN_IN_TITLE)}</h1>
						</div>
						<div className={classes.content}>
							<div className={classes.formWrapper}>
								<form onSubmit={submitHandler}>
									<fieldset>
										<legend>{t(keys.AUTH.SIGN_IN.SIGN_IN)}</legend>
										<TextInputOverlay
											ref={emailInputRef}
											id="email"
											label={t(keys.AUTH.SIGN_IN.EMAIL)}
											type="email"
											isValid={isEmailValid}
											value={emailState.value}
											error={t(keys.AUTH.SIGN_IN.WRONG_EMAIL)}
											onChange={emailChangeHandler}
											onBlur={validateEmailHandler}
											disabled={!!isLoading || !!isLoggedIn}
										/>
										<TextInputOverlay
											ref={passwordInputRef}
											id="password"
											label={t(keys.AUTH.SIGN_IN.PASSWORD)}
											type="password"
											isValid={isPasswordValid}
											value={passwordState.value}
											error={t(keys.AUTH.SIGN_IN.WRONG_PASSWORD)}
											onChange={passwordChangeHandler}
											onBlur={validatePasswordHandler}
											disabled={!!isLoading || !!isLoggedIn}
										/>
										{loginHasError && (
											<div className={classes.error}>
												<p>{t(keys.AUTH.SIGN_IN.SIGN_IN_FAILED)}</p>
											</div>
										)}
										<RoundCheckbox
											ref={stayConnected}
											defaultChecked={true}
											id="stayConnected"
											label={t(keys.AUTH.SIGN_UP.STAY_CONNECTED)}
											disabled={!!isLoading || !!isLoggedIn}
										/>
										<div className={classes.signIn}>
											<div className={classes.signIn_btn}>
												<button
													className={classes[!!isLoading || !!isLoggedIn ? "disable" : ""]}
													type="submit"
													disabled={!!isLoading || !!isLoggedIn}
												>
													{t(keys.AUTH.SIGN_IN.SIGN_IN)}
												</button>
												<ul>
													<li>
														<CustomNavLink to={`/sign-in/forgot-password${lngParm}`}>
															{t(keys.AUTH.SIGN_IN.FORGOT_PASSWORD)}
														</CustomNavLink>
													</li>
													<li>
														<CustomNavLink to={`/sign-up${lngParm}`}>
															{t(keys.AUTH.SIGN_IN.SIGN_UP)}
														</CustomNavLink>
													</li>
												</ul>
											</div>
										</div>
									</fieldset>
								</form>
							</div>
						</div>
					</div>
				</div>
			</main>
			<footer>
				<div className={classes.innerFooter}>
					<div className={classes.logoFooter}>
						<img src={lngId.includes("fr") ? nologo_fr : nologo_en} alt="logo" />
					</div>
					<p className={classes.rightFooter}>Copyright © 2023 Adfast. All Rights Reserved.</p>
					<div className={classes.linksFooter}>
						<CustomNavLink to={`/terms`}>{t(keys.AUTH.SIGN_UP.TERMS)}</CustomNavLink>
						<CustomNavLink to={"/contact-us"}>{t(keys.GLOBAL.FOOTER.CONTACT_US)}</CustomNavLink>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default Signin;
