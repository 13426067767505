import { useTranslation } from "react-i18next";

import Button from "../../ui/Button/Button";
import CustomNavLink from "../../ui/customNavLink/CustomNavLink";

import { keys } from "../../../locales/localeskeys";

import classes from "./Newsletter.module.scss";

const Newsletter = () => {
	const { t } = useTranslation();

	return (
		<div className={classes.newsletterContainer}>
			<div className={classes.newsletterHalf}>
				<h3 className={classes.newsletterHeader}>{t(keys.GLOBAL.ADNEWS.HEADER_LEFT)}</h3>
				<p className={classes.newsletterParagraph}>{t(keys.GLOBAL.ADNEWS.CAPTION_LEFT)}</p>
				<Button className={classes.button} id="btn_sub" color="primary" size="big">
					<CustomNavLink className={classes.navLink} to={`/newsletter/`}>
						{t(keys.GLOBAL.ADNEWS.BUTTON)}
					</CustomNavLink>
				</Button>
			</div>
			<div className={classes.newsletterVertical} />
			<hr />
			<div className={classes.newsletterHalf}>
				<h3 className={classes.newsletterHeader}>{t(keys.GLOBAL.ADNEWS.HEADER_RIGHT)}</h3>
				<p className={classes.newsletterParagraph}>{t(keys.GLOBAL.ADNEWS.CAPTION_RIGHT)}</p>
			</div>
		</div>
	);
};

export default Newsletter;
