import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import VariantSideBar from "./variantSidBar/VariantSideBar";
import VariantGrid from "./variantGrid/VariantGrid";
import LinkTree from "../../ui/LinkTree/LinkTree";
import decodeSlash from "../../../hooks/decodeSlash";

import { itemOverView } from "../../../store/product";
import { variantTitleSelector } from "../../../store/variant";

import { keys } from "../../../locales/localeskeys";

import classes from "./Variant.module.scss";

const Variant = () => {
	const { t, i18n } = useTranslation();
	const lngId = i18n.language;

	const params = useParams();
	const { itemid, brandid, specificationid, formatid } = decodeSlash(params);

	const item = useSelector(itemOverView);
	const title = useSelector(variantTitleSelector);

	return (
		<div>
			<LinkTree
				className={classes.Tree}
				tree={[
					{ label: t(keys.GLOBAL.NAVBAR.HOME), to: "/" },
					{ label: "AdStore", to: `/adstore?lng=${lngId}` },
					{
						label: `${item?.title ?? title}`,
						to: `/adstore/overview/${itemid}/${brandid}/?lng=${lngId}`,
					},
					{
						label: "Variants",
						to: `/adstore/variants/${itemid}/${brandid}/${specificationid}/${formatid}/?lng=${lngId}`,
					},
				]}
			/>
			<div className={classes.body}>
				<VariantSideBar />
				<VariantGrid />
			</div>
		</div>
	);
};

export default Variant;
